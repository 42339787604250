<template>
  <div class="">
    <v-row justify="space-between">
      <v-col class="d-flex" cols="12" sm="6">
        <v-btn class="mr-2 theme-color1" dark elevation="0" link
               tile
        >
          <v-icon>mdi-account-tie</v-icon>
        </v-btn
        >
        <h2 class="text-uppercase theme-font-color">{{ name }}</h2>
      </v-col>
      <v-col class="mb-1 d-flex" cols="12" sm="6">
        <v-select v-model="Filter.invoiceStatus" :items="JobFilter"
                  :menu-props="{ bottom: true, offsetY: true }" class="custom-input border-right JobFilter"
                  color="#64718A" dense hide-details
                  item-text="text" item-value="value" outlined style="max-width: 150px" @change="GetAll()"></v-select>

        <v-text-field v-model="Filter.filterText"
                      append-icon="mdi-magnify"
                      class="custom-input"
                      clearable color="#64718A" dense
                      hide-details
                      label="Search"
                      outlined
                      @focus="selectText"
                      @keyup="GetAll"
                      @click:clear="(Filter.filterText = ''), GetAll()"
        ></v-text-field>

      </v-col>
    </v-row>
    <div class="custom-line"></div>
    <v-data-table v-if="Invoices == null" :headers="headers" :items-per-page="Filter.pageSize" disable-sort
                  hide-default-footer></v-data-table>
    <v-data-table v-else :headers="headers" :items="Invoices"
                  :items-per-page="Filter.pageSize"
                  class="custom-table elevation-0"
                  disable-sort hide-default-footer
    >
      <template v-slot:[`item.sno`]="{ item }">
        <router-link :to="{ name: 'InvoiceDetails', params: { item } }" class="ms-0">
          INVOICE # {{ item.jobNo }}
        </router-link>
      </template>
      <template v-slot:[`item.grandTotal`]="{item}">
        {{ FormatPrice(item.grandTotal) }}
      </template>
      <template v-slot:[`item.invoiceCreationDate`]="{item}">
        <span v-if="item.invoiceCreationDate != null">{{ getFormattedDate(item.invoiceCreationDate) }}</span>
      </template>
      <template v-slot:[`item.paidAmount`]="{item}">
        {{ FormatPrice(item.paidAmount) }}
      </template>
      <template v-slot:[`item.discount`]="{item}">
        {{ FormatPrice(item.discount) }}
      </template>
      <template v-slot:[`item.dueAmount`]="{item}">
        {{ FormatPrice(item.dueAmount) }}
      </template>
      <template v-slot:[`item.invoiceStatus`]="{item}">
        <v-chip v-if="item.invoiceStatus == 0" class="type-chip" color="grey" dark label x-small>None</v-chip>
        <v-chip v-if="item.invoiceStatus == 1" class="type-chip" color="primary darknen-2" dark label x-small>Pending
        </v-chip>
        <v-chip v-if="item.invoiceStatus == 2" class="type-chip" color="purple" dark label x-small>Sent</v-chip>
        <v-chip v-if="item.invoiceStatus == 3" class="type-chip" color="red darken-2" dark label x-small>Unpaid</v-chip>
        <v-chip v-if="item.invoiceStatus == 4" class="type-chip" color="warning" dark label x-small>Partial</v-chip>
        <v-chip v-if="item.invoiceStatus == 5" class="type-chip" color="success darken-2" dark label x-small>Paid
        </v-chip>
      </template>
      <template v-slot:[`item.paymentMethod`]="{ item }">
        <span v-if="item.paymentMethod == 0"></span>
        <span v-if="item.paymentMethod == 100">Cash</span>
        <span v-if="item.paymentMethod == 200">Check</span>
        <span v-if="item.paymentMethod == 300">E Transfer</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon color="primary darken-1" dark left offset-x="true"
                small @click="takeData(item) , emailDialog = true">mdi-email-outline
        </v-icon>
        <v-menu class="pa-0" left offset-y>
          <template v-slot:activator="{ attrs, on }">
            <v-icon class="theme-font-color" dark left offset-x="true" small
                    v-bind="attrs" @click="takeData(item)" v-on="on">mdi-dots-vertical
            </v-icon>
          </template>
          <v-list class="pa-0" dense>
            <v-list-item v-if="item.invoiceStatus!== 5" dense link @click="paymentDialog = true">
              <v-list-item-icon class="nav-icon mr-2 ">
                <img src="../../../assets/sidebar/credit-card.svg"/>
              </v-list-item-icon>
              <v-list-item-title>Apply Payment
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="item.invoiceStatus!== 5" dense link
                         @click="paidAmount = CurrentInvoice.dueAmount, paymentDialog = true">
              <v-list-item-icon class="nav-icon mr-2 ">
                <v-icon color="success darken-2" small>mdi-check-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Mark Paid
              </v-list-item-title>
            </v-list-item>
            <v-list-item dense link
                         @click="DownloadPdf()">
              <v-list-item-icon class="nav-icon mr-2 ">
                <img src="../../../assets/pdf.svg"/>
              </v-list-item-icon>
              <v-list-item-title>
                Download
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

      </template>
    </v-data-table>
    <v-row align="center" class="no-gutters mt-2">
      <v-spacer style="width: 75%"></v-spacer>
      <span class="pagination-text">Rows per page</span>
      <v-select
          v-model="Filter.pageSize"
          :items="options"
          :menu-props="{ bottom: true, offsetY: true }"
          class="ma-2"
          dense
          style="width: 50px"
          @change="GetAll"
      ></v-select>
      <span v-if="pagination != null" class="mr-4 grey--text pagination-text">
        Page {{ pagination.page }} of {{ pagination.totalPages }}
      </span>
      <v-icon
          class="mr-1"
          color="primary darken-2"
          dark
          small
          @click="backwardButtonCall"
      >mdi-chevron-left
      </v-icon
      >
      <v-icon
          class="mr-1"
          color="primary darken-2"
          dark
          small
          @click="forwardButtonCall()"
      >mdi-chevron-right
      </v-icon
      >
    </v-row>
    <!-- Dialog Boxes -->
    <!-- Mail dialog -->
    <v-dialog v-if="CurrentInvoice != null" v-model="emailDialog" max-width="400" persistent>
      <v-card>
        <v-card-title class="text-h5"> Send Mail</v-card-title>
        <v-card-text>Are You sure? You want to send email to
          <b class="theme-font-color">{{
              CurrentInvoice.customer.fullName
            }}</b></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text tile @click="emailDialog = false">
            Cancel
          </v-btn>
          <v-btn class="theme-color1" elevation="0" tile
                 @click="(CurrentInvoice.isInvoiceSent = true),SendMail(), (emailDialog = false)">
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Delete dialog -->
    <v-dialog v-model="deleteDialog" max-width="400" persistent>
      <v-card>
        <v-card-title class="text-h5"> Delete Invoice</v-card-title>
        <v-card-text>Are You sure? You want to delete
          <b v-if="CurrentInvoice != null" class="error--text text--darken-2">{{
              CurrentInvoice.title
            }}</b></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text tile @click="deleteDialog = false">
            Cancel
          </v-btn>
          <v-btn color="error darken-2" elevation="0" tile @click="DeleteInvoice(), (deleteDialog = false)">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Payment Dialog -->
    <v-dialog v-model="paymentDialog" max-width="600" persistent>
      <v-card outlined>
        <v-toolbar color="#efefef" dense elevation="0" flat tile>
          <v-btn class="mr-2 theme-color1" dark elevation="0" height="35px" tile @click="paymentDialog = false">
            <v-icon dense>mdi-undo-variant
            </v-icon>
          </v-btn>
          <v-toolbar-title>
            <h4 class="text-uppercase theme-font-color">Receive Payment</h4>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn :disabled="PayBtnStatus || !paidAmount" class="ps-10 pe-10 login-btns theme-color1" dark
                 elevation="0" tile
                 @click="AddorUpdate(), (paymentDialog = false)">Pay
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field v-if="CurrentInvoice != null" v-model="CurrentInvoice.grandTotal"
                              class="custom-input my-input-common-border"
                              color="#64718A "
                              dense disabled hide-details label="Total Ammount"
                              outlined @focus="selectText">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-if="CurrentInvoice != null" v-model="CurrentInvoice.netDiscount"
                              class="custom-input my-input-common-border"
                              color="#64718A "
                              dense disabled hide-details label="Discount"
                              outlined @focus="selectText">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-if="CurrentInvoice != null" v-model="CurrentInvoice.dueAmount"
                              class="custom-input my-input-common-border"
                              color="#64718A "
                              dense disabled hide-details label="Due Amount"
                              outlined @focus="selectText">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-if="CurrentInvoice != null" v-model.number="paidAmount" :rules="[PriceRule]"
                              class="custom-input my-input-common-border"
                              color="#64718A "
                              dense label="Paid Amount" outlined
                              type="number" @focus="selectText">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-select v-if="CurrentInvoice != null"
                          v-model="CurrentInvoice.paymentMethod" :items="PaymentOptions"
                          :menu-props="{ bottom: true, offsetY: true }"
                          class="custom-input " color="#64718A"
                          dense item-text="text" item-value="value" label="Payment Method"
                          outlined></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Progress Bar -->
    <v-progress-circular v-if="loader"
                         class="custom-loader"
                         color="#64718A"
                         indeterminate
                         width="2"
    ></v-progress-circular>
  </div>
</template>
<script>
//  import DatePicker from 'vue2-datepicker';
// import 'vue2-datepicker/index.css';
import axios from "axios";
import config from "../../Configuration/config";
import moment from "moment";
import 'moment-timezone';

export default {
  // components: { DatePicker },
  name: "InvoicesModule",
  data() {
    return {
      name: "Invoice",
      deleteDialog: false, emailDialog: false,
      loader: false,
      paymentDialog: false,
      PayBtnStatus: true,
      paidAmount: 0,
      PaymentOptions: [
        {
          text: "Cash",
          value: 100
        },
        {
          text: "Check",
          value: 200
        },
        {
          text: "E Transfer",
          value: 300
        },
      ],
      headers: [
        {
          text: "#",
          align: "start",
          value: "sno",
          width: '140px'
        },
        {
          text: "Customer",
          value: "customer.fullName"
        },
        {
          text: "Customer Address",
          value: "customer.address"
        },
        {
          text: "Title",
          value: "title",
          sortable: false
        },
        {
          text: "Date",
          value: "invoiceCreationDate",
          sortable: false,
          align: 'center',

        },
        {
          text: "Price",
          value: "grandTotal",
          sortable: false,
          width: "70px",
          align: 'right'
        },
        {
          text: "Paid",
          value: "paidAmount",
          sortable: false,
          width: "90px",
          align: 'right'
        },
        {
          text: "Payment Method",
          value: "paymentMethod",
          sortable: false,
          align: 'center'
        },
        {
          text: "Due Amount",
          value: "dueAmount",
          sortable: false,
          width: "120px",
          align: 'right'
        },
        {
          text: "Status",
          value: "invoiceStatus",
          sortable: false,
          align: 'center',
          width: '80px'
        },

        {text: "Actions", value: "actions", width: "80px", sortable: false, align: 'right'},
      ],
      Invoices: [],
      options: [
        {text: "10", value: 10},
        {text: "25", value: 25},
        {text: "50", value: 50},
        {text: "100", value: 100},
      ],
      //Employees: null,
      CurrentInvoice: null,
      pagination: null,
      pageNumOnSearch: "",
      totalRecords: "",
      JobFilter: [
        {
          text: "All",
          value: 0
        },
        {
          text: "UnPaid",
          value: 1,
        },
        {
          text: "Paid",
          value: 2,
        },
      ],
      Filter: {
        CompanyId: axios.defaults.headers.common.CompanyId,
        pageSize: 10,
        page: 1,
        filterText: "",
        isActive: true,
        jobType: 130,
        invoiceStatus: 0
      },
      decimals: 2
    };
  },
  methods: {
    selectText(event) {
      event.target.select();
    },
    PriceRule: function () {
      if ((this.paidAmount <= this.CurrentInvoice.dueAmount && this.paidAmount >= 0)) {
        this.PayBtnStatus = false;
        return true;
      } else {
        this.PayBtnStatus = true;
        return "Paid amount cannot be greater than due amount or less than 0";
      }
    },
    FormatPrice(price) {
      return Math.round(price * 10 ** this.decimals) / 10 ** this.decimals
    },
    getFormattedDate(date) {
      /* const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get the user's timezone
       return moment.utc(date).tz(userTimezone).format('MMM DD, yyyy');*/
      return moment(date).format("MMM DD, YYYY");
    },
    async DownloadPdf() {
      try {
        this.loader = true;
        const fd = new FormData();
        fd.append("blobName", this.CurrentInvoice.invoicePdf);
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Job/DownloadPdf",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        const link = document.createElement('a');
        link.href = response.data;
        link.setAttribute('download', 'INVOICE_' + this.CurrentInvoice.jobNo + '.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.loader = false;
      } catch (error) {
        this.loader = false;
        this.$toast.error(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        })
      }
    },
    takeData(invoice) {
      this.CurrentInvoice = invoice;
      this.paidAmount = 0;

    },
    async GetAll() {
      if (this.$route.name === "InvoicesModule") {
        try {
          this.loader = true;
          let response = await axios({
            method: "POST",
            url: config.apiBaseUrl + "/api/Job/All",
            data: this.Filter,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          });
          this.Invoices = response.data.data;
          this.pagination = response.data.pagination
          this.loader = false;
        } catch (error) {
          console.log(error);
          this.loader = false;
        }
      }
    },
    async AddorUpdate() {
      try {
        this.loader = true;
        let response = await axios({
          method: "POST",
          url: config.apiBaseUrl + "/api/Job/MakePayment",
          data: {
            "jobId": this.CurrentInvoice.jobId,
            "paidAmount": this.paidAmount,
            "paymentMethod": this.CurrentInvoice.paymentMethod,
          },
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        this.paidAmount = 0;
        console.log(response);
        await this.GetAll();
      } catch (err) {
        this.loader = false;
        console.log(err);
      }
    },
    async SendMail() {
      try {
        this.loader = true;
        const fd = new FormData();
        fd.append("jobId", this.CurrentInvoice.jobId);
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Job/SendMail?jobType=" + 130,
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        this.GetAll();
        this.loader = false;
        console.log(response)
        if (response.status == 200) {
          this.loader = false;
          this.$toast.success("Mail sent successfully", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnfocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
          })
        }
        this.loader = false;
      } catch (error) {
        console.log(error);
        this.loader = false;
        this.$toast.error("Something went wrong!", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        })
      }
    },
    async DeleteInvoice() {
      try {
        this.loader = true;
        this.CurrentInvoice.isInvoice = false;
        this.CurrentInvoice.jobType = 120;
        let response = await axios({
          method: "POST",
          url: config.apiBaseUrl + "/api/Job/AddOrUpdate",
          data: this.CurrentInvoice,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        console.log(response);
        this.loader = false;
        this.GetAll();
      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },
    forwardButtonCall() {
      //it does not run on equal to condition
      if (this.Filter.page < this.pagination.totalPages) {
        this.Filter.page++;
        this.GetAll();
      } else {
        return;
      }
    },
    backwardButtonCall() {
      if (this.Filter.page > 1) {
        this.Filter.page--;
        this.GetAll();
      } else {
        return;
      }
    },
  },
  computed: {},

  created() {
    this.$store.state.DisableCompany = false;
    this.GetAll();
    this.$root.$on("myEvent", () => {
      this.Filter.page = 1;
      this.Filter.filterText = "";
      this.GetAll();
    });
  },
};
</script>
<style lang="scss" scoped>
// .v-application  a{
//   text-decoration: none;
// }
.chip {
  width: 50px;
  display: flex;
  justify-content: center;
}

.nav-icon img {
  width: 16px !important;
}

.type-chip {
  width: 50px !important;
  justify-content: center !important;
}
</style>