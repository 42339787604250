<template>
  <div>
    <div>
      <v-form ref="form" v-model="valid" class="mt-3" lazy-validation>
        <v-row class="ps-8 pe-8" justify="center">
          <v-col cols="12" md="12">
            <v-card elevation="0" outlined tile>
              <v-toolbar color="#efefef" dense elevation="0" flat tile>
                <v-btn class="mr-2 theme-color1" dark elevation="0" height="35px" link tile to="/customers">
                  <v-icon
                      dense>mdi-undo-variant
                  </v-icon>
                </v-btn>
                <v-toolbar-title>
                  <h4 class="text-uppercase theme-font-color">{{ name }}</h4>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-if="!editview" class="ps-10 pe-10 login-btns theme-color1" dark elevation="0" tile
                       @click="editview = !editview"
                >Edit
                </v-btn>
                <v-btn v-if="editview" :disabled="!validateform" class="ps-10 pe-10 login-btns theme-color1" dark
                       elevation="0"
                       tile
                       @click="AddorUpdate()">Save
                </v-btn>
              </v-toolbar>
              <v-row class="ma-0">
                <v-col cols="12" md="7">
                  <v-card-text>
                    <v-row class="pa-md-3">
                      <v-col class="pa-md-0" cols="12" md="6">
                        <v-text-field v-model="Customer.firstName" :disabled="!editview" :rules="[rules.required]"
                                      class="custom-input border-right" color="#64718A  "
                                      dense hide-details
                                      label="First Name"
                                      outlined required>
                        </v-text-field>
                      </v-col>
                      <v-col class="pa-md-0" cols="12" md="6">
                        <v-text-field v-model="Customer.lastName" :disabled="!editview" :rules="[rules.required]"
                                      class="custom-input bordered " color="#64718A  "
                                      dense hide-details label="Last Name"
                                      outlined required>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="pa-md-3">
                      <v-col class="pa-md-0 d-flex align-center" cols="12">
                        <div>
                          <v-switch v-model="Switch" :disabled="!editview" class="custom-switch"
                                    color="success darken-2"
                                    inset
                                    @change="ChangeView(Switch)"></v-switch>
                        </div>
                        <div>
                          <label class="font-weight-bold d-block grey--text text--darken-2">Wholesale</label>
                          <label class="grey--text">Click to select wholesale type</label>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row class="pa-md-3 pt-md-0">
                      <!--                      <v-col cols="12" md="6" class="pa-md-0">
                                              <v-text-field v-model="Customer.notes" label="Notes" required dense outlined hide-details
                                                            class="custom-input border-right" color="#64718A  ">
                                              </v-text-field>
                                            </v-col>-->

                      <v-col class="pa-md-0" cols="12" md="4">
                        <v-autocomplete v-model="Customer.customerSubType" :disabled="!editview || !Switch"
                                        :items="customersubtypes" :menu-props="{ bottom: true, offsetY: true }"
                                        :return-object="false"
                                        class="custom-input bordered"
                                        color="#64718A " dense
                                        hide-details item-text="value" item-value="value"
                                        label="Customer Type"
                                        outlined></v-autocomplete>
                      </v-col>
                      <v-col class="pa-md-0" cols="12" md="4">
                        <v-text-field v-model="Customer.taskId" :disabled="!taskview && !Switch"
                                      class="custom-input border-right"
                                      color="#64718A  " dense hide-details
                                      label="Task Id"
                                      outlined required>
                        </v-text-field>
                      </v-col>
                      <v-col class="pa-md-0" cols="12" md="4">
                        <v-text-field v-model="Customer.swo" :disabled="!taskview && !Switch"
                                      class="custom-input bordered " color="#64718A  "
                                      dense hide-details label="SWO #"
                                      outlined required>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="pa-md-3 mt-md-5">
                      <v-col class="pa-md-0" cols="12">
                        <v-textarea v-model="Customer.notes" :disabled="!editview" auto-grow
                                    class="rounded-0 my-input-common-border"
                                    color="#64718A" hide-details
                                    label="Notes" outlined rows="3"></v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-col>
                <v-col class="personal-info" cols="12" md="5">
                  <v-card-text>
                    <v-row class="pa-0">
                      <v-col class="pa-0 ps-3 pe-3">
                        <v-text-field v-model="Customer.address" :disabled="!editview" class="custom-input my-input2"
                                      color="#64718A  "
                                      dense hide-details label="Address"
                                      outlined required>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="pa-3 pb-0 pt-0">
                      <v-col class="pa-0" cols="12" md="6">
                        <v-text-field v-model="Customer.city" :disabled="!editview" class="custom-input my-input"
                                      color="#64718A  " dense hide-details
                                      label="City"
                                      outlined required>
                        </v-text-field>
                      </v-col>
                      <v-col class="pa-0" cols="12" md="6">
                        <v-text-field v-model="Customer.province" :disabled="!editview" class="custom-input my-input2"
                                      color="#64718A  " dense
                                      hide-details label="Province"
                                      outlined required>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="pa-0 ps-3 pe-3">
                      <v-col class="pa-0" cols="12" md="6">
                        <v-text-field v-model="Customer.postalCode" :disabled="!editview"
                                      class="my-input-common-border custom-input " color="#64718A  "
                                      dense
                                      hide-details label="Postal Code" outlined
                                      required>
                        </v-text-field>
                      </v-col>
                      <v-col class="pa-0" cols="12" md="6">
                        <v-autocomplete v-model="Customer.country" :disabled="!editview" :items="Countries"
                                        :menu-props="{ bottom: true, offsetY: true }" :return-object="false"
                                        class="custom-input my-input-common-border" color="#64718A"
                                        dense hide-details item-text="name.common"
                                        item-value="name.common" label="Country"
                                        outlined></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row class="pa-0 ps-3 pe-3">
                      <v-col class="pa-0 pt-md-5">
                        <v-text-field v-model="Customer.phoneNumber" v-mask="['###-###-####']" :disabled="!editview"
                                      :rules="[rules.required]" class="custom-input my-input-common-border"
                                      color="#64718A  "
                                      dense hide-details
                                      label="Phone" outlined prefix="+1"
                                      required type="" @focus="selectText">
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="pa-0 ps-3 pe-3">
                      <v-col class="pa-0">
                        <v-text-field v-model="Customer.email" :disabled="!editview" :rules="[rules.required]"
                                      class="custom-input my-input-common-border" color="#64718A  " dense hide-details
                                      label="Email"
                                      outlined required type="email">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <!-- Progress Bar -->
    <v-progress-circular v-if="loader" class="custom-loader" color="#64718A " indeterminate
                         width="2"></v-progress-circular>
  </div>
</template>
<script>
import moment from 'moment';
import 'moment-timezone';
import axios from "axios";
import config from "../Configuration/config";

export default {
  name: "AddCustomer",
  data() {
    return {
      name: "Add Customer",
      valid: true,
      Switch: false,
      taskview: false,
      editview: true,
      btnText: "Add Customer",
      loader: false,
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
      Countries: [],
      customersubtypes: [],
      //Model for Customer
      Customer: {
        companyId: axios.defaults.headers.common.CompanyId,
        customerId: 0,
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        address: "",
        city: "",
        province: "",
        postalCode: "",
        country: "",
        notes: "",
        customerCategory: 100,
        customerSubType: "",
        taskId: "",
        swo: "",
      },
    };
  },
  mounted() {
    this.$refs.form.validate();
  },
  computed: {

    validateform() {
      if (this.Customer.firstName == "" || this.Customer.lastName == "") {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    selectText(event) {
      event.target.select();
    },
    formatphoneNumberNumber() {
      this.Customer.phoneNumber = this.Customer.phoneNumber.replace(/[^0-9]/g, '')
          .replace(/^(\d{3})(\d{3})(\d{4})/g, '$1-$2-$3').substring(0, 12);
    },
    ChangeView(type) {
      if (!type) {
        this.Customer.customerCategory = 100
        this.Customer.swo = "";
        this.Customer.taskId = "";
        this.Customer.customerSubType = "";
      } else {
        this.Customer.customerCategory = 120;
        this.Customer.customerSubType = this.customersubtypes[0].value;
      }

    },
    validate() {
      if (this.Customer == null) {
        this.$refs.form.validate();
      }
    },
    getFormattedDate(date) {
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get the user's timezone
      return moment.utc(date).tz(userTimezone).format('MMM DD, yyyy');
      //return moment(date).format("MMM DD, YYYY");
    },
    async GetCountries() {
      let response = await axios.get("https://restcountries.com/v3.1/all");
      this.Countries = response.data;
      var canada = this.Countries.filter(x => x.name.common == "Canada");
      this.Customer.country = canada[0].name.common;

    },
    async GetCustomerType() {
      try {
        this.loader = true;
        const fd = new FormData();
        fd.append("type", 30);
        let response = await axios.get(config.apiBaseUrl + "/api/KeyValue/All");
        this.customersubtypes = response.data.filter((x) => x.type == 30);
        this.loader = false;
      } catch (error) {
        console.log(error);
        this.loader = false;
      }
    },
    async AddorUpdate() {
      try {
        switch (this.Switch) {
          case true:
            this.Customer.customerCategory = 110;
            break;
          case false:
            this.Customer.customerCategory = 100;
            break;
        }
        if (this.Customer.phoneNumber !== "") {
          if (this.Customer.phoneNumber !== null) {
            this.Customer.phoneNumber = this.Customer.phoneNumber.replace(/-/g, "");
          }
        }
        this.loader = true;
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Customer/AddOrUpdate",
          data: this.Customer,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        console.log(response);
        this.loader = false;
        if (
            response.data.isSuccessful == true &&
            response.data.msg == "Customer already exists with the same email!"
        ) {
          this.$toast.warning("Customer already exists with the same data!", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnfocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
          })
          ;
        } else {
          setTimeout(this.$router.push("/customers"), 3000);
        }
      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        })
        ;
      }
    },
    getCustomer() {
      if (this.$route.params.item != undefined) {
        this.Customer = this.$route.params.item;
        this.name = this.Customer.fullName;
        this.btnText = "Update Customer"
        if (this.Customer.customerCategory === 110) {
          this.Switch = true;
        }
      }
    }
  },
  // mounted() {
  //   this.$refs.form.validate();
  // },
  created() {
    this.$store.state.DisableCompany = true;
    if (this.$route.params.editview != undefined) {
      this.editview = false;
    }
    this.GetCountries();
    this.GetCustomerType();
    setTimeout(() => {
      this.getCustomer();
    }, 100);
    this.$root.$on("myEvent", () => {
      this.GetCustomerType();
      this.getCustomer();
    });
  },
};
</script>
<style scoped>
.v-text-field fieldset,
.v-text-field .v-input__control {
  border: 0px !important;
}

.datepicker {
  margin: 0;
  background: #fff !important;
  height: 48px !important;
  padding-left: 8px !important;
  border-radius: 3px;
}
</style>