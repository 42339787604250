<template>
  <div>
    <v-app-bar
      app
      class="flex-grow-0 theme-color1"
      clipped-left
      dark
      elevation="0"
      height="45"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-app-bar-title class="app-title">
        <h1>Tracking Buddy</h1>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-select
        v-if="Role == 10 || Role == 15"
        v-model="SelectedCompany"
        :disabled="this.$store.state.DisableCompany"
        :items="this.$store.state.companies"
        :menu-props="{ bottom: true, offsetY: true }"
        append-icon="mdi-chevron-down"
        background-color="#7c89a1"
        class="mr-3 d-flex"
        color="#fff"
        dark
        dense
        flat
        hide-details
        item-text="companyName"
        return-object
        solo
        @change="ChangeComapny"
      ></v-select>
      <span v-if="Profile != null" class="me-2">{{ Profile.fullName }}</span>
      <v-icon>mdi-account</v-icon>
      <v-menu left min-width="100px" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="mt-2" small v-bind="attrs" v-on="on"
            >mdi-chevron-down</v-icon
          >
        </template>
        <v-list class="notification-list text-center">
          <v-list-item link @click="dialog = true">
            <v-icon class="mr-2">mdi-account</v-icon>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="logout()">
            <v-icon class="mr-2">mdi-logout</v-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      color="#efefef"
      width="180"
      class=""
    >
      <v-list class="pa-0" dense>
        <v-list-item-group active-class="">
          <v-list-item v-if="Role == 10" link to="/dashboard">
            <v-list-item-icon class="nav-icon">
              <img src="../../assets/sidebar/apps.svg" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="sidebar-label"
                >Dashboard</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item v-if="Role == 10" link to="/employees">
            <v-list-item-icon class="nav-icon">
              <img src="../../assets/sidebar/user-add.svg" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="sidebar-label"
                >Employees</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="Role == 10 || Role == 15" link to="/customers">
            <v-list-item-icon class="nav-icon">
              <img src="../../assets/sidebar/users-alt.svg" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="sidebar-label"
                >Customers</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            v-if="Role == 10 || Role == 15"
            link
            to="/leads"
            @click="$store.state.jobType == 100"
          >
            <v-list-item-icon class="nav-icon">
              <img src="../../assets/sidebar/business-time.svg" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="sidebar-label">Leads</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="Role == 10 || Role == 15"
            link
            to="/qoutations"
            @click="$store.state.jobType == 110"
          >
            <v-list-item-icon class="nav-icon">
              <img src="../../assets/sidebar/file-invoice-dollar.svg" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="sidebar-label"
                >Quotes</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="Role == 10 || Role == 15"
            link
            to="/jobs"
            @click="$store.state.jobType == 120"
          >
            <v-list-item-icon class="nav-icon">
              <img src="../../assets/sidebar/briefcase.svg" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="sidebar-label">Jobs</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="Role == 10 || Role == 15"
            link
            to="/invoices"
            @click="$store.state.jobType == 130"
          >
            <v-list-item-icon class="nav-icon">
              <img src="../../assets/sidebar/credit-card.svg" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="sidebar-label"
                >Invoices</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>
          <v-list-item v-if="Role == 10 || Role == 15" link to="/expenses">
            <v-list-item-icon class="nav-icon">
              <img src="../../assets/sidebar/donate.svg" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="sidebar-label"
                >Overheads</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="Role == 10 || Role == 15" link to="/alerts">
            <v-list-item-icon class="nav-icon">
              <img src="../../assets/sidebar/cowbell.svg" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="sidebar-label"
                >Alerts</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="Role == 10 || Role == 15" link to="/services">
            <v-list-item-icon class="nav-icon">
              <img src="../../assets/sidebar/car-mechanic.svg" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="sidebar-label"
                >Services</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="Role == 10 || Role == 15" link to="/equipments">
            <v-list-item-icon class="nav-icon">
              <img src="../../assets/sidebar/equipments.svg" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="sidebar-label"
                >Equipments</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <!--          <v-list-item v-if="Role == 10 || Role == 15" link to="/inventory">-->
          <!--            <v-list-item-icon class="nav-icon">-->
          <!--              <img src="../../assets/sidebar/layers.svg" />-->
          <!--            </v-list-item-icon>-->
          <!--            <v-list-item-content>-->
          <!--              <v-list-item-title class="sidebar-label">Inventory</v-list-item-title>-->
          <!--            </v-list-item-content>-->
          <!--          </v-list-item>-->
          <v-list-item v-if="Role == 10 || Role == 15" link to="/settings">
            <v-list-item-icon class="nav-icon">
              <img src="../../assets/sidebar/api.svg" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="sidebar-label"
                >Settings</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="Role == 10" link to="/reports">
            <v-list-item-icon class="nav-icon">
              <img src="../../assets/sidebar/report.svg" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="sidebar-label"
                >Reports</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!-- User -->
    <v-dialog v-model="dialog" max-width="500px" persistent>
      <v-card class="rounded-0">
        <v-card-title>
          <span class="text-h5">Profile</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12" sm="6">
                <v-text-field
                  v-if="Profile != null"
                  v-model="Profile.fullName"
                  class="custom-input"
                  color="#64718A"
                  dense
                  disabled
                  hide-details
                  label="FullName*"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-if="Profile != null"
                  v-model="Profile.username"
                  class="custom-input"
                  color="#64718A"
                  dense
                  disabled
                  hide-details
                  label="Username"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12 pb-0">
                <v-text-field
                  v-if="Profile != null"
                  v-model="password"
                  :rules="[matchingCurrentPassword]"
                  class="custom-input"
                  color="#64718A"
                  dense
                  label="Current Password"
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col cols="12 pt-0">
                <v-text-field
                  v-if="Profile != null"
                  v-model="Profile.newPassword"
                  class="custom-input"
                  color="#64718A"
                  dense
                  hide-details
                  label="New Password"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-if="Profile != null"
                  v-model="reTypePassword"
                  :rules="[matchingPasswords]"
                  class="custom-input"
                  color="#64718A"
                  dense
                  label="Retype Password"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="ms-3 mr-4">
          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-1"
            dark
            elevation="0"
            tile
            width="100"
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            :disabled="
              (!Profile.newPassword && !reTypePassword) ||
              Profile.newPassword != reTypePassword ||
              Profile.password != password
            "
            color="#64718A"
            dark
            elevation="0"
            tile
            width="100"
            @click="(dialog = false), updateUser()"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-progress-circular
      v-if="loader"
      class="custom-loader"
      color="primary darken-2"
      indeterminate
      width="2"
    ></v-progress-circular>
  </div>
</template>
<script>
import axios from "axios";
import config from "../Configuration/config.js";

export default {
  name: "App",
  data() {
    return {
      loader: false,
      drawer: true,
      SelectedCompany: {},
      Companies: [],
      Profile: {
        fullName: "",
        username: "",
        password: "",
        newPassword: "",
      },
      password: "",
      reTypePassword: "",
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      dialog: false,
      Role: 0,
    };
  },
  methods: {
    matchingCurrentPassword: function () {
      if (this.password === this.Profile.password) {
        return true;
      } else {
        return "Current password not correct";
      }
    },
    matchingPasswords: function () {
      if (this.Profile.newPassword === this.reTypePassword) {
        return true;
      } else {
        return "Passwords does not match.";
      }
    },
    async GetAll() {
      try {
        const fd = new FormData();
        var username = sessionStorage.getItem("userName");
        fd.append("username", username);
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/User/Profile",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            AccessControlAllowOrigin: 'htpp"://localhost"8080',
          },
        });
        this.Profile = response.data;
      } catch (error) {
        console.log(error);
        this.$store.state.LoggedIn = false;
      }
    },
    async GetBranches() {
      try {
        let response = await axios.get(
          config.apiBaseUrl + "/api/Auth/GetBranches"
        );
        this.$store.state.companies = response.data;
        this.SelectedCompany = this.$store.state.companies[0];
      } catch (error) {
        console.log(error);
      }
    },
    async updateUser() {
      try {
        this.loader = true;
        await axios
          .post(config.apiBaseUrl + "/api/User/Update", {
            username: this.Profile.username,
            fullName: this.Profile.fullName,
            password: this.Profile.password,
            newPassword: this.Profile.newPassword,
          })
          .then(() => {
            this.$toast.success("Profile updated successfully.", {
              position: "top-center",
              timeout: 2000,
              closeOnClick: true,
              pauseOnfocusLoss: false,
              pauseOnHover: false,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
            this.loader = false;
          });
      } catch (err) {
        this.loader = false;
        this.$toast.warning("Something went wrong!", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
    logout() {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("userId");
      sessionStorage.removeItem("isLoggedin");
      sessionStorage.removeItem("role");
      axios.get(config.apiBaseUrl + "/api/User/Logout").then((res) => {
        console.log(res);
        (axios.defaults.headers.common["Authorization"] = null),
          (this.$store.state.LoggedIn = false);

        this.$router.push("/");
      });
    },
    ChangeComapny() {
      this.$store.state.SelectedCompany = this.SelectedCompany.companyId;
      axios.defaults.headers.common["CompanyId"] =
        this.SelectedCompany.companyId;
      sessionStorage.removeItem("CompanyId");
      sessionStorage.setItem("CompanyId", this.SelectedCompany.companyId);
      sessionStorage.removeItem("Company");
      sessionStorage.setItem("Company", this.SelectedCompany.companyName);
      sessionStorage.removeItem("CompanyAddress");
      sessionStorage.setItem("CompanyAddress", this.SelectedCompany.address);
      sessionStorage.removeItem("CompanyTel");
      sessionStorage.setItem(
        "CompanyTel",
        "+1" + this.SelectedCompany.address.phoneNumber
      );
      sessionStorage.removeItem("CompanyAddress2");
      sessionStorage.setItem(
        "CompanyAddress2",
        this.SelectedCompany.city +
          ", " +
          this.SelectedCompany.postalCode +
          " " +
          this.SelectedCompany.country
      );
      sessionStorage.removeItem("HST");
      sessionStorage.setItem("HST", this.SelectedCompany.hst);
      this.$root.$emit("myEvent");
    },
  },
  created() {
    this.GetAll();
  },
  beforeMount() {
    this.GetBranches();
    this.Role = sessionStorage.getItem("role");
  },
};
</script>
<style scoped>
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 15px;
}

.icon-color {
  color: #757575;
}

.custom-class {
  color: White;
  border-left: 5px solid rgb(7, 7, 85);
}

.v-select.v-select__selections {
  display: flex !important;
  flex-direction: column !important;
}

.v-select.v-input--dense .v-select__selection--comma {
  order: 2 !important;
}

.sidebar-label {
  /*font-size: 0.775rem !important;*/
  font-weight: 400 !important;
  text-transform: uppercase !important;
}

.notification-list {
  cursor: pointer;
  padding: 0 !important;
}

.notification-list .v-list-item {
  min-height: 40px !important;
}

.v-list {
  border-radius: 0 10px 10px 0px !important;
}

.v-select {
  max-width: 400px;
  cursor: pointer !important;
}

.sub-title {
  font-size: 16px !important;
  font-weight: bold !important;
  margin-left: 15px;
}

.app-title h1 {
  font-weight: 500;
  font-size: 1rem !important;
  text-transform: uppercase !important;
}

.nav-icon img {
  width: 18px !important;
}
</style>
