<template>
  <div class="">
    <v-row justify="space-between">
      <v-col class="d-flex" cols="12" sm="6">
        <v-btn class="mr-2 theme-color1" dark elevation="0" tile>
          <v-icon>mdi-account-tie</v-icon>
        </v-btn>
        <h2 class="text-uppercase theme-font-color">{{ name }}</h2>
      </v-col>
      <v-col class="mb-1 d-flex" cols="12" sm="5">
        <v-text-field v-model="Filter.filterText" append-icon="mdi-magnify" class="custom-input" clearable
                      color="#64718A" dense
                      hide-details label="Search" outlined
                      v-on:keyup="AllMethod" @click:clear="(Filter.filterText = ''), GetAll()"
                      @click:append="GetAll"></v-text-field>

        <v-btn class="ms-2 custom-btn theme-color1" dark elevation="0" height="40" link tile to="/addworker">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <div class="custom-line"></div>
    <v-data-table v-if="Employees == null" :headers="headers" class="custom-table" disable-sort
                  hide-default-header></v-data-table>
    <v-data-table v-else :headers="headers" :items="Employees" :items-per-page="Filter.pageSize" class="custom-table"
                  disable-sort
                  hide-default-footer>
      <template v-slot:[`item.email`]="{ item }">
        <router-link :to="{ name: 'AddWorker', params: { item , editView } }" class="ms-0"
                     style="text-decoration: underline">
          {{ item.email }}
        </router-link>
      </template>
      <template v-slot:[`item.originalHireDate`]="{ item }">
        <span>{{ getFormattedDate(item.originalHireDate) }}</span>
      </template>
      <template v-slot:[`item.phoneNumber`]="{ item }">
        <span v-if="item.phoneNumber !== ''">+1 {{ item.phoneNumber }}</span>
      </template>
      <template v-slot:[`item.user.role`]="{ item }">
        <span v-if="item.user.role === 10">Admin</span>
        <span v-if="item.user.role === 15">Office Admin</span>
        <span v-if="item.user.role === 20">Employee</span>
      </template>
      <template v-slot:[`item.isActive`]="{ item }">
        <v-chip v-if="item.isActive" color="success" label x-small>Active</v-chip>
        <v-chip v-else class="pe-2 ps-2" color="grey darken-1" dark label x-small>Inactive</v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon color="red" small @click="takeData(item), (deleteDialog = !deleteDialog)">mdi-trash-can-outline</v-icon>
        <router-link :to="{ name: 'AddWorker', params: { item } }" class="ma-0">
          <v-icon class="ms-2" color="primary darken-2" small @click="takeData(item)">mdi-pencil</v-icon>
        </router-link>
        <v-icon v-if="item.isActive == true" class="ms-1" color="success" small @click="takeData(item),
          (modelTitle = 'Edit Worker'),
          (activateDialog = !activateDialog)
        ">mdi-checkbox-marked
        </v-icon>
        <v-icon v-else class="ms-1" color="gray" small @click="takeData(item),(modelTitle = 'Edit Worker'),
          (activateDialog = !activateDialog)
        ">mdi-checkbox-marked
        </v-icon>
        <!-- <v-icon small @click="takeData(item), (revokeDialog = !revokeDialog)" color="warning"
          class="ms-1">mdi-cancel</v-icon> -->
      </template>
    </v-data-table>
    <v-row align="center" class="no-gutters mt-2">
      <v-spacer style="width: 75%"></v-spacer>
      <span class="pagination-text">Rows per page</span>
      <v-select v-model="Filter.pageSize" :items="options" :menu-props="{ bottom: true, offsetY: true }" class="ma-2"
                dense style="width: 50px"
                @change="GetAll"></v-select>
      <span v-if="pagination != null" class="mr-4 grey--text pagination-text">
        Page {{ pagination.page }} of {{ pagination.totalPages }}
      </span>
      <v-icon class="mr-1" color="primary darken-2" dark small @click="backwardButtonCall">mdi-chevron-left</v-icon>
      <v-icon class="mr-1" color="primary darken-2" dark small @click="forwardButtonCall()">mdi-chevron-right</v-icon>
    </v-row>

    <!-- Delete Dialog -->
    <v-dialog v-model="deleteDialog" max-width="400" persistent>
      <v-card>
        <v-card-title class="text-h5"> Delete</v-card-title>
        <v-card-text>Are You sure? You want to delete
          <b v-if="CurrentWorker != null" class="error--text text--darken-2">{{
              CurrentWorker.fullName
            }}</b></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" elevation="0" text tile @click="deleteDialog = false">
            Cancel
          </v-btn>
          <v-btn color="error darken-2" elevation="0" tile @click="DeleteWorker(), (deleteDialog = false)">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Activate Dialog -->
    <v-dialog v-model="activateDialog" max-width="500" persistent>
      <v-card>
        <v-card-title v-if="CurrentWorker != null && CurrentWorker.isActive == false" class="text-h5"> Activate
        </v-card-title>
        <v-card-title v-else class="text-h5"> Deactivate</v-card-title>
        <v-card-text>Are You sure? You want to <span v-if="CurrentWorker != null && CurrentWorker.isActive == false">activate </span><span
            v-else>deactivate </span>
          <b v-if="CurrentWorker != null" class="success--text text--darken-2">{{
              CurrentWorker.fullName
            }}</b></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" dark elevation="0" tile @click="activateDialog = false">
            Cancel
          </v-btn>
          <v-btn v-if="CurrentWorker != null && CurrentWorker.isActive == false" color="success darken-2" elevation="0"
                 tile @click="ActivateWorker(1), (activateDialog = false)">
            Activate
          </v-btn>
          <v-btn v-else color="gray darken-2" dark elevation="0" tile
                 @click="ActivateWorker(0), (activateDialog = false)">
            Deactivate
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Revoke Dialog -->
    <v-dialog v-model="revokeDialog" max-width="400" persistent>
      <v-card>
        <v-card-title class="text-h5"> Revoke Worker</v-card-title>
        <v-card-text>Are You sure? You want to revoke
          <b v-if="CurrentWorker != null" class="warning--text text--darken-2">{{
              CurrentWorker.fullName
            }}</b></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success darken-1" text @click="revokeDialog = false">
            Cancel
          </v-btn>
          <v-btn color="warning darken-2" @click="RevokeWorker(), (revokeDialog = false)">
            Revoke
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Progress Bar -->
    <v-progress-circular v-if="loader" class="custom-loader" color="#64718A" indeterminate
                         width="2"></v-progress-circular>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import config from "../Configuration/config";

export default {
  name: "WorkersModule",
  data() {
    return {
      name: "Employees",
      deleteDialog: false,
      activateDialog: false,
      revokeDialog: false,
      loader: false,
      editView: false,
      headers: [
        {
          text: "User ID",
          value: "email",
          sortable: false,
        },
        {
          text: "Full Name",
          value: "fullName",
        },
        {
          text: "Phone",
          value: "phoneNumber",
        },

        {
          text: "City",
          value: "city",
          sortable: false,
        },
        {
          text: "Address",
          value: "address",
          sortable: false,
        },
        {
          text: "Role",
          value: "user.role",
          sortable: false,
        },
        {
          text: "Status",
          value: "isActive",
          align: "center",
          sortable: false,
        },
        {
          text: "Actions",
          align: "center",
          value: "actions",
          width: "100px",
          sortable: false,
        },

      ],
      Employees: [],
      options: [
        {text: "10", value: 10},
        {text: "25", value: 25},
        {text: "50", value: 50},
        {text: "100", value: 100},
      ],
      //Employees: null,
      CurrentWorker: null,
      pagination: null,
      Filter: {
        page: 1,
        pageSize: 10,
        filterText: "",
        totalRecords: 0,
        totalPages: 0,
      }
    };
  },
  methods: {
    takeData(worker) {
      this.CurrentWorker = worker;
    },
    getFormattedDate(date) {
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get the user's timezone
      return moment.utc(date).tz(userTimezone).format('MMM DD, yyyy');
      //return moment(date).format("MMM DD, YYYY");
    },
    async GetAll() {
      if (this.$route.name == "WorkersModule") {
        try {

          this.loader = true;
          let response = await axios({
            method: "POST",
            url: config.apiBaseUrl + "/api/Employee/All",
            data: this.Filter,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          });
          this.Employees = response.data.data;
          this.pagination = response.data.pagination;
          this.loader = false
        } catch (err) {
          console.log(err);
          this.loader = false;
          this.$toast.warning("Something went wrong!.", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnfocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false
          })
          ;
        }
      }
    },
    async DeleteWorker() {
      try {
        this.loader = true;
        const fd = new FormData();
        fd.append("empId", this.CurrentWorker.employeId);
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Employee/Remove",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        this.loader = false;
        this.GetAll();
        console.log(response);
        if (response.data.isSuccessful == false) {
          this.$toast.warning("Employee assigned in job or invoice can't be removed", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnfocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false
          })
        }
      } catch (error) {
        this.loader = false;
        this.$toast.warning("Something went wrong!.", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
        console.log(error);
      }
    },
    async ActivateWorker(type) {
      try {
        var isActive = false;
        switch (type) {
          case 0:
            isActive = false;
            break;
          case 1:
            isActive = true;
            break;
        }
        this.loader = true;
        const fd = new FormData();
        fd.append("employeId", this.CurrentWorker.employeId);
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Employee/MarkActive?isActive=" + isActive,
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        this.loader = false;
        this.GetAll();
        console.log(response);
      } catch (error) {
        console.log(error);
        this.loader = false;
        this.$toast.warning("Something went wrong!.", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        })
        ;
      }
    },
    async RevokeWorker() {
      try {
        this.loader = true;
        const fd = new FormData();
        fd.append("empId", this.CurrentWorker.employeId);
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Employee/Revoke",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        this.loader = false;
        this.GetAll();
        console.log(response);
      } catch (error) {
        console.log(error);
        this.loader = false;
        this.$toast.warning("Something went wrong!.", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        })
        ;
      }
    },
    forwardButtonCall() {
      //it does not run on equal to condition
      if (this.Filter.page < this.pagination.totalPages) {
        this.Filter.page++;
        this.GetAll();
      } else {
        return;
      }
    },
    backwardButtonCall() {
      if (this.Filter.page > 1) {
        this.Filter.page--;
        this.GetAll();
      } else {
        return;
      }
    },
    AllMethod() {
      setTimeout(() => {
        this.loader = true;
      }, 500);
      setTimeout(this.GetAll, 1000);
    }
  },
  created() {
    this.$store.state.DisableCompany = false;
    this.GetAll();
    this.$root.$on("myEvent", () => {
      this.Filter.page = 1;
      this.Filter.filterText = "";
      this.GetAll();
    });
  },
};
</script>
<style lang="scss" scoped>
.password-span {
  -webkit-text-security: disc !important;
}
</style>