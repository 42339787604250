<template>
  <div class="pa-1">
  <span class="chart-title">Leads</span>
  <span class="d-block totalNumber">Total number of Assigned Leads: {{Total}}</span>
    <apexchart height="300" type="bar" :options="chartOptions" :series="ChartData"></apexchart>
  </div>
</template>
<script>

export default {
  name: 'LeadChart',
  data() {
    return {
      chartOptions: {
        
        chart: {
          id: 'vuechart-example',
          toolbar: {
            show: false
          }
        },
        grid: {
          yaxis: {
            lines: {
              show: false
            }
          },
        },
        xaxis: {
          categories: ["Assigned Leads"],
        },

        //Colors and options
        fill: {
          colors: ["#5b2d13"],
          opacity: 1
        },

        
      },
      data: {
        series: [],
        xaxis: {
          position: "top",
          categories: []
        }
      }
    }
  },
  computed:{
    ChartData(){
      setTimeout
      return this.$store.state.AssignedLeads
    },
    Total(){
      return this.$store.state.AssignedLeads[0].data
    }
  },
  created() {
    this.$store.state.AssignedLeads = [
      {
        name: "Assigned Jobs",
        data: [0]
      }]
  }
};
</script>