<template>
  <div>
    <v-row class="pa-0">
      <v-col class="pa-0">
        <v-toolbar class="theme-color1" elevation="1">
          <v-toolbar-title v-if="User == null">Tracking Buddy</v-toolbar-title>
          <v-toolbar-title v-else>{{ User.fullName }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-icon color="#fff">mdi-account</v-icon>
            <v-menu left min-width="100px" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mt-2" color="#fff" small v-bind="attrs" v-on="on">mdi-chevron-down</v-icon>
              </template>
              <v-list class="notification-list text-center" dense>
                <v-list-item link @click="dialog = true">
                  <v-icon class="mr-2">mdi-account</v-icon>
                  <v-list-item-title>Profile</v-list-item-title>
                </v-list-item>
                <v-list-item link @click="logout()">
                  <v-icon class="mr-2">mdi-logout</v-icon>
                  <v-list-item-title>Logout</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar-items>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row class="pa-0">
      <v-col class="pa-0">
        <v-list two-line>
          <div v-if="Jobs == null">
            <v-skeleton-loader v-for="index in 8" :key="index" class="mb-3"
                               type="list-item-avatar-two-line"></v-skeleton-loader>
          </div>
          <template v-for="(item, index) in Jobs" v-else>

            <v-list-item :key="item.index" link @click="setJob(item)">
              <v-list-item-avatar :style="`background-color: ${item.color}`">

                <span class="white--text">{{ item.title.substring(0, 1) }}</span>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="item.title"></v-list-item-title>

                <v-list-item-subtitle v-if="Jobs != null" class="font-weight-light"
                                      v-html="item.customer.fullName"></v-list-item-subtitle>

              </v-list-item-content>
              <v-spacer></v-spacer>
              <div class="d-flex flex-column justify-end align-end">
                <span class="grey--text caption align-end">{{ getFormattedDate(item.startDate) }}</span>
                <v-btn v-if="item.jobStatus == 3" class="badge" color="success" outlined x-small>Completed</v-btn>
              </div>
            </v-list-item>
            <v-divider :key="index" inset></v-divider>
          </template>
        </v-list>
      </v-col>
    </v-row>
    <!-- User -->
    <v-dialog v-model="dialog" max-width="500px" persistent>
      <v-card class="rounded-0">
        <v-card-title>
          <span class="text-h5">Profile</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-if="User != null" v-model="User.username" class="custom-input" color="#64718A" dense
                              disabled
                              hide-details label="Username" outlined></v-text-field>
              </v-col>
              <v-col cols="12 pb-0">
                <v-text-field v-if="User != null" v-model="password" :rules="[matchingCurrentPassword]"
                              class="custom-input" color="#64718A" dense
                              label="Current Password" outlined>
                </v-text-field>

              </v-col>
              <v-col cols="12 pt-0">
                <v-text-field v-if="User != null" v-model="User.newPassword" class="custom-input" color="#64718A" dense
                              hide-details label="New Password" outlined></v-text-field>

              </v-col>
              <v-col cols="12">
                <v-text-field v-if="User != null" v-model="reTypePassword" :rules="[matchingPasswords]"
                              class="custom-input" color="#64718A" dense
                              label="Retype Password" outlined></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" dark elevation="0" tile @click="dialog = false">
            Close
          </v-btn>
          <v-btn
              :disabled="(!User.newPassword && !reTypePassword) || (User.newPassword != reTypePassword)||(User.password != password)"
              color="#64718A" dark elevation="0"
              tile
              @click="updateUser(), (dialog = false)">
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import axios from "axios";
import config from "@/components/Configuration/config";
import moment from "moment";
import 'moment-timezone';

export default {
  name: "MobileComp",
  data() {
    return {
      name: "Worker",
      loader: true,
      dialog: false,
      UserName: "",
      User: {
        fullName: "",
        username: "",
        password: "",
        newPassword: "",
      },
      password: "",
      reTypePassword: "",
      Worker: null,
      Jobs: null,
      Colors: [
        "AliceBlue",
        "AntiqueWhite",
        "Aqua",
        "Aquamarine",
        "Azure",
        "Beige",
        "Bisque",
        "Black",
        "BlanchedAlmond",
        "Blue",
        "BlueViolet",
        "Brown",
        "BurlyWood",
        "CadetBlue",
        "Chartreuse",
        "Chocolate",
        "Coral",
        "CornflowerBlue",
        "Cornsilk",
        "Crimson",
        "Cyan",
        "DarkBlue",
        "DarkCyan",
        "DarkGoldenRod",
        "DarkGray",
        "DarkGrey",
        "DarkGreen",
        "DarkKhaki",
        "DarkMagenta",
        "DarkOliveGreen",
        "DarkOrange",
        "DarkOrchid",
        "DarkRed",
        "DarkSalmon",
        "DarkSeaGreen",
        "DarkSlateBlue",
        "DarkSlateGray",
        "DarkSlateGrey",
        "DarkTurquoise",
        "DarkViolet",
        "DeepPink",
        "DeepSkyBlue",
        "DimGray",
        "DimGrey",
        "DodgerBlue",
        "FireBrick",
        "ForestGreen",
        "Fuchsia",
        "Gainsboro",
        "Gold",
        "GoldenRod",
        "Gray",
        "Grey",
        "Green",
        "GreenYellow",
        "HoneyDew",
        "HotPink",
        "IndianRed",
        "Indigo",
        "Ivory",
        "Khaki",
        "Lavender",
        "LavenderBlush",
        "LawnGreen",
        "LemonChiffon",
        "LightBlue",
        "LightCoral",
        "LightCyan",
        "LightGoldenRodYellow",
        "LightGray",
        "LightGrey",
        "LightGreen",
        "LightPink",
        "LightSalmon",
        "LightSeaGreen",
        "LightSkyBlue",
        "LightSlateGray",
        "LightSlateGrey",
        "LightSteelBlue",
        "LightYellow",
        "Lime",
        "LimeGreen",
        "Linen",
        "Magenta",
        "Maroon",
        "MediumAquaMarine",
        "MediumBlue",
        "MediumOrchid",
        "MediumPurple",
        "MediumSeaGreen",
        "MediumSlateBlue",
        "MediumSpringGreen",
        "MediumTurquoise",
        "MediumVioletRed",
        "MidnightBlue",
        "MintCream",
        "MistyRose",
        "Moccasin",
        "Navy",
        "OldLace",
        "Olive",
        "OliveDrab",
        "Orange",
        "OrangeRed",
        "Orchid",
        "PaleGoldenRod",
        "PaleGreen",
        "PaleTurquoise",
        "PaleVioletRed",
        "PapayaWhip",
        "PeachPuff",
        "Peru",
        "Pink",
        "Plum",
        "PowderBlue",
        "Purple",
        "RebeccaPurple",
        "Red",
        "RosyBrown",
        "RoyalBlue",
        "SaddleBrown",
        "Salmon",
        "SandyBrown",
        "SeaGreen",
        "SeaShell",
        "Sienna",
        "Silver",
        "SkyBlue",
        "SlateBlue",
        "SlateGray",
        "SlateGrey",
        "Snow",
        "SpringGreen",
        "SteelBlue",
        "Tan",
        "Teal",
        "Thistle",
        "Tomato",
        "Turquoise",
        "Violet",
        "Yellow",
        "YellowGreen"
      ]
    }
  },
  methods: {
    setJob(item) {
      sessionStorage.setItem('myJob', JSON.stringify(item));
      this.$router.push('/jobdetails')
    },
    matchingCurrentPassword: function () {
      if (this.password === this.User.password) {
        return true;
      } else {
        return "Current password not correct"
      }
    },
    matchingPasswords: function () {
      if (this.User.newPassword === this.reTypePassword) {
        return true;
      } else {
        return "Passwords does not match.";
      }
    },
    getFormattedDate(date) {
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get the user's timezone
      return moment.utc(date).tz(userTimezone).format('MMM DD, yyyy');
      //return moment(date).format("DD, MMM");
    },
    async getuser() {
      try {
        const fd = new FormData();
        fd.append("username", this.UserName);
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/User/Profile",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        this.User = response.data
        axios.defaults.headers.common.CompanyId = this.User.companyId;
        sessionStorage.setItem('JobEmployeId', this.User.employeId)
        this.GetJobs();
      } catch (error) {
        this.loader = false;
        console.log(error.message);
      }
    },

    async GetJobs() {
      try {
        const fd = new FormData();
        fd.append("empId", this.User.employeId);
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Employee/AssignedJobs",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        this.Jobs = response.data;
        this.Jobs.forEach(job => {
          const random = Math.floor(Math.random() * this.Colors.length);
          job.color = this.Colors[random]
        })

      } catch (error) {
        this.loader = false;
        console.log(error.message);
      }
    },
    updateUser() {
      try {
        axios
            .post(config.apiBaseUrl + "/api/User/Update", {
              username: this.User.username,
              fullName: this.User.fullName,
              password: this.User.password,
              newPassword: this.User.newPassword
            })
            .then(() => {
              this.$toast.success("Profile updated successfully.", {
                position: "top-center",
                timeout: 2000,
                closeOnClick: true,
                pauseOnfocusLoss: false,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            });
      } catch (err) {
        console.log(err)
        this.$toast.warning("Something went wrong!", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
    logout() {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("userId");
      sessionStorage.removeItem("isLoggedin");

      sessionStorage.setItem("isLoggedin", false);
      axios.get(config.apiBaseUrl + "/api/User/Logout").then((res) => {
        console.log(res);
        axios.defaults.headers.common["Authorization"] = null,
            this.$store.state.LoggedIn = false;
        this.$router.push("/");
      });
    },
  },
  created() {
    this.UserName = sessionStorage.getItem("userName");
    this.getuser();
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300&display=swap');

.badge {
  font-family: 'Manrope', sans-serif !important;
}

.custom-list tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
  border: none !important;
}
</style>