import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import VueAxios from 'vue-axios'
import config from '@/components/Configuration/config';
//use vuex in vue
Vue.use(Vuex);
Vue.use(VueAxios, axios);

const LOGIN = "LOGIN";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGOUT = "LOGOUT";

export default new Vuex.Store({
    state: {
        SelectedCompany: null,
        companies: [],
        token: sessionStorage.getItem("token"),
        role: null,
        LoggedIn: false,
        fromDate: null,
        toDate: null,
        pending: false,
        selectedBranch: null,
        myrole: null,
        Branches: [],
        roleDes: "",
        jobType: 100,
        AssignedLeads: [],
        JobsRevenue: [],
        DisableCompany: false,
    },
    getters: {
        //TO GET VALUES OF STATE
        isLoggedIn: state => state.isLoggedIn,
        isPending: state => state.pending

    },
    mutations: {
        LOGIN(state) {
            state.pending = true;
        },

        LOGIN_SUCCESS(state) {
            state.isLoggedIn = true;
            state.pending = false
        },
        LOGOUT(state) {
            state.isLoggedIn = false;
        }
    },
    actions: {
        login({commit}, creds) {
            //MAKE FORM DATA
            let formData = new FormData();
            formData.append('email', creds.email);
            formData.append('password', creds.password);
            //CHANGE PENDING STATE
            commit(LOGIN);
            return new Promise((resolve) => {
                axios.post(config.apiBaseUrl + '/Login/auth/login', formData, {
                    headers: {'Content-Type': 'multipart/form-data'}
                })
                    .then((res) => {
                        if (res.data.status == 1) {
                            //CHECK PENDING STATE

                            //SET TOKEN AND USER DATA FOR LATER USAGE
                            sessionStorage.setItem("user", res.data);
                            sessionStorage.setItem("token", res.data.token);
                            sessionStorage.setItem("role", res.data.data[0].role);


                            commit(LOGIN_SUCCESS);

                            resolve(res);

                        } else {
                            ("Login Failed with-" + res.data.Error);
                        }
                    })
                    .catch((err) => {
                        console.warn(err);
                    });

            });
        },
        logout({commit}) {
            return new Promise((resolve) => {
                sessionStorage.removeItem("user");
                sessionStorage.removeItem("token");
                sessionStorage.removeItem("role");
                //TO CHANGE ISLOGGENIN STATE TO FALSE
                commit(LOGOUT);
                resolve();
            });

        }

    },
    modules: {}
})
