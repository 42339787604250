import { render, staticRenderFns } from "./AddQuotation.vue?vue&type=template&id=2741a919&scoped=true&"
import script from "./AddQuotation.vue?vue&type=script&lang=js&"
export * from "./AddQuotation.vue?vue&type=script&lang=js&"
import style0 from "./AddQuotation.vue?vue&type=style&index=0&id=2741a919&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2741a919",
  null
  
)

export default component.exports