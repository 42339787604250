<template>
  <div class="">
    <v-row justify="space-between">
      <v-col class="d-flex" cols="12" sm="6">
        <v-btn class="mr-2 theme-color1" dark elevation="0" tile>
          <v-icon>mdi-account-tie</v-icon>
        </v-btn>
        <h2 class="text-uppercase theme-font-color">{{ name }}</h2>
      </v-col>
      <v-col class="mb-1 d-flex" cols="12" sm="6">
        <v-select v-model="Filter.leadStatus" :items="JobFilter"
                  :menu-props="{ bottom: true, offsetY: true }" class="custom-input border-right JobFilter"
                  color="#64718A" dense hide-details
                  item-text="text" item-value="value" outlined style="max-width: 150px" @change="GetAll()"></v-select>
        <v-text-field v-model="Filter.filterText" append-icon="mdi-magnify" class="custom-input " clearable
                      color="#64718A" dense
                      hide-details label="Search" outlined
                      v-on:keyup="GetAll"
                      @click:clear="(Filter.filterText = ''), GetAll()" @click:append="GetAll"></v-text-field>

        <v-btn :to="{ name: 'AddLead' }" class="ms-2 custom-btn theme-color1" dark elevation="0" height="40" link tile>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <div class="custom-line"></div>
    <v-data-table v-if="JobRequests == null" :headers="headers" :items-per-page="Filter.pageSize"
                  class="custom-table elevation-0"
                  disable-sort hide-default-footer>
    </v-data-table>
    <v-data-table v-else :headers="headers" :items="JobRequests" :items-per-page="Filter.pageSize"
                  class="custom-table elevation-0"
                  disable-sort hide-default-footer>
      <template v-slot:[`item.sno`]="{ item }">
        <router-link :to="{ name: 'AddLead', params: { item , editView } }" class="ms-0">
          LEAD # {{ item.leadNo }}
        </router-link>
      </template>
      <template v-slot:[`item.leadCreationDate`]="{ item }">
        <span>{{ getFormattedDate(item.leadCreationDate) }}</span>
      </template>
      <template v-slot:[`item.leadStatus`]="{ item }">
        <span v-if="item.leadStatus === 1"><v-chip class="chip" color="warning darken-1" dark label
                                                   x-small>Open</v-chip></span>
        <span v-if="item.leadStatus === 2"><v-chip class="chip" color="primary darken-3" dark label
                                                   x-small>Assigned</v-chip></span>
        <span v-if="item.leadStatus === 3"><v-chip class="chip" color="success" dark label
                                                   x-small>Completed</v-chip></span>
        <span v-if="item.leadStatus === 4"><v-chip class="chip" color="gray" dark label x-small>Closed</v-chip></span>
      </template>
      <template v-slot:[`item.leadSent`]="{ item }">
        <v-chip v-if="item.leadSent === false || item.leadSent === null" class="chip" color="danger" dark label x-small>
          Not Sent
        </v-chip>
        <v-chip v-if="item.leadSent === true" class="chip" color="success" dark label x-small>Sent</v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn :disabled="item.leadStatus == 4" icon x-small>
          <i class="mgc_mail_send_line" style="font-size: 16px; cursor: pointer"
             @click="takeData(item), SendMailDialog = true"></i>
        </v-btn>

        <router-link v-if="item.leadStatus !== 4" :to="{ name: 'AddLead', params: { item } }" class="ms-0">
          <v-icon :disabled="item.leadStatus == 4" class="ms-2" color="primary darken-1" small @click="takeData(item)">
            mdi-pencil
          </v-icon>
        </router-link>
        <v-icon v-else :disabled="item.leadStatus == 4" class="ms-2" color="primary darken-1" small
                @click="takeData(item)">
          mdi-pencil
        </v-icon>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon :disabled="item.leadStatus == 4"
                    class="ms-2" color="purple" small v-bind="attrs"
                    @click="takeData(item), (qouteDialog = true)"
                    v-on="on">mdi-file-delimited
            </v-icon>
          </template>
          <span>Move to Quote</span>
        </v-tooltip>
        <v-icon :disabled="item.leadStatus == 4" class="ms-2" color="red" small
                @click="takeData(item), (deleteDialog = true)">mdi-trash-can-outline
        </v-icon>
      </template>
    </v-data-table>
    <v-row align="center" class="no-gutters mt-2">
      <v-spacer style="width: 75%"></v-spacer>
      <span class="pagination-text">Rows per page</span>
      <v-select v-model="Filter.pageSize" :items="options" :menu-props="{ bottom: true, offsetY: true }" class="ma-2"
                dense style="width: 50px"
                @change="GetAll"></v-select>
      <span v-if="pagination != null" class="mr-4 grey--text pagination-text">
        Page {{ pagination.page }} of {{ pagination.totalPages }}
      </span>
      <v-icon class="mr-1" color="primary darken-2" dark small @click="backwardButtonCall">mdi-chevron-left</v-icon>
      <v-icon class="mr-1" color="primary darken-2" dark small @click="forwardButtonCall()">mdi-chevron-right</v-icon>
    </v-row>
    <v-dialog v-model="deleteDialog" max-width="400" persistent>
      <v-card>
        <v-card-title class="text-h7"> Delete Job Request</v-card-title>
        <v-card-text>Are You sure? You want to delete
          <b v-if="CurrentRequest != null" class="error--text text--darken-2">{{
              CurrentRequest.customer.fullName
            }}</b>'s rquest.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text tile @click="deleteDialog = false">
            Cancel
          </v-btn>
          <v-btn color="error darken-2" elevation="0" tile @click="DeleteRequest(), (deleteDialog = false)">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="CurrentRequest != null" v-model="qouteDialog" max-width="400" persistent>
      <v-card>
        <v-card-title class="text-h7"> Move to Quotation</v-card-title>
        <v-card-text>Are You sure? You want to move
          <b color="#64718A">{{
              CurrentRequest.customer.fullName
            }}</b>'s rquest to quotation.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" dark elevation="0" tile @click="qouteDialog = false">
            Cancel
          </v-btn>
          <v-btn color="#64718A" dark elevation="0" tile
                 @click="(CurrentRequest.isQuote = true),MarkAsQoutation(), (qouteDialog = false)">
            Mark
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Mail dialog -->
    <v-dialog v-if="CurrentRequest != null" v-model="SendMailDialog" max-width="400" persistent>
      <v-card>
        <v-card-title class="text-h5"> Send Mail</v-card-title>
        <v-card-text>Are You sure? You want to send email to lead employees.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text tile @click="SendMailDialog = false">
            Cancel
          </v-btn>
          <v-btn class="theme-color1" elevation="0" tile
                 @click="SendLeadMail(), (SendMailDialog = false)">
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-progress-circular v-if="loader" class="custom-loader" color="#64718A" indeterminate width="2">
    </v-progress-circular>
  </div>
</template>
<script>
import moment from 'moment';
import 'moment-timezone';
import axios from "axios";
import config from "../Configuration/config";

export default {
  name: "LeadModule",
  data() {
    return {
      name: "Lead",
      deleteDialog: false,
      qouteDialog: false,
      editView: false,
      SendMailDialog: false,
      loader: false,
      headers: [
        {
          text: "#",
          align: "start",
          value: "sno",
          width: '140px'
        },
        {
          text: "Title",
          value: "title",
        },
        {
          text: "Customer",
          value: "customer.fullName",
        },
        {
          text: "Lead Date",
          value: "leadCreationDate",
        },
        {
          text: "Description",
          value: "leadDescription",
        },
        {
          text: "Lead Sent",
          value: "leadSent"
        },
        {
          text: "Status",
          value: "leadStatus",
          width: '100px',
          align: 'center'
        },

        {
          text: "Actions",
          value: "actions",
          align: "center",
          width: "130px",
          sortable: false,
        },
      ],
      options: [
        {text: "10", value: 10},
        {text: "25", value: 25},
        {text: "50", value: 50},
        {text: "100", value: 100},
      ],
      JobFilter: [
        {
          text: "All",
          value: 0
        },
        {
          text: "Open",
          value: 1,
        },
        {
          text: "Assigned",
          value: 2,
        },
        {
          text: "Completed",
          value: 3,
        },
        {
          text: "Closed",
          value: 4,
        },
      ],
      jobType: 100,
      JobRequests: null,
      CurrentRequest: null,
      pagination: null,
      Filter: {
        CompanyId: axios.defaults.headers.common.CompanyId,
        pageSize: 10,
        page: 1,
        filterText: "",
        isActive: true,
        jobType: 100,
        leadStatus: 0,
      },
    };
  },

  methods: {

    getFormattedDate(date) {

      /*    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get the user's timezone
          return moment.utc(date).tz(userTimezone).format('MMM DD, yyyy');
    */
      return moment(date).format("MMM DD, yyyy");
    },
    takeData(request) {
      this.CurrentRequest = request;
    },
    async SendLeadMail() {
      try {
        this.loader = true;
        if (this.CurrentRequest.jobEmployees.length === 0) {
          this.$toast.warning("No assigned employees found!", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnfocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
          });
          this.loader = false;
          return;
        }
        // eslint-disable-next-line no-unused-vars
        let response = await axios({
          method: "POST",
          url: config.apiBaseUrl + "/api/Job/ResendLeadMail",
          data: this.CurrentRequest,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        this.loader = false;
        this.GetAll();
        this.$toast.success("Email sent", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });

      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },
    async GetAll() {
      if (this.$route.name === "LeadModule") {
        try {
          this.loader = true;
          let response = await axios({
            method: "POST",
            url: config.apiBaseUrl + "/api/Job/All",
            data: this.Filter,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          });
          this.JobRequests = response.data.data;
          this.loader = false;
          this.pagination = response.data.pagination;

        } catch (error) {
          this.loader = false;
          this.$toast.warning(error.message, {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnfocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
          });
        }
      }
    },
    async MarkAsQoutation() {
      try {

        this.loader = true;
        const fd = new FormData();
        fd.append("jobId", this.CurrentRequest.jobId);
        // eslint-disable-next-line no-unreachable
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Job/MoveToQuote",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        console.log(response);
        this.GetAll();
        this.loader == false;
      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },
    async DeleteRequest() {
      try {
        this.CurrentRequest.isLead = false;
        this.loader = true;
        const fd = new FormData();
        fd.append("jobId", this.CurrentRequest.jobId);
        let responsed = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Job/Remove?type=100",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        console.log(responsed);
        this.loader = false;
        this.GetAll();
      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },
    forwardButtonCall() {
      //it does not run on equal to condition
      if (this.Filter.page < this.pagination.totalPages) {
        this.Filter.page++;
        this.GetAll();
      } else {
        return;
      }
    },
    backwardButtonCall() {
      if (this.Filter.page > 1) {
        this.Filter.page--;
        this.GetAll();
      } else {
        return;
      }
    },
  },
  created() {
    this.$store.state.DisableCompany = false;
    this.GetAll();
    this.$root.$on("myEvent", () => {
      this.Filter.page = 1;
      this.Filter.filterText = "";
      this.GetAll();
    });
  },
};
</script>
<style scoped>
.chip {
  width: 80px;
  justify-content: center;
}
</style>