<template>
  <div>
    <v-row>
      <v-col class="d-flex" cols="12">
        <v-btn class="mr-2 theme-color1" dark elevation="0" tile>
          <v-icon>mdi-cog</v-icon>
        </v-btn>
        <v-tabs v-model="tab" class="pa-0 ma-0" color="#64718A" height="35">
          <v-tab v-for="(item, idx) in tabs" :key="idx" @change="GetData(item)">
            {{ item.text }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-end mt-2 mb-1">
      <v-col class="d-flex" cols="12" md="5">
        <v-text-field v-model="KeyValue.value" class="custom-input mr-2" clearable color="#64718A" dense hide-details
                      outlined placeholder="Add Type" @keyup.enter="AddType(), (KeyValue.value = '')"></v-text-field>
        <v-btn :disabled="!KeyValue.value" class="login-btns theme-color1" elevation="0" height="40" tile
               @click="AddType(), (KeyValue.value = '')">Add
        </v-btn>
      </v-col>
    </v-row>
    <div class="custom-line"></div>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(item, index) in tabs" :key="index">
        <v-data-table :headers="header" :items="KeyValues" :items-per-page="Filter.pageSize" class="custom-table"
                      disable-sort
                      hide-default-footer>
          <template v-slot:[`item.sno`]="{ index }">
            <span>{{ index + 1 }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <!-- <v-icon
              small
              @click="takeData(item), (editdialog = !editdialog)"
              color="success"
              >mdi-pencil</v-icon
            > -->
            <v-icon class="ms-2" color="red" small @click="DeleteType(item)">mdi-trash-can-outline</v-icon>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
    <!-- Delete Dialog -->
    <!-- <v-dialog v-model="deleteDialog" persistent max-width="400">
      <v-card>
        <v-card-title class="text-h5"> Delete Type </v-card-title>
        <v-card-text
          >Are You sure? You want to delete
          <b class="error--text text--darken-2">{{
            KeyValue.value
          }}</b></v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success darken-1" text @click="deleteDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="error darken-2"
            @click="DeleteType(), (deleteDialog = false)"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <v-dialog v-model="editdialog" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          <span class="text-h5">Edit Type</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12" sm="6">
                <v-text-field v-model="KeyValue.value" color="primary darken-2 darken-1" dense filled flat
                              hide-details></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-2 darken-1" text @click="editdialog = false">
            Close
          </v-btn>
          <v-btn color="primary darken-2 darken-1" @click="AddType(), (editdialog = false)">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Progress Bar -->
    <v-progress-circular v-if="loader" class="custom-loader" color="#64718A" indeterminate
                         width="2"></v-progress-circular>
  </div>
</template>
<script>
import axios from "axios";
import config from "../Configuration/config";

export default {
  name: "SettingsComp",
  data() {
    return {
      name: "Settings",
      tab: null,
      deleteDialog: false,
      loader: false,
      editdialog: false,
      typeValue: "",
      header: [
        {text: "#", align: "left", value: "sno", width: "15%"},
        {text: "Type", value: "value"},
        {text: "Actions", value: "actions", width: "100px", align: "center"},
      ],
      tabs: [
        {text: "Customer SubType", value: 30},
        {text: "Employee Type", value: 20},
        {text: "Alert Type", value: 40},
        {text: "Overhead Type", value: 50},
      ],
      KeyValues: [],
      currentValue: {text: "Customer SubType", value: 30},
      currentType: null,
      Filter: {
        page: 1,
        pageSize: 10,
        filterText: "",
      },
      KeyValue: {
        key: 0,
        value: "",
        type: 0,
      }
    };
  },
  methods: {
    takeData(item) {
      this.KeyValue = item;
    },
    GetData(item) {
      this.currentValue = item;
      this.GetAll();
    },
    async GetAll() {
      try {
        this.loader = true;
        const fd = new FormData();
        fd.append("type", this.currentValue.value);
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/KeyValue/Get",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        this.KeyValues = response.data;
        this.loader = false;

      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,})
        ;
      }
    },
    async AddType() {
      try {
        this.loader = true;
        this.KeyValue.type = this.currentValue.value;
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/KeyValue/AddorUpdate",
          data: this.KeyValue,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        console.log(response);
        this.GetAll();
        this.loader = false;
        this.KeyValue.type = 0;
        this.KeyValue.value = "",
            this.KeyValue.key = 0
      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,})
        ;
      }
    },
    async DeleteType(item) {
      try {
        this.loader = true;
        const fd = new FormData();
        fd.append("key", item.key);
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/KeyValue/Remove",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        console.log(response);
        this.loader = false;
        this.GetAll();
      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,})
        ;
      }
    },
  },
  created() {
    this.$store.state.DisableCompany = false;
    this.GetAll();
  },
};
</script>
<style scoped>
.theme--dark.v-tabs-items {
  background-color: transparent !important;
}

.theme--light.v-tabs-items {
  background-color: transparent !important;
}

.login-btns {
  color: #fff;
}
</style>