<template>
  <div id="app">
    <v-app>
      <HeaderComp v-if="this.$store.state.LoggedIn && Role != 20"/>
      <v-main>
        <router-view class="pa-2"/>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import HeaderComp from "./components/Navigation/HeaderComp.vue";
// import store from "./store";
export default {
  name: "App",
  components: {
    HeaderComp,
  },
  data: () => ({}),
  methods: {
    isLogged() {
      this.$store.state.LoggedIn = sessionStorage.getItem("isLoggedin");
      this.$store.state.role = sessionStorage.getItem("role")
    },
  },
  computed: {
    Role() {
      return this.$store.state.role;
    }
  },
  beforeCreate() {
    this.$store.state.LoggedIn = sessionStorage.getItem("isLoggedin");
  },
  created() {
    this.isLogged();
  },
};
</script>
<style>
@import "./assets/style.css";
@import "./assets/MingIcons/MingCute.css";
@import "./assets/RemixIcons/remixicon.css";
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@500&display=swap');

/* #app{
 background-color: #f8f9fd; 
}*/
#app {

}
</style>