<template>
  <div>
    <v-row justify="space-between">
      <v-col class="d-flex" cols="12" sm="6">
        <v-btn class="mr-2 theme-color1" dark elevation="0" link tile>
          <v-icon>mdi-chart-bar</v-icon>
        </v-btn>
        <h2 class="text-uppercase theme-font-color">{{ name }}</h2>
      </v-col>
    </v-row>
    <div class="custom-line"></div>
    <v-row class="mt-2">
      <v-col cols="12" md="3">
        <v-card class="centered elevation-3 report-card">
          <p class="text-center pa-2 title mb-0">Overhead / Materials</p>
          <v-card-text>
            <v-row class="pa-3 justify-center">
              <v-col class="report-col pa-0 d-flex" cols="8">
                <span class="date-label">Select Month</span>
                <input v-model="OverheadMonth" class="datepicker date-input custom-date" type="month"
                       width="100%">
                <v-btn color="success" depressed height="40" tile @click="OverheadReport">
                  <i class="mgc_download_line btn-icon"></i>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card class="centered elevation-3 report-card">
          <p class="text-center pa-2 title mb-0">Jobs</p>
          <v-card-text>
            <v-row class="pa-3 justify-center">
              <v-col class="report-col pa-0 d-flex" cols="8">
                <span class="date-label">Select Month</span>
                <input v-model="JobReportMonth" class="datepicker date-input custom-date" type="month"
                       width="100%">
                <v-btn color="success" depressed height="40" tile @click="JobsReport()">
                  <i class="mgc_download_line btn-icon"></i>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card class="centered elevation-3 report-card">
          <p class="text-center pa-2 title mb-0">Payroll</p>
          <v-card-text>
            <v-row class="pa-3 justify-center">
              <v-col class="report-col pa-0 d-flex" cols="8">
                <span class="date-label">Select Month</span>
                <input v-model="PayrollMonth" class="datepicker date-input custom-date" type="month"
                       width="100%">
                <v-btn color="success" depressed height="40" tile @click="EmployeesReport()">
                  <i class="mgc_download_line btn-icon"></i>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card class="centered elevation-3 report-card">
          <p class="text-center pa-2 title mb-0">Master Data</p>
          <v-card-text>
            <v-row class="pa-3 justify-center">
              <v-col class="report-col pa-0 d-flex" cols="8">
                <span class="date-label">Select Month</span>
                <input v-model="InvoiceMonth" class="datepicker date-input custom-date" type="month"
                       width="100%">
                <v-btn color="success" depressed height="40" tile @click="MasterDataReport()">
                  <i class="mgc_download_line btn-icon"></i>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-progress-circular v-if="loader" class="custom-loader" color="#64718A" indeterminate width="2">
    </v-progress-circular>
  </div>
</template>
<script>
import moment from 'moment';
import 'moment-timezone';
import axios from "axios";
import config from "@/components/Configuration/config";

export default {
  name: "ReportsComp",
  data() {
    return {
      name: "Reports",
      loader: false,
      OverheadMonth: new Date().toJSON().toString(),
      JobReportMonth: new Date().toJSON().toString(),
      PayrollMonth: new Date().toJSON().toString(),
      InvoiceMonth: new Date().toJSON().toString(),
      Filter: {
        "month": null,
        "companyId": axios.defaults.headers.common.CompanyId,
        "userId": sessionStorage.getItem("UserId"),
      }
    };
  },
  mounted() {
    this.JobReportMonth = moment(this.JobReportMonth).format("YYYY-MM");
    this.OverheadMonth = moment(this.OverheadMonth).format("YYYY-MM");
    this.PayrollMonth = moment(this.PayrollMonth).format("YYYY-MM");
    this.InvoiceMonth = moment(this.InvoiceMonth).format("YYYY-MM");
  },
  methods: {
    async OverheadReport() {
      try {
        this.Filter.month = this.OverheadMonth;
        this.loader = true;
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Overhead/GenerateReport",
          data: this.Filter,
          headers: {},
        });
        if (response.data.data != null) {
          var sampleArr = this.base64ToArrayBuffer(response.data.data);
          this.saveByteArray(moment(this.OverheadMonth).format("MMM YYYY") + " Overhead.xlsx", sampleArr);
          if (response.data.msg === "Ok") {
            this.$toast.success("File Downloaded", {
              position: "top-center",
              timeout: 1000,
              closeOnClick: true,
              pauseOnfocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.79,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: false,
              icon: true,
              rtl: false,
            });
          }
        } else {
          if (response.data.msg === "No overhead found") {
            this.$toast.warning("No overhead found!", {
              position: "top-center",
              timeout: 1000,
              closeOnClick: true,
              pauseOnfocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.79,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: false,
              icon: true,
              rtl: false,
            });
          } else {
            this.$toast.error("Something went wrong!", {
              position: "top-center",
              timeout: 1000,
              closeOnClick: true,
              pauseOnfocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.79,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: false,
              icon: true,
              rtl: false,
            });
          }
        }
        this.loader = false;
      } catch (err) {
        this.loader = false;
        this.$toast.warning(err.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },
    async JobsReport() {
      try {
        this.Filter.month = this.JobReportMonth;
        this.loader = true;
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Job/GenerateReport",
          data: this.Filter,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        if (response.data.data != null) {
          var sampleArr = this.base64ToArrayBuffer(response.data.data);
          this.saveByteArray(moment(this.JobReportMonth).format("MMM YYYY") + " Job Report.xlsx", sampleArr);
          if (response.data.msg === "Ok") {
            this.$toast.success("File Downloaded", {
              position: "top-center",
              timeout: 1000,
              closeOnClick: true,
              pauseOnfocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.79,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: false,
              icon: true,
              rtl: false,
            });
          }
        } else {
          if (response.data.msg === "No completed job found!") {
            this.$toast.warning("No completed job found!", {
              position: "top-center",
              timeout: 1000,
              closeOnClick: true,
              pauseOnfocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.79,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: false,
              icon: true,
              rtl: false,
            });
          } else {
            this.$toast.warning(response.data.msg, {
              position: "top-center",
              timeout: 1000,
              closeOnClick: true,
              pauseOnfocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.79,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: false,
              icon: true,
              rtl: false,
            });
          }
        }
        this.loader = false;
      } catch (err) {
        this.loader = false,
            console.log(err);
      }
    },
    async MasterDataReport() {
      try {
        this.Filter.month = this.InvoiceMonth;
        this.loader = true;
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Job/GenerateInvoiceReport",
          data: this.Filter,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        if (response.data.data != null) {
          var sampleArr = this.base64ToArrayBuffer(response.data.data);
          this.saveByteArray(moment(this.InvoiceMonth).format("MMM YYYY") + " Master Data Report.xlsx", sampleArr);
          if (response.data.msg === "Ok") {
            this.$toast.success("File Downloaded", {
              position: "top-center",
              timeout: 1000,
              closeOnClick: true,
              pauseOnfocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.79,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: false,
              icon: true,
              rtl: false,
            });
          }
        } else {
          if (response.data.msg === "No invoice found!") {
            this.$toast.warning("No invoice found!", {
              position: "top-center",
              timeout: 1000,
              closeOnClick: true,
              pauseOnfocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.79,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: false,
              icon: true,
              rtl: false,
            });
          } else {
            this.$toast.warning(response.data.msg, {
              position: "top-center",
              timeout: 1000,
              closeOnClick: true,
              pauseOnfocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.79,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: false,
              icon: true,
              rtl: false,
            });
          }
        }
        this.loader = false;
      } catch (err) {
        this.loader = false,
            console.log(err);
      }
    },
    async EmployeesReport() {
      try {
        this.Filter.month = this.PayrollMonth;
        this.loader = true;
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Employee/GenerateReport",
          data: this.Filter,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        if (response.data.data != null) {
          var sampleArr = this.base64ToArrayBuffer(response.data.data);
          this.saveByteArray(moment(this.PayrollMonth).format("MMM YYYY") + " Wages Report.xlsx", sampleArr);
          if (response.data.msg == "Ok") {
            this.$toast.success("File Downloaded", {
              position: "top-center",
              timeout: 1000,
              closeOnClick: true,
              pauseOnfocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.79,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: false,
              icon: true,
              rtl: false,
            });
          }
        } else {
          if (response.data.msg == "No completed job found!") {
            this.$toast.warning("No completed job found!", {
              position: "top-center",
              timeout: 1000,
              closeOnClick: true,
              pauseOnfocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.79,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: false,
              icon: true,
              rtl: false,
            });
          } else {
            this.$toast.error("Something went wrong!", {
              position: "top-center",
              timeout: 1000,
              closeOnClick: true,
              pauseOnfocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.79,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: false,
              icon: true,
              rtl: false,
            });
          }
        }
        this.loader = false;
      } catch (err) {
        this.loader = false,
            console.log(err);
      }
    },
    base64ToArrayBuffer(base64) {
      var binaryString = window.atob(base64);
      var binaryLen = binaryString.length;
      var bytes = new Uint8Array(binaryLen);
      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes;
    },
    saveByteArray(reportName, byte) {
      var blob = new Blob([byte], {type: "application/xlsx"});
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      var fileName = reportName;
      link.download = fileName;
      link.click();
    },
  },
  created() {
    this.$store.state.DisableCompany = false;
  }
}
;
</script>
<style scoped>
.report-col {
  position: relative !important;
}

.date-label {
  position: absolute !important;
  top: -12px !important;
  left: 5px !important;
  background-color: #ffffff !important;
  padding: 2px;
  width: 65px;
}
</style>
