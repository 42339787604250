<template>
  <div class="">
    <v-row justify="space-between">
      <v-col class="d-flex" cols="12" sm="6">
        <v-btn class="mr-2 theme-color1" dark elevation="0" tile>
          <v-icon>mdi-tools</v-icon>
        </v-btn>
        <h2 class="text-uppercase theme-font-color">{{ name }}</h2>
      </v-col>
      <v-col class="mb-1 d-flex" cols="12" sm="5">
        <v-text-field v-model="Filter.filterText" append-icon="mdi-magnify" class="custom-input" clearable
                      color="#64718A" dense
                      hide-details label="Search" outlined v-on:keyup="AllMethod"
                      @click:clear="(Filter.filterText = ''), GetAll()" @click:append="GetAll"></v-text-field>
        <v-btn class="ms-2 theme-color1" dark elevation="0" tile @click="(ClearData()), addDialog = true">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <div class="custom-line"></div>
    <v-data-table v-if="Equipments == null" :headers="headers" :items-per-page="Filter.pageSize"
                  class="custom-table elevation-0"
                  disable-sort hide-default-footer>
    </v-data-table>
    <v-data-table v-else :headers="headers" :items="Equipments" :items-per-page="Filter.pageSize"
                  class="custom-table elevation-0"
                  disable-sort hide-default-footer>
      <template v-slot:[`item.sno`]="{ index }">
        <span>{{ index + 1 }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon class="ms-2" color="primary darken-1" small @click="takeData(item), (addDialog = true)">mdi-pencil
        </v-icon>
        <v-icon class="ms-2" color="red" small @click="takeData(item), (deleteDialog = true)">mdi-trash-can-outline
        </v-icon>
      </template>
    </v-data-table>
    <v-row align="center" class="no-gutters mt-2">
      <v-spacer style="width: 75%"></v-spacer>
      <span class="pagination-text">Rows per page</span>
      <v-select v-model="Filter.pageSize" :items="options" :menu-props="{ bottom: true, offsetY: true }" class="ma-2"
                dense
                style="width: 50px" @change="GetAll"></v-select>
      <span v-if="pagination != null" class="mr-4 grey--text pagination-text">
        Page {{ pagination.page }} of {{ pagination.totalPages }}
      </span>
      <v-icon class="mr-1" color="primary darken-2" dark small @click="backwardButtonCall">mdi-chevron-left</v-icon>
      <v-icon class="mr-1" color="primary darken-2" dark small @click="forwardButtonCall()">mdi-chevron-right</v-icon>
    </v-row>
    <!-- Delete Dialog -->
    <v-dialog v-model="deleteDialog" max-width="400" persistent>
      <v-card>
        <v-card-title class="text-h5"> Delete Equipment</v-card-title>
        <v-card-text>Are You sure? You want to delete
          <b v-if="Equipment != null" class="error--text text--darken-2">{{
              Equipment.title
            }}</b></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success darken-1" text @click="deleteDialog = false">
            Cancel
          </v-btn>
          <v-btn color="error darken-2" depressed tile @click="DeleteEquipment(), (deleteDialog = false)">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Add Dialog -->
    <v-dialog v-model="addDialog" max-width="700" persistent>
      <v-card outlined>
        <v-toolbar color="#efefef" dense elevation="0" flat tile>
          <v-btn class="mr-2 theme-color1" dark elevation="0" height="35px" tile @click="addDialog = false">
            <v-icon dense>mdi-undo-variant
            </v-icon>
          </v-btn>
          <v-toolbar-title>
            <h4 class="text-uppercase theme-font-color">{{ dialogTitle }}</h4>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn class="ps-10 pe-10 login-btns theme-color1" dark elevation="0" tile
                 @click="AddEquipment(), (addDialog = false)">Save
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col class="ps-1 pe-1" cols="12" md="6">
                <v-text-field v-model="Equipment.title" class="custom-input " color="#64718A" dense
                              hide-details label="Equipment Name" outlined></v-text-field>
              </v-col>
              <v-col class="ps-1 pe-1" cols="12" md="6">
                <v-text-field v-model="Equipment.manufacturer" class="custom-input " color="#64718A" dense
                              hide-details label="Manufacturer" outlined></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="ps-1 pe-1" cols="12" md="6">
                <v-text-field v-model="Equipment.equipmentType" class="custom-input " color="#64718A" dense
                              hide-details label="Equipment Type" outlined></v-text-field>
              </v-col>
              <v-col class="ps-1 pe-1" cols="12" md="6">
                <v-text-field v-model="Equipment.modelNumber" class="custom-input " color="#64718A" dense
                              hide-details label="Model" outlined></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="ps-1 pe-1" cols="12" md="6">
                <v-text-field v-model.number="Equipment.price" append-icon="mdi-currency-usd" class="custom-input "
                              color="#64718A"
                              dense hide-details label="Equipment Price"
                              outlined type="number" @focus="selectText"></v-text-field>
              </v-col>
              <!--              <v-col cols="12" md="6" class="ps-1 pe-1">-->
              <!--                <v-text-field label="Model" v-model="Equipment.modelNumber" color="#64718A" outlined-->
              <!--                              class="custom-input " dense hide-details></v-text-field>-->
              <!--              </v-col>-->
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-progress-circular v-if="loader" class="custom-loader" color="#64718A" indeterminate
                         width="2"></v-progress-circular>
  </div>
</template>
<script>
import axios from "axios";
import config from "../Configuration/config";

export default {
  name: "EquipmentComp",
  data() {
    return {
      name: "Equipments",
      deleteDialog: false,
      valid: false,
      dialogTitle: "New Equipment",
      addDialog: false,
      loader: false,
      rules: {
        required: [(value) => !!value || ""],
        min: [v => v > 0 || 'Price must be greater than 0',
        ]
      },
      headers: [
        {
          text: "#",
          align: "start",
          value: "sno",
          width: '70px'
        },
        {
          text: "Title",
          align: "start",
          value: "title",
        },
        {
          text: "Manufacturer",
          align: "start",
          value: "manufacturer",
        },
        {
          text: "Type",
          align: "start",
          value: "equipmentType",
        },
        {
          text: "Model",
          align: "start",
          value: "modelNumber",
        },
        {
          text: "Price",
          value: "price",
        },


        {text: "Actions", value: "actions", width: "10%", sortable: false},
      ],
      options: [
        {text: "10", value: 10},
        {text: "25", value: 25},
        {text: "50", value: 50},
        {text: "100", value: 100},
      ],
      customerTypes: [],
      Equipment: {
        companyId: axios.defaults.headers.common.CompanyId,
        createdAt: new Date(),
        equipmentId: 0,
        title: "",
        manufacturer: "",
        equipmentType: "",
        modelNumber: "",
        price: 0
      },
      Equipments: null,

      pagination: null,
      pageNumOnSearch: "",

      totalRecords: "",
      Filter: {
        pageSize: 10,
        filterText: "",
        page: 1,
      }
    };
  },
  methods: {
    selectText(event) {
      event.target.select();
    },
    ClearData() {
      this.Equipment = {},
          this.Equipment.equipmentId = 0;
      this.Equipment.title = "";
      this.Equipment.modelNumber = "";
      this.Equipment.manufacturer = "";
      this.Equipment.equipmentType = "";
      this.Equipment.price = 0;
      this.Equipment.companyId = axios.defaults.headers.common.CompanyId;
    },
    takeData(Equipment) {
      this.Equipment = Equipment;
      this.Equipment.manufacturer = this.Equipment.manufacturer ? this.Equipment.manufacturer : "";
      this.Equipment.equipmentType = this.Equipment.equipmentType ? this.Equipment.equipmentType : "";
      this.Equipment.modelNumber = this.Equipment.modelNumber ? this.Equipment.modelNumber : "";
      this.Equipment.details = this.Equipment.details ? this.Equipment.details : "";
      this.dialogTitle = "Update Equipment"
    },
    validate() {
      if (this.currentEmployee == null) {
        this.$refs.form.validate();
      }
    },
    async GetAll() {
      if (this.$route.name == "EquipmentsModule") {
        try {
          this.loader = true;
          let response = await axios({
            method: "POST",
            url: config.apiBaseUrl + "/api/Equipment/All",
            data: this.Filter,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          });
          this.Equipments = response.data.data;
          this.pagination = response.data.pagination;
          this.loader = false;
        } catch (err) {
          this.loader = false;
          this.$toast.warning(err.message, {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnfocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
          })
          ;
        }
      }
    },

    async AddEquipment() {
      try {
        this.loader = true;
        let response = await axios({
          method: "POST",
          url: config.apiBaseUrl + "/api/Equipment/AddOrUpdate",
          data: this.Equipment,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          }
        });
        console.log(response);
        if (response.data.isSuccessful == false) {
          this.$toast.warning(response.data.msg, {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnfocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
          })
          ;
        }
        this.loader = false;
        this.GetAll();
      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        })
        ;
      }
    },
    async DeleteEquipment() {
      try {
        this.loader = true;
        const fd = new FormData();
        fd.append("equipmentId", this.Equipment.equipmentId);
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Equipment/Remove",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        console.log(response);
        this.loader = false;
        this.GetAll();
      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        })
        ;
      }
    },
    forwardButtonCall() {
      //it does not run on equal to condition
      if (this.Filter.page < this.pagination.totalPages) {
        this.Filter.page++;
        this.GetAll();
      } else {
        return;
      }
    },
    backwardButtonCall() {
      if (this.Filter.page > 1) {
        this.Filter.page--;
        this.GetAll();
      } else {
        return;
      }
    },
    AllMethod() {
      setTimeout((this.loader = true), 500);
      setTimeout(this.GetAll, 1000);
    },
  },
  created() {
    this.$store.state.DisableCompany = false;
    this.GetAll();
    this.$root.$on("myEvent", () => {
      this.Filter.page = 1;
      this.Filter.filterText = "";
      this.GetAll();
    });
  },
};
</script>
<style scoped>
.login-btns {
  color: #fff;
}
</style>