<template>
  <div>
    <div>
      <div class="mt-3">
        <v-row class="ps-8 pe-8" justify="center">
          <v-col cols="12" md="12">
            <v-card elevation="0" outlined tile>
              <v-toolbar class="tab-color" dense elevation="0" flat tile>
                <v-btn
                    class="mr-2 theme-color1"
                    dark
                    elevation="0"
                    height="35px"
                    link
                    tile
                    to="/leads"
                >
                  <v-icon dense>mdi-undo-variant</v-icon>
                </v-btn>
                <v-toolbar-title>
                  <h4 class="text-uppercase theme-font-color">
                    {{ name }} for
                    <span v-if="SelectedCustomer != null" class="black--text">
                      {{ SelectedCustomer.fullName }}</span
                    >
                  </h4>
                </v-toolbar-title>

                <v-spacer></v-spacer>
                <div class="Status">
                  <v-chip
                      v-if="Job.leadStatus == 1"
                      class="grey darken-1 mr-2"
                      dark
                      label
                      small
                  >
                    Opened
                  </v-chip>
                  <v-chip
                      v-if="Job.leadStatus == 2"
                      class="primary darken-3 mr-2"
                      dark
                      label
                      small
                  >Assinged
                  </v-chip>
                  <v-chip
                      v-if="Job.leadStatus == 3"
                      class="success darken-2 mr-2"
                      dark
                      label
                      small
                  >
                    Completed
                  </v-chip>
                  <v-chip
                      v-if="Job.leadStatus == 4"
                      class="red darken-3 mr-2"
                      dark
                      label
                      small
                  >
                    Closed
                  </v-chip>
                </div>
                <v-btn-toggle dense multiple tile>
                  <v-btn
                      :disabled="!Job.title || Job.customerId === 0 || !editview"
                      class="ps-12 pe-12 login-btns theme-color1"
                      dark
                      elevation="0"
                      tile
                      @click="AddorUpdate()"
                  >Save
                  </v-btn>
                  <v-menu
                      v-if="Job.jobType >= 100 && Save"
                      class="pa-0"
                      left
                      offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          class="theme-color1"
                          dark
                          left
                          offset-x="true"
                          v-bind="attrs"
                          v-on="on"
                      >
                        <v-icon color="#fff" small>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list class="pa-0" dense>
                      <v-list-item
                          v-if="Job.jobType == 100"
                          dense
                          link
                          @click="Job.leadClosed = true"
                      >
                        <v-list-item-icon class="nav-icon mr-2">
                          <img src="../../assets/sidebar/briefcase.svg"/>
                        </v-list-item-icon>
                        <v-list-item-title
                            @click="
                            (Job.isQuote = true), AddorUpdate(Job.jobType + 10)
                          "
                        >Move to Quotation
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-btn-toggle>
              </v-toolbar>

              <v-card-text class="pb-2">
                <v-row class="mt-2 ps-5 pe-5">
                  <v-col class="pa-0" cols="12" md="6">
                    <v-text-field
                        v-model="Job.title"
                        :disabled="!editview"
                        class="custom-input my-input2"
                        color="#64718A "
                        dense
                        hide-details
                        label="Title"
                        outlined
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="pb-2">
                <v-row class="ps-5 pe-5">
                  <v-col class="pa-0 d-flex" cols="12" md="6">
                    <v-autocomplete
                        v-model="SelectedCustomer"
                        :disabled="!editview"
                        :filter="customSearch"
                        :items="Customers"
                        :loading="isLoading"
                        :menu-props="{ bottom: true, offsetY: true }"
                        :search-input.sync="search"
                        class="custom-input my-input"
                        dense
                        hide-details
                        hide-no-data
                        hide-selected
                        item-text="fullName"
                        item-value="customerId"
                        label="Customer"
                        outlined
                        return-object
                        @change="selectCustomer"
                    >
                      <template v-slot:item="data">
                        <template v-if="typeof data.item !== 'object'">
                          <v-list-item-content
                              v-text="data.item"
                          ></v-list-item-content>
                        </template>
                        <template v-else>
                          <v-list-item-content>
                            <v-list-item-title
                            >{{ data.item.fullName }}
                              <span class="caption">
                                <span v-if="data.item.phoneNumber !== ''"
                                >| +1-{{ data.item.phoneNumber }}
                                </span>
                                | {{ data.item.email }} |
                                <span class="text-truncate"> {{
                                    data.item.address + " " + data.item.city + " " + data.item.province
                                  }} | </span>
                                <span v-if="data.item.customerCategory === 100"> D </span>
                             <span v-if="data.item.customerCategory === 110"> W - <span
                                 class="text-truncate" style="max-width: 5px !important;">{{
                                 data.item.customerSubType.slice(0, 4)
                               }}</span> </span>
                               </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>

                    <v-btn
                        :disabled="Job.quoteApproved || !editview"
                        class="theme-color1"
                        depressed
                        height="40"
                        tile
                        @click="OpenCustomerDialog()"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-text class="">
                <v-row class="ps-5 pe-5">
                  <v-col class="pa-0" cols="12">
                    <v-textarea
                        v-model="Job.leadDescription"
                        :disabled="!editview"
                        auto-grow
                        class="rounded-0 my-input-common-border"
                        color="#64718A"
                        hide-details
                        label="Description"
                        outlined
                        rows="2"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text>
                <v-divider></v-divider>
              </v-card-text>
              <v-card-text class="pt-0 pb-0">
                <v-row class="ps-4 pe-5">
                  <v-col class="d-flex align-center" cols="12">
                    <div>
                      <v-switch
                          v-model="Switch"
                          :disabled="!editview"
                          class="custom-switch"
                          color="success darken-2"
                          inset
                      ></v-switch>
                    </div>
                    <div>

                      <label class="font-weight-bold d-block"
                      >On-Site Assessment required</label
                      >
                      <label
                      >Schedule an assessment to collect more information
                        before job.</label
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="pt-0">
                <v-row v-if="Switch" class="ps-5 pe-5">
                  <v-card class="pb-0" elevation="0" outlined tile width="100%">
                    <v-toolbar class="tab-color" dense elevation="0" tile>
                      <v-toolbar-title class="font-weight-bold theme-font-color"
                      >Assessment
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-row class="align-center">
                            <v-col class="d-flex" cols="12" md="6">
                              <v-row>
                                <v-col class="pa-3 pr-md-0" cols="12" md="6">
                                  <label>Assessment Date</label>
                                  <input
                                      v-model="Job.assessmentStartDate"
                                      :disabled="!switchControl || !editview"
                                      class="custom-Jobdate"
                                      type="date"
                                      value="2018-8-20"
                                      width="100%"
                                  />
                                </v-col>
                                <v-col class="pa-3 pl-md-0" cols="12" md="6">
                                  <label>Assessment Time</label>
                                  <input
                                      v-model="Job.assessmentStartTime"
                                      :disabled="!switchControl || !editview"
                                      class="custom-Jobdate"
                                      type="time"
                                      value="JobRequest.startTime"
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-divider vertical></v-divider>
                            <v-col>
                              <v-row class="pa-1 justify-end">
                                <v-col class="d-flex" cols="12">
                                  <v-autocomplete
                                      v-model="Job.jobEmployees"
                                      :disabled="!editview"
                                      :items="Workers"
                                      :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                    }"
                                      :search-input.sync="searchInput"
                                      class="custom-input my-input-common-border"
                                      dense
                                      hide-details
                                      item-text="fullName"
                                      item-value="employeId"
                                      label="Employees"
                                      multiple
                                      outlined
                                      return-object
                                      @input="searchInput=null"
                                  >
                                  </v-autocomplete>
                                </v-col>
                                <v-col class="pa-0 ps-3" cols="12">
                                  <v-chip
                                      v-for="item in Job.jobEmployees"
                                      :key="item.employeeId"
                                      class="float-left mr-2"
                                      label
                                      small
                                  >{{ item.fullName }}
                                  </v-chip>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-card-text class="pb-0">
                      <v-row class="pb-2">
                        <v-col cols="12">
                          <v-data-table
                              :headers="CompletedJobHeader"
                              :items="Job.jobEmployees"
                              :items-per-page="100"
                              class="custom-table elevation-0"
                              disable-sort
                              hide-default-footer
                          >
                            <template v-slot:[`item.sno`]="{ index }">
                              <span>{{ index + 1 }}</span>
                            </template>
                            <template v-slot:[`item.totalHours`]="{ item }">
                              <v-text-field
                                  v-model.number="item.totalHours"
                                  :disabled="!editview"
                                  :rules="[rules.discount]"
                                  class="wage-field"
                                  dense
                                  hide-details
                                  type="number"
                                  @blur="ChangeWage(item)"
                                  @focus="selectText"
                              ></v-text-field>
                            </template>

                            <template v-slot:[`item.visitType`]="{ item }">
                              <v-chip
                                  v-if="item.visitType == 10"
                                  class="type-chip"
                                  color="orange darken1"
                                  dark
                                  label
                                  x-small
                              >Assessment
                              </v-chip>
                              <v-chip
                                  v-if="item.visitType == 20"
                                  class="type-chip"
                                  color="purple"
                                  dark
                                  label
                                  x-small
                              >Job
                              </v-chip>
                            </template>
                            <template v-slot:[`item.action`]="{ item }">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                      class="me-1"
                                      color="primary"
                                      small
                                      v-bind="attrs"
                                      @click="TakeNotes(item.installerNotes),(ShowNotes = true)"
                                      v-on="on"
                                  >
                                    mdi-information
                                  </v-icon>
                                </template>
                                <span>Lead Notes</span>
                              </v-tooltip>
                              <v-icon
                                  color="primary"
                                  small
                                  @click="
                                  takeImages(item.jobImages),
                                    (showGallery = true)
                                "
                              >
                                mdi-image-outline
                              </v-icon>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                      v-if="item.jobCompleted == true"
                                      :disabled="!editview"
                                      class="ms-1"
                                      color="success"
                                      small
                                      v-bind="attrs"
                                      @click="item.jobCompleted = false"
                                      v-on="on"
                                  >mdi-checkbox-marked
                                  </v-icon
                                  >
                                  <v-icon
                                      v-else
                                      :disabled="!editview"
                                      class="ms-1"
                                      color="gray"
                                      small
                                      v-bind="attrs"
                                      @click="item.jobCompleted = true"
                                      v-on="on"
                                  >mdi-checkbox-marked
                                  </v-icon
                                  >
                                </template>
                                <span v-if="item.jobCompleted == false">Mark Task Complete</span>
                                <span v-else>UnMark Task Complete</span>
                              </v-tooltip>
                              <v-btn class="pt-0" icon small @click="SendMail(item)">
                                <i class="mgc_mail_send_line btn-icon email-btn-icon theme-font-color"></i>
                              </v-btn>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-row>
              </v-card-text>
              <v-card-text class="pb- pt-0">
                <v-row class="ps-5 pe-5 mt-0">
                  <v-card elevation="0" outlined tile width="100%">
                    <v-toolbar class="tab-color" dense elevation="0" tile>
                      <v-toolbar-title class="font-weight-bold theme-font-color"
                      >Services & Equipments
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text class="pb-0">
                      <v-row class="pt-0 mb-2">
                        <v-col cols="12" md="6">
                          <v-autocomplete
                              v-model="Service"
                              :disabled="!editview"
                              :items="Services"
                              :menu-props="{ bottom: true, offsetY: true }"
                              class="custom-input my-input-common-border"
                              clearable
                              color="#64718A"
                              dense
                              hide-details
                              item-text="title"
                              item-value="serviceId"
                              label="Services"
                              multiple
                              outlined
                              return-object
                              small-chips
                              @change="(event) => ServicesEquipments(10)"
                          >
                            <template v-slot:item="data">
                              <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content
                                    v-text="data.item"
                                ></v-list-item-content>
                              </template>
                              <template v-else>
                                <v-list-item-content>
                                  <v-list-item-title
                                  >{{ data.item.title }}
                                    <span
                                        v-if="!data.item.forWholesale"
                                        class="caption"
                                    >
                                      (Domestic)</span
                                    >
                                    <span
                                        v-if="data.item.forWholesale"
                                        class="caption"
                                    >
                                      (Wholesale /
                                      {{ data.item.wholesaleTitle }})</span
                                    >

                                  </v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-autocomplete
                              v-model="Equipment"
                              :disabled="!editview"
                              :items="Equipments"
                              :menu-props="{ bottom: true, offsetY: true }"
                              class="custom-input my-input-common-border"
                              clearable
                              color="#64718A"
                              dense
                              hide-details
                              item-text="title"
                              item-value="equipmentId"
                              label="Equipments"
                              multiple
                              outlined
                              return-object
                              small-chips
                              @change="(event) => ServicesEquipments(20)"
                          >
                            <template v-slot:item="data">
                              <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content
                                    v-text="data.item"
                                ></v-list-item-content>
                              </template>
                              <template v-else>
                                <v-list-item-content>
                                  <v-list-item-title
                                  >{{ data.item.title }}
                                    <span class="caption">
                                      ({{ data.item.details }})
                                    </span>

                                  </v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </template>

                          </v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row class="pb-2">
                        <v-col cols="12">
                          <v-data-table
                              :headers="headers"
                              :items="Job.jobItems"
                              :items-per-page="Filter.pageSize"
                              class="custom-table elevation-0"
                              disable-sort
                              hide-default-footer
                          >
                            <template v-slot:[`item.sno`]="{ index }">
                              <span>{{ index + 1 }}</span>
                            </template>
                            <template v-slot:[`item.jobItemType`]="{ item }">
                              <span v-if="item.jobItemType === 10">Service</span>
                              <span v-if="item.jobItemType === 20">Equipment</span>
                            </template>
                            <template v-slot:[`item.price`]="{item}">
                              <input v-model.number="item.price" :disabled="!editview"
                                     class="text-right total-input"
                                     min="0"
                                     step=".01"
                                     type="number"
                                     @blur="ChangeAmount"
                                     @focus="selectText"
                                     @input="FormatPrice(item)"
                              >
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                              <v-icon
                                  :disabled="!editview"
                                  color="red"
                                  small
                                  @click="deleteItem(item)"
                              >
                                mdi-trash-can-outline
                              </v-icon>
                            </template>
                          </v-data-table>
                          <v-divider class="mt-2"></v-divider>
                          <v-row class="mt-2 d-flex justify-end align-center">
                            <v-col cols="12" md="5">
                              <v-row>
                                <v-col class="d-flex justify-end">
                                  <span
                                      class="total-label text--primary text-right"
                                  >AMOUNT</span
                                  >
                                </v-col>
                                <v-col class="d-flex justify-end" cols="3">
                                  <!--                                  <span-->
                                  <!--                                      class="total-amount text&#45;&#45;primary me-4"-->
                                  <!--                                  >{{ Job.netAmount }}</span-->
                                  <!--                                  >-->
                                  <input v-model="netAmountFormatted"
                                         class="total-input border-bottom-hide"
                                         disabled
                                         min="0" placeholder='0.00' step='0.01' type='number' value='0.00'
                                         @blur="ChangeAmount"
                                         @focus="selectText"

                                  />
                                </v-col>
                              </v-row>
                              <v-row class="align-baseline">
                                <v-col class="pt-0 d-flex justify-end">
                                  <span class="total-label text--primary"
                                  >OTHERS</span
                                  >
                                </v-col>
                                <v-col class="d-flex justify-end pt-0" cols="3">
                                  <input
                                      v-model.number="otherChargesFormatted"
                                      :disabled="!editview"
                                      class="total-input" min="0" placeholder='0.00' step='0.01' type='number'
                                      value='0.00'
                                      @blur="ChangeAmount"
                                      @focus="selectText"

                                  />
                                </v-col>
                              </v-row>
                              <v-row class="align-baseline">
                                <v-col class="pt-1 d-flex justify-end">
                                  <span class="total-label text--primary"
                                  >DISCOUNT</span
                                  >
                                </v-col>
                                <v-col class="d-flex justify-end pt-1" cols="3">
                                  <input
                                      id="discount_input"
                                      v-model.number="discountFormatted"
                                      :disabled="!editview"
                                      class="total-input"
                                      min="0"
                                      step=".01"
                                      type="number"
                                      @blur="ChangeAmount"
                                      @focus="selectText"
                                  />
                                </v-col>
                              </v-row>
                              <!--                              <v-row>-->
                              <!--                                <v-col class="pt-0 d-flex justify-end">-->
                              <!--                                  <span class="total-label text&#45;&#45;primary"-->
                              <!--                                  >SUBTOTAL</span-->
                              <!--                                  >-->
                              <!--                                </v-col>-->
                              <!--                                <v-col cols="3" class="pt-0 d-flex justify-end">-->
                              <!--                                  <span-->
                              <!--                                      class="total-amount text&#45;&#45;primary me-4"-->
                              <!--                                  >{{ Job.subTotal }}</span-->
                              <!--                                  >-->
                              <!--                                </v-col>-->
                              <!--                              </v-row>-->
                              <v-row class="align-baseline">
                                <v-col class="pt-0 d-flex justify-end">
                                  <span class="total-label text--primary"
                                  >HST (%)</span
                                  >
                                </v-col>
                                <v-col class="d-flex justify-end pt-0" cols="3">
                                  <input
                                      id="tax_input"
                                      v-model.number="hstFormatted"
                                      :disabled="!editview"
                                      class="total-input"
                                      min="0"
                                      step=".01"
                                      type="number"
                                      @blur="ChangeAmount"
                                      @focus="selectText"
                                  />
                                </v-col>
                              </v-row>
                              <v-row class="align-baseline">
                                <v-col class="pt-1 d-flex justify-end">
                                  <span
                                      class="total-label text--primary"
                                  >HST Amount</span
                                  >
                                </v-col>
                                <v-col class="d-flex justify-end" cols="3">
                                  <input
                                      v-model.number="hstAmountFormatted"
                                      class="total-input border-bottom-hide"
                                      disabled
                                      min="0"
                                      type="number"
                                      @blur="ChangeAmount"
                                      @focus="selectText"
                                  />
                                  <!--                                  <span
                                                                        class="total-amount text&#45;&#45;primary me-4"
                                                                    >{{ Job.hstAmount }}</span
                                                                    >-->
                                </v-col>
                              </v-row>
                              <v-row class="align-baseline">
                                <v-col class="pt-1 d-flex justify-end">
                                  <span
                                      class="total-label text--primary font-weight-bold"
                                  >TOTAL</span
                                  >
                                </v-col>
                                <v-col class="d-flex justify-end" cols="3">

                                  <!--                                  <span-->
                                  <!--                                      class="total-amount text&#45;&#45;primary me-4 font-weight-bold"-->
                                  <!--                                  >{{ Job.grandTotal }}</span-->
                                  <!--                                  >-->
                                  <input
                                      v-model.number="totalFormatted"
                                      class="total-input border-bottom-hide total-amount text--primary font-weight-bold"
                                      disabled
                                      min="0"
                                      type="number"
                                      @blur="ChangeAmount"
                                      @focus="selectText"
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <!--      Lead Notes-->
      <v-dialog v-model="ShowNotes" max-width="700">
        <v-card class="pb-2">
          <v-card-title>
            <p class="title mb-0">{{ Job.title }}</p>
            <v-spacer></v-spacer>
            <v-icon color="red" @click="ShowNotes = false">mdi-close</v-icon>
          </v-card-title>
          <v-divider class="mb-2"></v-divider>
          <v-card-text>
            <template>
              <v-row>
                <v-col>
                  <span>
                    {{ EmployeeLeadNotes }}
                  </span>
                </v-col>
              </v-row>
            </template>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!--    Gallery-->
      <v-dialog v-model="showGallery" max-width="700">
        <v-card class="pb-2">
          <v-card-title>
            <p class="title mb-0">{{ Job.title }}</p>
            <v-spacer></v-spacer>
            <v-icon color="red" @click="showGallery = false">mdi-close</v-icon>
          </v-card-title>
          <v-divider class="mb-2"></v-divider>
          <v-card-text>
            <template>
              <v-row>
                <v-col
                    v-for="n in GalleryImages"
                    :key="n"
                    class="d-flex child-flex"
                    cols="4"
                >
                  <v-img
                      v-if="n != ''"
                      :src="n"
                      aspect-ratio="1"
                      class="grey lighten-2"
                  >
                    <template v-slot:placeholder>
                      <v-row
                          align="center"
                          class="fill-height ma-0"
                          justify="center"
                      >
                        <v-progress-circular
                            color="grey lighten-5"
                            indeterminate
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
                <span v-if="GalleryImages.length == 0" class="heading pa-2">No image uploaded yet</span>
              </v-row>
            </template>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!--      Add Customer-->
      <v-dialog v-model="addCustomerDialog" max-width="700">
        <v-card class="pb-2">
          <v-toolbar color="#efefef" dense elevation="0" flat tile>
            <v-btn
                class="mr-2 theme-color1"
                dark
                elevation="0"
                height="35px"
                tile
                @click="addCustomerDialog = false"
            >
              <v-icon dense>mdi-undo-variant</v-icon>
            </v-btn>
            <v-toolbar-title>
              <h4 class="text-uppercase theme-font-color">Add Customer</h4>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                :disabled="!Customer.firstName || !Customer.lastName"
                class="ps-10 pe-10 login-btns theme-color1"
                dark
                elevation="0"
                tile
                @click="AddorUpdateCustomer(), (addCustomerDialog = false)"
            >Save
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row class="mt-5">
                <v-col class="pa-0" cols="12" md="6">
                  <v-text-field
                      v-model="Customer.firstName"
                      :rules="[rules.required]"
                      class="custom-input my-input"
                      color="#64718A "
                      dense
                      hide-details
                      label="First Name"
                      outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pa-0" cols="12" md="6">
                  <v-text-field
                      v-model="Customer.lastName"
                      :rules="[rules.required]"
                      class="custom-input my-input2"
                      color="#64718A "
                      dense
                      hide-details
                      label="Last Name"
                      outlined
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="">
                <v-col class="pa-0" cols="12" md="6">
                  <v-text-field
                      v-model="Customer.email"
                      :rules="[rules.required]"
                      class="custom-input border-right"
                      color="#64718A "
                      dense
                      hide-details
                      label="Email"
                      outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pa-0" cols="12" md="6">
                  <v-text-field
                      v-model="Customer.phoneNumber"
                      v-mask="['###-###-####']"
                      :rules="[rules.required]"
                      class="custom-input my-input-common-border"
                      color="#64718A "
                      dense
                      hide-details
                      label="Phone"
                      outlined
                      prefix="+1"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-10">
                <v-col class="pa-0" cols="12" md="6">
                  <v-text-field
                      v-model="Customer.address"
                      class="custom-input my-input"
                      color="#64718A "
                      dense
                      hide-details
                      label="Address"
                      outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pa-0" cols="12" md="6">
                  <v-text-field
                      v-model="Customer.city"
                      class="custom-input my-input2"
                      color="#64718A "
                      dense
                      hide-details
                      label="City"
                      outlined
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pa-0" cols="12" md="6">
                  <v-text-field
                      v-model="Customer.postalCode"
                      class="custom-input border-right"
                      color="#64718A "
                      dense
                      hide-details
                      label="Postal Code"
                      outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pa-0" cols="12" md="6">
                  <v-autocomplete
                      v-model="Customer.country"
                      :items="Countries"
                      :menu-props="{ bottom: true, offsetY: true }"
                      :return-object="false"
                      class="custom-input my-input-common-border"
                      dense
                      hide-details
                      item-text="name.common"
                      item-value="name.common"
                      label="Country"
                      outlined
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row v-if="Job.jobType == 110" class="ps-2">
                <v-col class="pa-0 d-flex align-center" cols="12" md="6">
                  <v-switch
                      v-model="CustomerSwitch"
                      class="custom-switch"
                      color="success darken-2"
                      inset
                      @change="ChangeView(Switch)"
                  ></v-switch>
                  <div>
                    <span
                        v-if="CustomerSwitch"
                        class="font-weight-bold d-block grey--text text--darken-2"
                    >Wholesale</span
                    >
                    <span
                        v-if="!CustomerSwitch"
                        class="font-weight-bold d-block grey--text text--darken-2"
                    >Domestic</span
                    >
                  </div>
                </v-col>
                <v-col class="pa-0 mt-3" cols="12" md="6">
                  <v-autocomplete
                      v-model="Customer.customerSubType"
                      :disabled="!CustomerSwitch"
                      :items="customersubtypes"
                      :menu-props="{ bottom: true, offsetY: true }"
                      :return-object="false"
                      class="custom-input bordered"
                      color="#64718A "
                      dense
                      hide-details
                      item-text="value"
                      item-value="value"
                      label="Customer Type"
                      outlined
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <v-progress-circular
        v-if="loader"
        class="custom-loader"
        color="#64718A"
        indeterminate
        width="2"
    >
    </v-progress-circular>
  </div>
</template>

<script>
import axios from "axios";
import config from "../Configuration/config";

export default {
  name: "AddLead",
  data() {
    return {
      name: "Lead",
      addCustomerDialog: false,
      showGallery: false,
      EmployeeLeadNotes: "",
      ShowNotes: false,
      editview: false,
      descriptionLimit: 60,
      searchInput: null,
      entries: [],
      isLoading: false,
      model: null,
      search: "",
      Print: false,
      Save: false,
      paymentDialg: false,
      CustomerSwitch: false,
      JobSave: false,
      Switch: false,
      switchControl: true,
      dialog: false,
      loader: false,
      loader2: false,
      jobview: false,
      rules: {
        tax: (value) => (value >= 0 && value <= 100) || "Invalid tax ",
        discount: (value) => value >= 0 || "Invalid Number",
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },

      AssignedEmployees: [],
      CompletedJobHeader: [
        {
          text: "#",
          align: "left",
          value: "sno",
          width: "70px",
        },
        {
          text: "Name",
          align: "left",
          value: "fullName",
        },
        {
          text: "Visit",
          align: "center",
          value: "visitType",
        },
        {
          text: "Hourly Rate",
          align: "start",
          value: "employee.hourlyRate",
          width: "150px",
        },
        {
          text: "Worked Hours",
          align: "start",
          value: "totalHours",
          width: "150px",
        },

        {
          text: "Net Wage",
          align: "center",
          value: "totalWage",
          width: "150px",
        },
        {
          text: "Action",
          value: "action",
          width: "120px",
          align: "center",
        },
      ],
      headers: [
        {
          text: "#",
          align: "left",
          value: "sno",
          width: "70px",
        },
        {
          text: "Title",
          align: "left",
          value: "title",
        },
        {
          text: "JobItem Type",
          value: "jobItemType"
        },
        {
          text: "Type",
          value: "customerType",
        },
        {
          text: "Amount",
          align: "right",
          value: "price",
          width: "100px",
        },
        {
          text: "",
          value: "actions",
          width: "50px",
        },
      ],
      editedItem: {},
      worker: 0,
      requestDateMenu: false,
      customerdatemenu: false,
      SelectedCustomer: null,
      btnText: "Add Job Request",
      valid: true,
      Services: [],
      ServicesHeader: [
        {
          // text: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" + "#",
          text: "#",
          align: "center",
          value: "sno",
          width: "80px",
        },
        {
          text: "Service",
          value: "title",
        },
        {
          text: "Action",
          align: "right",
          value: "action",
        },
      ],
      EmployeesHeader: [
        {
          text: "#",
          // text: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" + "#",
          align: "center",
          value: "sno",
          width: "80px",
        },
        {
          text: "Name",
          value: "fullName",
        },
        {
          text: "Action",
          align: "center",
          value: "action",
        },
      ],
      customersubtypes: [],
      Customer: {
        companyId: axios.defaults.headers.common.CompanyId,
        customerId: 0,
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        address: "",
        city: "",
        province: "",
        postalCode: "",
        customerSubType: "",
        country: "",
        notes: "",
        customerCategory: 100,
        taskId: "",
        swo: "",
      },
      Countries: [],
      customerTypes: null,
      Job: {
        companyId: axios.defaults.headers.common.CompanyId,
        jobId: 0,
        title: "",
        assessmentStartDate: new Date(),
        assessmentStartTime: new Date(),
        markAsQoute: false,
        isApproved: false,
        jobStatus: 0,
        jobNote: "",
        isLead: false,
        isQuote: false,
        isJob: false,
        isInvoice: false,
        leadStatus: 1,
        quoteStatus: 0,
        customerId: 0,
        quoteApproved: false,
        quoteCancelled: false,
        leadClosed: false,
        leadDescription: "",
        quoteDescription: "",
        taskId: "",
        swo: "",
        isPaid: false,
        paymentMethod: 0,
        discount: 0.00,
        hst: 13.00,
        hstAmount: 0,
        otherCharges: 0,
        paidAmount: 0,
        dueAmount: 0,
        netAmount: 0,
        grandTotal: 0,
        jobItems: [],
        jobEmployees: [],
      },
      HstAmount: 0,
      Customers: [],
      ServicesWorkers: [],
      Equipments: [],
      Workers: null,
      Filter: {
        CompanyId: axios.defaults.headers.common.CompanyId,
        page: 1,
        pageSize: 10,
        pagination: null,
        pageNumOnSearch: "",
        filterText: "",
        customerCategory: 100,
        forDomestic: true,
        forWholesale: false,
      },
      Service: [],
      Equipment: [],
      GalleryImages: [],
      netTax: 13,
      dnetDiscount: 0,
      netCharges: 0,
      decimals: 2,
    };
  },

  computed: {
    netAmountFormatted: {
      get() {
        return this.Job.netAmount.toFixed(2);
      },
      set(value) {
        this.Job.netAmount = parseFloat(value);
      }
    },
    otherChargesFormatted: {
      get() {
        return this.Job.otherCharges.toFixed(2);
      },
      set(value) {
        this.Job.otherCharges = parseFloat(value);

      }
    },
    discountFormatted: {
      get() {
        return this.Job.discount.toFixed(2);
      },
      set(value) {
        this.Job.discount = parseFloat(value);
      }
    },
    hstFormatted: {
      get() {
        return this.Job.hst.toFixed(2);
      },
      set(value) {
        this.Job.hst = parseFloat(value);
      }
    },
    hstAmountFormatted: {
      get() {
        return this.Job.hstAmount.toFixed(2);
      },
      set(value) {
        this.Job.hstAmount = parseFloat(value);
      }
    },
    totalFormatted: {
      get() {
        return this.Job.grandTotal.toFixed(2);
      },
      set(value) {
        this.Job.grandTotal = parseFloat(value);
      }
    },

  },
  methods: {
    FormatPrice(item) {
      //var index = this.Job.jobItems.indexOf(item);
      //item.amount.toFixed(2);
      item.price = Math.round(item.price * 100) / 100;
    },

    FormatNumber(num) {
      return num.toFixed(2);
    },
    selectText(event) {
      event.target.select();
    },
    ChangeAmount() {
      if (isNaN(this.Job.otherCharges) || this.Job.otherCharges < 0) {
        this.Job.otherCharges = 0
      }
      if (isNaN(this.Job.discount) || this.Job.discount < 0) {
        this.Job.discount = 0
      }
      if (isNaN(this.Job.hst) || this.Job.hst < 0) {
        this.Job.hst = 0
      }
      if (this.Job.jobItems.length < 1) {
        this.Job.netAmount = 0;
        this.Job.discount = 0;
        this.Job.hst = 13;
        this.Job.otherCharges = 0;
        this.Job.grandTotal = 0;
        return;
      }
      if (this.Job.jobItems.length > 0) {
        this.Job.jobItems.forEach(element => {
          if (element.price == "") {
            element.price = 0;
          }
        })
      }
      this.Job.netAmount = this.Job.jobItems.reduce(
          (acc, o) => acc + parseFloat(o.price),
          0
      );
      if (this.Job.discount > this.Job.netAmount) {
        this.Job.discount = this.Job.netAmount;
      }
      let subTotal = this.Job.netAmount + this.Job.otherCharges - this.Job.discount;

      this.Job.grandTotal = subTotal;
      if (this.Job.hst > 100) {
        this.Job.hst = 100;
        var element = document.getElementById("tax_input");
        element.classList.add("taxInput");
      } else if (this.Job.hst < 0) {
        this.Job.hst = 13;
        var element2 = document.getElementById("tax_input");
        element2.classList.add("taxInput");
      }
      if (this.Job.hst > 0 || this.Job.hst <= 100) {
        this.Job.hstAmount = (this.Job.hst / 100) * subTotal;
        this.Job.grandTotal = this.Job.hstAmount + subTotal;
      }
    },
    customSearch(item, queryText) {
      return (
          item.fullName
              .toLocaleLowerCase()
              .indexOf(queryText.toLocaleLowerCase()) > -1 ||
          item.email.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
          item.phoneNumber
              .toLocaleLowerCase()
              .indexOf(queryText.toLocaleLowerCase()) > -1 ||
          item.address
              .toLocaleLowerCase()
              .indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    takeImages(jobImages) {
      this.GalleryImages = [];
      if (jobImages != null) {
        const imageurl = jobImages.split(/\s*,\s*/);
        if (!this.GalleryImages.includes(...imageurl)) {
          this.GalleryImages.push(...imageurl);
        }
      }
    },
    TakeNotes(InstallerNotes) {
      this.EmployeeLeadNotes = InstallerNotes;
    },
    async SendMail(employee) {
      try {
        this.loader = true;
        var fd = new FormData();
        fd.append("employeeId", employee.employeId);
        fd.append("jobId", this.Job.jobId);
        let response = await axios({
          method: 'post',
          url: config.apiBaseUrl + "/api/Job/SendEmployeeMail",
          data: fd,
          headers: {
            "Content-Type": "mutlipart/formdata",
            Accept: "application/json",
          }
        });
        if (response.data == true) {
          this.$toast.success("Mail Sent successfully", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnfocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
          });
        } else {
          this.$toast.warning("Mail sending failed!.", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnfocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
          });
        }
        this.loader = false;
      } catch (error) {
        this.loader = false;
        console.log(error);
        this.$toast.warning("Mail sending failed!.", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },
    OpenCustomerDialog() {
      this.addCustomerDialog = true;
      setTimeout(this.validate, 200);
    },
    validate() {
      this.$refs.form.validate();
    },
    ServicesEquipments(type) {
      var index = this.Service.length - 1;
      var equipmentindex = this.Equipment.length - 1;
      if (index > -1) {
        if (type == 10) {
          if (this.Service[0].wholesaleTitle === "") {
            this.Service[0].wholesaleTitle = "Domestic"
          }
          if (!this.Job.jobItems.some(service => service.title === this.Service[0].title && service.customerType === this.Service[0].wholesaleTitle)) {
            this.Job.jobItems.push(this.Service[0])
            var service = this.Job.jobItems.find(({serviceId}) => serviceId === this.Service[0].serviceId);
            service.jobItemType = 10;
            if (this.Service[0].wholesaleTitle !== "") {
              service.customerType = this.Service[0].wholesaleTitle
            } else {
              service.customerType = "Domestic"
            }
          }
          this.Service = [];
          //this.Job.jobItems[index].customerType  = this.Job.jobItems[index].wholesaleTitle;
        }
      }
      if (equipmentindex > -1)
        if (type == 20) {
          if (!this.Job.jobItems.some(equipment => equipment.title === this.Equipment[0].title && equipment.details === this.Equipment[0].details)) {
            this.Job.jobItems.push(this.Equipment[0])
            var equipment = this.Job.jobItems.find(({equipmentId}) => equipmentId === this.Equipment[0].equipmentId);
            equipment.jobItemType = 20;
            equipment.customerType = this.Equipment[0].details;
          }
          this.Equipment = [];
        }
      this.ChangeAmount();
    },
    ChangeWage(item) {
      var index = this.Job.jobEmployees.indexOf(item);
      if (!isNaN(item.totalHours)) {
        if (item.totalHours > 0) {
          item.totalWage =
              item.totalHours * this.Job.jobEmployees[index].employee.hourlyRate;
        }
      }
    },
    ChangeView(type) {
      if (!type) {
        this.Customer.customerCategory = 100;
        this.Customer.customerSubType = "";
      } else {
        this.Customer.customerCategory = 110;
      }
    },
    formatPhoneNumber() {
      this.Customer.phone = this.Customer.phone
          .replace(/[^0-9]/g, "")
          .replace(/^(\d{3})(\d{3})(\d{4})/g, "$1-$2-$3")
          .substring(0, 12);
    },
    async GetEquipments() {
      if (this.$route.name == "AddLead") {
        try {
          this.loader = true;
          let response = await axios.post(
              config.apiBaseUrl + "/api/Equipment/GetAll",
              {
                pageSize: this.Filter.pageSize,
                page: this.Filter.page,
                filterText: this.Filter.filterText,
                isActive: true,
              }
          );
          this.Equipments = response.data.data;
          this.loader = false;
        } catch (err) {
          this.loader = false;
        }
      }
    },

    async GetCountries() {
      let response = await axios.get("https://restcountries.com/v3.1/all");
      this.Countries = response.data;
      this.Countries = response.data;
      var canada = this.Countries.filter((x) => x.name.common == "Canada");
      this.Customer.country = canada[0].name.common;
    },
    selectCustomer() {
      this.Job.customerId = this.SelectedCustomer.customerId;
      if (this.SelectedCustomer.customerCategory == 100) {
        this.Filter.forDomestic = true;
        this.Filter.forWholesale = false;
        this.Filter.filterText = "";
      } else {
        this.Filter.forDomestic = false;
        this.Filter.forWholesale = true;
        this.Filter.filterText = "";
      }
      this.GetServices();
    },
    AddService(item) {
      this.Job.jobItems.push(item);
    },
    async GetCustomerType() {
      try {
        const fd = new FormData();
        fd.append("type", 30);
        let response = await axios.get(config.apiBaseUrl + "/api/KeyValue/All");
        this.customerTypes = response.data.filter((x) => x.type == 10);
        this.customersubtypes = response.data.filter((x) => x.type == 30);
      } catch (error) {
        this.loader = false;

      }
    },
    async AddorUpdateCustomer() {
      try {
        this.loader = true;
        if (this.CustomerSwitch) {
          this.Customer.customerCategory = 110;
        } else {
          this.Customer.customerCategory = 100;
        }
        if (this.Customer.phoneNumber !== "") {
          this.Customer.phoneNumber = this.Customer.phoneNumber.replace(/-/g, "");
        }
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Customer/AddOrUpdate",
          data: this.Customer,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        this.SelectedCustomer = response.data.data;
        this.Job.customerId = this.SelectedCustomer.customerId;
        this.search = this.SelectedCustomer.fullName;
        this.loader = false;
        this.selectCustomer();
        this.loader = false;
      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },

    async GetServices() {
      try {
        this.loader = true;
        let response = await axios({
          method: "POST",
          url: config.apiBaseUrl + "/api/Service/GetServicesByCustomer",
          data: this.Filter,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        this.Services = response.data.data;
        this.loader = false;
      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },
    async GetWorkers() {
      try {
        this.loader2 = true;
        const fd = new FormData();
        fd.append("serviceId", this.editedItem.serviceId);
        let response = await axios({
          method: "POST",
          url: config.apiBaseUrl + "/api/Employee/GetWorkers",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        this.ServicesWorkers = response.data;
        this.loader2 = false;
      } catch (error) {
        this.loader = false;

      }
    },
    async AddorUpdate() {
      try {

        this.loader = true;
        this.Job.jobItems.forEach((element) => {
          if (element.taxRate == null && element.discount == null) {
            element.amount = element.price;
          }
          element.service = null;
        });
        if (this.Job.jobEmployees != null && this.Job.jobEmployees.length > 0) {
          this.Job.jobEmployees.forEach((element) => {
            element.visitType = 10;
          });
        }
        this.Job.isLead = true;

        if (!this.Switch) {
          this.Job.assessmentStartDate = null;
          this.Job.assessmentStartTime = null;
        }
        let response = await axios({
          method: "POST",
          url: config.apiBaseUrl + "/api/Job/AddOrUpdateLead",
          data: this.Job,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        if (response.data.isSuccessful == true) {
          if (this.Job.jobEmployees.length > 0) {
            this.$toast.success("Notification has been sent.", {
              position: "top-center",
              timeout: 1041,
              closeOnClick: true,
              pauseOnfocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.79,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: false,
              icon: true,
              rtl: false,
            });
          }
        }
        this.$router.push("/leads");
        this.loader = false;
      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },
    async GetAllWorker() {
      try {
        this.loader = true;
        let response = await axios.post(
            config.apiBaseUrl + "/api/Employee/GetAllForAssessment",
            {
              pageSize: this.pageSize,
              page: this.page,
              filterText: this.filterText,
              isActive: true,
            }
        );
        this.Workers = response.data;
        this.pagination = response.data.pagination;
        this.loader = false;
      } catch (err) {
        this.loader = false;
        this.$toast.warning(err.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      var index = this.Job.jobItems.indexOf(item);
      this.Job.jobItems.splice(index, 1);
      this.ChangeAmount();
    },
    save() {
      if (this.Job.jobItems.length == 0) {
        this.Job.hst = 13;
        this.Job.netAmount = 0;
        this.Job.grandTotal = 0;
        this.Job.discount = 0;
        return;
      }
      this.Job.jobItems.push(this.editedItem);
      this.close();
    },
    async GetJobRequest() {
      if (this.$route.params.item != undefined) {
        this.loader == true;
        this.Job = this.$route.params.item;
        if (this.$route.params.editView != undefined) {
          this.editview = this.$route.params.editView;
        } else {
          this.editview = true;
        }
        try {
          const fd = new FormData();
          fd.append("jobId", this.Job.jobId);
          fd.append("visitType", 10);
          let response = await axios({
            method: "post",
            url: config.apiBaseUrl + "/api/Job/Get",
            data: fd,
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
            },
          });
          this.Job = response.data.job;
          if (
              this.Job.jobEmployees != null &&
              this.Job.jobEmployees.length > 0
          ) {
            this.Switch = true;
            this.switchControl = true;
          }
          if (this.Job.assessmentStartDate != null) {
            this.Job.assessmentStartDate = this.Job.assessmentStartDate.substring(0, 10);
          }
          if (this.Job.assessmentStartTime != null) {
            this.Job.assessmentStartTime = this.Job.assessmentStartTime.substring(11, 16);
          }
          this.SelectedCustomer = this.Job.customer;
          this.Customers.push(this.Job.customer);
          this.ChangeAmount();
          this.loader == false;
          this.selectCustomer();
        } catch (error) {
          this.loader = false;
          this.$toast.warning(error.message, {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnfocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
          });
        }
      } else {
        this.editview = !this.editview;
      }
    },
  },
  watch: {


    async search(val) {
      if (this.isLoading) return;
      if (val == "" || val == null) {
        return;
      }
      this.isLoading = true;
      // Lazily load input items
      this.loader = true;
      this.Filter.filterText = val;
      this.Filter.customerCategory = 100;
      let response = await axios({
        method: "POST",
        url: config.apiBaseUrl + "/api/Customer/GetCustomers",
        data: this.Filter,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      this.Customers = response.data.data;
      this.loader = false;
      this.isLoading = false;
    },
  },
  created() {
    this.$store.state.DisableCompany = true;
    this.Filter.customerCategory = 100;
    this.GetEquipments();
    this.GetJobRequest();
    this.GetCountries();
    this.GetCustomerType();
    this.GetAllWorker();
    this.$root.$on("myEvent", () => {
      this.GetEquipments();
      this.GetCountries();
      this.GetCustomerType();
      this.GetAllWorker();
    });
  },
};
</script>
<style scoped>
.v-btn-toggle .v-btn.v-btn.v-size--default {
  min-width: 25px;
  min-height: 0;
}

.nav-icon img {
  width: 16px !important;
}

.menu-list.v-list-item {
  height: 30px;
  padding-right: 8px !important;
  padding-left: 8px !important;
}

.menu-list {
  padding: 0px !important;
  box-shadow: none;
}

.total-label {
  font-size: 15px;
  margin-left: 80px;
}

.total-amount {
  font-size: 15px;
}

.wage-field input {
  text-align: right !important;
  align-items: right !important;
}

.total-input {
  border-bottom: 1px solid grey;
  width: 75px !important;
  font-size: 15px !important;
  text-align: right;
}

.total-input:focus {
  outline: none !important;
}

.taxInput {
  border: 1px solid red;
}
</style>
