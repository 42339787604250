import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import lottie from 'lottie-web';
import axios from 'axios'
import VueTheMask from 'vue-the-mask'
import {defineElement} from 'lord-icon-element';
// define "lord-icon" custom element with default properties
defineElement(lottie.loadAnimation);

//Apex Charts
import VueApexCharts from 'vue-apexcharts'

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)


// //Vue toast
import Toast, {POSITION} from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import config from "@/components/Configuration/config";

Vue.use(Toast, {
    // Setting the global default position
    position: POSITION.TOP_CENTER,
    transition: "Vue-Toastification__fade",
    maxToasts: 20,
    newestOnTop: true,
});

//Auto logout Code
// Set the timeout duration in milliseconds
const timeoutDuration = 10 * 60 * 1000; // 10 minutes

// Define the function to logout the user
const logoutUser = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("isLoggedin");
    sessionStorage.removeItem('role');
    // eslint-disable-next-line no-unused-vars
    axios.get(config.apiBaseUrl + "/api/User/Logout").then(r => {
    });
    axios.defaults.headers.common["Authorization"] = null,
        store.state.LoggedIn = false,
        router.push('/')
}
// Set a timer to logout the user after the timeout duration
let timeoutId;
const startTimeout = () => {
    timeoutId = setTimeout(logoutUser, timeoutDuration);
};
// Reset the timer when the user interacts with the app
const resetTimeout = () => {
    clearTimeout(timeoutId);
    startTimeout();
};

// Add an event listener to reset the timer on user interaction
window.addEventListener('mousemove', resetTimeout);
window.addEventListener('keypress', resetTimeout);
window.addEventListener('scroll', resetTimeout);

// Axios default header token
axios.defaults.headers.common["Authorization"] =
    "Bearer " + sessionStorage.getItem("token");
axios.defaults.headers.common["CompanyId"] = sessionStorage.getItem("CompanyId");
store.state.LoggedIn = sessionStorage.getItem("isLoggedin");
store.state.role = sessionStorage.getItem("role");
axios.defaults.baseURL = config.apiBaseUrl;
Vue.config.productionTip = false
Vue.use(VueTheMask)
new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')