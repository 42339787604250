<template>
  <div class="">
    <v-row justify="space-between">
      <v-col class="d-flex" cols="12" sm="4">
        <v-btn class="mr-2 theme-color1" dark elevation="0" tile>
          <v-icon>mdi-view-dashboard</v-icon>
        </v-btn>
        <h2 class="text-uppercase theme-font-color">{{ name }}</h2>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="mb-1 d-flex ps-0" cols="12" sm="5">
        <v-col class="d-flex align-center pa-0 ma-0">
          <label class="caption me-0">FROM</label>
          <v-menu
              v-model="fromDateMenu"
              :close-on-content-click="false"
              max-width="260"
              offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  :menu-props="{ bottom: true, offsetY: true }"
                  :value="computedFromDate"
                  append-icon="mdi-calendar"
                  class="custom-input my-input-common-border ms-1 month-input"
                  dense
                  hide-details
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="fromDate"
                class="custom-input my-input-common-border"
                no-title
                width="260"
                @change="fromDateMenu = false, changeFromDate()"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="d-flex align-center pa-0 ma-0">
          <label class="caption me-1 ms-1">TO</label>
          <v-menu
              v-model="toDateMenu"
              :close-on-content-click="false"
              max-width="260"
              offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  :menu-props="{ bottom: true, offsetY: true }"
                  :value="computedToDate"
                  append-icon="mdi-calendar"
                  class="custom-input my-input-common-border month-input"
                  dense
                  hide-details
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="toDate"
                :menu-props="{ bottom: true, offsetY: true }"
                class="custom-input my-input-common-border"
                no-title
                width="260"
                @change="toDateMenu = false , changeToDate()"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-col>
    </v-row>
    <div class="custom-line"></div>
    <v-row class="mt-1" justify="space-between">
      <v-col class="pe-0" cols="12" md="4">
        <v-card class="align-center " elevation="0" height="150" outlined tile>
          <v-card-title class="pa-2 justify-center text-uppercase font-weight-bold">LEADS</v-card-title>
          <v-card-text class="custom-card">
            <v-row>
              <v-col>
                <span class="caption d-flex justify-center">Open</span>
                <div class="d-flex justify-center">
                  <h1 v-if="Data == null" class="theme-font-color display-3 dashboard-h1" @click="ChangeRoute(1)">0</h1>
                  <h1 v-else class="theme-font-color display-3 dashboard-h1" @click="ChangeRoute(1)">{{
                      Data.openLeads
                    }}</h1>
                </div>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col>
                <span class="caption d-flex justify-center">Assigned</span>
                <div class="d-flex justify-center">
                  <h1 v-if="Data == null" class="theme-font-color display-3 dashboard-h1" @click="ChangeRoute(1)">0</h1>
                  <h1 v-else class="theme-font-color display-3 dashboard-h1" @click="ChangeRoute(1)">
                    {{ Data.assignedLeads }}</h1>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="pe-0" cols="12" md="4">
        <v-card class="align-center " elevation="0" height="150" outlined tile>
          <v-card-title class="pa-2 justify-center text-uppercase font-weight-bold">Quotes</v-card-title>
          <v-card-text class="custom-card">
            <v-row>
              <v-col>
                <span class="caption d-flex justify-center">Open</span>
                <div class="d-flex justify-center">
                  <h1 v-if="Data == null" class="theme-font-color display-3 dashboard-h1" @click="ChangeRoute(2)">0</h1>
                  <h1 v-else class="theme-font-color display-3 dashboard-h1" @click="ChangeRoute(2)">{{
                      Data.openQuotes
                    }}</h1>
                </div>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col>
                <span class="caption d-flex justify-center">Approved</span>
                <div class="d-flex justify-center">
                  <h1 v-if="Data == null" class="theme-font-color display-3 dashboard-h1" @click="ChangeRoute(2)">0</h1>
                  <h1 v-else class="theme-font-color display-3 dashboard-h1" @click="ChangeRoute(2)">
                    {{ Data.approvedQuotes }}</h1>
                </div>
              </v-col>

            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="align-center " elevation="0" height="150" outlined tile>
          <v-card-title class="pa-2 justify-center text-uppercase font-weight-bold">Jobs / Units</v-card-title>
          <v-card-text class="custom-card">
            <v-row>
              <v-col>
                <span class="caption d-flex justify-center">In Progress</span>
                <div class="d-flex justify-center">
                  <h1 v-if="Data == null" class="theme-font-color display-3 dashboard-h1" @click="ChangeRoute(3)">0</h1>
                  <h1 v-else class="theme-font-color display-3 dashboard-h1" @click="ChangeRoute(3)">{{
                      Data.jobs
                    }}</h1>
                </div>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col>
                <span class="caption d-flex justify-center">Units</span>
                <div class="d-flex justify-center">
                  <h1 v-if="Data == null" class="theme-font-color display-3 dashboard-h1" @click="ChangeRoute(4)">0</h1>
                  <h1 v-else class="theme-font-color display-3 dashboard-h1" @click="ChangeRoute(4)">{{
                      Data.units
                    }}</h1>
                </div>
              </v-col>

            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pe-0" cols="12" md="4">
        <v-card class="align-center " elevation="0" height="150" outlined tile>
          <v-card-title class="pa-2 justify-center text-uppercase font-weight-bold">Invoices</v-card-title>
          <v-card-text class="custom-card">
            <v-row>
              <v-col>
                <span class="caption d-flex justify-center">To Be Sent</span>
                <div class="d-flex justify-center">
                  <h1 v-if="Data == null" class="theme-font-color display-3 dashboard-h1" @click="ChangeRoute(5)">0</h1>
                  <h1 v-else class="theme-font-color display-3 dashboard-h1" @click="ChangeRoute(5)">{{
                      Data.toBeSent
                    }}</h1>
                </div>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col>
                <span class="caption d-flex justify-center">To Be Paid</span>
                <div class="d-flex justify-center">
                  <h1 v-if="Data == null" class="theme-font-color display-3 dashboard-h1" @click="ChangeRoute(5)">0</h1>
                  <h1 v-else class="theme-font-color display-3 dashboard-h1" @click="ChangeRoute(5)">{{
                      Data.toBePaid
                    }}</h1>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="pe-0" cols="12" md="4">
        <v-card class="align-center " elevation="0" height="150" outlined tile>
          <v-card-title class="pa-2 justify-center text-uppercase font-weight-bold">Receivable</v-card-title>
          <v-card-text class="custom-card">
            <v-row class="pt-0">
              <v-col class="pt-0">
                <span class="caption d-flex justify-center pt-0">Amount</span>
                <div class="d-flex justify-center">
                  <h1 v-if="Data == null" class="theme-font-color display-2" @click="ChangeRoute(5)">0</h1>
                  <h1 v-else class="theme-font-color display-2" @click="ChangeRoute(5)">{{
                      FormatPrice(Data.recievableAmount)
                    }}</h1>
                </div>
              </v-col>

            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="align-center " elevation="0" height="150" outlined tile>
          <v-card-title class="pa-2 justify-center text-uppercase font-weight-bold">Alerts</v-card-title>
          <v-card-text class="custom-card">
            <v-row>
              <v-col>
                <span class="caption d-flex justify-center">Sent</span>
                <div class="d-flex justify-center">
                  <h1 v-if="Data == null" class="theme-font-color display-3 dashboard-h1" @click="ChangeRoute(6)">0</h1>
                  <h1 v-else class="theme-font-color display-3 dashboard-h1" @click="ChangeRoute(6)">{{
                      Data.sentAlerts
                    }}</h1>
                </div>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col>
                <span class="caption d-flex justify-center">Pending</span>
                <div class="d-flex justify-center">
                  <h1 v-if="Data == null" class="theme-font-color display-3 dashboard-h1" @click="ChangeRoute(6)">0</h1>
                  <h1 v-else class="theme-font-color display-3 dashboard-h1" @click="ChangeRoute(6)">{{
                      Data.pendingAlerts
                    }}</h1>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>


    <v-row class="mt-1">
      <v-col class="chart-col" cols="12" md="6">
        <v-card elevation="0" outlined tile>
          <LeadChart/>
        </v-card>
      </v-col>
      <v-col class="chart-col" cols="12" md="6">
        <v-card elevation="0" outlined tile>
          <ClosedQuoteWonChart/>
        </v-card>
      </v-col>
    </v-row>
    <v-progress-circular v-if="loader" class="custom-loader" color="#64718A" indeterminate
                         width="2"></v-progress-circular>

  </div>
</template>

<script>
import LeadChart from "@/components/Dashboards/Charts/LeadChart";
import ClosedQuoteWonChart from "@/components/Dashboards/Charts/ClosedQuoteWonChart";
import axios from "axios";
import config from "../Configuration/config";
import moment from "moment";
import 'moment-timezone';

export default {
  name: "DashboardComp",
  components: {
    ClosedQuoteWonChart,
    LeadChart,
  },
  data: function () {
    return {
      name: "Dashboard",
      loader: false,
      menu: false,
      fromDateMenu: false,
      toDateMenu: false,
      fromDate: new Date(new Date().setDate(1)).toISOString(),
      toDate: new Date().toISOString(),
      DashboardTypes: [
        {text: "Sale Dashboard", value: 50},
        {text: "Salaried", value: 60},
        {text: "Per Task", value: 70},
      ],
      Data: null,
      DashboardSelect: [],
      decimals: 2,
    };
  },
  methods: {

    FormatPrice(price) {
      return Math.round(price * 10 ** this.decimals) / 10 ** this.decimals
    },
    ChangeRoute(type) {
      switch (type) {
        case 1:
          this.$router.push('/leads')
          break;
        case 2:
          this.$router.push('/qoutations')
          break;
        case 3:
          this.$router.push('/jobs')
          break;
        case 4:
          this.$router.push('/services')
          break;
        case 5:
          this.$router.push('/invoices')
          break;
        case 6:
          this.$router.push('/alerts')
          break;
      }
    },
    changeFromDate() {
      if (this.fromDate > this.toDate) {
        this.$toast.warning("From Date can't be greater than To Date", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        })
        return;
      } else {
        this.GetAll();
      }
    },
    changeToDate() {
      if (this.toDate < this.fromDate) {
        this.$toast.warning("To Date can't be smaller than From Date", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
        return;
      } else {
        this.GetAll();
      }
    },
    async GetAll() {
      if (this.$route.name === "DashboardComp") {
        this.loader = true;
        try {
          let response = await axios.post(
              config.apiBaseUrl + "/api/Dashboard/GetDashboardData",
              {
                companyId: axios.defaults.headers.common.CompanyId,
                fromDate: this.fromDate,
                toDate: this.toDate,
              }
          );
          this.Data = response.data.data;
          this.$store.state.JobsRevenue = [
            {
              name: "Jobs Revenue",
              data: [this.Data.jobsRevenue]
            },
          ];
          this.$store.state.AssignedLeads = [
            {
              name: "Assigned Jobs",
              data: [this.Data.assignedLeads]
            },
          ];
          this.loader = false;
        } catch (err) {
          this.loader = false;
          console.log(err);
        }
      }
    },
  },
  computed: {
    computedFromDate() {
      return this.fromDate ? moment(this.fromDate).format('MMM DD, YYYY') : ''
    },
    computedToDate() {
      return this.toDate ? moment(this.toDate).format('MMM DD, YYYY') : ''
    },
  },
  created() {
    this.GetAll();
    this.$store.state.DisableCompany = false;
    this.$root.$on("myEvent", () => {
      this.GetAll();
    });
  }
};
</script>

<style scoped>
.v-card__text {
  font-size: 25px !important;
  /* color: #000 !important; */
}

.dashboard-h1 {
  cursor: pointer;
}

/* .container {
  min-width: 1247px;
} */
.card-content {
  font-size: 85px !important;
}

.custom-card {
  align-items: center !important;
  box-sizing: border-box !important;
  height: 78% !important;
  display: flex !important;
  justify-content: center !important;
}

.chart-col {
  height: 350px !important;
}

.datepicker {
  margin: 0;
  border: 1px solid #ccc !important;
  height: 40px !important;
  padding-left: 8px !important;
  border-radius: 0px !important;
  width: 70%;
}

@media (min-width: 960px) {
  .col-md-2 {
    flex: 1 0 16.6666666667%;
    max-width: 21.666667%;
  }
}
</style>