<template>
  <div>
    <v-row justify="space-between">
      <v-col class="d-flex" cols="12" sm="6">
        <v-btn class="mr-2 theme-color1" dark elevation="0" tile>
          <v-icon>mdi-layers</v-icon>
        </v-btn>
        <h2 class="text-uppercase theme-font-color">{{ name }}</h2>
      </v-col>
      <v-col class="mb-1 d-flex" cols="12" sm="5">
        <v-text-field v-model="Filter.filterText" append-icon="mdi-magnify" class="custom-input" clearable
                      color="#64718A" dense
                      hide-details label="Search" outlined v-on:keyup="GetAll"
                      @click:clear="(Filter.filterText = ''), GetAll()" @click:append="GetAll"></v-text-field>
        <v-btn class="ms-2 theme-color1" dark elevation="0" tile @click="(ClearData()), addDialog = true">
          <v-icon>mdi-file-excel-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <div class="custom-line"></div>
    <v-data-table :headers="header" :items="Inventory" :items-per-page="pageSize"
                  class="custom-table elevation-0" disable-sort
                  hide-default-footer>
      <template v-slot:[`item.sno`]="{ index }">
        <span>{{ index + 1 }}</span>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-icon class="ms-2" color="primary darken-1" small @click="takeData(item), (addDialog = true)">mdi-pencil
        </v-icon>
        <v-icon class="ms-2" color="red" small @click="takeData(item), (deleteDialog = true)">mdi-trash-can-outline
        </v-icon>
      </template>
    </v-data-table>
    <v-row align="center" class="no-gutters mt-2">
      <v-spacer style="width: 75%"></v-spacer>
      <span class="pagination-text">Rows per page</span>
      <v-select v-model="Filter.pageSize" :items="options" :menu-props="{ bottom: true, offsetY: true }" class="ma-2"
                dense
                style="width: 50px" @change="GetAll"></v-select>
      <span v-if="pagination != null" class="mr-4 grey--text pagination-text">
        Page {{ pagination.page }} of {{ pagination.totalPages }}
      </span>
      <v-icon class="mr-1" color="primary darken-2" dark small @click="backwardButtonCall">mdi-chevron-left</v-icon>
      <v-icon class="mr-1" color="primary darken-2" dark small @click="forwardButtonCall()">mdi-chevron-right</v-icon>
    </v-row>


    <!-- Delete Dialog -->
    <v-dialog v-model="deleteDialog" max-width="400" persistent>
      <v-card>
        <v-card-title class="text-h5"> Delete Product</v-card-title>
        <v-card-text>Are You sure? You want to delete
          <b v-if="Product != null" class="error--text text--darken-2">{{
              Product.product
            }}</b></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success darken-1" text @click="deleteDialog = false">
            Cancel
          </v-btn>
          <v-btn color="error darken-2" @click="DeleteEquipment(), (deleteDialog = false)">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Add Dialog -->
    <v-dialog v-model="addDialog" max-width="500" persistent>
      <v-card outlined>
        <v-toolbar color="#efefef" dense elevation="0" flat tile>
          <v-btn class="mr-2 theme-color1" dark elevation="0" height="35px" tile @click="addDialog = false">
            <v-icon dense>mdi-undo-variant
            </v-icon>
          </v-btn>
          <v-toolbar-title>
            <h4 class="text-uppercase theme-font-color">Add Inventory</h4>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn class="ps-10 pe-10 login-btns theme-color1" dark elevation="0" tile
                 @click="AddInventory(), (addDialog = false)">Import
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
          <v-form ref="form">
            <v-row>
              <v-col class="ps-1 pe-1 d-flex" cols="12">
                <v-file-input
                    v-model="SelectedFile"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    class="custom-input" dense hide-details label="Click here to import file"
                    outlined prepend-icon="mdi-file" show-size></v-file-input>
                <!-- <v-text-field label="File" class="custom-input" dense hide-details v-if="SelectedFile == null"  color="#64718A" outlined></v-text-field>
                <v-text-field label="File" v-else v-model="SelectedFile.name" color="#64718A" outlined
                  class="custom-input" dense hide-details></v-text-field>

                <v-btn elevation="0" tile dark class="login-btns theme-color1" height="40" :loading="isSelecting" @click="handleFileImport">
                  Upload File
                </v-btn>
                <input ref="uploader" class="d-none" type="file" @change="onFileChanged"> -->
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-progress-circular v-if="loader" class="custom-loader" color="#64718A" indeterminate
                         width="2"></v-progress-circular>

  </div>
</template>

<script>
import axios from "axios";
import config from "@/components/Configuration/config";
import * as XLSX from "xlsx";

export default {
  name: "InventoryModule",
  data() {
    return {
      name: "Inventory",
      addDialog: false,
      deleteDialog: false,
      loader: false,
      isSelecting: false,
      header: [
        {
          text: "#",
          value: 'sno',
          width: '100px'
        },
        {
          text: "Manufacturer",
          value: 'manufacturer',
        },
        {
          text: "Product",
          value: 'product',
        },
        {
          text: "Product Type",
          value: 'productType',
        }, {
          text: "Model",
          value: 'model',
        },
        {
          text: "Quantity",
          value: 'quantity',
          width: '120px',
          align: 'center'
        }, {
          text: "Action",
          value: 'action',
          width: '120px'
        },

      ],
      Data: [],
      SelectedFile: null,
      Inventory: [],
      Product: {
        "companyId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "productId": 0,
        "manufacturer": "",
        "product": "",
        "productType": "",
        "model": "",
        "serialNumber": "",
        "costPrice": 0,
        "salePrice": 0,
        "quantity": 1,
        "isSold": false
      },
      pagination: null,
      options: [
        {text: "10", value: 10},
        {text: "25", value: 25},
        {text: "50", value: 50},
        {text: "100", value: 100},
      ],
      Filter: {
        CompanyId: axios.defaults.headers.common.CompanyId,
        filterText: "",
        page: 1,
        pageSize: 10
      }
    }
  },
  methods: {
    ClearData() {
      this.Product = {},
          this.Product.productId = 0;
      this.Product.product = "";
      this.Product.companyId = axios.defaults.headers.common.CompanyId;
    },
    takeData(product) {
      this.Product = product;
    },

    async GetAll() {
      if (this.$route.name == "InventoryModule") {
        try {
          this.loader = true;
          let response = await axios({
            method: "POST",
            url: config.apiBaseUrl + "/api/Inventory/All",
            data: this.Filter,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            }
          });
          this.Inventory = response.data.data;
          this.pagination = response.data.pagination;
          this.loader = false;
        } catch (error) {
          this.loader = false;
          this.$toast.warning(error.message, {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnfocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
          });
        }
      }
    },
    // handleFileImport() {
    //   this.isSelecting = true;

    //   // After obtaining the focus when closing the FilePicker, return the button state to normal
    //   window.addEventListener('focus', () => {
    //     this.isSelecting = false
    //   }, { once: true });

    //   // Trigger click on the FileInput
    //   this.$refs.uploader.click();
    // },
    // onFileChanged(e) {
    //   this.SelectedFile = e.target.files[0];
    //   console.log(this.SelectedFile)
    //   // Do whatever you need with the file, liek reading it with FileReader
    // },
    async AddInventory() {
      try {
        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(this.SelectedFile);
        fileReader.onload = (e) => {
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, {type: "binary"});
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws, {header: 1});
          data.forEach(element => {
            const Product = {
              "companyId": axios.defaults.headers.common.CompanyId,
              "productId": 0,
              "manufacturer": "",
              "product": "",
              "productType": "",
              "model": "",
              "serialNumber": "",
              "costPrice": 0,
              "salePrice": 0,
              "quantity": 0,
              "isSold": false
            }
            Product.manufacturer = element[0];
            Product.product = element[1];
            Product.productType = element[2];
            Product.model = element[3];
            Product.serialNumber = element[4];
            Product.costPrice = element[5];
            Product.salePrice = element[6];
            Product.quantity = element[7];
            this.Data.push(Product);
          })
          this.AddProduct();
        }
      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },

    async AddProduct() {
      try {
        let response = await axios({
          method: "POST",
          url: config.apiBaseUrl + "/api/Inventory/Import",
          data: this.Data,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          }
        });
        console.log(response);
        await this.GetAll();
      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },
    async DeleteEquipment() {
      try {
        const fd = new FormData();
        fd.append("productId", this.Product.productId);
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Inventory/Remove",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        console.log(response);
        this.GetAll();
      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },
    forwardButtonCall() {
      //it does not run on equal to condition
      if (this.Filter.page < this.pagination.totalPages) {
        this.Filter.page++;
        this.GetAll();
      } else {
        return;
      }
    },
    backwardButtonCall() {
      if (this.Filter.page > 1) {
        this.Filter.page--;
        this.GetAll();
      } else {
        return;
      }
    },
    AllMethod() {
      setTimeout((this.loader = true), 500);
      setTimeout(this.GetAll, 1000);
    },
  },
  created() {
    this.$store.state.DisableCompany = false;
    this.GetAll();
    this.$root.$on("myEvent", () => {
      this.Filter.filterText = "";
      this.Filter.page = 1;
      this.GetAll();
    });
  },
}
</script>

<style scoped>

</style>