<template>
  <div class="">
    <v-row justify="space-between">
      <v-col class="d-flex" cols="12" sm="5">
        <v-btn class="mr-2 theme-color1" dark elevation="0" link tile>
          <v-icon>mdi-currency-usd</v-icon>
        </v-btn>
        <h2 class="text-uppercase theme-font-color">{{ name }}</h2>
      </v-col>
      <v-col class="mb-1 d-flex ps-0" cols="12" sm="6">
        <v-col class="d-flex align-center pa-0 ma-0">
          <label class="caption me-1">From</label>
          <v-menu
              v-model="fromMonthMenu"
              :close-on-content-click="false"
              :nudge-right="0"
              min-width="290"
              offset-y
              transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  :value="computedFromMonth"
                  append-icon="mdi-calendar"
                  class="custom-input my-input-common-border month-input"
                  dense
                  hide-details
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="Filter.fromDate"
                no-title
                type="month"
                @change="GetAll(1)"
                @input="fromMonthMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="d-flex align-center pa-0 ma-0">
          <label class="caption ms-1 me-1">To</label>
          <v-menu
              v-model="toMonthMenu"
              :close-on-content-click="false"
              :nudge-right="0"
              min-width="290"
              offset-y
              transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  :value="computedToMonth"
                  append-icon="mdi-calendar"
                  class="custom-input my-input-common-border month-input"
                  dense
                  hide-details
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="Filter.toDate"
                no-title
                type="month"
                @change="GetAll(2)"
                @input="toMonthMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-btn class="ms-2 theme-color1" dark elevation="0" tile @click="(ClearData()),addDialog = true">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn :loading="isSelecting" class="ms-2 theme-color1" dark elevation="0" tile @click="handleFileImport()">
          <!--          <v-icon>mdi-file-excel-outline</v-icon>-->
          <i class="ri-file-excel-2-line" style="font-size: 20px"></i>
        </v-btn>
        <input :key="fileInputKey" ref="uploader" class="d-none" type="file"
               @change="onFileChanged($event)">
        <v-btn class="ms-2 text-capitalize red lighten-5" text tile @click="RemoveExpense">
          <i class="mgc_delete_2_line" style="color: red; font-size: 16px"></i>
        </v-btn>
      </v-col>

    </v-row>
    <div class="custom-line"></div>
    <v-data-table v-if="Expenses == null" :headers="headers" :items-per-page="Filter.pageSize"
                  class="custom-table elevation-0"
                  disable-sort hide-default-footer>
    </v-data-table>
    <v-data-table v-else v-model="selected" :headers="headers" :items="Expenses"
                  :items-per-page="Filter.pageSize"
                  :single-select="singleSelect" class="custom-table elevation-0" disable-sort
                  hide-default-footer
                  item-key="expenseId"
                  show-select>
      <template v-slot:[`item.sno`]="{index}">
        {{ index + 1 }}
      </template>
      <template v-slot:[`item.overheadType`]="{ item }">
        <span v-if="item.overheadType == 100">Overhead</span>
        <span v-if="item.overheadType == 200">Material</span>
      </template>
      <template v-slot:[`item.frequency`]="{ item }">
        <span v-if="item.frequency == 300">Monthly</span>
        <span v-if="item.frequency == 400">Yearly</span>
      </template>
      <template v-slot:[`item.paymentDate`]="{ item }">
        <span>{{ getFormattedDate(item.paymentDate) }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <!--        <v-icon class="ms-2" color="success" small @click="takeData(item), (addDialog = true)"><i-->
        <!--            class="mgc_edit_line"></i></v-icon>-->
        <i class="mgc_edit_line edit-icon" @click="takeData(item), (addDialog = true)"></i>
        <!--        <v-icon v-if="selected.length === 0" class="ms-2" color="red" small-->
        <!--                @click="takeData(item), (deleteDialog = true)">mdi-trash-can-outline-->
        <!--        </v-icon>-->
      </template>
    </v-data-table>
    <v-row align="center" class="no-gutters mt-2">
      <v-spacer style="width: 75%"></v-spacer>
      <span class="pagination-text">Rows per page</span>
      <v-select v-model="Filter.pageSize" :items="options" :menu-props="{ bottom: true, offsetY: true }" class="ma-2"
                dense style="width: 50px"
                @change="GetAll"></v-select>
      <span v-if="pagination != null" class="mr-4 grey--text pagination-text">
        Page {{ pagination.page }} of {{ pagination.totalPages }}
      </span>
      <v-icon class="mr-1" color="primary darken-2" dark small @click="backwardButtonCall">mdi-chevron-left</v-icon>
      <v-icon class="mr-1" color="primary darken-2" dark small @click="forwardButtonCall()">mdi-chevron-right</v-icon>
    </v-row>
    <!-- Delete Dialog -->
    <v-dialog v-model="deleteDialog" max-width="400" persistent>
      <v-card>
        <v-card-title class="text-h5"> Delete Overhead</v-card-title>
        <v-card-text>Are You sure? You want to delete
          <b v-if="OverHead != null" class="error--text text--darken-2">{{
              OverHead.expenseHead
            }}</b></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">
            Cancel
          </v-btn>
          <v-btn color="error darken-2" elevation="0" tile @click="RemoveExpense(), (deleteDialog = false)">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Add Dialog -->
    <v-dialog v-model="addDialog" max-width="700" persistent>
      <v-card outlined>
        <v-toolbar color="#efefef" dense elevation="0" flat tile>
          <v-btn class="mr-2 theme-color1" dark elevation="0" height="35px" tile @click="addDialog = false">
            <v-icon
                dense>mdi-undo-variant
            </v-icon>
          </v-btn>
          <v-toolbar-title>
            <h4 class="text-uppercase theme-font-color">New Overhead / Material</h4>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn :disabled="!OverHead.expenseHead" class="ps-10 pe-10 login-btns theme-color1" dark elevation="0" tile
                 @click="AddorUpdate(), (addDialog = false)">Save
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
          <v-form ref="form">
            <v-row>
              <v-col class="ps-1 pe-1" cols="12" md="6">
                <v-text-field v-model="OverHead.expenseHead" class="custom-input" color="#64718A" dense
                              hide-details label="Title" outlined></v-text-field>
              </v-col>
              <v-col class="ps-1 pe-1" cols="12" md="6">
                <v-select v-model="OverHead.overheadType" :items="OverheadTypes"
                          :menu-props="{ bottom: true, offsetY: true }" :return-object="false"
                          class="custom-input"
                          color="#64718A" dense hide-details item-text="text" item-value="value" label="Overhead Type"
                          outlined></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="ps-1 pe-1" cols="12" md="6">
                <v-text-field v-model="OverHead.beneficiary" class="custom-input" color="#64718A" dense hide-details
                              outlined placeholder="Beneficiary" required></v-text-field>
              </v-col>
              <v-col class="ps-1 pe-1" cols="12" md="6">
                <v-text-field
                    v-model="OverHead.licensePlate"
                    class="custom-input"
                    color="#64718A"
                    dense
                    hide-details outlined placeholder="License Plate" required
                ></v-text-field>
              </v-col>

            </v-row>
            <v-row>
              <v-col class="ps-1 pe-1" cols="12" md="4">
                <v-select v-model="OverHead.frequency" :items="frequencies"
                          :menu-props="{ bottom: true, offsetY: true }" :return-object="false"
                          class="custom-input" color="#64718A" dense hide-details item-value="value" label="Frequency"
                          outlined></v-select>
              </v-col>
              <v-col class="ps-1 pe-1" cols="12" md="4">
                <v-text-field v-model.number="OverHead.payment" append-icon="mdi-currency-usd" class="custom-input"
                              color="#64718A" dense hide-details
                              label="Payment" outlined required
                              type="number" @focus="selectText"></v-text-field>
              </v-col>

              <v-col class="date-col ps-1 pe-1" cols="12" md="4">
                <span class="date-label">Payment Date</span>
                <input v-model="OverHead.paymentDate" class="datepicker date-input custom-date" type="date"
                       width="100%">
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <!--        <v-card-actions>-->
        <!--          <v-spacer></v-spacer>-->
        <!--          <v-btn color="grey darken-1" elevation="0" dark tile @click="addDialog = false">-->
        <!--            Cancel-->
        <!--          </v-btn>-->
        <!--          <v-btn  class="login-btns theme-color1" tile elevation="0" :disabled="!valid"-->
        <!--            @click="AddorUpdate(), (addDialog = false)">-->
        <!--            Save-->
        <!--          </v-btn>-->
        <!--        </v-card-actions>-->
      </v-card>
    </v-dialog>
    <!-- Progress Bar -->
    <v-progress-circular v-if="loader" class="custom-loader" color="primary darken-2" indeterminate
                         width="2"></v-progress-circular>
  </div>
</template>
<script>
import moment from "moment";
import 'moment-timezone';
import axios from "axios";
import config from "../Configuration/config";

export default {
  name: "ExpensesModule",
  data() {
    return {
      name: "Overheads / Material",
      deleteDialog: false,
      valid: false,
      loader: false,
      singleSelect: false,
      selected: [],
      SelelectedOverheads: [],
      isSelecting: false,
      addDialog: false,
      selectedFile: null,
      fromMonthMenu: false,
      toMonthMenu: false,
      menu: null,
      fileInputKey: Date.now(),
      filterdatemenu: false,
      headers: [
        {
          text: "#",
          value: "sno",
          align: 'start',
          width: '70px'
        }, {
          text: "Expense Head",
          value: "expenseHead",
        },
        {
          text: "Type",
          value: "overheadType",
        },
        {
          text: "Frequency",
          value: "frequency",
          sortable: false,
        },
        {
          text: "Payment",
          value: "payment",
          sortable: false,
        },
        {
          text: "Beneficiary",
          value: "beneficiary",
          sortable: false,
        },
        {
          text: "License Plate",
          value: "licensePlate",
          sortable: false,
        },
        {
          text: "Payment Date",
          value: "paymentDate",
          align: "center",
          sortable: false,
        },

        {text: "Actions", value: "actions", align: 'center', width: "100px", sortable: false},
      ],
      rules: {
        required: [(value) => !!value || ""],
        min: [v => v > 0 || 'Price must be greater than 0',
        ]
      },
      expensetypes: [
        {text: "Fixed", value: 10},
        {text: "Variable", value: 20},
      ],
      frequencies: [
        {text: "Monthly", value: 300},
        {text: "Yearly", value: 400},
      ],
      //Model
      OverHead: {
        companyId: axios.defaults.headers.common.CompanyId,
        expenseId: 0,
        expenseHead: "",
        type: 10,
        frequency: 300,
        overheadType: 100,
        payment: 0,
        paymentDate: new Date(),
        beneficiary: "",
        licensePlate: "",
      },
      options: [
        {text: "10", value: 10},
        {text: "25", value: 25},
        {text: "50", value: 50},
        {text: "100", value: 100},
      ],
      daterange: [
        new Date(new Date().getFullYear(), new Date().getMonth() - 1),
        new Date(new Date().getFullYear(), new Date().getMonth()),

      ],
      Expenses: null,

      OverheadTypes: [
        {
          text: "Overhead",
          value: 100
        },
        {
          text: "Material",
          value: 200
        },
      ],
      pagination: null,
      pageNumOnSearch: "",
      Filter: {
        fromDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 2).toISOString(),
        toDate: new Date().toISOString(),
        month: new Date().toJSON().substring(0, 10),
        page: 1,
        pageSize: 10,
        filterText: "",
        totalRecords: 0,
      }
    };
  },


  methods: {
    selectText(event) {
      event.target.select();
    },
    ClearData() {
      this.OverHead = {},
          this.OverHead.companyId = axios.defaults.headers.common.CompanyId,
          this.OverHead.expenseId = 0;
      this.OverHead.expenseHead = "";
      this.OverHead.type = 10;
      this.OverHead.frequency = 300;
      this.OverHead.payment = 0;
      this.OverHead.paymentDate = new Date();
      this.OverHead.benificiary = "";
      this.OverHead.licensePlate = "";
      this.OverHead.overheadType = 100;
    },
    takeData(expense) {
      this.OverHead = expense;
      this.OverHead.paymentDate = this.OverHead.paymentDate.substring(0, 10);
      this.selected = [];
      this.selected.push(this.OverHead)

    },

    getFormattedDate(date) {
      /*const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get the user's timezone
      return moment.utc(date).tz(userTimezone).format('MMM DD, YYYY');*/
      return moment(date).format("MMM DD, yyyy");
    },

    async GetAll(type) {
      if (this.$route.name == "ExpensesModule") {
        try {
          this.Filter.fromDate = moment(this.Filter.fromDate).format("YYYY-MM")
          this.Filter.toDate = moment(this.Filter.toDate).format("YYYY-MM")
          if (this.Filter.fromDate > this.Filter.toDate) {
            switch (type) {
              case 1:
                this.Filter.fromDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 2).toISOString();
                this.ShowToast("From date cannot be greater than to date");
                break;
              case 2:
                this.Filter.toDate = new Date().toISOString();
                this.ShowToast("To date cannot be smaller than from date");
                break;
            }
            return;
          }
          this.loader = true;
          let response = await axios({
            method: "POST",
            url: config.apiBaseUrl + "/api/Overhead/All",
            data: this.Filter,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          });
          this.Expenses = response.data.data;
          this.pagination = response.data.pagination;
          this.loader = false;
        } catch (err) {
          this.loader = false;
          this.ShowToast("Something went wrong!")
        }
      }
    },
    ShowToast(message) {
      this.$toast.warning(message, {
        position: "top-center",
        timeout: 2000,
        closeOnClick: true,
        pauseOnfocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.79,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: false,
        icon: true,
        rtl: false,
      })
    },
    async RemoveExpense() {
      try {
        if (this.selected.length === 0) {
          this.ShowToast("No overhead or material selected!");

          return;
        }
        this.SelelectedOverheads = [];
        this.loader = true;
        const fd = new FormData();
        this.selected.forEach(element => {
          fd.append("expenseIds", element.expenseId)
        })
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Overhead/Remove",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        console.log(response);
        this.selected = [];
        this.SelelectedOverheads = [];
        this.GetAll();
        this.loader = false;
      } catch (error) {
        this.loader = false;
        this.loader = false;
        this.ShowToast("Something went wrong!");

      }
    },
    forwardButtonCall() {
      //it does not run on equal to condition
      if (this.Filter.page < this.pagination.totalPages) {
        this.Filter.page++;
        this.GetAll();
      } else {
        return;
      }
    },
    backwardButtonCall() {
      if (this.Filter.page > 1) {
        this.Filter.page--;
        this.GetAll();
      } else {
        return;
      }
    },
    async AddorUpdate() {
      try {
        this.loader = true;
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/OverHead/AddOrUpdate",
          data: this.OverHead,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        console.log(response);
        this.loader = false;
        this.GetAll();
      } catch (error) {
        this.loader = false;
        this.ShowToast("Something went wrong!");

      }
    },
    getOverHead() {
      if (this.$route.params.item != undefined) {
        this.OverHead = this.$route.params.item;
        this.name = this.OverHead.expenseHead;
        this.btnText = "Update OverHead";
        this.OverHead.paymentDate = this.OverHead.paymentDate.substring(0, 10);
      }
    },
    handleFileImport() {
      this.isSelecting = true;
      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, {once: true});
      // Trigger click on the FileInput
      this.$refs.uploader.click();
    },
    GetExtension(path) {
      let baseName = path.name; //.split(/[\\/]/).pop(), // extracts file name from full path
      let pos = baseName.lastIndexOf("."); // gets the last position of `.`
      if (baseName === "" || pos < 1) // if the file name is empty or ...
        return ""; // the dot not found (-1) or comes first (0)
      return baseName.slice(pos + 1); // extracts extension ignoring "."
    },
    async onFileChanged(e) {
      this.fileInputKey = Date.now();
      this.loader = true
      this.selectedFile = e.target.files[0];
      let ext = this.GetExtension(this.selectedFile);
      try {
        if (ext === "xlsx" || ext === "csv" || ext === "xls") {
          this.loader = true;
          const fd = new FormData();
          fd.append("file", this.selectedFile)
          fd.append("companyId", axios.defaults.headers.common.CompanyId,)
          let response = await axios({
            method: "post",
            url: config.apiBaseUrl + "/api/Overhead/UploadDocument",
            data: fd,
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
            },
          });
          if (response.status == 200) {
            this.$toast.success("Data import successfully!", {
              position: "top-center",
              timeout: 1000,
              closeOnClick: true,
              pauseOnfocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.79,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: false,
              icon: true,
              rtl: false,
            })
            ;
            this.loader = false;
          } else {
            this.loader = false;
            this.ShowToast("Something went wrong!");

          }
          this.loader = false;
          this.GetAll();
        } else {
          this.loader = false;
          this.ShowToast("Invalid file type. Please select an excel file.")

          return;
        }
      } catch (error) {
        this.loader = false;
        this.ShowToast("Something went wrong!");

      }
    }
    ,
  },
  computed: {
    computedFromMonth() {
      return this.Filter.fromDate ? moment(this.Filter.fromDate).format('MMM-YYYY') : ''
    }
    ,
    computedToMonth() {
      return this.Filter.toDate ? moment(this.Filter.toDate).format('MMM-YYYY') : ''
    }
    ,
    // range() {
    //   if(this.daterange[0] > this.daterange[1]){
    //     this.swapElements(this.daterange, 0,1)
    //   }
    //   return moment(this.daterange[0]).format("MMM-DD-yyyy") + " ~ " + moment(this.daterange[1]).format("MMM-DD-yyyy");
    // }
  }
  ,
  created() {
    this.$store.state.DisableCompany = false;
    this.GetAll();
    this.getOverHead();
    this.$root.$on("myEvent", () => {
      this.Filter.page = 1;
      this.Filter.filterText = "";
      this.GetAll();
    });
  }
  ,
}
;
</script>
<style scoped>
.login-btns {
  color: #fff;
}

.date-label {
  position: absolute;
  top: 0;
}
</style>