<template>
  <div id="my-container" class="theme-color1">
    <v-container class="pa-0" fluid>
      <v-row align="center" class="login-container" justify="center">
        <v-col cols="12" md="4">
          <v-card class="d-flex flex-column p-4" elevation="1">
            <h2 class="text-center theme-font-color text-uppercase">
              Tracking Buddy
            </h2>
            <h3 class="text-center grey--text mb-2 font-weight-regular">
              Welcome Back!
            </h3>
            <v-form autocomplete="off">
              <v-text-field
                  v-model="email"
                  v-model.lazy="email"
                  autocomplete="new-email"
                  class="custom-input login-input v-input--is-label-active"
                  color="#64718A"
                  label="Email Address"
                  name="email"
                  outlined
                  persistent-placeholder
                  placeholder=" "
                  prepend-inner-icon="mdi-email"
                  type="email"
                  @focus="selectText"
              ></v-text-field>
              <!-- :rules="[rules.required, rules.email]" -->

              <v-text-field
                  id="password"
                  v-model="password"
                  :type="ShowPassword ? 'text' : 'password'"
                  autocomplete="new-password"
                  class="custom-input login-input v-input--is-label-active"
                  color="#64718A"
                  counter
                  label="Password"
                  name="password"
                  outlined
                  persistent-placeholder
                  placeholder=" "
                  prepend-inner-icon="mdi-lock"
                  @focus="selectText"
                  v-on:keyup.enter="loginMethod()"
              >
                <template v-slot:append>
                  <i v-if="ShowPassword" class="mgc_eye_line eye_icon"
                     @click="ShowPassword = !ShowPassword"></i>
                  <i v-if="!ShowPassword" class="mgc_eye_close_line eye_icon"
                     @click="ShowPassword = !ShowPassword"></i>
                </template>
              </v-text-field>
            </v-form>
            <div class="d-flex align-center">
              <router-link align="start" class="forgot" to="/forgotpassword"
              >Forgot password?
              </router-link
              >
              <v-spacer></v-spacer>
              <v-btn
                  :disabled="!email || !password"
                  class="login-btns theme-color1"
                  height="45"
                  tile
                  width="25%"
                  @click="loginMethod(), (loader = true)"
              >
                Login
              </v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <v-progress-circular
          v-if="loader"
          :size="30"
          :width="2"
          class="login-loader"
          color="#64718A"
          indeterminate
      ></v-progress-circular>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import config from "../Configuration/config.js";

export default {
  name: "LoginPage",

  data: () => ({
    title: "Preliminary report",
    email: "",
    dialog: false,
    initialValue: "",
    ShowPassword: false,
    password: "",
    loader: null,
    loading: false,
    error: null,
    autofilled: false,
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
      emailMatch: () => `The email and password you entered don't match`,
    },
  }),
  watch: {
    email() {
      this.autofilled = true;
    },
  },
  methods: {
    selectText(event) {
      event.target.select();
    },
    changeStyle() {
      sessionStorage.removeItem("isLoggedin");
      this.$store.state.LoggedIn = false;
      sessionStorage.removeItem("role");
      this.$store.state.role = null;
    },
    async loginMethod() {
      this.loader = true;
      try {
        if (this.$store.state.LoggedIn == true || sessionStorage.getItem("role") != null) {
          this.loader = false;
          this.$toast.error("Another or same user already logged in!", {
            position: "top-center",
            timeout: 1041,
            closeOnClick: true,
            pauseOnfocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
          })
          ;
          return;
        }

        let response = await axios.post(
            config.apiBaseUrl + "/api/Auth/Authenticate",
            {
              username: this.email,
              password: this.password,
            }
        );
        sessionStorage.setItem("token", response.data.token);
        sessionStorage.setItem("UserId", response.data.userId);
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + sessionStorage.getItem("token");
        if (response.data.role == 10 || response.data.role == 15) {
          this.$store.state.companies = response.data.companies;
          this.$store.state.selectedBranch = this.$store.state.companies[0];
          axios.defaults.headers.common.CompanyId =
              this.$store.state.selectedBranch.companyId;
          sessionStorage.setItem(
              "CompanyId",
              this.$store.state.selectedBranch.companyId
          );
          /*sessionStorage.setItem("Companies", response.data.companies);
          sessionStorage.setItem(
              "Company",
              this.$store.state.selectedBranch.companyName
          );
          sessionStorage.setItem("HST", this.$store.state.selectedBranch.hst);
          sessionStorage.setItem(
              "CompanyAddress",
              this.$store.state.selectedBranch.address
          );
          sessionStorage.setItem(
              "CompanyTel",
              "+1" + this.$store.state.selectedBranch.phoneNumber
          );
          sessionStorage.setItem(
              "CompanyAddress2",
              this.$store.state.selectedBranch.city +
              ", " +
              this.$store.state.selectedBranch.postalCode +
              " " +
              this.$store.state.selectedBranch.country
          );*/
        }
        sessionStorage.setItem("isLoggedin", true);
        this.$store.state.LoggedIn = sessionStorage.getItem("isLoggedin");
        sessionStorage.setItem("role", response.data.role);
        this.$store.state.role = sessionStorage.getItem("role");
        sessionStorage.setItem("userName", this.email);
        switch (response.data.role) {
          case 10:
            await this.$router.push("/dashboard");
            break;
          case 15:
            await this.$router.push("/customers");
            break;
          case 20:
            await this.$router.push("/mobile");
            break;
        }
        //this.getuser();
      } catch (err) {
        this.loader = false;
        if (err.response.data == "user is not active") {
          this.loader = false;
          this.$toast.error("User is not active, Please contact admin", {
            position: "top-center",
            timeout: 1041,
            closeOnClick: true,
            pauseOnfocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
          })
          ;
        } else {
          this.loader = false;
          this.$toast.error("Username or password incorrect", {
            position: "top-center",
            timeout: 1041,
            closeOnClick: true,
            pauseOnfocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
          })
          ;
        }
      }
    },
    /*async getuser() {
      try {
        let user = await axios.get(config.apiBaseUrl + "/api/User/Get");
        sessionStorage.setItem("isLoggedin", true);
        this.User = user.data;
        this.$store.state.LoggedIn = sessionStorage.getItem("isLoggedin");
        this.loader = false;
        sessionStorage.setItem("role", this.User.role);
        this.$store.state.role = sessionStorage.getItem("role");
        sessionStorage.setItem("userName", this.email);
        switch (this.User.role) {
          case 10:
            await this.$router.push("/dashboard");
            break;
          case 15:
            await this.$router.push("/customers");
            break;
          case 20:
            await this.$router.push("/mobile");
            break;
        }
      } catch (error) {
        this.loader = false;
        console.log(error.message);
        this.$toast.warning("something went wrong!", {
          position: "top-center",
          timeout: 1041,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        })
        ;
      }
    },*/
  },
  beforeMount() {
    this.changeStyle();
  },
  created() {
  },
};
</script>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  font-family: "Roboto", sans-serif;
}

.v-card {
  padding: 20px;
  padding-top: 30px;
  padding-bottom: 25px;
}

.login-btns {
  color: #fff;
}

.v-application a {
  margin-left: 0px;
  text-decoration: none;
  text-transform: initial;
  font-weight: 500;
  font-size: 13px;
}

.login-container {
  height: 100vh;
}

.login-loader {
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
}

.eye_icon {
  font-size: 22px;
  cursor: pointer;
}

</style>
