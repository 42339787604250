<template>
  <div>
    <v-row class="pa-0">
      <v-col class="pa-0">
        <v-toolbar class="theme-color1" dense elevation="1">
          <v-btn icon link to="/mobile">
            <v-icon class="white--text">mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title
              v-if="Job.isLead === true && (Job.isQuote === false || Job.isQuote == null) && (Job.isJob === false || Job.isJob == null)"
              class="">{{ Job.title }} <span class="caption">LEAD # {{
              Job.leadNo
            }}</span><span v-if="Company != ''"> ({{ Company }}) </span>
          </v-toolbar-title>
          <v-toolbar-title
              v-if="Job.isQuote === true && (Job.isLead === true || Job.isLead === null) && (Job.isJob === false || Job.isJob === null)"
              class="">{{ Job.title }} <span class="caption">QUOTE # {{
              Job.quoteNo
            }}</span><span v-if="Company != ''"> ({{ Company }}) </span>
          </v-toolbar-title>
          <v-toolbar-title
              v-if="(Job.isJob === true)||(Job.isLead == true && Job.isQuote == true && Job.isJob == true)"
              class="">{{ Job.title }} <span
              class="caption">JOB # {{ Job.jobNo }}</span><span v-if="Company != ''"> ({{ Company }}) </span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <!--          <v-menu offset-y content-class="elevation-1">-->
          <!--            <template v-slot:activator="{ on, attrs }">-->
          <!--              <v-btn v-bind="attrs" v-on="on" icon>-->
          <!--                <v-icon class="white&#45;&#45;text">mdi-dots-vertical</v-icon>-->
          <!--              </v-btn>-->
          <!--            </template>-->
          <!--            <v-list dense class="pa-0">-->
          <!--              <v-list-item link dense @click="HoursDialog = true" v-if="Job.isLead == true && Job.isJob == false">-->
          <!--                <v-list-item-icon class="nav-icon mr-2 ">-->
          <!--                  <v-icon small color="success darken-2">mdi-alarm-plus</v-icon>-->
          <!--                </v-list-item-icon>-->
          <!--                <v-list-item-title>Add Assessment Hours-->
          <!--                </v-list-item-title>-->
          <!--              </v-list-item>-->
          <!--              <v-list-item link dense @click="workedhourdialog = true" v-if="Job.isJob == true">-->
          <!--                <v-list-item-icon class="nav-icon mr-2 ">-->
          <!--                  <v-icon small color="success darken-2">mdi-alarm-plus</v-icon>-->
          <!--                </v-list-item-icon>-->
          <!--                <v-list-item-title>Add Job Hours-->
          <!--                </v-list-item-title>-->
          <!--              </v-list-item>-->
          <!--            </v-list>-->
          <!--          </v-menu>-->

        </v-toolbar>
      </v-col>
    </v-row>
    <v-row class="pa-0">
      <v-col class="pa-0">
        <v-list class="custom-list" dense>
          <v-list-item>
            <v-list-item-title>Customer</v-list-item-title>
            <v-list-item-subtitle>{{ Job.customer.fullName }}</v-list-item-subtitle>
          </v-list-item>
          <v-list-item v-if="Job.isLead === true && Job.isQuote === null && Job.isJob === null">
            <v-list-item-title>Description</v-list-item-title>
            <v-list-item-subtitle>{{ Job.leadDescription }}</v-list-item-subtitle>
          </v-list-item>
          <v-list-item
              v-if="(Job.isJob === true &&( Job.isQuote === false ||  Job.isQuote === null) && (Job.isLead === false || Job.isLead == null))||(Job.isLead == true && Job.isQuote == true && Job.isJob == true)">
            <v-list-item-title>Description</v-list-item-title>
            <v-list-item-subtitle>{{ Job.jobNote }}</v-list-item-subtitle>
          </v-list-item>
          <v-list-item v-if="Job.isLead === false && Job.isQuote === true && Job.isJob === null">
            <v-list-item-title>Description</v-list-item-title>
            <v-list-item-subtitle>{{ Job.quoteDescription }}</v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>Start Time</v-list-item-title>
            <v-list-item-subtitle>{{ getFormattedDate(Job.startTime) }}</v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>End Time</v-list-item-title>
            <v-list-item-subtitle>{{ getFormattedDate(Job.endDate) }}</v-list-item-subtitle>
          </v-list-item>
          <v-list-item
              v-if="Job.isLead === true && (Job.isQuote === false || Job.isQuote == null) && (Job.isJob === false || Job.isJob === null)">
            <v-list-item-title>Type</v-list-item-title>
            <v-list-item-subtitle>Lead</v-list-item-subtitle>
          </v-list-item>
          <v-list-item
              v-if="(Job.isLead === true || Job.isLead === null) && Job.isQuote === true && (Job.isJob === false || Job.isJob === null)">
            <v-list-item-title>Type</v-list-item-title>
            <v-list-item-subtitle>Quote</v-list-item-subtitle>
          </v-list-item>
          <v-list-item
              v-if="(Job.isLead === true || Job.isLead == null) && (Job.isQuote === true  || Job.isQuote === null )&& Job.isJob === true">
            <v-list-item-title>Type</v-list-item-title>
            <v-list-item-subtitle>Job</v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>Work Oder</v-list-item-title>
            <v-list-item-subtitle>
              <a v-if="Job.workOrder != null" :href="Job.workOrder" download link
                 text>{{ computedWorkOrder(Job.workOrder) }}
              </a>
            </v-list-item-subtitle>
          </v-list-item>
          <v-list-group v-if="Job.Qoute == true || Job.isJob == true" :value="true" active-class="theme-color1"
                        append-icon="mdi-chevron-down">
            <template v-slot:activator>
              <v-list-item-title class="subtitle-1">Services & Equipments</v-list-item-title>
            </template>
            <v-list-item v-for="(item, index) in Job.jobItems" :key="index" link>
              <v-list-item-title v-text="item.title"></v-list-item-title>
              <v-list-item-subtitle v-text="item.customerType"></v-list-item-subtitle>
            </v-list-item>
          </v-list-group>
        </v-list>
        <v-divider></v-divider>
        <v-list-item
            v-if="Job.isLead === true || Job.isJob === true" class="d-block mb-1">
          <label class="caption">Job Start Hour</label>
          <input
              v-model="CompleteJobModel.jobStartHour"
              class="custom-Jobdate JobHour"
              type="time"
              value="JobRequest.startTime"
          />
        </v-list-item>
        <v-list-item
            v-if="Job.isLead === true || Job.isJob === true" class="d-block mb-2">
          <label class="caption">Job End Hour</label>
          <input
              v-model="CompleteJobModel.jobEndHour"
              class="custom-Jobdate JobHour"
              type="time"
              value="JobRequest.startTime"
          />
        </v-list-item>
        <v-list-item
            v-if="Job.isLead === true && (Job.isJob === false || Job.isJob === null) && (Job.isQuote == false || Job.isQuote == null)">
          <v-text-field v-model="CompleteJobModel.totalHours" append-icon="mdi-alarm" dense hide-details
                        label="Assessment Hours"
                        outlined type="number"></v-text-field>
        </v-list-item>

        <v-list-item v-if="Job.isJob === true">
          <v-text-field v-model="CompleteJobModel.totalHours" :rules="positiveNumberRule" append-icon="mdi-alarm"
                        dense
                        label="Worked Hours"
                        outlined type="number"></v-text-field>
        </v-list-item>
        <v-list-item v-if="Job.isLead == true && Job.isQuote == false && Job.isJob == false" class="mt-3">
          <v-textarea v-model="CompleteJobModel.remarks" auto-grow dense hide-details label="Remarks" outlined
                      rows="10"></v-textarea>
        </v-list-item>
        <v-list-item class="mt-3">
          <v-textarea v-model="CompleteJobModel.installerNotes" auto-grow dense hide-details label="Installer Notes"
                      outlined
                      rows="2"></v-textarea>
        </v-list-item>
        <v-list-item class="mt-3">
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left" width="10px">
                  #
                </th>
                <th class="text-left">
                  Name
                </th>
                <th class="text-left" style="width: 30px">

                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(item,index) in JobImages"
                  :key="index"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ computedJobCompletion(item.name) }}</td>
                <td>
                  <v-icon class="ms-2" color="red" small
                          @click="RemoveImage(item)">mdi-trash-can-outline
                  </v-icon>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>

        </v-list-item>
        <v-list-item class="row justify-center mt-3">
          <v-btn :loading="isSelecting" class="me-2 d-block" color="success"
                 dark @click="handleFileImport()">Upload Images
          </v-btn>
          <input :key="fileInputKey" ref="uploader" class="d-none" type="file" @change="onFileChanged($event)">
        </v-list-item>
        <v-list-item class="d-flex justify-end mt-15">
          <v-btn class="success darken-1" depressed @click="CompleteJob">Sumbit</v-btn>
        </v-list-item>
      </v-col>
    </v-row>
    <v-progress-circular v-if="loader" class="custom-loader" color="#64718A" indeterminate
                         width="1"></v-progress-circular>
  </div>
</template>

<script>
import moment from "moment";
import 'moment-timezone';
import axios from 'axios';
import config from "../Configuration/config";

export default {
  name: "JobDetails",
  data() {
    return {
      Job: null,
      positiveNumberRule: [
        (v) => !!v || 'Number is required',
        (v) => v >= 0 || 'Number must be greater than or equal to 0',
      ],
      Company: "",
      loader: false,
      HoursDialog: false,
      time: null,
      JobStartHourDialog: false,
      JobEndHourDialog: false,
      JobEmployeId: sessionStorage.getItem('JobEmployeId'),
      WorkedHours: 0,
      JobImages: [],
      Remarks: "",
      JobCompleted: false,
      isSelecting: false,
      selectedFile: null,
      fileInputKey: Date.now(),
      FileCount: 0,
      WorkOrder: [],
      JobImagesHeaders: [
        {
          text: '#',
          value: 'sno'
        },
        {
          text: 'File Name',
          value: 'name'
        }
      ],
      CompleteJobModel: {
        id: 0,
        jobId: 0,
        totalHours: 0,
        employeId: sessionStorage.getItem('JobEmployeId'),
        remarks: "Existing Equipment",
        jobImages: "",
        installerNotes: "",
        jobStartHour: null,
        jobEndHour: null,
      }
    }
  },
  methods: {

    computedWorkOrder(workOrder) {
      if (workOrder != "") {
        if (workOrder != null) {

          let saf = workOrder.substring(workOrder.lastIndexOf("_") + 1);
          return saf.replaceAll(/%20/g, " ");
        }
      }
      return "";
    },
    computedJobCompletion(name) {
      if (name != "") {
        if (name != null) {
          let saf = name.substring(name.lastIndexOf("_") + 1);
          return saf.replaceAll(/%20/g, " ");
        }
      }
      return "";
    },
    async RemoveImage(item) {
      try {
        this.loader = true;
        const url = item.name;
        const docsIndex = url.indexOf('docs/');
        const blobName = url.substring(docsIndex + 5);
        let fd = new FormData();
        fd.append("blobName", blobName);
        let index = this.JobImages.indexOf(item);
        this.CompleteJobModel.jobImages = this.CompleteJobModel.jobImages.replace(item.name + ",", "").replace("," + item.name, "");
        this.JobImages.splice(index, 1);
        let response = await axios({
          method: "Post",
          url: config.apiBaseUrl + "/api/Job/RemoveBlob",
          data: fd,
          headers: {
            'Content-Type': "multiform/form-data",
            'Accept': 'application/json'
          }
        });
        console.log(response);
        this.loader = false;

      } catch (e) {
        this.loader = false;
        console.log(e)
      }
    },
    NegativeHours: function () {
      if (this.Job.totalHours === 0) {
        return true;
      } else return "Job hours cannot be negative";
    },
    ShowDocument(link) {
      var myWindow = window.open("", "_blank",);
      const img = document.createElement("img");
      img.src = link;
      myWindow.document.body.appendChild(img);
    },

    handleFileImport() {
      this.isSelecting = true;
      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, {once: true});
      this.$refs.uploader.click();

    },
    async onFileChanged(e) {
      this.fileInputKey = Date.now();
      this.loader = true
      this.selectedFile = e.target.files[0];
      try {
        const fd = new FormData();
        fd.append("file", this.selectedFile);
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Employee/UploadDocument/UploadDocument",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        if (response.status == 200) {
          this.$toast.success("File uploaded successfully!", {
            position: "top-center",
            timeout: 1000,
            closeOnClick: true,
            pauseOnfocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
          });
        } else if (response.status == 204) {
          this.$toast.warning("File upload failed!", {
            position: "top-center",
            timeout: 1000,
            closeOnClick: true,
            pauseOnfocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
          });
        }

        // eslint-disable-next-line no-unreachable
        if (this.CompleteJobModel.jobImages == null || this.CompleteJobModel.jobImages == "") {
          this.CompleteJobModel.jobImages = response.data;
        } else {
          this.CompleteJobModel.jobImages += "," + response.data;
        }
        this.FileCount++;
        var imageObject = {
          name: response.data
        }
        this.JobImages.push(imageObject);
        this.loader = false;
      } catch (error) {
        this.loader = false;
        console.log(error);
      }
    },
    getFormattedDate(date) {
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get the user's timezone
      return moment.utc(date).tz(userTimezone).format('MMM DD, yyyy');
      //return moment(date).format("MMM DD, YYYY");
    },
    CompleteJob: async function () {
      try {
        this.loader = true;
        let response = await axios({
          method: "POST",
          url: config.apiBaseUrl + "/api/Employee/CompleteJob",
          data: this.CompleteJobModel,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        this.loader = false;
        console.log(response);
        this.$router.push('/mobile')
      } catch (error) {
        this.loader = false;
        this.$toast.warning("Something went wrong", {
          position: "top-center",
          timeout: 1000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },
    async GetJob() {
      this.loader = true;
      try {
        const fd = new FormData();
        fd.append("jobId", this.Job.jobId);
        if (this.Job.isLead === true && (this.Job.isJob == false || this.Job.isJob == null)) {
          fd.append("visitType", 10);
        } else {
          fd.append("visitType", 20);
        }
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Job/Get",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        this.Job = response.data.job;

        this.CompleteJobModel.id = this.Job.jobId;
        this.CompleteJobModel.jobId = this.Job.jobId;
        this.CompleteJobModel.employeeId = this.JobEmployeId;
        this.Company = response.data.company;
        if (this.Job.assessmentStartDate != null && this.Job.assessmentStartTime != null) {
          this.Job.assessmentStartDate = this.Job.assessmentStartDate.substring(0, 10);
          this.Job.assessmentStartTime = this.Job.assessmentStartTime.substring(11, 16);
        }
        this.loader = false;
        const employee = this.Job.jobEmployees.find((emp) => emp.employeId == this.JobEmployeId);
        if (employee) {
          if (employee.jobImages != '') {
            if (employee.jobImages != null) {
              this.CompleteJobModel.jobImages = employee.jobImages;
              employee.jobImages.split(",").forEach((str) => {
                // Create an object and assign the string as a property
                const obj = {name: str};
                this.JobImages.push(obj);
              });
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
      /*if (this.Job.jobItems.length > 0) {
        var temApparray = [];
        this.Job.jobItems.forEach(element => {
          if (element.jobItemType == 10) {
            temApparray.push(element);
          }
        });
        this.Job.jobItems = temApparray;
      }*/
      if (this.Job.jobEmployees.length > 0) {
        this.Job.jobEmployees.forEach(element => {
          if (element.employeId == this.JobEmployeId) {
            this.CompleteJobModel.totalHours = element.totalHours
            this.CompleteJobModel.remarks = element.remarks;
            this.CompleteJobModel.installerNotes = element.installerNotes
            this.JobCompleted = element.jobCompleted;
            this.CompleteJobModel.id = element.id;
            return;
          }
        });
        //this.Job.jobItems = temApparray;
      }

      this.loader = false;
    }
  },
  created() {
    this.JobEmployeId = sessionStorage.getItem("JobEmployeId");
    let storedJsonString = sessionStorage.getItem("myJob");
    this.Job = JSON.parse(storedJsonString);
    this.GetJob();


  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300&display=swap');

* {
  font-family: 'Manrope', sans-serif !important;
}

.custom-list .v-list-item:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
  border: none !important;
}

.v-simple-table {
  width: 100% !important;
}

.theme--light.v-data-table {
  background-color: #fff;
  width: 100% !important;
  color: rgba(0, 0, 0, .87);
}
</style>