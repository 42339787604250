<template>
  <div class="">
    <v-row justify="space-between">
      <v-col class="d-flex" cols="12" sm="6">
        <v-btn class="mr-2 theme-color1" dark elevation="0" link tile>
          <v-icon>mdi-briefcase</v-icon>
        </v-btn>
        <h2 class="text-uppercase theme-font-color">{{ name }}</h2>
      </v-col>
      <v-col class="mb-1 d-flex" cols="12" sm="6">
        <v-select v-model="Filter.jobStatus" :items="JobFilter"
                  :menu-props="{ bottom: true, offsetY: true }" class="custom-input border-right JobFilter"
                  color="#64718A" dense hide-details
                  item-text="text" item-value="value" outlined style="max-width: 150px" @change="GetAll()"></v-select>
        <v-text-field v-model="Filter.filterText" append-icon="mdi-magnify" class="custom-input" clearable
                      color="#64718A" dense
                      hide-details outlined placeholder="Search" @keyup="GetAll()"
                      @click:clear="(Filter.filterText = ''), GetAll()"></v-text-field>
        <v-btn :to="{ name: 'AddJob'}" class="ms-2 custom-btn theme-color1" dark elevation="0" height="40" link tile>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>

    </v-row>
    <div class="custom-line"></div>
    <v-data-table v-if="Jobs == null" :headers="headers" :items-per-page="Filter.pageSize"
                  class="custom-table elevation-0"
                  disable-sort hide-default-footer>
    </v-data-table>
    <v-data-table v-else :headers="headers" :items="Jobs" :items-per-page="Filter.pageSize"
                  class="custom-table elevation-0"
                  disable-sort hide-default-footer>
      <template v-slot:[`item.sno`]="{ item }">
        <router-link :to="{ name: 'AddJob', params: { item , editView } }" class="ms-0">
          JOB # {{ item.jobNo }}
        </router-link>
      </template>
      <template v-slot:[`item.jobCreationDate`]="{item}">
        <span v-if="item.jobCreationDate != null">{{
            getFormattedDate(item.jobCreationDate)
          }}</span>
      </template>
      <template v-slot:[`item.customer.customerCategory`]="{item}">
        <span v-if="item.customer.customerCategory === 100">Domestic</span>
        <span v-if="item.customer.customerCategory === 110">Wholesale</span>
      </template>
      <template v-slot:[`item.jobCompletionDate`]="{item}">
        <span v-if="item.jobCompletionDate != null">{{ getFormattedDate(item.jobCompletionDate) }}</span>
      </template>
      <template v-slot:[`item.grandTotal`]="{item}">
        {{ FormatPrice(item.grandTotal) }}
      </template>
      <template v-slot:[`item.jobStatus`]="{item}">
        <v-chip v-if="item.jobStatus == 0" class="custom-chip" color="grey darken-2" dark label x-small>None</v-chip>
        <v-chip v-if="item.jobStatus == 1" class="custom-chip" color="primary" dark label x-small>In Progress</v-chip>
        <v-chip v-if="item.jobStatus == 2" class="custom-chip" color="purple darken-2" dark label x-small>Assigned
        </v-chip>
        <v-chip v-if="item.jobStatus == 3" class="custom-chip" color="success darken-2" dark label x-small>Completed
        </v-chip>
        <v-chip v-if="item.jobStatus == 4" class="custom-chip" color="gray darken-2" dark label x-small>Closed</v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <!--        <v-icon small @click="takeData(item)" color="success darken-1" class="">mdi-check-circle</v-icon>-->
        <router-link :to="{ name: 'AddJob', params: { item, jobview } }" class="ms-0">
          <v-icon v-if="item.jobStatus != 4" class="ms-1" color="primary darken-1" small @click="takeData(item)">
            mdi-pencil
          </v-icon>
        </router-link>
        <v-menu class="" left offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-if="item.jobStatus != 4" class="ms-1" color="#000" left offset-x="true"
                    small v-bind="attrs" @click="takeData(item)" v-on="on">mdi-dots-vertical
            </v-icon>
          </template>
          <v-list class="pa-0" dense>
            <v-list-item dense link
                         @click="CloseJob()">
              <v-list-item-icon class="nav-icon mr-2">
                <v-icon color="success" small>mdi-check-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                Close Job
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-icon v-if="item.jobStatus != 4" class="" color="red" small @click="takeData(item), (deleteDialog = true)">
          mdi-trash-can-outline
        </v-icon>
      </template>
    </v-data-table>
    <v-row align="center" class="no-gutters mt-2">
      <v-spacer style="width: 75%"></v-spacer>
      <span class="pagination-text">Rows per page</span>
      <v-select v-model="Filter.pageSize" :items="options" :menu-props="{ bottom: true, offsetY: true }" class="ma-2"
                dense style="width: 50px"
                @change="GetAll"></v-select>
      <span v-if="pagination != null" class="mr-4 grey--text pagination-text">
        Page {{ pagination.page }} of {{ pagination.totalPages }}
      </span>
      <v-icon class="mr-1" color="primary darken-2" dark small @click="backwardButtonCall">mdi-chevron-left</v-icon>
      <v-icon class="mr-1" color="primary darken-2" dark small @click="forwardButtonCall()">mdi-chevron-right</v-icon>
    </v-row>


    <!-- Delete dialog -->
    <v-dialog v-model="deleteDialog" max-width="400" persistent>
      <v-card>
        <v-card-title class="text-h5"> Delete Job</v-card-title>
        <v-card-text>Are You sure? You want to delete
          <b v-if="currentJob != null" class="error--text text--darken-2">{{
              currentJob.title
            }}</b></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text tile @click="deleteDialog = false">
            Cancel
          </v-btn>
          <v-btn color="error darken-2" elevation="0" tile @click="DeleteJob(), (deleteDialog = false)">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Cancellation dialog -->
    <v-dialog v-if="currentJob!= null" v-model="cancelDialog" max-width="400" persistent>
      <v-card>
        <v-card-title class="subtitle-1">Please Select Reason</v-card-title>
        <v-card-text>
          <v-select v-model="currentJob.cancellationReason" :items="CancellationReasons" :return-object="false"
                    item-text="text" item-value="value" label="Select Reason">

          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text tile @click="cancelDialog = false">
            Cancel
          </v-btn>
          <v-btn class="theme-color1" elevation="0" tile @click="UpdateJob(9), (cancelDialog = false)">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-progress-circular v-if="loader" class="custom-loader" color="#64718A" indeterminate
                         width="2"></v-progress-circular>
  </div>
</template>
<script>
import axios from "axios";
import config from "../Configuration/config";
import moment from "moment";
import 'moment-timezone';

export default {
  name: "JobModule",
  data() {
    return {
      name: "Jobs",
      jobview: false,
      editView: false,
      cancelDialog: false,
      loader: false,
      deleteDialog: false,
      headers: [
        {
          text: "#",
          align: "start",
          value: "sno",
          width: '140px'
        },
        {
          text: "Title",
          value: "title",
        },
        {
          text: "Customer",
          value: "customer.fullName",
        },
        {
          text: "Creation Date",
          value: "jobCreationDate",
        },
        {
          text: "Customer Type",
          value: "customer.customerCategory",
        },
        {
          text: "Status Date",
          value: "jobCompletionDate",
        },
        {
          text: "Discount",
          value: "discount",
        },
        {
          text: "Price",
          value: "grandTotal",
        },
        {
          text: "Status",
          value: "jobStatus",
          align: 'center',
          width: "70px"
        },

        {text: "Actions", value: "actions", align: "center", width: "130px", sortable: false},
      ],
      options: [
        {text: "10", value: 10},
        {text: "25", value: 25},
        {text: "50", value: 50},
        {text: "100", value: 100},
      ],
      CancellationReasons: [
        {text: "None", value: 0},
        {text: "Not Approved", value: 10},
        {text: "Too Expensive", value: 20},
      ],
      Jobs: null,
      currentJob: null,
      pagination: null,
      pageNumOnSearch: "",
      totalRecords: "",
      JobFilter: [
        {
          text: "All",
          value: 0
        },
        {
          text: "In Progress",
          value: 1,
        }, {
          text: "Assigned",
          value: 2,
        },
        {
          text: "Completed",
          value: 3,
        },
        {
          text: "Closed",
          value: 4,
        },
      ],
      Filter: {
        CompanyId: axios.defaults.headers.common.CompanyId,
        pageSize: 10,
        page: 1,
        filterText: "",
        isActive: true,
        jobType: 120,
        jobStatus: 0,
      },
      decimals: 2
    };
  },
  methods: {
    getFormattedDate(date) {
      /*const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get the user's timezone
      return moment.utc(date).tz(userTimezone).format('MMM DD, yyyy');*/
      return moment(date).format("MMM DD, YYYY");
    },
    FormatPrice(price) {
      return Math.round(price * 10 ** this.decimals) / 10 ** this.decimals
    },
    takeData(job) {
      this.currentJob = job;

    },
    async GetAll() {
      if (this.$route.name == "JobModule") {
        try {
          this.loader = true;
          this.Job
          let response = await axios({
            method: "POST",
            url: config.apiBaseUrl + "/api/Job/All",
            data: this.Filter,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          });
          this.Jobs = response.data.data;
          this.pagination = response.data.pagination
          this.loader = false;
        } catch (error) {
          console.log(error);
          this.loader = false;
        }
      }
    },
    async CloseJob() {
      try {
        if (this.currentJob.jobStatus != 3) {
          this.$toast("Incomplete job cannot be closed", {
            position: "top-center",
            timeout: 3000,
            closeOnClick: true,
            pauseOnfocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
          });
          return;
        }
        this.loader = true;
        const fd = new FormData();
        fd.append("jobId", this.currentJob.jobId);
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Job/CloseJob",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        console.log(response);
        this.loader = false;
        this.GetAll();
      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },
    async DeleteJob() {
      try {
        this.loader = true;
        this.loader = true;
        const fd = new FormData();
        fd.append("jobId", this.currentJob.jobId);
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Job/Remove?type=120",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        this.GetAll();
        console.log(response)
        this.loader = false
      } catch (error) {
        console.log(error);
        this.loader = false;
      }
    },

    async UpdateJob(status) {
      this.currentJob.jobStatus = status;
      if (status == 9) {
        this.currentJob.quoteCancelled = true;
      }
      try {
        this.loader = true;
        let response = await axios({
          method: "POST",
          url: config.apiBaseUrl + "/api/Job/AddOrUpdate",
          data: this.currentJob,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        console.log(response);
        this.loader = false;
        this.GetAll();
      } catch (err) {
        console.log(err);
      }
    },

    forwardButtonCall() {
      //it does not run on equal to condition
      if (this.Filter.page < this.pagination.totalPages) {
        this.Filter.page++;
        this.GetAll();
      } else {
        return;
      }
    },
    backwardButtonCall() {
      if (this.Filter.page > 1) {
        this.Filter.page--;
        this.GetAll();
      } else {
        return;
      }
    },
  },
  created() {
    this.$store.state.DisableCompany = false;
    this.GetAll();
    this.$root.$on("myEvent", () => {
      this.Filter.page = 1;
      this.Filter.filterText = "";
      this.GetAll();
    });
  },
};
</script>
<style scoped>
.custom-chip {
  width: 80px !important;
  justify-content: center;
}
</style>