<template>
  <div>
    <div>
      <v-form ref="form" v-model="valid" class="mt-3" lazy-validation>
        <v-row class="ps-8 pe-8" justify="center">
          <v-col cols="12" md="12">
            <v-card elevation="0" style="border: 1px solid #e3e3e3" tile>
              <v-toolbar color="#efefef" dense elevation="0" flat tile>
                <v-btn
                    class="mr-2 theme-color1"
                    dark
                    elevation="0"
                    height="35px"
                    link
                    tile
                    to="/employees"
                >
                  <v-icon dense>mdi-undo-variant</v-icon>
                </v-btn>
                <v-toolbar-title>
                  <h4 class="text-uppercase theme-font-color">{{ name }}</h4>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                    v-if="!editview"
                    class="ps-10 pe-10 login-btns theme-color1"
                    dark
                    elevation="0"
                    tile
                    @click="editview = !editview"
                >Edit
                </v-btn>
                <v-btn
                    v-if="editview"
                    :disabled="!validateform || !validateEmail"
                    class="ps-10 pe-10 login-btns theme-color1"
                    dark
                    elevation="0"
                    tile
                    @click="AddWorker"
                >
                  Save
                </v-btn>
              </v-toolbar>
              <v-row class="ma-0">
                <v-col cols="12" md="7">
                  <v-card-text>
                    <v-row class="pa-md-3">
                      <v-col class="pa-md-0" cols="12" md="6">
                        <v-text-field
                            v-model="Employee.firstName"
                            :disabled="!editview"
                            :rules="[rules.required]"
                            class="custom-input border-right"
                            color="#64718A  "
                            dense
                            hide-details
                            label="First Name"
                            outlined
                            required
                        >
                        </v-text-field>
                      </v-col>
                      <v-col class="pa-md-0" cols="12" md="6">
                        <v-text-field
                            v-model="Employee.lastName"
                            :disabled="!editview"
                            :rules="[rules.required]"
                            class="custom-input bordered"
                            color="#64718A  "
                            dense
                            hide-details
                            label="Last Name"
                            outlined
                            required
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="pa-md-3">
                      <v-col class="pa-md-0" cols="12" md="6">
                        <!--                        <v-text-field  v-if="Employee.user == null"  :disabled="!editview" label="Password" dense hide-details outlined v-model="Employee.password"-->
                        <!--                                      :append-icon="ShowPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="ShowPassword ? 'text' : 'password'"-->
                        <!--                                       counter autocomplete="off" color="#64718A" class="custom-input my-input-common-border"-->
                        <!--                                      @click:append="ShowPassword = !ShowPassword"></v-text-field>-->
                        <v-text-field
                            v-model="Employee.user.password"
                            :append-icon="
                            ShowPassword ? 'mdi-eye' : 'mdi-eye-off'
                          "
                            :disabled="!editview"
                            :type="ShowPassword ? 'text' : 'password'"
                            autocomplete="new-password"
                            class="custom-input my-input-common-border login-input"
                            color="#64718A"
                            counter
                            dense
                            hide-details
                            label="Password"
                            outlined
                            @keydown.space.prevent
                            @click:append="ShowPassword = !ShowPassword"
                        ></v-text-field>
                      </v-col>
                      <v-col class="pa-md-0" cols="12" md="6">
                        <v-select
                            v-model="Employee.user.role"
                            :disabled="!editview"
                            :items="Roles"
                            :menu-props="{ bottom: true, offsetY: true }"
                            :return-object="false"
                            class="custom-input bordered"
                            color="#64718A  "
                            dense
                            hide-details
                            item-text="text"
                            item-value="value"
                            label="Role"
                            outlined
                            persistent-hint
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row class="pa-md-3">
                      <v-col class="pa-md-0" cols="12" md="6">
                        <v-autocomplete
                            v-model="Employee.employeType"
                            :disabled="!editview"
                            :items="EmployeType"
                            :menu-props="{ bottom: true, offsetY: true }"
                            :return-object="false"
                            class="custom-input border-right"
                            color="#64718A  "
                            dense
                            hide-details
                            item-text="text"
                            item-value="value"
                            label="Employee Type"
                            outlined
                        ></v-autocomplete>
                      </v-col>
                      <v-col class="date-col pa-md-0" cols="12" md="6">
                        <span class="date-label">Original Hire Date</span>
                        <input
                            v-model="Employee.originalHireDate"
                            :disabled="!editview"
                            class="datepicker date-input custom-date border-right"
                            type="date"
                            width="100%"
                        />
                      </v-col>
                    </v-row>
                    <v-row class="pa-md-3">
                      <v-col class="date-col pa-md-0" cols="12" md="6">
                        <span class="date-label">Rehire Date</span>
                        <input
                            v-model="Employee.rehireDate"
                            :disabled="!editview"
                            class="datepicker date-input custom-date"
                            type="date"
                            width="100%"
                        />
                      </v-col>
                      <v-col class="date-col pa-md-0" cols="12" md="6">
                        <span class="date-label">Termination Date</span>
                        <input
                            v-model="Employee.terminationDate"
                            :disabled="!editview"
                            class="datepicker date-input custom-date border-right"
                            type="date"
                            width="100%"
                        />
                      </v-col>
                    </v-row>
                    <v-row class="pa-md-3">
                      <v-col class="pa-md-0" cols="12" md="4">
                        <v-select
                            v-model="Employee.wageType"
                            :disabled="!editview"
                            :items="wageTypes"
                            :menu-props="{ bottom: true, offsetY: true }"
                            :return-object="false"
                            class="custom-input bordered"
                            color="#64718A  "
                            dense
                            hide-details
                            item-text="text"
                            item-value="value"
                            label="Wage Type"
                            outlined
                        ></v-select>
                      </v-col>
                      <v-col class="pa-md-0" cols="12" md="4">
                        <v-text-field
                            v-model.number="Employee.hourlyRate"
                            :disabled="!editview"
                            append-icon="mdi-currency-usd"
                            class="custom-input border-right"
                            color="#64718A  "
                            dense
                            hide-details
                            label="Hourly Rate"
                            outlined
                            required
                            type="number"
                            @focus="selectText"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col class="pa-md-0" cols="12" md="4">
                        <v-text-field
                            v-model.number="Employee.salary"
                            :disabled="!editview"
                            append-icon="mdi-currency-usd"
                            class="custom-input bordered"
                            color="#64718A  "
                            dense
                            hide-details
                            label="Annual Salary"
                            outlined
                            required
                            type="number"
                            @focus="selectText"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-col>
                <v-col class="personal-info pb-0" cols="12" md="5">
                  <v-card-text>
                    <v-row class="pa-0">
                      <v-col class="pa-0 ps-3 pe-3">
                        <v-text-field
                            v-model="Employee.address"
                            :disabled="!editview"
                            class="custom-input my-input2"
                            color="#64718A  "
                            dense
                            hide-details
                            label="Address"
                            outlined
                            required
                            type=""
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="pa-3 pb-0 pt-0">
                      <v-col class="pa-0" cols="12" md="6">
                        <v-text-field
                            v-model="Employee.city"
                            :disabled="!editview"
                            class="custom-input my-input"
                            color="#64718A  "
                            dense
                            hide-details
                            label="City"
                            outlined
                            required
                        >
                        </v-text-field>
                      </v-col>
                      <v-col class="pa-0" cols="12" md="6">
                        <v-text-field
                            v-model="Employee.province"
                            :disabled="!editview"
                            class="custom-input my-input2"
                            color="#64718A  "
                            dense
                            hide-details
                            label="Province"
                            outlined
                            required
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="pa-0 ps-3 pe-3">
                      <v-col class="pa-0" cols="12" md="6">
                        <v-text-field
                            v-model="Employee.postalCode"
                            :disabled="!editview"
                            class="my-input-common-border custom-input"
                            color="#64718A  "
                            dense
                            hide-details
                            label="Postal Code"
                            outlined
                            required
                            @focus="selectText"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col class="pa-0" cols="12" md="6">
                        <v-autocomplete
                            v-model="Employee.country"
                            :disabled="!editview"
                            :items="Countries"
                            :menu-props="{ bottom: true, offsetY: true }"
                            :return-object="false"
                            class="custom-input my-input-common-border"
                            color="#64718A"
                            dense
                            hide-details
                            item-text="name.common"
                            item-value="name.common"
                            label="Country"
                            outlined
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row class="pa-0 ps-3 pe-3">
                      <v-col class="pa-0 pt-md-5">
                        <v-text-field
                            v-model="Employee.phoneNumber"
                            v-mask="['###-###-####']"
                            :disabled="!editview"
                            :rules="[rules.counter]"
                            class="custom-input my-input2"
                            color="#64718A"
                            dense
                            hide-details
                            label="Phone"
                            outlined
                            prefix="+1"
                            required
                            @focus="selectText"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="pa-0 ps-3 pe-3">
                      <v-col class="pa-0">
                        <v-text-field
                            v-model="Employee.email"
                            :disabled="!editview"
                            :rules="[rules.email]"
                            autocomplete="new-email"
                            class="custom-input my-input-common-border"
                            color="#64718A"
                            dense
                            hide-details
                            label="Email"
                            outlined
                            required
                            type="email"
                        >
                        </v-text-field>
                        <v-text-field
                            v-model="Employee.email"
                            :disabled="!editview"
                            :rules="[rules.email]"
                            autocomplete="nope"
                            class="d-none custom-input my-input-common-border"
                            color="#64718A"
                            dense
                            hide-details
                            label="Email"
                            outlined
                            required
                            type="email"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="">
                      <v-col
                          class="d-flex align-center justify-start pb-2 ps-2 pt-0"
                      >
                        <v-checkbox
                            v-model="Employee.forCommunication"
                            :disabled="!editview"
                            color="primary"
                            dense
                            hide-details
                            name="communication"
                        ></v-checkbox>
                        <label class="mt-2" for="communication"
                        >Use for communication</label
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-col>
              </v-row>
              <v-row class="mt-0 pa-md-3 ps-md-10 pe-md-10">
                <v-card elevation="0" outlined tile width="100%">
                  <v-toolbar class="tab-color" dense elevation="0" tile>
                    <v-toolbar-title class="font-weight-bold theme-font-color"
                    >Documents
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-row class="mt-0">
                    <v-col class="pa-md-3 pe-md-0" cols="12" md="6">
                      <v-card-text class="pt-0 pe-0">
                        <div
                            class="d-flex justify-space-between grey lighten-4 pa-1"
                        >
                          <div class="d-flex align-center">
                            <v-icon
                                class="ps-0 pe-0 me-5"
                                color="success"
                                small
                            >
                              mdi-check-circle
                            </v-icon>
                            <span
                                v-if="Employee.drivingLicense === ''"
                                class="documents_name"
                            >Driving License</span
                            >
                            <a
                                v-else
                                :href="Employee.drivingLicense"
                                class="documents_name"
                                @click="ShowDocument(Employee.drivingLicense)"
                            >Driving License</a
                            ><span v-if="Employee.drivingLicense != ''" class="caption ms-2"> ({{
                              showDoc(Employee.drivingLicense)
                            }})</span>
                          </div>
                          <div>
                            <v-icon
                                :disabled="!editview"
                                :loading="isSelecting"
                                class="me-2"
                                color="success"
                                dark
                                rounded
                                @click="handleFileImport(1)"
                            >mdi-upload
                            </v-icon>
                            <input
                                :key="fileInputKey"
                                ref="uploader1"
                                class="d-none"
                                type="file"
                                @change="onFileChanged($event, 'Driving License')"
                            />
                            <v-icon
                                v-if="Employee.drivingLicense != ''"
                                :disabled="!editview"
                                color="red darken-2"
                                dark
                                small
                                @click="Employee.drivingLicense = ''"
                            >mdi-trash-can-outline
                            </v-icon>
                          </div>
                        </div>
                        <div
                            class="d-flex justify-space-between white lighten-3 pa-1"
                        >
                          <div class="d-flex align-center">
                            <v-icon
                                class="ps-0 pe-0 me-5"
                                color="success"
                                small
                            >
                              mdi-check-circle
                            </v-icon>
                            <span
                                v-if="Employee.gasLicense === ''"
                                class="documents_name"
                            >Gas License</span
                            >
                            <a
                                v-else
                                :href="Employee.gasLicense"
                                class="documents_name"
                                @click="ShowDocument(Employee.gasLicense)"
                            >Gas License</a
                            ><span v-if="Employee.gasLicense != ''" class="caption ms-2"> ({{
                              showDoc(Employee.gasLicense)
                            }})</span>
                          </div>
                          <div>
                            <v-icon
                                :disabled="!editview"
                                :loading="isSelecting"
                                class="me-2"
                                color="success"
                                dark
                                rounded
                                @click="handleFileImport(2)"
                            >mdi-upload
                            </v-icon>
                            <input
                                :key="fileInputKey"
                                ref="uploader2"
                                class="d-none"
                                type="file"
                                @change="onFileChanged($event, 'Gas License')"
                            />
                            <v-icon
                                v-if="Employee.gasLicense != ''"
                                :disabled="!editview"
                                color="red darken-2"
                                dark
                                small
                                @click="Employee.gasLicense = ''"
                            >mdi-trash-can-outline
                            </v-icon>
                          </div>
                        </div>
                        <div
                            class="d-flex justify-space-between grey lighten-4 pa-1"
                        >
                          <div class="d-flex align-center">
                            <v-icon
                                class="ps-0 pe-0 me-5"
                                color="success"
                                small
                            >
                              mdi-check-circle
                            </v-icon>
                            <span
                                v-if="Employee.sinDocument === ''"
                                class="documents_name"
                            >SIN (Social Insurance Number)</span
                            >
                            <a
                                v-else
                                :href="Employee.sinDocument"
                                class="documents_name"
                                @click="ShowDocument(Employee.sinDocument)"
                            >SIN (Social Insurance Number)</a
                            ><span v-if="Employee.sinDocument != ''" class="caption ms-2"> ({{
                              showDoc(Employee.sinDocument)
                            }})</span>
                          </div>
                          <div>
                            <v-icon
                                :disabled="!editview"
                                :loading="isSelecting"
                                class="me-2"
                                color="success"
                                dark
                                rounded
                                @click="handleFileImport(3)"
                            >mdi-upload
                            </v-icon>
                            <input
                                :key="fileInputKey"
                                ref="uploader3"
                                class="d-none"
                                type="file"
                                @change="
                                onFileChanged(
                                  $event,
                                  'SIN (Social Insurance Number)'
                                )
                              "
                            />
                            <v-icon
                                v-if="Employee.sinDocument != ''"
                                :disabled="!editview"
                                color="red darken-2"
                                dark
                                small
                                @click="Employee.sinDocument = ''"
                            >mdi-trash-can-outline
                            </v-icon>
                          </div>
                        </div>
                        <div
                            class="d-flex justify-space-between white lighten-3 pa-1"
                        >
                          <div class="d-flex align-center">
                            <v-icon
                                class="ps-0 pe-0 me-5"
                                color="success"
                                small
                            >
                              mdi-check-circle
                            </v-icon>
                            <span
                                v-if="Employee.drivingAbstract === ''"
                                class="documents_name"
                            >Driving Abstract</span
                            >
                            <a
                                v-else
                                :href="Employee.drivingAbstract"
                                class="documents_name"
                                @click="ShowDocument(Employee.drivingAbstract)"
                            >Driving Abstract</a
                            ><span v-if="Employee.drivingAbstract != ''" class="caption ms-2"> ({{
                              showDoc(Employee.drivingAbstract)
                            }})</span>
                          </div>
                          <div>
                            <v-icon
                                :disabled="!editview"
                                :loading="isSelecting"
                                class="me-2"
                                color="success"
                                dark
                                rounded
                                @click="handleFileImport(4)"
                            >mdi-upload
                            </v-icon>
                            <input
                                :key="fileInputKey"
                                ref="uploader4"
                                class="d-none"
                                type="file"
                                @change="
                                onFileChanged($event, 'Driving Abstract')
                              "
                            />
                            <v-icon
                                v-if="Employee.drivingAbstract != ''"
                                :disabled="!editview"
                                color="red darken-2"
                                dark
                                small
                                @click="Employee.drivingLicense = ''"
                            >mdi-trash-can-outline
                            </v-icon>
                          </div>
                        </div>
                        <!--                        v-if="Employee.employeType == 10"-->
                        <div
                            class="d-flex justify-space-between grey lighten-4 pa-1"
                        >
                          <div class="d-flex align-center">
                            <v-icon
                                class="ps-0 pe-0 me-5"
                                color="success"
                                small
                            >
                              mdi-check-circle
                            </v-icon>
                            <span
                                v-if="Employee.hstDocument === ''"
                                class="documents_name"
                            >Hst Document</span
                            >
                            <a
                                v-else
                                :href="Employee.hstDocument"
                                class="documents_name"
                                @click="ShowDocument(Employee.hstDocument)"
                            >Hst Document</a
                            ><span v-if="Employee.hstDocument != ''" class="caption ms-2"> ({{
                              showDoc(Employee.hstDocument)
                            }})</span>
                          </div>
                          <div>
                            <v-icon
                                :disabled="!editview"
                                :loading="isSelecting"
                                class="me-2"
                                color="success"
                                dark
                                rounded
                                @click="handleFileImport(5)"
                            >mdi-upload
                            </v-icon>
                            <input
                                :key="fileInputKey"
                                ref="uploader5"
                                class="d-none"
                                type="file"
                                @change="onFileChanged($event, 'Hst Document')"
                            />
                            <v-icon
                                v-if="Employee.hstDocument != ''"
                                :disabled="!editview"
                                color="red darken-2"
                                dark
                                small
                                @click="Employee.hstDocument = ''"
                            >mdi-trash-can-outline
                            </v-icon>
                          </div>
                        </div>
                        <div
                            class="d-flex justify-space-between white lighten-3 pa-1"
                        >
                          <div class="d-flex align-center">
                            <v-icon
                                class="ps-0 pe-0 me-5"
                                color="success"
                                small
                            >
                              mdi-check-circle
                            </v-icon>
                            <span
                                v-if="Employee.wsibDocument === ''"
                                class="documents_name"
                            >WSIB Document</span
                            >
                            <a
                                v-else
                                :href="Employee.wsibDocument"
                                class="documents_name"
                                @click="ShowDocument(Employee.wsibDocument)"
                            >WSIB Document</a
                            ><span v-if="Employee.wsibDocument != ''" class="caption ms-2"> ({{
                              showDoc(Employee.wsibDocument)
                            }})</span>
                          </div>
                          <div>
                            <v-icon
                                :disabled="!editview"
                                :loading="isSelecting"
                                class="me-2"
                                color="success"
                                dark
                                rounded
                                @click="handleFileImport(10)"
                            >mdi-upload
                            </v-icon>
                            <input
                                :key="fileInputKey"
                                ref="uploader6"
                                class="d-none"
                                type="file"
                                @change="onFileChanged($event, 'WSIB Document')"
                            />
                            <v-icon
                                v-if="Employee.wsibDocument != ''"
                                :disabled="!editview"
                                color="red darken-2"
                                dark
                                small
                                @click="Employee.wsibDocument = ''"
                            >mdi-trash-can-outline
                            </v-icon>
                          </div>
                        </div>
                      </v-card-text>
                    </v-col>
                    <v-col class="pa-md-3 ps-md-0" cols="12" md="6">
                      <v-card-text class="pt-0 pr-0">
                        <div
                            class="d-flex justify-space-between grey lighten-4 pa-1"
                        >
                          <div class="d-flex align-center">
                            <v-icon
                                class="ps-0 pe-0 me-5"
                                color="success"
                                small
                            >
                              mdi-check-circle
                            </v-icon>
                            <span
                                v-if="Employee.criminalRecord === ''"
                                class="documents_name"
                            >Criminal Record</span
                            >
                            <a
                                v-else
                                :href="Employee.criminalRecord"
                                class="documents_name"
                                @click="ShowDocument(Employee.criminalRecord)"
                            >Criminal Record</a
                            ><span v-if="Employee.criminalRecord != ''" class="caption ms-2"> ({{
                              showDoc(Employee.criminalRecord)
                            }})</span>
                          </div>
                          <div>
                            <v-icon
                                :disabled="!editview"
                                :loading="isSelecting"
                                class="me-2"
                                color="success"
                                dark
                                rounded
                                @click="handleFileImport(6)"
                            >mdi-upload
                            </v-icon>
                            <input
                                :key="fileInputKey"
                                ref="uploader7"
                                class="d-none"
                                type="file"
                                @change="onFileChanged($event, 'Criminal Record')"
                            />
                            <v-icon
                                v-if="Employee.criminalRecord != ''"
                                :disabled="!editview"
                                color="red darken-2"
                                dark
                                small
                                @click="Employee.criminalRecord = ''"
                            >mdi-trash-can-outline
                            </v-icon>
                          </div>
                        </div>
                        <!--                        v-if="Employee.employeType == 10"-->
                        <div
                            class="d-flex justify-space-between white lighten-3 pa-1"
                        >
                          <div class="d-flex align-center">
                            <v-icon
                                class="ps-0 pe-0 me-5"
                                color="success"
                                small
                            >
                              mdi-check-circle
                            </v-icon>
                            <span
                                v-if="Employee.contractorLicense === ''"
                                class="documents_name"
                            >Contractor License</span
                            >
                            <a
                                v-else
                                :href="Employee.contractorLicense"
                                class="documents_name"
                                @click="ShowDocument(Employee.contractorLicense)"
                            >Contractor License</a
                            ><span v-if="Employee.contractorLicense != ''" class="caption ms-2"> ({{
                              showDoc(Employee.contractorLicense)
                            }})</span>
                          </div>
                          <div>
                            <v-icon
                                :disabled="!editview"
                                :loading="isSelecting"
                                class="me-2"
                                color="success"
                                dark
                                rounded
                                @click="handleFileImport(7)"
                            >mdi-upload
                            </v-icon>
                            <input
                                :key="fileInputKey"
                                ref="uploader7"
                                class="d-none"
                                type="file"
                                @change="
                                onFileChanged($event, 'Contractor License')
                              "
                            />
                            <v-icon
                                v-if="Employee.contractorLicense != ''"
                                :disabled="!editview"
                                color="red darken-2"
                                dark
                                small
                                @click="Employee.contractorLicense = ''"
                            >mdi-trash-can-outline
                            </v-icon>
                          </div>
                        </div>
                        <!--                        v-if="Employee.employeType == 10"-->
                        <div
                            class="d-flex justify-space-between grey lighten-4 pa-1"
                        >
                          <div class="d-flex align-center">
                            <v-icon
                                class="ps-0 pe-0 me-5"
                                color="success"
                                small
                            >
                              mdi-check-circle
                            </v-icon>
                            <span
                                v-if="Employee.liabilityInsurance === ''"
                                class="documents_name"
                            >Liability Insurance</span
                            >
                            <a
                                v-else
                                :href="Employee.liabilityInsurance"
                                class="documents_name"
                                @click="ShowDocument(Employee.liabilityInsurance)"
                            >Liability Insurance</a
                            ><span v-if="Employee.liabilityInsurance != ''" class="caption ms-2"> ({{
                              showDoc(Employee.liabilityInsurance)
                            }})</span>
                          </div>
                          <div>
                            <v-icon
                                :disabled="!editview"
                                :loading="isSelecting"
                                class="me-2"
                                color="success"
                                dark
                                rounded
                                @click="handleFileImport(8)"
                            >mdi-upload
                            </v-icon>
                            <input
                                :key="fileInputKey"
                                ref="uploader8"
                                class="d-none"
                                type="file"
                                @change="
                                onFileChanged($event, 'Liability Insurance')
                              "
                            />
                            <v-icon
                                v-if="Employee.liabilityInsurance != ''"
                                :disabled="!editview"
                                color="red darken-2"
                                dark
                                small
                                @click="Employee.liabilityInsurance = ''"
                            >mdi-trash-can-outline
                            </v-icon>
                          </div>
                        </div>
                        <!--                        v-if="Employee.employeType == 10"-->
                        <div
                            class="d-flex justify-space-between white lighten-3 pa-1"
                        >
                          <div class="d-flex align-center">
                            <v-icon
                                class="ps-0 pe-0 me-5"
                                color="success"
                                small
                            >
                              mdi-check-circle
                            </v-icon>
                            <span
                                v-if="Employee.employeeContractDocument === ''"
                                class="documents_name"
                            >Employee Contract Document</span
                            >
                            <a
                                v-else
                                :href="Employee.employeeContractDocument"
                                class="documents_name"
                                @click="
                                ShowDocument(Employee.employeeContractDocument)
                              "
                            >Employee Contract Document</a><span v-if="Employee.employeeContractDocument != ''"
                                                                 class="caption ms-2"> ({{
                              showDoc(Employee.employeeContractDocument)
                            }})</span>
                          </div>
                          <div>
                            <v-icon
                                :disabled="!editview"
                                :loading="isSelecting"
                                class="me-2"
                                color="success"
                                dark
                                rounded
                                @click="handleFileImport(9)"
                            >mdi-upload
                            </v-icon>
                            <input
                                :key="fileInputKey"
                                ref="uploader9"
                                class="d-none"
                                type="file"
                                @change="
                                onFileChanged(
                                  $event,
                                  'Employee Contract Document'
                                )
                              "
                            />
                            <v-icon
                                v-if="Employee.employeeContractDocument != ''"
                                :disabled="!editview"
                                color="red darken-2"
                                dark
                                small
                                @click="Employee.employeeContractDocument = ''"
                            >
                              mdi-trash-can-outline
                            </v-icon>
                          </div>
                        </div>
                        <div
                            class="d-flex justify-space-between grey lighten-4 pa-1"
                        >
                          <div class="d-flex align-center">
                            <v-icon
                                class="ps-0 pe-0 me-5"
                                color="success"
                                small
                            >
                              mdi-check-circle
                            </v-icon>
                            <span
                                v-if="Employee.refrigerationLicense === ''"
                                class="documents_name"
                            >Refrigeration License</span
                            >
                            <a
                                v-else
                                :href="Employee.refrigerationLicense"
                                class="documents_name"
                                @click="
                                ShowDocument(Employee.refrigerationLicense)
                              "
                            >Refrigeration Licence</a><span v-if="Employee.refrigerationLicense != ''"
                                                            class="caption ms-2"> ({{
                              showDoc(Employee.refrigerationLicense)
                            }})</span>
                          </div>
                          <div>
                            <v-icon
                                :disabled="!editview"
                                :loading="isSelecting"
                                class="me-2"
                                color="success"
                                dark
                                rounded
                                @click="handleFileImport(11)"
                            >mdi-upload
                            </v-icon>
                            <input
                                :key="fileInputKey"
                                ref="uploader11"
                                class="d-none"
                                type="file"
                                @change="
                                onFileChanged($event, 'Refrigeration License')
                              "
                            />
                            <v-icon
                                v-if="Employee.refrigerationLicense != ''"
                                :disabled="!editview"
                                color="red darken-2"
                                dark
                                small
                                @click="Employee.refrigerationLicense = ''"
                            >
                              mdi-trash-can-outline
                            </v-icon>
                          </div>
                        </div>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-card>
              </v-row>
              <v-row class="mt-0 pa-md-3">
                <v-col class="pa-md-3" cols="12" md="12">
                  <v-card-text class="pt-0">
                    <v-row justify="end">
                      <v-col class="d-flex" cols="12" md="6">
                        <v-btn
                            v-if="!AllServices"
                            :disabled="!editview"
                            class="caption theme-color1 text-capitalize"
                            depressed
                            height="40"
                            tile
                            @click="
                            SelectAllServices(), (AllServices = !AllServices)
                          "
                        >Select All
                        </v-btn>
                        <v-btn
                            v-if="AllServices"
                            :disabled="!editview"
                            class="caption theme-color1 text-capitalize"
                            depressed
                            height="40"
                            tile
                            @click="
                            ClearAllServices(), (AllServices = !AllServices)
                          "
                        >Clear All
                        </v-btn>
                        <v-autocomplete
                            key="serviceId"
                            v-model="Service"
                            :disabled="!editview"
                            :items="Services"
                            :menu-props="{ bottom: true, offsetY: true }"
                            class="custom-input my-input-common-border"
                            color="#64718A"
                            counter
                            deletable-chips
                            dense
                            item-text="title"
                            item-value="serviceId"
                            label="Services"
                            multiple
                            outlined
                            return-object
                            single-line
                            small-chips
                            @change="AddTax()"
                        >
                          <template v-slot:item="data">
                            <template v-if="typeof data.item !== 'object'">
                              <v-list-item-content
                                  v-text="data.item"
                              ></v-list-item-content>
                            </template>
                            <template v-else>
                              <v-list-item-content>
                                <v-list-item-title
                                >{{ data.item.title }}
                                  <span
                                      v-if="!data.item.forWholesale"
                                      class="caption"
                                  >
                                    (Domestic)</span
                                  >
                                  <span
                                      v-if="data.item.forWholesale"
                                      class="caption"
                                  >
                                    (Wholesale /
                                    {{ data.item.wholesaleTitle }})</span
                                  >
                                </v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-data-table
                        v-if="Employee.services != null"
                        v-model="Employee.services"
                        :headers="headers"
                        :items="Employee.services"
                        :items-per-page="100"
                        checkbox-color="success"
                        class="custom-table workerServices-table"
                        disable-sort
                        elevation="0"
                        hide-default-footer
                        item-key="serviceId"
                        selectable-key="serviceId"
                        @change="AddTax()"
                    >
                      <template v-slot:[`item.sno`]="{ index }">
                        <span>{{ index + 1 }}</span>
                      </template>
                      <template v-slot:[`item.serviceType`]="{ item }">
                        <span class="caption">{{ item.serviceType }}</span>
                      </template>
                      <template v-slot:[`item.taxRate`]="{ item }">
                        <input
                            v-model.number="item.taxRate"
                            :disabled="!editview"
                            class="custom-number-input"
                            min="0"
                            type="number"
                            @change="ChangeAmount(item)"
                            @focus="selectText"
                        />
                      </template>
                      <template v-slot:[`item.laborCost`]="{ item }">
                        <input
                            v-model.number="item.laborCost"
                            :disabled="!editview"
                            class="custom-number-input"
                            min="0"
                            type="number"
                            @change="ChangeAmount(item)"
                            @focus="selectText"
                        />
                      </template>

                      <template v-slot:[`item.action`]="{ index }">
                        <v-icon
                            :disabled="!editview"
                            color="red"
                            small
                            @click="deleteItem(index)"
                        >
                          mdi-trash-can-outline
                        </v-icon>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="" justify="end">
          <v-col class="pt-0 pb-0 d-flex justify-end" cols="12" md="4"></v-col>
        </v-row>
      </v-form>
    </div>
    <!-- Progress Bar -->
    <v-progress-circular
        v-if="loader"
        class="custom-loader"
        color="#64718A"
        indeterminate
        width="2"
    ></v-progress-circular>
  </div>
</template>
<script>
import moment from "moment";
import axios from "axios";
import config from "../Configuration/config";
import 'moment-timezone';

export default {
  name: "AddWorker",
  data() {
    return {
      name: "New Employee",
      dialog: false,
      editview: true,
      AllServices: false,
      ShowPassword: false,
      SelectedServices: [],
      valid: true,
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length > 11 || "Max 20 characters",
        numberRule: (v) => {
          if (!v.trim()) return true;
          if (!isNaN(parseFloat(v)) && v >= 0 && v <= 999) return true;
          return "Number has to be between 0 and 999";
        },
        email: (value) => {
          const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      loader: false,
      fileInputKey: Date.now(),
      isSelecting: false,
      selectedFile: null,
      btnText: "Add",
      eligibility: [
        {state: "Yes", abbr: "Has Company", value: true},
        {state: "No", abbr: "Has Company", value: false},
      ],
      userAccess: [
        {access: "Enabled", abbr: "Has Company", value: true},
        {access: "Disabled", abbr: "Has Company", value: false},
      ],
      Countries: [],
      wageTypes: [
        {text: "Hourly", value: 50},
        {text: "Salaried", value: 60},
        {text: "Per Task", value: 70},
      ],
      Roles: [
        {text: "Admin", value: 10},
        {text: "Office Admin", value: 15},
        {text: "Employee", value: 20},
      ],
      Status: [
        {text: "Active", value: true},
        {text: "Inactive", value: false},
      ],
      Communications: [
        {text: "Yes", value: true},
        {text: "No", value: false},
      ],
      EmployeType: [
        {
          text: "Employed",
          value: 20,
        },
        {
          text: "Contractor",
          value: 10,
        },
      ],
      Services: [],
      headers: [
        {
          text: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" + "#",
          align: "center",
          value: "sno",
          width: "100px",
        },
        {
          text: "Service",
          align: "left",
          value: "title",
          width: "350px",
        },
        {
          text: "Customer Type",
          align: "left",
          value: "customerType",
        },
        {
          text: "Labour Cost",
          align: "left",
          value: "laborCost",
          width: "150px",
        },
        {
          text: "Tax Rate (%)",
          align: "left",
          value: "taxRate",
          width: "120px",
        },
        {
          text: "Total",
          align: "left",
          value: "price",
          width: "100px",
        },
        {text: "", value: "action", width: "50px"},
      ],
      Documents: [],
      DocumentsArray: [],
      role: 20,
      Employee: {
        companyId: axios.defaults.headers.common.CompanyId,
        employeId: 0,
        firstName: "",
        lastName: "",
        fullName: "",
        isActive: true,
        email: "",
        forCommunication: false,
        phoneNumber: "",
        originalHireDate: new Date(),
        rehireDate: null,
        terminationDate: null,
        isEligible: true,
        userAccess: true,
        address: "",
        city: "",
        postalCode: "",
        province: "",
        country: "",
        hourlyRate: 0,
        salary: 0,
        employeType: 20,
        wageType: 50,
        sin: "string",
        hasEmail: true,
        services: [],
        password: "",
        //Documents
        drivingLicense: "",
        gasLicense: "",
        sinDocument: "",
        drivingAbstract: "",
        criminalRecord: "",
        contractorLicense: "",
        liabilityInsurance: "",
        hstDocument: "",
        wsibDocument: "",
        refrigerationLicense: "",
        employeeContractDocument: "",
        user: {
          userId: 0,
          username: "string",
          password: "",
          role: 20,
        },
      },
      Price: 0,
      deletedServices: [],
      pagination: null,
      Filter: {
        CompanyId: axios.defaults.headers.common.CompanyId,
        page: 1,
        pageSize: 10,
        pageNumOnSearch: "",
        filterText: "",
        employeId: 0,
      },
      CurrentRow: null,
      CurrentPrice: 0,
      Service: [],
    };
  },
  computed: {
    validateEmail() {
      /* eslint-disable */
      return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          this.Employee.email
      );
    },
    validateform() {
      if (
          this.Employee.firstName == "" ||
          this.Employee.lastName == "" ||
          this.Employee.phoneNumber.length < 12
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    showDoc(doc) {
      if (doc != "") {
        if (doc != null) {

          let saf = doc.substring(doc.lastIndexOf("_") + 1);
          return saf.replaceAll(/%20/g, " ");
        }
      }
      return "";
    },
    selectText(event) {
      event.target.select();
    },
    AddTax() {
      var index = this.Service.length - 1;
      if (index > -1) {
        if (this.Service[0].wholesaleTitle === "") {
          this.Service[0].wholesaleTitle = "Domestic";
        }
        if (
            !this.Employee.services.some(
                (service) =>
                    service.title === this.Service[0].title &&
                    service.customerType === this.Service[0].wholesaleTitle
            )
        ) {
          this.Employee.services.push(this.Service[0]);
          let service = this.Employee.services.find(
              ({serviceId}) => serviceId === this.Service[0].serviceId
          );
          switch (this.Employee.employeType) {
            case 20:
              service.taxRate = 0;
              break;
            case 10:
              service.taxRate = 13;
              break;
          }
          service.laborCost = 0;
          service.price = 0;
          if (this.Service[0].wholesaleTitle !== "") {
            service.customerType = this.Service[0].wholesaleTitle;
          } else {
            service.customerType = "Domestic";
          }
        }
        this.Service = [];
        //this.Job.jobItems[index].customerType  = this.Job.jobItems[index].wholesaleTitle;
      }

      // var index = this.Employee.services.length - 1;
      // if (this.Employee.services.length > 0) {
      //   this.Employee.services[index].laborCost = 0;
      //   this.Employee.services[index].taxRate = 0;
      //   this.Employee.services[index].price = 0;
      //   if (this.Employee.services[index].wholesaleTitle === "") {
      //     this.Employee.services[index].customerType = "Domestic"
      //   } else {
      //     this.Employee.services[index].customerType = this.Employee.services[index].wholesaleTitle;
      //   }
      // }
    },
    ChangeAmount(item) {
      let service = this.Employee.services.find(
          ({serviceId}) => serviceId === item.serviceId
      );
      if (item.laborCost == "") {
        item.laborCost = 0;
        service.laborCost = 0;
      }
      if (item.taxRate == "") {
        item.taxRate = 0;
        service.taxRate = 0;
      }
      let taxPrice = (service.laborCost / 100) * item.taxRate;
      if (isNaN(taxPrice)) {
        taxPrice = 0;
      }
      service.price = item.laborCost + taxPrice;
    },
    // ChangeTax(item){
    //   var taxPrice = (item.price / 100) * item.taxRate;
    //   var service = this.Employee.services.find(({ serviceId }) => serviceId === item.serviceId);
    //   service.price = item.price + taxPrice;
    // },
    SelectAllServices() {
      this.Employee.services = this.Services;
      this.Employee.services.forEach((service) => {
        switch (this.Employee.employeType) {
          case 20:
            service.taxRate = 0;
            break;
          case 10:
            service.taxRate = 13;
            break;
        }
        service.laborCost = 0;
        service.price = 0;
        if (service.wholesaleTitle !== "") {
          service.customerType = service.wholesaleTitle;
        } else {
          service.customerType = "Domestic";
        }
      });
    },
    ClearAllServices() {
      this.Employee.services = [];
    },

    formatPhoneNumber() {
      this.Employee.phoneNumber = this.Employee.phoneNumber
          .replace(/[^0-9]/g, "")
          .replace(/^(\d{3})(\d{3})(\d{4})/g, "$1-$2-$3")
          .substring(0, 12);
    },
    handleFileImport(num) {
      this.isSelecting = true;
      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener(
          "focus",
          () => {
            this.isSelecting = false;
          },
          {once: true}
      );
      // Trigger click on the FileInput
      switch (num) {
        case 1:
          this.$refs.uploader1.click();
          break;
        case 2:
          this.$refs.uploader2.click();
          break;
        case 3:
          this.$refs.uploader3.click();
          break;
        case 4:
          this.$refs.uploader4.click();
          break;
        case 5:
          this.$refs.uploader5.click();
          break;
        case 6:
          this.$refs.uploader6.click();
          break;
        case 7:
          this.$refs.uploader7.click();
          break;
        case 8:
          this.$refs.uploader8.click();
          break;
        case 9:
          this.$refs.uploader9.click();
          break;
        case 10:
          this.$refs.uploader10.click();
          break;
        case 11:
          this.$refs.uploader11.click();
          break;
      }
    },
    async onFileChanged(e, type) {
      this.loader = true;
      this.selectedFile = e.target.files[0];
      try {
        const fd = new FormData();
        fd.append("file", this.selectedFile);
        let response = await axios({
          method: "post",
          url:
              config.apiBaseUrl + "/api/Employee/UploadDocument/UploadDocument",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });

        if (response.status == 200) {
          this.$toast.success("File uploaded successfully!", {
            position: "top-center",
            timeout: 1000,
            closeOnClick: true,
            pauseOnfocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
          });
          switch (type) {
            case "Driving License":
              this.Employee.drivingLicense = response.data;
              break;
            case "Gas License":
              this.Employee.gasLicense = response.data;
              break;
            case "SIN (Social Insurance Number)":
              this.Employee.sinDocument = response.data;
              break;
            case "Driving Abstract":
              this.Employee.drivingAbstract = response.data;
              break;
            case "Hst Document":
              this.Employee.hstDocument = response.data;
              break;
            case "Criminal Record":
              this.Employee.criminalRecord = response.data;
              break;
            case "Contractor License":
              this.Employee.contractorLicense = response.data;
              break;
            case "Liability Insurance":
              this.Employee.liabilityInsurance = response.data;
              break;
            case "Employee Contract Document":
              this.Employee.employeeContractDocument = response.data;
              break;
            case "WSIB Document":
              this.Employee.wsibDocument = response.data;
              break;
            case "Refrigeration License":
              this.Employee.refrigerationLicense = response.data;
              break;
          }
        } else if (response.status != 200) {
          this.loader = false;
          this.$toast.warning("File upload failed!", {
            position: "top-center",
            timeout: 1000,
            closeOnClick: true,
            pauseOnfocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
          });
        }

        this.loader = false;
      } catch (error) {
        this.loader = false;
        console.log(error);
      }
    },

    ShowDocument(link) {
      var myWindow = window.open("", "_blank");
      const img = document.createElement("img");
      img.src = link;
      myWindow.document.body.appendChild(img);
    },

    validate() {
      if (this.Employee == null) {
        this.$refs.form.validate();
      }
    },
    getFormattedDate(date) {
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get the user's timezone
      return moment.utc(date).tz(userTimezone).format('MMM DD, yyyy');
      //return moment(date).format("DD-MM-YYYY");
    },
    async GetCountries() {
      try {
        let response = await axios.get("https://restcountries.com/v3.1/all");
        this.Countries = response.data;
        var canada = this.Countries.filter((x) => x.name.common == "Canada");
        this.Employee.country = canada[0].name.common;
      } catch (error) {
        console.log(error.message);
        this.loader = false;
      }
    },

    async GetServices() {
      try {
        this.loader = true;
        this.Filter.employeId = this.Employee.employeId;
        let response = await axios({
          method: "POST",
          url: config.apiBaseUrl + "/api/Service/GetServicesForEmployees",
          data: this.Filter,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        this.Services = response.data.data;
        this.loader = false;
      } catch (error) {
        console.log(error);
        this.loader = false;
      }
    },
    AddWorker: async function () {
      try {
        if (this.Employee.services.length > 0) {
          this.Employee.services.forEach((element) => {
            element.totalPrice = element.price;
          });
        }
        if (this.Employee.phoneNumber !== "") {
          this.Employee.phoneNumber = this.Employee.phoneNumber.replace(
              /-/g,
              ""
          );
        }

        this.loader = true;
        this.Employee.phoneNumber = this.Employee.phoneNumber.replace(/-/g, "");
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Employee/AddOrUpdate",
          data: this.Employee,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        this.loader = false;
        if (
            response.data.isSuccessful == false &&
            response.data.msg == "Employee already exists with the same email!"
        ) {
          this.$toast.warning("Employee already exists with the same email!", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnfocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
          });
        }
        if (response.data.isSuccessful === true) {
          setTimeout(this.$router.push("/employees"), 3000);
        }
      } catch (error) {
        console.log(error);
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },
    // Modal Functions
    deleteItem(index) {
      this.Employee.services.splice(index, 1);
    },

    async GetEmployee() {
      if (this.$route.params.item != undefined) {
        this.loader = true;
        if (this.$route.params.editView != undefined) {
          this.editview = this.$route.params.editView;
        }
        try {
          this.Employee = this.$route.params.item;

          const fd = new FormData();
          fd.append("username", this.Employee.email);
          let response = await axios({
            method: "post",
            url: config.apiBaseUrl + "/api/Employee/Get",
            data: fd,
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
            },
          });
          this.Employee = response.data;
          this.name = this.Employee.firstName + " " + this.Employee.lastName;
          this.GetServices();
          if (this.Employee.originalHireDate != null) {
            this.Employee.originalHireDate =
                this.Employee.originalHireDate.substring(0, 10);
          }
          if (this.Employee.rehireDate != null) {
            this.Employee.rehireDate = this.Employee.rehireDate.substring(0, 10);
          }
          if (this.Employee.terminationDate != null) {
            this.Employee.terminationDate =
                this.Employee.terminationDate.substring(0, 10);
          }
          this.loader = false;
        } catch (error) {
          console.log(error);
          this.loader = false;
        }
      }
    },
  },
  mounted() {
    this.$refs.form.validate();
  },
  created() {
    this.$store.state.DisableCompany = true;
    if (this.$route.params.item == undefined) {
      this.GetServices();
    }
    this.GetCountries();
    this.GetEmployee();
    this.$root.$on("myEvent", () => {
      this.GetEmployee();
      this.GetServices();
    });
  },
};
</script>
<style scoped>
.v-text-field fieldset,
.v-text-field .v-input__control {
  border: 0px !important;
}

.v-text-field ::v-deep label {
  font-size: 14px;
}

.datepicker {
  margin: 0;
  height: 40px !important;
  padding-left: 8px !important;
  border: 1px solid #ccc !important;
}

.documents_name {
  font-size: 13px !important;
}

.file-input {
  width: 100%;
}
</style>
