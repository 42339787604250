<template>
  <div>
    <div>
      <v-form class="mt-3">
        <v-row class="ps-8 pe-8" justify="center">
          <v-col cols="12" md="12">
            <v-card elevation="0" outlined tile>
              <v-toolbar class="tab-color" dense elevation="0" flat tile>
                <v-btn
                    class="mr-2 theme-color1"
                    dark
                    elevation="0"
                    height="35px"
                    link
                    tile
                    to="/qoutations"
                >
                  <v-icon dense>mdi-undo-variant</v-icon>
                </v-btn>
                <v-toolbar-title>
                  <h4 class="text-uppercase theme-font-color">
                    {{ name }} for
                    <span v-if="SelectedCustomer != null" class="black--text">
                      {{ SelectedCustomer.fullName }}</span
                    >
                  </h4>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <div class="Status">
                  <v-chip
                      v-if="Job.quoteStatus === 1"
                      class="custom-chip grey darken-1 mr-2"
                      dark
                      label
                      x-small
                  >Open
                  </v-chip
                  >
                  <v-chip
                      v-if="Job.quoteStatus === 2"
                      class="custom-chip success darken-1 mr-2"
                      dark
                      label
                      x-small
                  >Approved
                  </v-chip
                  >
                  <v-chip
                      v-if="Job.quoteStatus === 3"
                      class="custom-chip red darken-1 mr-2"
                      dark
                      label
                      x-small
                  >Cancelled
                  </v-chip
                  >
                </div>

                <v-btn-toggle dense multiple tile>
                  <v-btn
                      :disabled="
                      !Job.title ||
                      Job.customerId == 0 ||
                      jobview ||
                      Job.quoteStatus === 3
                    "
                      class="ps-12 pe-12 login-btns theme-color1"
                      dark
                      elevation="0"
                      tile
                      @click="AddorUpdate()"
                  >Save
                  </v-btn>
                  <v-menu class="pa-0" left offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          :disabled="Job.jobId == 0 || Job.quoteStatus === 3"
                          class="theme-color1"
                          dark
                          left
                          offset-x="true"
                          v-bind="attrs"
                          v-on="on"
                      >
                        <v-icon color="#fff" small>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list class="pa-0" dense>
                      <v-list-item
                          v-if="Job.quoteStatus != 3"
                          :disabled="Job.jobId == 0 || Job.quoteStatus != 1"
                          dense
                          link
                          @click="(Job.quoteStatus = 2), CloseQuote()"
                      >
                        <v-list-item-icon class="nav-icon mr-2">
                          <img
                              src="../../assets/sidebar/file-invoice-dollar.svg"
                          />
                        </v-list-item-icon>
                        <v-list-item-title> Approve</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                          v-if="Job.quoteStatus != 3"
                          :disabled="Job.jobId == 0 || Job.quoteStatus != 1"
                          dense
                          link
                          @click="(Job.quoteStatus = 3), CloseQuote()"
                      >
                        <v-list-item-icon class="nav-icon mr-2">
                          <img src="../../assets/cross-circle.svg"/>
                        </v-list-item-icon>
                        <v-list-item-title> Cancel</v-list-item-title>
                      </v-list-item>
                      <v-list-item dense link @click="DownloadPdf()">
                        <v-list-item-icon class="nav-icon mr-2">
                          <img src="../../assets/pdf.svg"/>
                        </v-list-item-icon>
                        <v-list-item-title>Download</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-btn-toggle>
              </v-toolbar>
              <v-card-text class="pb-2">
                <v-row class="mt-2 ps-5 pe-5">
                  <v-col class="pa-0" cols="12" md="6">
                    <v-text-field
                        v-model="Job.title"
                        :disabled="jobview"
                        class="custom-input my-input2"
                        color="#64718A "
                        dense
                        hide-details
                        label="Title"
                        outlined
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="pb-2">
                <v-row class="ps-5 pe-5">
                  <v-col class="pa-0 d-flex" cols="12" md="6">
                    <v-autocomplete
                        v-if="$route.params.item == undefined"
                        v-model="SelectedCustomer"
                        :disabled="jobview"
                        :filter="customSearch"
                        :items="Customers"
                        :loading="isLoading"
                        :menu-props="{ bottom: true, offsetY: true }"
                        :search-input.sync="search"
                        class="custom-input my-input"
                        dense
                        hide-details
                        hide-no-data
                        hide-selected
                        item-text="fullName"
                        item-value="customerId"
                        label="Customer"
                        outlined
                        return-object
                        @change="selectCustomer"
                    >
                      <template v-slot:item="data">
                        <template v-if="typeof data.item !== 'object'">
                          <v-list-item-content v-text="data.item"></v-list-item-content>
                        </template>
                        <template v-else>
                          <v-list-item-content>
                            <v-list-item-title>{{ data.item.fullName }} <span class="caption"> <span
                                v-if="data.item.phoneNumber !== ''">| +1-{{ data.item.phoneNumber }} </span>
                              | {{ data.item.email }} |
                             <span class="text-truncate"> {{
                                 data.item.address + " " + data.item.city + " " + data.item.province
                               }} | </span>
                                <span v-if="data.item.customerCategory === 100"> D </span>
                                <span v-if="data.item.customerCategory === 110"> W - <span
                                    class="text-truncate" style="max-width: 5px !important;">{{
                                    data.item.customerSubType.slice(0, 4)
                                  }}</span> </span>

                            </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                    <v-autocomplete
                        v-else
                        v-model="SelectedCustomer"
                        :disabled="Job.quoteApproved || jobview"
                        :items="Customers"
                        :menu-props="{ bottom: true, offsetY: true }"
                        class="custom-input my-input-common-border"
                        dense
                        hide-details
                        item-text="fullName"
                        item-value="customerId"
                        label="Customer"
                        outlined
                        return-object
                        @change="selectCustomer"
                    >
                    </v-autocomplete>

                    <v-btn
                        :disabled="Job.quoteApproved || jobview"
                        class="theme-color1"
                        depressed
                        height="40"
                        tile
                        @click="OpenCustomerDialog"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-text class="">
                <v-row class="ps-5 pe-5">
                  <v-col class="pa-0" cols="12">
                    <v-textarea
                        v-model="Job.quoteDescription"
                        :disabled="jobview"
                        auto-grow
                        class="rounded-0 my-input-common-border"
                        color="#64718A"
                        hide-details
                        label="Description"
                        outlined
                        rows="2"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text>
                <v-divider></v-divider>
              </v-card-text>
              <v-card-text class="pb- pt-0">
                <v-row class="ps-5 pe-5 mt-0">
                  <v-card elevation="0" outlined tile width="100%">
                    <v-toolbar class="tab-color" dense elevation="0" tile>
                      <v-toolbar-title class="font-weight-bold theme-font-color"
                      >Services & Equipments
                      </v-toolbar-title
                      >
                      <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text class="pb-0">
                      <v-row class="pt-0 mb-2">
                        <v-col cols="12" md="6">
                          <v-autocomplete
                              v-model="Service"
                              :disabled="jobview"
                              :items="Services"
                              :menu-props="{ bottom: true, offsetY: true }"
                              class="custom-input my-input-common-border"
                              clearable
                              color="#64718A"
                              dense
                              hide-details
                              item-text="title"
                              item-value="serviceId"
                              label="Services"
                              multiple
                              outlined
                              return-object
                              small-chips
                              @change="(event) => ServicesEquipments(10)"
                          >
                            <template v-slot:item="data">
                              <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content
                                    v-text="data.item"
                                ></v-list-item-content>
                              </template>
                              <template v-else>
                                <v-list-item-content>
                                  <v-list-item-title
                                  >{{ data.item.title }}
                                    <span
                                        v-if="!data.item.forWholesale"
                                        class="caption"
                                    >
                                      (Domestic)</span
                                    >
                                    <span
                                        v-if="data.item.forWholesale"
                                        class="caption"
                                    >
                                      (Wholesale /
                                      {{ data.item.wholesaleTitle }})</span
                                    >
                                  </v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-autocomplete
                              v-model="Equipment"
                              :disabled="jobview"
                              :items="Equipments"
                              :menu-props="{ bottom: true, offsetY: true }"
                              class="custom-input my-input-common-border"
                              clearable
                              color="#64718A"
                              dense
                              hide-details
                              item-text="title"
                              item-value="equipmentId"
                              label="Equipments"
                              multiple
                              outlined
                              return-object
                              small-chips
                              @change="(event) => ServicesEquipments(20)"
                          >
                            <template v-slot:item="data">
                              <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content
                                    v-text="data.item"
                                ></v-list-item-content>
                              </template>
                              <template v-else>
                                <v-list-item-content>
                                  <v-list-item-title
                                  >{{ data.item.title }}
                                    <span class="caption">
                                      ({{ data.item.details }})
                                    </span>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </template>

                          </v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row class="pb-2">
                        <v-col cols="12">
                          <v-data-table
                              :headers="headers"
                              :items="Job.jobItems"
                              :items-per-page="100"
                              class="custom-table elevation-0"
                              disable-sort
                              hide-default-footer
                          >
                            <template v-slot:[`item.sno`]="{ index }">
                              <span>{{ index + 1 }}</span>
                            </template>
                            <template v-slot:[`item.jobItemType`]="{ item }">
                              <span v-if="item.jobItemType === 10">Service</span>
                              <span v-if="item.jobItemType === 20">Equipment</span>
                            </template>
                            <template v-slot:[`item.price`]="{item}">
                              <input v-model.number="item.price" :disabled="jobview" class="text-right total-input"
                                     type="number"
                                     @blur="ChangeAmount()" @focus="selectText"
                                     @input="FormatPrice(item)">
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                              <v-icon
                                  :disabled="jobview"
                                  color="red"
                                  small
                                  @click="deleteItem(item)"
                              >
                                mdi-trash-can-outline
                              </v-icon>
                            </template>
                          </v-data-table>
                          <v-divider class="mt-2"></v-divider>
                          <v-row class="mt-2 d-flex justify-end align-center">
                            <v-col cols="12" md="5">
                              <v-row>
                                <v-col class="d-flex justify-end">
                                  <span
                                      class="total-label text--primary text-right"
                                  >AMOUNT</span
                                  >
                                </v-col>
                                <v-col class="d-flex justify-end" cols="3">
                                  <!--                                  <span-->
                                  <!--                                      class="total-amount text&#45;&#45;primary me-4"-->
                                  <!--                                  >{{ Job.netAmount }}</span-->
                                  <!--                                  >-->
                                  <input
                                      v-model.number="netAmountFormatted"
                                      class="total-input border-bottom-hide"
                                      disabled
                                      min="0"
                                      type="number"
                                      @blur="ChangeAmount"
                                      @focus="selectText"
                                  />
                                </v-col>
                              </v-row>
                              <v-row class="align-baseline">
                                <v-col class="pt-0 d-flex justify-end">
                                  <span class="total-label text--primary"
                                  >OTHERS</span
                                  >
                                </v-col>
                                <v-col class="d-flex justify-end pt-0" cols="3">
                                  <input
                                      v-model.number="otherChargesFormatted"
                                      :disabled="jobview"
                                      class="total-input"
                                      min="0"
                                      type="number"
                                      @blur="ChangeAmount"
                                      @focus="selectText"
                                  />
                                </v-col>
                              </v-row>
                              <v-row class="align-baseline">
                                <v-col class="pt-1 d-flex justify-end">
                                  <span class="total-label text--primary"
                                  >DISCOUNT</span
                                  >
                                </v-col>
                                <v-col class="d-flex justify-end pt-1" cols="3">
                                  <input
                                      id="discount_input"
                                      v-model.number="discountFormatted"
                                      :disabled="jobview"
                                      class="total-input"
                                      min="0"
                                      type="number"
                                      @blur="ChangeAmount"
                                      @focus="selectText"
                                  />
                                </v-col>
                              </v-row>
                              <!--                              <v-row>-->
                              <!--                                <v-col class="pt-0 d-flex justify-end">-->
                              <!--                                  <span class="total-label text&#45;&#45;primary"-->
                              <!--                                  >SUBTOTAL</span-->
                              <!--                                  >-->
                              <!--                                </v-col>-->
                              <!--                                <v-col cols="3" class="pt-0 d-flex justify-end">-->
                              <!--                                  <span-->
                              <!--                                      class="total-amount text&#45;&#45;primary me-4"-->
                              <!--                                  >{{ Job.subTotal }}</span-->
                              <!--                                  >-->
                              <!--                                </v-col>-->
                              <!--                              </v-row>-->
                              <v-row class="align-baseline">
                                <v-col class="pt-0 d-flex justify-end">
                                  <span class="total-label text--primary"
                                  >HST (%)</span
                                  >
                                </v-col>
                                <v-col class="d-flex justify-end pt-0" cols="3">
                                  <input
                                      id="tax_input"
                                      v-model.number="hstFormatted"
                                      :disabled="jobview"
                                      class="total-input"
                                      min="0"
                                      type="number"
                                      @blur="ChangeAmount"
                                      @focus="selectText"
                                  />
                                </v-col>
                              </v-row>
                              <v-row class="align-baseline">
                                <v-col class="pt-1 d-flex justify-end">
                                  <span
                                      class="total-label text--primary"
                                  >HST Amount</span
                                  >
                                </v-col>
                                <v-col class="d-flex justify-end" cols="3">
                                  <input
                                      v-model.number="hstAmountFormatted"
                                      class="total-input border-bottom-hide"
                                      disabled
                                      min="0"
                                      type="number"
                                      @blur="ChangeAmount"
                                      @focus="selectText"
                                  />
                                  <!--                                  <span
                                                                        class="total-amount text&#45;&#45;primary me-4"
                                                                    >{{ Job.hstAmount }}</span
                                                                    >-->
                                </v-col>
                              </v-row>
                              <v-row class="align-baseline">
                                <v-col class="pt-1 d-flex justify-end">
                                  <span
                                      class="total-label text--primary font-weight-bold"
                                  >TOTAL</span
                                  >
                                </v-col>
                                <v-col class="d-flex justify-end" cols="3">

                                  <!--                                  <span-->
                                  <!--                                      class="total-amount text&#45;&#45;primary me-4 font-weight-bold"-->
                                  <!--                                  >{{ Job.grandTotal }}</span-->
                                  <!--                                  >-->
                                  <input
                                      v-model.number="totalFormatted"
                                      class="total-input border-bottom-hide total-amount text--primary font-weight-bold"
                                      disabled
                                      min="0"
                                      type="number"
                                      @blur="ChangeAmount"
                                      @focus="selectText"
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
      <!--      Add Customer-->
      <v-dialog v-model="addCustomerDialog" max-width="700">
        <v-card class="pb-2">
          <v-toolbar color="#efefef" dense elevation="0" flat tile>
            <v-btn
                class="mr-2 theme-color1"
                dark
                elevation="0"
                height="35px"
                tile
                @click="addCustomerDialog = false"
            >
              <v-icon dense>mdi-undo-variant</v-icon>
            </v-btn>
            <v-toolbar-title>
              <h4 class="text-uppercase theme-font-color">Add Customer</h4>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                :disabled="
                !Customer.firstName ||
                !Customer.lastName
              "
                class="ps-10 pe-10 login-btns theme-color1"
                dark
                elevation="0"
                tile
                @click="AddorUpdateCustomer(), (addCustomerDialog = false)"
            >Save
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row class="mt-5">
                <v-col class="pa-0" cols="12" md="6">
                  <v-text-field
                      v-model="Customer.firstName"
                      :rules="[rules.required]"
                      class="custom-input my-input"
                      color="#64718A "
                      dense
                      hide-details
                      label="First Name"
                      outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pa-0" cols="12" md="6">
                  <v-text-field
                      v-model="Customer.lastName"
                      :rules="[rules.required]"
                      class="custom-input my-input2"
                      color="#64718A "
                      dense
                      hide-details
                      label="Last Name"
                      outlined
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="">
                <v-col class="pa-0" cols="12" md="6">
                  <v-text-field
                      v-model="Customer.email"
                      :rules="[rules.required]"
                      class="custom-input border-right"
                      color="#64718A "
                      dense
                      hide-details
                      label="Email"
                      outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pa-0" cols="12" md="6">
                  <v-text-field
                      v-model="Customer.phoneNumber"
                      v-mask="['###-###-####']"
                      :rules="[rules.required]"
                      class="custom-input my-input-common-border"
                      color="#64718A "
                      dense
                      hide-details
                      label="Phone"
                      outlined
                      prefix="+1"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-10">
                <v-col class="pa-0" cols="12" md="6">
                  <v-text-field
                      v-model="Customer.address"
                      class="custom-input my-input"
                      color="#64718A "
                      dense
                      hide-details
                      label="Address"
                      outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pa-0" cols="12" md="6">
                  <v-text-field
                      v-model="Customer.city"
                      class="custom-input my-input2"
                      color="#64718A "
                      dense
                      hide-details
                      label="City"
                      outlined
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pa-0" cols="12" md="6">
                  <v-text-field
                      v-model="Customer.postalCode"
                      class="custom-input border-right"
                      color="#64718A "
                      dense
                      hide-details
                      label="Postal Code"
                      outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pa-0" cols="12" md="6">
                  <v-autocomplete
                      v-model="Customer.country"
                      :items="Countries"
                      :menu-props="{ bottom: true, offsetY: true }"
                      :return-object="false"
                      class="custom-input my-input-common-border"
                      dense
                      hide-details
                      item-text="name.common"
                      item-value="name.common"
                      label="Country"
                      outlined
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row v-if="Job.isQuote === true" class="ps-2">
                <v-col class="pa-0 d-flex align-center" cols="12" md="6">
                  <v-switch
                      v-model="CustomerSwitch"
                      class="custom-switch"
                      color="success darken-2"
                      inset
                      @change="ChangeView(CustomerSwitch)"
                  ></v-switch>
                  <div>
                    <span
                        v-if="CustomerSwitch"
                        class="font-weight-bold d-block grey--text text--darken-2"
                    >Wholesale</span
                    >
                    <span
                        v-if="!CustomerSwitch"
                        class="font-weight-bold d-block grey--text text--darken-2"
                    >Domestic</span
                    >
                  </div>
                </v-col>
                <v-col class="pa-0 mt-3" cols="12" md="6">
                  <v-autocomplete
                      v-model="Customer.customerSubType"
                      :disabled="!CustomerSwitch"
                      :items="customersubtypes"
                      :menu-props="{ bottom: true, offsetY: true }"
                      :return-object="false"
                      class="custom-input bordered"
                      color="#64718A "
                      dense
                      hide-details
                      item-text="value"
                      item-value="value"
                      label="Customer Type"
                      outlined
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <v-progress-circular
        v-if="loader"
        class="custom-loader"
        color="#64718A"
        indeterminate
        width="2"
    >
    </v-progress-circular>
  </div>
</template>
<script>
import axios from "axios";
import config from "../Configuration/config";
import moment from "moment";
import 'moment-timezone';

export default {
  name: "AddQuotation",
  data() {
    return {
      name: "Quote",
      addCustomerDialog: false,
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model: null,
      search: "",
      Print: false,
      Save: false,
      paymentDialg: false,
      CustomerSwitch: false,
      JobSave: false,
      Switch: false,
      switchControl: true,
      dialog: false,
      loader: false,
      loader2: false,
      jobview: false,
      rules: {
        tax: (value) => (value >= 0 && value <= 100) || "Invalid tax ",
        discount: (value) => value >= 0 || "Invalid Number",
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },

      headers: [
        {
          text: "#",
          align: "left",
          value: "sno",
          width: "70px",
        },
        {
          text: "Title",
          align: "left",
          value: "title",
        },
        {
          text: "JobItem Type",
          value: "jobItemType"
        },
        {
          text: "Type",
          value: "customerType",
        },
        {
          text: "Amount",
          align: "right",
          value: "price",
          width: "100px",
        },
        {
          text: "",
          value: "actions",
          width: "50px",
        },
      ],
      editedItem: {},
      worker: 0,
      requestDateMenu: false,
      customerdatemenu: false,
      SelectedCustomer: null,
      btnText: "Add Job Request",
      valid: true,
      Customers: [],
      Services: [],
      ServicesHeader: [
        {
          // text: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" + "#",
          text: "#",
          align: "center",
          value: "sno",
          width: "80px",
        },
        {
          text: "Service",
          value: "title",
        },

        {
          text: "Action",
          align: "right",
          value: "action",
        },
      ],
      EmployeesHeader: [
        {
          text: "#",
          // text: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" + "#",
          align: "center",
          value: "sno",
          width: "80px",
        },
        {
          text: "Name",
          value: "fullName",
        },
        {
          text: "Action",
          align: "center",
          value: "action",
        },
      ],
      customersubtypes: [],
      Customer: {
        companyId: axios.defaults.headers.common.CompanyId,
        customerId: 0,
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        address: "",
        city: "",
        province: "",
        postalCode: "",
        customerSubType: "",
        country: "",
        notes: "",
        customerCategory: 110,
        taskId: "",
        swo: "",
      },
      Countries: [],
      customerTypes: null,
      Service: [],
      Equipment: [],
      Job: {
        companyId: axios.defaults.headers.common.CompanyId,
        jobId: 0,
        title: "",
        customerTime: new Date(),
        markAsQoute: false,
        isApproved: false,
        expiryDate: new Date(),
        jobStatus: 0,
        jobNote: "",
        isLead: false,
        isQuote: true,
        isJob: false,
        isInvoice: false,
        leadStatus: 0,
        quoteStatus: 0,
        customerId: 0,
        quoteApproved: false,
        quoteCancelled: false,
        leadClosed: false,
        leadDescription: "",
        quoteDescription: "",
        taskId: "",
        cancellationReason: 0,
        swo: "",
        termsOfJob: "",
        isPaid: false,
        paymentMethod: 0,
        discount: 0,
        hst: 13,
        hstAmount: 0,
        otherCharges: 0,
        paidAmount: 0,
        dueAmount: 0,
        netAmount: 0,
        grandTotal: 0,
        workOrder: "",
        jobItems: [],
        jobEmployees: [],
      },
      TotalAmount: 0,
      ServicesWorkers: [],
      Equipments: [],
      Workers: null,
      Filter: {
        CompanyId: axios.defaults.headers.common.CompanyId,
        page: 1,
        pageSize: 10,
        pagination: null,
        pageNumOnSearch: "",
        filterText: "",
        customerCategory: 110,
        forDomestic: true,
        forWholesale: true,
      },
      /* Company: sessionStorage.getItem("Company"),
       Address: sessionStorage.getItem("CompanyAddress"),
       Address2: sessionStorage.getItem("CompanyAddress2"),
       CompanyTel: sessionStorage.getItem("CompanyTel"),
       HST: sessionStorage.getItem("HST"),*/
      decimals: 2,
    };
  },
  computed: {
    netAmountFormatted: {
      get() {
        return this.Job.netAmount.toFixed(2);
      },
      set(value) {
        this.Job.netAmount = parseFloat(value);
      }
    },
    otherChargesFormatted: {
      get() {
        return this.Job.otherCharges.toFixed(2);
      },
      set(value) {
        this.Job.otherCharges = parseFloat(value);
      }
    },
    discountFormatted: {
      get() {
        return this.Job.discount.toFixed(2);
      },
      set(value) {
        this.Job.discount = parseFloat(value);
      }
    },
    hstFormatted: {
      get() {
        return this.Job.hst.toFixed(2);
      },
      set(value) {
        this.Job.hst = parseFloat(value);
      }
    },
    hstAmountFormatted: {
      get() {
        return this.Job.hstAmount.toFixed(2);
      },
      set(value) {
        this.Job.hstAmount = parseFloat(value);
      }
    },
    totalFormatted: {
      get() {
        return this.Job.grandTotal.toFixed(2);
      },
      set(value) {
        this.Job.grandTotal = parseFloat(value);
      }
    },
  },
  methods: {
    FormatPrice(item) {
      //var index = this.Job.jobItems.indexOf(item);
      //item.amount.toFixed(2);
      item.price = Math.round(item.price * 100) / 100;
    },
    selectText(event) {
      event.target.select();
    },
    ChangeAmount() {
      if (isNaN(this.Job.otherCharges) || this.Job.otherCharges < 0) {
        this.Job.otherCharges = 0
      }
      if (isNaN(this.Job.discount) || this.Job.discount < 0) {
        this.Job.discount = 0
      }
      if (isNaN(this.Job.hst) || this.Job.hst < 0) {
        this.Job.hst = 0
      }
      if (this.Job.jobItems.length < 1) {
        this.Job.netAmount = 0;
        this.Job.discount = 0;
        this.Job.hst = 13;
        this.Job.otherCharges = 0;
        this.Job.grandTotal = 0;
        return;
      }
      this.Job.netAmount = this.Job.jobItems.reduce(
          (acc, o) => acc + parseFloat(o.price),
          0
      );
      if (this.Job.discount > this.Job.netAmount) {
        this.Job.discount = this.Job.netAmount;
      }
      let subTotal = this.Job.netAmount + this.Job.otherCharges - this.Job.discount;
      this.Job.grandTotal = subTotal;
      if (this.Job.hst > 100) {
        this.Job.hst = 100;
        var element = document.getElementById("tax_input");
        element.classList.add("taxInput");
      } else if (this.Job.hst < 0) {
        this.Job.hst = 13;
        var element2 = document.getElementById("tax_input");
        element2.classList.add("taxInput");
      }
      if (this.Job.hst > 0 || this.Job.hst <= 100) {
        this.Job.hstAmount = (this.Job.hst / 100) * subTotal;
        this.Job.grandTotal = this.Job.hstAmount + subTotal;
      }
      this.Job.netAmount = Math.round(this.Job.netAmount * 10 ** this.decimals) / 10 ** this.decimals
      this.Job.otherCharges = Math.round(this.Job.otherCharges * 10 ** this.decimals) / 10 ** this.decimals
      this.Job.discount = Math.round(this.Job.discount * 10 ** this.decimals) / 10 ** this.decimals
      this.Job.grandTotal = Math.round(this.Job.grandTotal * 10 ** this.decimals) / 10 ** this.decimals
      this.Job.hstAmount = Math.round(this.Job.hstAmount * 10 ** this.decimals) / 10 ** this.decimals
    },
    customSearch(item, queryText) {
      return (
          item.fullName.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
          item.email.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
          ||
          item.phoneNumber.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
          ||
          item.address.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    OpenCustomerDialog() {
      this.addCustomerDialog = true;
      setTimeout(this.validate, 200);
    },
    validate() {
      this.$refs.form.validate();
    },

    ServicesEquipments(type) {
      var index = this.Service.length - 1;
      var equipmentindex = this.Equipment.length - 1;
      if (index > -1) {
        if (type == 10) {
          if (this.Service[0].wholesaleTitle === "") {
            this.Service[0].wholesaleTitle = "Domestic"
          }
          if (!this.Job.jobItems.some(service => service.title === this.Service[0].title && service.customerType === this.Service[0].wholesaleTitle)) {
            this.Job.jobItems.push(this.Service[0])
            var service = this.Job.jobItems.find(({serviceId}) => serviceId === this.Service[0].serviceId);
            service.jobItemType = 10;
            if (this.Service[0].wholesaleTitle !== "") {
              service.customerType = this.Service[0].wholesaleTitle
            } else {
              service.customerType = "Domestic"
            }
          }
          this.Service = [];
          //this.Job.jobItems[index].customerType  = this.Job.jobItems[index].wholesaleTitle;
        }
      }
      if (equipmentindex > -1)
        if (type == 20) {
          if (!this.Job.jobItems.some(equipment => equipment.title === this.Equipment[0].title && equipment.details === this.Equipment[0].details)) {
            this.Job.jobItems.push(this.Equipment[0])
            var equipment = this.Job.jobItems.find(({equipmentId}) => equipmentId === this.Equipment[0].equipmentId);
            equipment.jobItemType = 20;
            equipment.customerType = this.Equipment[0].details;
          }
          this.Equipment = [];
        }
      this.ChangeAmount();
    },
    async DownloadPdf() {
      try {
        this.loader = true;
        const fd = new FormData();
        fd.append("blobName", this.Job.quotePdf);
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Job/DownloadPdf",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        console.log(response);
        const link = document.createElement("a");
        link.href = response.data;
        link.setAttribute("download", "QUOTE_" + this.Job.quoteNo + ".pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.loader = false;
      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },

    ChangePrice(item) {
      const index = this.Job.jobItems.indexOf(item);
      if (!isNaN(item.price)) {
        item.amount = item.price;
      }
      if (!isNaN(item.discount)) {
        if (item.discount > 0) {
          item.amount = item.price - item.discount;
        } else {
          item.discount = 0;
        }
      }
      if (!isNaN(item.taxRate)) {
        if (item.taxRate >= 0 && item.taxRate <= 100) {
          item.taxAmount = (item.taxRate / 100) * item.amount;
          item.amount += item.taxAmount;
        } else {
          item.taxRate = 0;
        }
      }
      this.Job.jobItems.splice(index, 1, item);
      this.TotalAmount = 0;
      this.Job.jobItems.forEach((item) => {
        this.TotalAmount += item.amount;
      });
    },
    ChangeView(type) {
      if (!type) {
        this.Customer.customerCategory = 100;
        this.Customer.swo = "";
        this.Customer.taskId = "";
        this.Customer.customerSubType = "";
      } else {
        this.Customer.customerCategory = 120;
        this.Customer.customerSubType = this.customersubtypes[0].value;
      }
    },
    formatPhoneNumber() {
      this.Customer.phoneNumber = this.Customer.phoneNumber
          .replace(/[^0-9]/g, "")
          .replace(/^(\d{3})(\d{3})(\d{4})/g, "$1-$2-$3")
          .substring(0, 12);
    },
    async GetEquipments() {
      if (this.$route.name == "AddQuotation") {
        try {
          this.loader = true;
          let response = await axios.post(
              config.apiBaseUrl + "/api/Equipment/GetAll",
              {
                pageSize: this.Filter.pageSize,
                page: this.Filter.page,
                filterText: this.Filter.filterText,
                isActive: true,
              }
          );
          this.Equipments = response.data.data;
          this.loader = false;
        } catch (err) {
          this.loader = false;

        }
      }
    },
    async GetCountries() {
      if (this.$route.name == "AddQuotation") {
        let response = await axios.get("https://restcountries.com/v3.1/all");
        this.Countries = response.data;
        this.Countries = response.data;
        var canada = this.Countries.filter((x) => x.name.common == "Canada");
        this.Customer.country = canada[0].name.common;
      }
    },
    getFormattedDate(date) {
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get the user's timezone
      return moment.utc(date).tz(userTimezone).format('MMM DD, yyyy');
      //return moment(date).format("MMM DD, YYYY");
    },
    selectCustomer() {
      this.Job.customerId = this.SelectedCustomer.customerId;
      if (this.SelectedCustomer.customerCategory == 100) {
        this.Filter.forDomestic = true;
        this.Filter.forWholesale = false;
        this.Filter.filterText = "";
      } else {
        this.Filter.forDomestic = false;
        this.Filter.forWholesale = true;
        this.Filter.filterText = "";
      }
      this.GetServices();
    },
    async CloseQuote() {
      try {
        const fd = new FormData();
        fd.append("jobId", this.Job.jobId);
        let response = await axios({
          method: "post",
          url:
              config.apiBaseUrl +
              "/api/Job/CloseQuote?status=" +
              this.Job.quoteStatus,
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        console.log(response);
        this.$router.push("/qoutations");
        this.loader = false;
      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },
    AddService(item) {
      this.Job.jobItems.push(item);
    },
    async GetCustomerType() {
      try {
        const fd = new FormData();
        fd.append("type", 30);
        let response = await axios.get(config.apiBaseUrl + "/api/KeyValue/All");
        this.customerTypes = response.data.filter((x) => x.type == 10);
        this.customersubtypes = response.data.filter((x) => x.type == 30);
      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },
    async AddorUpdateCustomer() {
      try {
        this.loader = true;
        if (this.CustomerSwitch) {
          this.Customer.customerCategory = 110;
        } else {
          this.Customer.customerCategory = 100;
        }
        if (this.Customer.phoneNumber !== "") {
          this.Customer.phoneNumber = this.Customer.phoneNumber.replace(/-/g, "");
        }
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Customer/AddOrUpdate",
          data: this.Customer,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        this.SelectedCustomer = response.data.data;
        this.Job.customerId = this.SelectedCustomer.customerId;
        this.search = this.SelectedCustomer.fullName;
        this.loader = false;
        this.selectCustomer();
      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },
    async GetServices() {
      try {
        this.loader = true;
        let response = await axios({
          method: "POST",
          url: config.apiBaseUrl + "/api/Service/GetServicesByCustomer",
          data: this.Filter,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        this.Services = response.data.data;
        this.loader = false;
      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },
    async AddorUpdate() {
      try {
        this.loader = true;
        this.Job.jobItems.forEach((element) => {
          if (element.taxRate == null && element.discount == null) {
            element.amount = element.price;
          }
          element.service = null;
        });
        if (this.jobview) {
          if (this.Job.jobEmployees.length > 0) {
            this.Job.jobEmployees.forEach((element) => {
              element.forJob = true;
            });
          }
        }
        this.Job.isQuote = true;
        let response = await axios({
          method: "POST",
          url: config.apiBaseUrl + "/api/Job/AddOrUpdateQuote",
          data: this.Job,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        console.log(response);
        this.$router.push("/qoutations");
        this.loader = false;
      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },
    async GetAllWorker() {
      try {
        this.loader = true;
        let response = await axios.post(
            config.apiBaseUrl + "/api/Employee/All",
            {
              pageSize: this.pageSize,
              page: this.page,
              filterText: this.filterText,
              isActive: true,
            }
        );
        this.Workers = response.data.data;
        this.pagination = response.data.pagination;
        this.loader = false;
      } catch (err) {
        this.loader = false;
        this.$toast.warning(err.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      var index = this.Job.jobItems.indexOf(item);
      this.Job.jobItems.splice(index, 1);
      this.ChangeAmount();
    },
    save() {
      if (this.Job.jobItems.length == 0) {
        this.Job.hst = 13;
        this.Job.netAmount = 0;
        this.Job.grandTotal = 0;
        this.Job.discount = 0;
        return;
      }
      this.Job.jobItems.push(this.editedItem);
      this.close();
    },
    async GetJobRequest() {
      if (this.$route.params.item != undefined) {
        if (this.$route.params.editView != undefined) {
          this.jobview = true;
        } else {
          this.jobview = false;
        }
        this.loader = true;
        this.Job = this.$route.params.item;
        try {
          const fd = new FormData();
          fd.append("jobId", this.Job.jobId);
          let response = await axios({
            method: "post",
            url: config.apiBaseUrl + "/api/Job/Get",
            data: fd,
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
            },
          });
          this.Job = response.data.job;
          this.SelectedCustomer = this.Job.customer;
          this.Customers.push(this.Job.customer)
          this.GetEquipments();
          this.ChangeAmount();
          this.selectCustomer();
          this.loader == false;
        } catch (error) {
          this.loader = false;
          this.$toast.warning(error.message, {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnfocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
          });
        }
      }
    },
  },
  watch: {
    async search(val) {
      if (this.isLoading) return;
      if (val == "" || val == null) {
        return;
      }
      this.isLoading = true
      // Lazily load input items
      this.loader = true;
      this.Filter.filterText = val;

      let response = await axios({
        method: "POST",
        url: config.apiBaseUrl + "/api/Customer/GetCustomers",
        data: this.Filter,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      this.Customers = response.data.data;
      this.loader = false;
      this.isLoading = false;
      if (val == "") {
        this.Customers = []
      }
    },
    "Job.jobEmployees": function () {
      if (this.Job.jobEmployees.length > 0) {
        this.JobSave = true;
      } else {
        this.JobSave = false;
      }
    },
  },
  created() {
    this.$store.state.DisableCompany = true;
    this.GetEquipments();
    this.GetJobRequest();
    this.GetCountries();
    this.GetCustomerType();
    this.GetAllWorker();
    this.$root.$on("myEvent", () => {
      this.GetCountries();
      this.GetCustomerType();
      this.GetAllWorker();
      this.GetEquipments();
    });
  },
};
</script>
<style scoped>
.v-btn-toggle .v-btn.v-btn.v-size--default {
  min-width: 25px;
  min-height: 0;
}

.nav-icon img {
  width: 16px !important;
}

.menu-list.v-list-item {
  height: 30px;
  padding-right: 8px !important;
  padding-left: 8px !important;
}

.menu-list {
  padding: 0px !important;
  box-shadow: none;
}

.total-label {
  font-size: 15px;
  margin-left: 80px;
}

.total-amount {
  font-size: 15px;
}

.total-input {
  border-bottom: 1px solid grey;
  width: 75px !important;
  font-size: 15px !important;
  text-align: right;
}

.total-input:focus {
  outline: none !important;
}

.taxInput {
  border: 1px solid red;
}

.custom-chip {
  width: 80px !important;
  justify-content: center;
}
</style>
