<template>
  <div class="pa-1">
  <span class="chart-title">Total Jobs Revenue</span>
  <span class="d-block totalNumber">Sum of Amount: {{Total}} $</span>
    <apexchart height="300" type="bar" :options="chartOptions" :series="ChartData"></apexchart>
  </div>
</template>
<script>

export default {
  name: 'LeadChart',
  data() {
    return {
      chartOptions: {
        chart: {
          id: 'vuechart-example',
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          // bar: {
          //   horizontal: true,
          // }
        },
        grid: {
          yaxis: {
            lines: {
              show: false
            }
          },
        },
        labels: ['Amount'],

        //Colors and options
        fill: {
          colors: ["#64718A"],
          opacity: 1
        },

      }, data: {
        series: [],
        xaxis: {
          position: "top",
          categories: []
        }
      },


    }
  },
  computed:{
    ChartData(){
      return this.$store.state.JobsRevenue
    },
    Total(){
      return this.$store.state.JobsRevenue[0].data
    }
  },
  created() {
    this.$store.state.JobsRevenue = [
      {
        name: "Jobs Revenue",
        data: [0]
      },
    ];
  }
};
</script>
