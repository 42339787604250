<template>
  <div class="">
    <v-row justify="space-between">
      <v-col class="d-flex" cols="12" sm="6">
        <v-btn class="mr-2 theme-color1" dark elevation="0" tile>
          <v-icon>mdi-tools</v-icon>
        </v-btn>
        <h2 class="text-uppercase theme-font-color">{{ name }}</h2>
      </v-col>
      <v-col class="mb-1 d-flex" cols="12" sm="5">
        <v-text-field v-model="Filter.filterText" append-icon="mdi-magnify" class="custom-input" clearable
                      color="#64718A" dense
                      hide-details label="Search" outlined v-on:keyup="GetAll"
                      @click:clear="(Filter.filterText = ''), GetAll()" @click:append="GetAll"></v-text-field>
        <v-btn class="ms-2 theme-color1" dark elevation="0" tile
               @click="(ClearData(), ChangeView(true)), addDialog = true">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <div class="custom-line"></div>
    <v-data-table v-if="Services == null" :headers="headers" :items-per-page="Filter.pageSize"
                  class="custom-table elevation-0"
                  disable-sort hide-default-footer>
    </v-data-table>
    <v-data-table v-else :headers="headers" :items="Services" :items-per-page="Filter.pageSize"
                  class="custom-table elevation-0"
                  disable-sort hide-default-footer>
      <template v-slot:[`item.sno`]="{ index }">
        {{ index + 1 }}
      </template>
      <template v-slot:[`item.customerType`]="{ item }">
        <span v-if="!item.forWholesale">Domestic</span>
        <span v-if="item.forWholesale">Wholesale ({{ item.wholesaleTitle }})</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon class="ms-2" color="primary darken-1" small
                @click="takeData(item), (addDialog = true) , editMode = true">mdi-pencil
        </v-icon>
        <v-icon class="ms-2" color="red" small @click="takeData(item), (deleteDialog = true)">mdi-trash-can-outline
        </v-icon>
      </template>
    </v-data-table>
    <v-row align="center" class="no-gutters mt-2">
      <v-spacer style="width: 75%"></v-spacer>
      <span class="pagination-text">Rows per page</span>
      <v-select v-model="Filter.pageSize" :items="options" :menu-props="{ bottom: true, offsetY: true }" class="ma-2"
                dense
                style="width: 50px" @change="GetAll"></v-select>
      <span v-if="pagination != null" class="mr-4 grey--text pagination-text">
        Page {{ pagination.page }} of {{ pagination.totalPages }}
      </span>
      <v-icon class="mr-1" color="primary darken-2" dark small @click="backwardButtonCall">mdi-chevron-left</v-icon>
      <v-icon class="mr-1" color="primary darken-2" dark small @click="forwardButtonCall()">mdi-chevron-right</v-icon>
    </v-row>
    <!-- Delete Dialog -->
    <v-dialog v-model="deleteDialog" max-width="400" persistent>
      <v-card>
        <v-card-title class="text-h5"> Delete Service</v-card-title>
        <v-card-text>Are You sure? You want to delete
          <b v-if="Service != null" class="error--text text--darken-2">{{
              Service.title
            }}</b></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success darken-1" text @click="deleteDialog = false">
            Cancel
          </v-btn>
          <v-btn color="error darken-2" elevation="0" tile @click="DeleteService(), (deleteDialog = false)">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Add Dialog -->
    <v-dialog v-model="addDialog" max-width="500" persistent>
      <v-card outlined>
        <v-toolbar color="#efefef" dense elevation="0" flat tile>
          <v-btn class="mr-2 theme-color1" dark elevation="0" height="35px" tile
                 @click="addDialog = false, editMode = false">
            <v-icon dense>mdi-undo-variant
            </v-icon>
          </v-btn>
          <v-toolbar-title>
            <h4 class="text-uppercase theme-font-color">{{ DialogText }}</h4>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn :disabled="!Service.title || !enableButton" class="ps-10 pe-10 login-btns theme-color1" dark
                 elevation="0"
                 tile @click="AddService(), (addDialog = false)">Save
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col class="ps-1 pe-1" cols="12">
                <v-text-field v-model="Service.title" class="custom-input " color="#64718A" dense
                              hide-details label="Service Name" outlined></v-text-field>

              </v-col>

            </v-row>

            <v-row>
              <v-col class="ps-1 pe-1" cols="12" md="6">
                <v-text-field v-model.number="Service.price" :rules="[PriceRule]" class="custom-input" color="#64718A"
                              dense label="Service Price"
                              outlined suffix="$" type="number" @focus="selectText"
                              @keyup.enter="addDialog = false,AddService()"></v-text-field>
              </v-col>
              <v-col class="ps-1 pe-1" cols="12" md="6">
                <v-text-field v-model.number="Service.serviceUnit" :rules="[UnitRule]" class="custom-input"
                              color="#64718A"
                              dense label="Service Unit"
                              outlined type="number" @focus="selectText"
                              @keyup.enter="addDialog = false,AddService()">
                  <template v-slot:append>
                    <i class="ri-stack-line"></i>
                  </template>
                </v-text-field>
              </v-col>

            </v-row>
            <v-row align="center" class="ps-2 mt-0">
              <v-col class="ps-1 pe-1 d-flex justify-space-between" cols="12" md="5">
                <div class="d-flex align-center">
                  <v-switch v-model="Service.forWholesale" class="custom-switch" color="success darken-2"
                            inset @change="ChangeView(Service.forWholesale)"></v-switch>
                  <label>For Wholesale</label>
                </div>
              </v-col>
              <v-col class="pa-0" cols="12" md="7">
                <v-select v-model="Service.wholesaleTitle" :disabled="!Service.forWholesale"
                          :items="customersubtypes" :menu-props="{ bottom: true, offsetY: true }" :no-filter="true"
                          :return-object="false"
                          class="custom-input bordered"
                          color="#64718A "
                          dense hide-details
                          item-text="value" item-value="value" label="Customer Type"
                          outlined></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-progress-circular v-if="loader" class="custom-loader" color="#64718A" indeterminate
                         width="2"></v-progress-circular>
  </div>
</template>
<script>
import axios from "axios";
import config from "../Configuration/config";

export default {
  name: "ServicesModule",
  data() {
    return {
      name: "Services",
      deleteDialog: false,
      DialogText: "New Service",
      Switch: false,
      enableButton: false,
      companyId: axios.defaults.headers.common.CompanyId,
      valid: false,
      addDialog: false,
      loader: false,
      editMode: false,
      customersubtypes: [],
      rules: {
        required: [(value) => !!value || ""],
        min: [v => v > 0 || 'Price must be greater than 0',
        ]
      },
      headers: [
        {
          text: "#",
          align: "start",
          value: "sno",
          width: '70px'
        }, {
          text: "Service Name",
          align: "start",
          value: "title",
        },
        {
          text: "Customer Type",
          value: "customerType",
        },
        {
          text: "Service Price",
          value: "price",
          align: 'right'
        },
        {
          text: "Service Unit",
          value: "serviceUnit",
          align: 'right'
        },

        {text: "Actions", value: "actions", width: "120px", sortable: false},
      ],
      options: [
        {text: "10", value: 10},
        {text: "25", value: 25},
        {text: "50", value: 50},
        {text: "100", value: 100},
      ],
      customerTypes: [],
      Service: {
        companyId: axios.defaults.headers.common.CompanyId,
        serviceId: 0,
        title: "",
        price: 0,
        forWholesale: false,
        wholesaleTitle: "",
        serviceUnit: 0,
      },
      Services: null,
      pagination: null,
      ServiceWholeSaleTitle: {},
      Filter: {
        page: 1,
        pageSize: 10,
        filterText: "",
      }
    };
  },
  methods: {
    selectText(event) {
      event.target.select();
    },
    PriceRule: function () {
      if (this.Service.price > 0) {
        this.enableButton = true;
        return true;
      } else {
        this.Service.price = 0
        this.enableButton = false;
        return "Price must be greater than 0";
      }
    },
    UnitRule: function () {
      if (this.Service.serviceUnit >= 0) {
        this.enableButton = true;
        return true;
      } else {
        this.enableButton = false;
        this.Service.serviceUnit = 0
        return "Unit cannot be negative";
      }
    },
    ChangeView(type) {
      if (!type) {
        this.Service.forWholesale = false;
        this.Service.wholesaleTitle = ""
      } else {
        this.Switch = true;
        this.Service.forWholesale = true;
        this.Service.wholesaleTitle = this.customersubtypes[0].value;
      }
    },
    ClearData() {
      this.Service = {}
      this.DialogText = "New Service";
      this.Service.companyId = axios.defaults.headers.common.CompanyId;
      this.Service.price = 0;
      this.Service.forWholesale = true;
    },
    takeData(service) {
      this.Service = service;
      this.DialogText = "Update Service"
    },
    validate() {
      if (this.currentEmployee == null) {
        this.$refs.form.validate();
      }
    },
    async GetAll() {
      if (this.$route.name == "ServicesModule") {
        try {
          // eslint-disable-next-line no-unreachable
          this.loader = true;
          let response = await axios({
                method: "POST",
                url: config.apiBaseUrl + "/api/Service/All",
                data: this.Filter,
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                }
              }
          );
          this.Services = response.data.data;
          this.pagination = response.data.pagination;
          this.loader = false;
        } catch (err) {
          this.loader = false;
          this.$toast.warning(err.message, {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnfocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
          });
        }
      }
    },
    async GetCustomerType() {
      try {
        const fd = new FormData();
        fd.append("type", 30);
        let response = await axios.get(config.apiBaseUrl + "/api/KeyValue/All");
        this.customersubtypes = response.data.filter((x) => x.type === 30);
      } catch (error) {
        this.loader = false;
      }
    },
    async AddService() {
      try {
        this.loader = true;
        let response = await axios({
          method: "POST",
          url: config.apiBaseUrl + "/api/Service/AddOrUpdate",
          data: this.Service,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          }
        });
        console.log(response);
        this.loader = false;
        this.GetAll();
      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },
    async DeleteService() {
      try {
        this.loader = true;
        const fd = new FormData();
        fd.append("serviceId", this.Service.serviceId);
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Service/Remove",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        if (response.data == false) {
          this.$toast.warning("Service used in employee services or job cannot be deleted.", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnfocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
          })
        } else {
          this.GetAll();
        }
        this.loader = false;
      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },
    forwardButtonCall() {
      //it does not run on equal to condition
      if (this.Filter.page < this.pagination.totalPages) {
        this.Filter.page++;
        this.GetAll();
      } else {
        return;
      }
    },
    backwardButtonCall() {
      if (this.Filter.page > 1) {
        this.Filter.page--;
        this.GetAll();
      } else {
        return;
      }
    },
  },
  created() {
    this.$store.state.DisableCompany = false;
    this.GetAll();
    this.GetCustomerType();
    this.$root.$on("myEvent", () => {
      this.Filter.page = 1;
      this.Filter.filterText = "";
      this.GetAll();
      this.GetCustomerType();
    });
  },
};
</script>
<style scoped>
.login-btns {
  color: #fff;
}
</style>