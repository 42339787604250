<template>
  <div class="">
    <v-row justify="space-between">
      <v-col class="d-flex" cols="12" sm="6">
        <v-btn class="mr-2 theme-color1" dark elevation="0" tile>
          <v-icon>mdi-book-check</v-icon>
        </v-btn>
        <h2 class="text-uppercase theme-font-color">{{ name }}</h2>
      </v-col>
      <v-col class="mb-1 d-flex" cols="12" sm="6">
        <v-select v-model="Filter.quoteStatus" :items="JobFilter"
                  :menu-props="{ bottom: true, offsetY: true }" class="custom-input border-right JobFilter"
                  color="#64718A" dense hide-details
                  item-text="text" item-value="value" outlined style="max-width: 150px" @change="GetAll()"></v-select>
        <v-text-field v-model="Filter.filterText" append-icon="mdi-magnify" class="custom-input" clearable dense
                      hide-details
                      outlined placeholder="Search" @keyup="GetAll"
                      @click:clear="(Filter.filterText = ''), GetAll()"></v-text-field>
        <v-btn :to="{ name: 'AddQuotation', params: { jobType } }" class="ms-2 theme-color1" dark elevation="0"
               height="40" link
               tile>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <div class="custom-line"></div>
    <v-data-table v-if="Qoutations == null" :headers="headers" :items-per-page="Filter.pageSize"
                  class="custom-table elevation-0"
                  disable-sort hide-default-footer>
    </v-data-table>
    <v-data-table v-else :headers="headers" :items="Qoutations" :items-per-page="Filter.pageSize"
                  class="custom-table elevation-0"
                  disable-sort hide-default-footer>
      <template v-slot:[`item.sno`]="{ item }">
        <router-link :to="{ name: 'AddQuotation', params: { item, editView } }" class="ms-0">
          QUOTE # {{ item.quoteNo }}
        </router-link>
      </template>
      <template v-slot:[`item.quoteCreationDate`]="{ item }">
        {{ getFormattedDate(item.quoteCreationDate) }}
      </template>
      <template v-slot:[`item.expiryDate`]="{ item }">
        {{ getFormattedDate(item.expiryDate) }}
      </template>

      <template v-slot:[`item.quoteStatus`]="{ item }">
        <span v-if="item.quoteStatus == 1"><v-chip class="type-chip" color="grey darken-2" dark label
                                                   x-small>Open</v-chip></span>
        <span v-if="item.quoteStatus == 2"><v-chip class="type-chip" color="success darken-1" dark label
                                                   x-small>Approved</v-chip></span>
        <span v-if="item.quoteStatus == 3"><v-chip class="type-chip" color="red darken-3" dark label
                                                   x-small>Cancelled</v-chip></span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon :disabled="item.quoteStatus == 2 || item.quoteStatus == 3" class="ms-2"
                color="primary darken-1"
                small @click="takeData(item), (emailDialog = true)">mdi-email-outline
        </v-icon>
        <router-link v-if="item.quoteStatus == 0 || item.quoteStatus == 1"
                     :to="{ name: 'AddQuotation', params: { item } }"
                     class="ms-0"
                     v-on:click.prevent>
          <v-icon v-if="item.quoteStatus != 3" :disabled="item.quoteStatus == 2 || item.quoteStatus == 3" class="ms-2"
                  color="primary darken-1" small
                  @click="takeData(item)">mdi-pencil
          </v-icon>
        </router-link>
        <v-icon v-else :disabled="item.quoteStatus == 2 || item.quoteStatus == 3" class="ms-2"
                color="primary darken-1" small
                @click="takeData(item)">mdi-pencil
        </v-icon>
        <v-menu class="pa-0" left offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon :disabled="item.quoteStatus == 3" color="primary" small v-bind="attrs" @click="takeData(item)"
                    v-on="on">mdi-dots-vertical
            </v-icon>
          </template>
          <v-list class="pa-0" dense>
            <v-list-item :disabled="item.quoteStatus == 2" dense link @click="item.quoteStatus = 2, CloseQuote()">
              <v-list-item-icon :disabled="item.quoteStatus == 2" class="nav-icon mr-2 ">
                <img src="../../assets/sidebar/file-invoice-dollar.svg"/>
              </v-list-item-icon>
              <v-list-item-title>
                Approve
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="item.quoteStatus != 3" :disabled="item.quoteStatus == 2" dense link
                         @click="item.quoteStatus = 3, CloseQuote()">
              <v-list-item-icon :disabled="item.quoteStatus == 2" class="nav-icon mr-2 ">
                <img src="../../assets/cross-circle.svg"/>
              </v-list-item-icon>
              <v-list-item-title>
                Cancel
              </v-list-item-title>
            </v-list-item>
            <v-list-item dense link @click="DownloadPdf()">
              <v-list-item-icon class="nav-icon mr-2 ">
                <img src="../../assets/pdf.svg"/>
              </v-list-item-icon>
              <v-list-item-title>Download
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-icon :disabled="item.quoteStatus == 2" class="ms-2" color="red" small
                @click="takeData(item), (deleteDialog = true)">mdi-trash-can-outline
        </v-icon>
      </template>
    </v-data-table>
    <v-row align="center" class="no-gutters mt-2">
      <v-spacer style="width: 75%"></v-spacer>
      <span class="pagination-text">Rows per page</span>
      <v-select v-model="Filter.pageSize" :items="options" :menu-props="{ bottom: true, offsetY: true }" class="ma-2"
                dense
                style="width: 50px" @change="GetAll"></v-select>
      <span v-if="pagination != null" class="mr-4 grey--text pagination-text">
        Page {{ pagination.page }} of {{ pagination.totalPages }}
      </span>
      <v-icon class="mr-1" color="primary darken-2" dark small @click="backwardButtonCall">mdi-chevron-left</v-icon>
      <v-icon class="mr-1" color="primary darken-2" dark small @click="forwardButtonCall()">mdi-chevron-right</v-icon>
    </v-row>

    <!-- Delete dialog -->
    <v-dialog v-model="deleteDialog" max-width="400" persistent>
      <v-card>
        <v-card-title class="text-h5"> Delete Qoutation</v-card-title>
        <v-card-text>Are You sure? You want to delete
          <b v-if="CurrentQoutation != null" class="error--text text--darken-2">{{
              CurrentQoutation.title
            }}</b></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text tile @click="deleteDialog = false">
            Cancel
          </v-btn>
          <v-btn color="error darken-2" elevation="0" tile @click="DeleteQoutation(), (deleteDialog = false)">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Mail dialog -->
    <v-dialog v-if="CurrentQoutation != null" v-model="emailDialog" max-width="400" persistent>
      <v-card>
        <v-card-title class="text-h5"> Send Mail</v-card-title>
        <v-card-text>Are You sure? You want to send email to
          <b class="theme-font-color">{{
              CurrentQoutation.customer.fullName
            }}</b></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text tile @click="emailDialog = false">
            Cancel
          </v-btn>
          <v-btn class="theme-color1" elevation="0" tile
                 @click="(CurrentQoutation.isMailSent = true), SendMail(), (emailDialog = false)">
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Mark Dialog -->
    <v-dialog v-model="qouteDialog" max-width="400" persistent>
      <v-card>
        <v-card-title class="text-h7"> Mark as Job</v-card-title>
        <v-card-text>Are You sure? You want to mark
          <b v-if="CurrentQoutation != null" class="purple--text">{{
              CurrentQoutation.customer.fullName
            }}</b>'s rquest as job.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="qouteDialog = false">
            Cancel
          </v-btn>
          <v-btn color="purple darken-2 white--text" @click="MarkAsQoutation(), (qouteDialog = false)">
            Mark
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-progress-circular v-if="loader" class="custom-loader" color="#64718A" indeterminate width="2">
    </v-progress-circular>
  </div>
</template>
<script>
import moment from "moment";
import 'moment-timezone';
import axios from "axios";
import config from "../Configuration/config";

export default {
  name: "QoutationsModule",
  data() {
    return {
      name: "Quotes",
      deleteDialog: false,
      qouteDialog: false,
      editView: false,
      emailDialog: false,
      loader: false,
      jobType: 110,
      headers: [
        {
          text: "#",
          align: "start",
          value: "sno",
          width: '140px'
        },
        {
          text: "Title",
          align: "start",
          value: "title",
        },
        {
          text: "Customer",
          align: "start",
          value: "customer.fullName",
        },
        {
          text: "Qoute Date",
          value: "quoteCreationDate",
        },
        {
          text: "Description",
          value: "quoteDescription",
        },
        {
          text: "Status",
          value: "quoteStatus",
          align: "center",
          width: '100px'
        },

        {
          text: "Actions",
          value: "actions",
          align: "right",
          width: "150px",
          sortable: false,
        },
      ],
      options: [
        {text: "10", value: 10},
        {text: "25", value: 25},
        {text: "50", value: 50},
        {text: "100", value: 100},
      ],
      Qoutations: null,
      CurrentQoutation: null,
      pagination: null,
      pageNumOnSearch: "",
      totalRecords: "",
      JobFilter: [
        {
          text: "All",
          value: 0
        },
        {
          text: "Open",
          value: 1,
        },
        {
          text: "Approved",
          value: 2,
        },
        {
          text: "Cancelled",
          value: 3,
        },
      ],
      Filter: {
        CompanyId: axios.defaults.headers.common.CompanyId,
        pageSize: 10,
        page: 1,
        filterText: "",
        isActive: true,
        jobType: 110,
        quoteStatus: 0
      },
    };
  },
  methods: {
    async DownloadPdf() {
      try {
        this.loader = true;
        const fd = new FormData();
        fd.append("blobName", this.CurrentQoutation.quotePdf);
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Job/DownloadPdf",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        const link = document.createElement('a');
        link.href = response.data;
        link.setAttribute('download', 'QUOTE_' + this.CurrentQoutation.quoteNo + '.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.loader = false;
      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },
    getFormattedDate(date) {
      /* const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get the user's timezone
       return moment.utc(date).tz(userTimezone).format('MMM DD, yyyy');*/
      return moment(date).format("MMM DD, YYYY");
    },
    takeData(data) {
      this.CurrentQoutation = data;
    },
    async CloseQuote() {
      try {
        this.loader = true;
        const fd = new FormData();
        fd.append("jobId", this.CurrentQoutation.jobId);
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Job/CloseQuote?status=" + this.CurrentQoutation.quoteStatus,
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        console.log(response);
        this.GetAll();
        this.loader == false;
      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },
    async SendMail() {
      try {
        this.loader = true;
        const fd = new FormData();
        fd.append("jobId", this.CurrentQoutation.jobId);
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Job/SendMail?jobType=" + 110,
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        if (response.status == 200) {
          this.loader = false;
          this.$toast.success("Email sent successfully", {
            position: "top-center",
            timeout: 1041,
            closeOnClick: true,
            pauseOnfocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
          })
        }
        let response2 = await axios({
          method: "POST",
          url: config.apiBaseUrl + "/api/Job/AddOrUpdateQuote",
          data: this.CurrentQoutation,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        this.GetAll();
        console.log(response2)

        this.loader = false;
      } catch (error) {

        console.log(error);
        this.loader = false;
        this.$toast.error("Something went wrong!", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        })
      }
    },

    async GetAll() {
      if (this.$route.name == "QoutationsModule") {
        try {
          this.loader = true;
          let response = await axios({
            method: "POST",
            url: config.apiBaseUrl + "/api/Job/All",
            data: this.Filter,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          });
          this.Qoutations = response.data.data;
          this.pagination = response.data.pagination
          this.loader = false;
        } catch (error) {
          this.loader = false;
          this.$toast.warning(error.message, {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnfocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
          });
        }
      }
    },


    forwardButtonCall() {
      //it does not run on equal to condition
      if (this.Filter.page < this.pagination.totalPages) {
        this.Filter.page++;
        this.GetAll();
      } else {
        return;
      }
    },
    backwardButtonCall() {
      if (this.Filter.page > 1) {
        this.Filter.page--;
        this.GetAll();
      } else {
        return;
      }
    },
    async DeleteQoutation() {
      try {
        this.loader = true;
        this.loader = true;
        const fd = new FormData();
        fd.append("jobId", this.CurrentQoutation.jobId);
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Job/Remove?type=110",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        this.GetAll();
        console.log(response)
        this.loader = false
      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },
  },
  created() {
    this.$store.state.DisableCompany = false;
    this.GetAll();
    this.$root.$on("myEvent", () => {
      this.Filter.page = 1;
      this.GetAll();
    });
  },
};
</script>
<style scoped>.nav-icon img {
  width: 16px !important;
}

.type-chip {
  width: 80px !important;
  justify-content: center !important;
}</style>