<template>
  <div>
    <div>
      <v-form ref="form" v-model="valid" class="mt-3" lazy-validation>
        <v-row class="ps-8 pe-8" justify="center">
          <v-col cols="12" md="12">
            <v-card elevation="0" outlined tile>
              <v-toolbar class="tab-color" dense elevation="0" flat tile>

                <v-btn class="mr-2 theme-color1" dark elevation="0" height="35px" link tile
                       to="/invoices">
                  <v-icon dense>mdi-undo-variant
                  </v-icon>
                </v-btn>
                <v-toolbar-title>
                  <h4 class="text-uppercase theme-font-color">{{ name }} for <span v-if="SelectedCustomer != null"
                                                                                   class="black--text">                                   {{
                      SelectedCustomer.fullName
                    }}</span>
                  </h4>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <div class="Status">
                  <v-chip v-if="Job.invoiceStatus == 1" class="primary darken-1 mr-2" dark label small>Pending</v-chip>
                  <v-chip v-if="Job.invoiceStatus == 2" class="purple darken-2 mr-2" dark label small>Sent</v-chip>
                  <v-chip v-if="Job.invoiceStatus == 3" class="red darken-2 mr-2" dark label small>Un Paid</v-chip>
                  <v-chip v-if="Job.invoiceStatus ==4" class="orange darken-2 mr-2" dark label small>Partial Paid
                  </v-chip>
                  <v-chip v-if="Job.invoiceStatus ==5" class="success darken-2 mr-2" dark label small>Paid</v-chip>

                </div>
                <v-btn-toggle dense multiple tile>
                  <v-menu class="pa-0" left offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="theme-color1" dark left offset-x="true" v-bind="attrs" v-on="on">
                        <v-icon color="#fff" small>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list class="pa-0" dense>
                      <v-list-item dense link
                                   @click="DownloadPdf()">
                        <v-list-item-icon class="nav-icon mr-2 ">
                          <v-icon color="success">mdi-file-code-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          Download
                        </v-list-item-title>
                      </v-list-item>


                    </v-list>
                  </v-menu>
                </v-btn-toggle>

              </v-toolbar>

              <v-card-text class="pb-2">
                <v-row class="mt-2 ps-5 pe-5">
                  <v-col class="pa-0" cols="12" md="6">
                    <v-text-field v-model="Job.title" class="custom-input my-input2" color="#64718A " dense disabled
                                  hide-details
                                  label="Title" outlined>
                    </v-text-field>
                  </v-col>

                </v-row>
              </v-card-text>
              <v-card-text class="pb-2">
                <v-row class="ps-5 pe-5">
                  <v-col class="pa-0 d-flex" cols="12" md="6">
                    <v-autocomplete v-if="$route.params.item == undefined" v-model="SelectedCustomer" :items="Customers"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                    class="custom-input my-input"
                                    dense disabled hide-details item-text="fullName"
                                    label="Customer"
                                    outlined return-object
                                    @change="selectCustomer">
                    </v-autocomplete>
                    <v-autocomplete v-else v-model="SelectedCustomer" :items="Customers"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                    class="custom-input my-input-common-border" dense
                                    disabled hide-details item-text="fullName" label="Customer"
                                    outlined
                                    return-object
                                    @change="selectCustomer">
                    </v-autocomplete>

                    <v-btn class="theme-color1" depressed disabled height="40" tile
                           @click="addCustomerDialog = true">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="pb-2">
                <v-row class="ps-5 pe-5">
                  <v-col class="pa-0 d-flex" cols="12" md="6">
                    <input
                        ref="uploader1"
                        class="custom-input my-input d-none"
                        disabled
                        name="workOder"
                        type="file"
                    />
                    <v-text-field
                        v-model="Job.workOrder"
                        class="custom-input my-input2 work-order-input"
                        dense
                        disabled
                        hide-details
                        label="Upload Work Order"
                        outlined
                        readonly
                        width="100%"
                    >
                      <template v-slot:append>
                        <i v-if="Job.workOrder !== ''" class="mgc_eye_line input-icon"
                           @click="ShowDocument(Job.workOrder)"></i>
                      </template>
                    </v-text-field>
                    <v-btn
                        class="theme-color1"
                        depressed
                        disabled
                        height="40"
                        tile
                    >
                      <v-icon>mdi-file-upload-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="ps-5 pe-5">
                  <v-col class="pa-0 d-flex" cols="12" md="6">
                    <input
                        ref="uploader2"
                        class="custom-input my-input d-none"
                        disabled
                        name="jobPdf"
                        type="file"
                        @change="onFileChanged($event, 'jobPdf')"
                    />
                    <v-text-field
                        v-model="Job.jobCompletionPdf"
                        :disabled="!jobview"
                        class="custom-input my-input2 work-order-input"
                        dense
                        hide-details
                        label="Upload Job Completion Pdf"
                        outlined
                        readonly
                        width="100%"
                    >
                      <template v-slot:append>
                        <i v-if="Job.jobCompletionPdf !== ''" class="mgc_eye_line input-icon"
                           @click="ShowDocument(Job.jobCompletionPdf)"></i>
                      </template>
                    </v-text-field>
                    <v-btn
                        class="theme-color1"
                        depressed

                        disabled
                        height="40"
                        tile
                    >
                      <i class="mgc_upload_2_line" style="font-size: 22px"></i>
                      <!--                    <v-icon>mdi-file-upload-outline</v-icon>-->
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="">
                <v-row class="ps-5 pe-5">
                  <v-col class="pa-0" cols="12" md="6">
                    <v-textarea
                        v-model="Job.jobNote"
                        auto-grow
                        class="rounded-0 border-right"
                        color="#64718A"
                        disabled
                        hide-details
                        label="Description"
                        outlined
                        rows="3"
                    ></v-textarea>
                  </v-col>
                  <v-col class="pa-0" cols="12" md="6">
                    <v-text-field v-model="Job.swo" class="custom-input my-input2 " color="#64718A  " dense
                                  disabled hide-details label="SWO #"
                                  outlined required>
                    </v-text-field>
                    <v-text-field v-model="Job.taskId" class="custom-input bordered" color="#64718A" dense
                                  disabled height="42" hide-details
                                  label="Task Id" outlined required>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text>
                <v-divider></v-divider>
              </v-card-text>
              <v-card-text class="pb- pt-0">
                <v-row class="ps-5 pe-5 mt-0">
                  <v-card elevation="0" outlined tile width="100%">
                    <v-toolbar class="tab-color" dense elevation="0" tile>
                      <v-toolbar-title class="font-weight-bold theme-font-color">Services & Equipments</v-toolbar-title>
                      <v-spacer></v-spacer>

                    </v-toolbar>
                    <v-card-text class="pb-0">
                      <v-row class="pt-0 mb-2">
                        <v-col cols="12" md="6">
                          <v-autocomplete v-model="Job.jobItems" :items="Services"
                                          :menu-props="{ bottom: true, offsetY: true }"
                                          class="custom-input my-input-common-border"

                                          color="#64718A"
                                          dense disabled hide-details item-text="title" label="Services" multiple
                                          outlined return-object
                                          small-chips
                                          @change="(event) => (ServicesEquipments(10),GetSpecificEmployees())">
                            <template v-slot:item="data">
                              <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                              </template>
                              <template v-else>
                                <v-list-item-content>
                                  <v-list-item-title>{{ data.item.title }} <span v-if="!data.item.forWholesale"
                                                                                 class="caption"> (Domestic)</span>
                                    <span v-if="data.item.forWholesale"
                                          class="caption"> (Wholesale / {{ data.item.wholesaleTitle }})</span>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-autocomplete v-model="Job.jobItems" :items="Equipments"
                                          :menu-props="{ bottom: true, offsetY: true }"
                                          class="custom-input my-input-common-border" color="#64718A"
                                          dense disabled hide-details item-text="title" label="Equipments" multiple
                                          outlined return-object
                                          small-chips
                                          @change="(event) => ServicesEquipments(20)"></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row class="pb-2">
                        <v-col cols="12">
                          <v-data-table :headers="headers" :items="Job.jobItems"
                                        :items-per-page="Filter.pageSize"
                                        class="custom-table elevation-0"
                                        disable-sort hide-default-footer>
                            <template v-slot:[`item.sno`]="{ index }">
                              <span>{{ index + 1 }}</span>
                            </template>
                            <template v-slot:[`item.jobItemType`]="{ item }">
                              <span v-if="item.jobItemType === 10">Service</span>
                              <span v-if="item.jobItemType === 20">Equipment</span>
                            </template>
                          </v-data-table>
                          <v-divider></v-divider>
                          <v-row class="mt-2 d-flex justify-end align-center">
                            <v-col cols="12" md="5">
                              <v-row>
                                <v-col class="d-flex justify-end">
                                  <span
                                      class="total-label text--primary text-right"
                                  >AMOUNT</span
                                  >
                                </v-col>
                                <v-col class="d-flex justify-end" cols="3">
                                  <!--                                  <span-->
                                  <!--                                      class="total-amount text&#45;&#45;primary me-4"-->
                                  <!--                                  >{{ Job.netAmount }}</span-->
                                  <!--                                  >-->
                                  <input
                                      v-model.number="netAmountFormatted"
                                      class="total-input border-bottom-hide"
                                      disabled
                                      min="0"
                                      type="number"
                                      @blur="ChangeAmount"
                                      @focus="selectText"
                                  />
                                </v-col>
                              </v-row>
                              <v-row class="align-baseline">
                                <v-col class="pt-0 d-flex justify-end">
                                  <span class="total-label text--primary"
                                  >OTHERS</span
                                  >
                                </v-col>
                                <v-col class="d-flex justify-end pt-0" cols="3">
                                  <input
                                      v-model.number="otherChargesFormatted"
                                      :disabled="!jobview"
                                      class="total-input"
                                      min="0"
                                      type="number"
                                      @blur="ChangeAmount"
                                      @focus="selectText"
                                  />
                                </v-col>
                              </v-row>
                              <v-row class="align-baseline">
                                <v-col class="pt-1 d-flex justify-end">
                                  <span class="total-label text--primary"
                                  >DISCOUNT</span
                                  >
                                </v-col>
                                <v-col class="d-flex justify-end pt-1" cols="3">
                                  <input
                                      id="discount_input"
                                      v-model.number="discountFormatted"
                                      :disabled="!jobview"
                                      class="total-input"
                                      min="0"
                                      type="number"
                                      @blur="ChangeAmount"
                                      @focus="selectText"
                                  />
                                </v-col>
                              </v-row>
                              <!--                              <v-row>-->
                              <!--                                <v-col class="pt-0 d-flex justify-end">-->
                              <!--                                  <span class="total-label text&#45;&#45;primary"-->
                              <!--                                  >SUBTOTAL</span-->
                              <!--                                  >-->
                              <!--                                </v-col>-->
                              <!--                                <v-col cols="3" class="pt-0 d-flex justify-end">-->
                              <!--                                  <span-->
                              <!--                                      class="total-amount text&#45;&#45;primary me-4"-->
                              <!--                                  >{{ Job.subTotal }}</span-->
                              <!--                                  >-->
                              <!--                                </v-col>-->
                              <!--                              </v-row>-->
                              <v-row class="align-baseline">
                                <v-col class="pt-0 d-flex justify-end">
                                  <span class="total-label text--primary"
                                  >HST (%)</span
                                  >
                                </v-col>
                                <v-col class="d-flex justify-end pt-0" cols="3">
                                  <input
                                      id="tax_input"
                                      v-model.number="hstFormatted"
                                      :disabled="!jobview"
                                      class="total-input"
                                      min="0"
                                      type="number"
                                      @blur="ChangeAmount"
                                      @focus="selectText"
                                  />
                                </v-col>
                              </v-row>
                              <v-row class="align-baseline">
                                <v-col class="pt-1 d-flex justify-end">
                                  <span
                                      class="total-label text--primary"
                                  >HST Amount</span
                                  >
                                </v-col>
                                <v-col class="d-flex justify-end" cols="3">
                                  <input
                                      v-model.number="hstAmountFormatted"
                                      class="total-input border-bottom-hide"
                                      disabled
                                      min="0"
                                      type="number"
                                      @blur="ChangeAmount"
                                      @focus="selectText"
                                  />
                                  <!--                                  <span
                                                                        class="total-amount text&#45;&#45;primary me-4"
                                                                    >{{ Job.hstAmount }}</span
                                                                    >-->
                                </v-col>
                              </v-row>
                              <v-row class="align-baseline">
                                <v-col class="pt-1 d-flex justify-end">
                                  <span
                                      class="total-label text--primary font-weight-bold"
                                  >TOTAL</span
                                  >
                                </v-col>
                                <v-col class="d-flex justify-end" cols="3">

                                  <!--                                  <span-->
                                  <!--                                      class="total-amount text&#45;&#45;primary me-4 font-weight-bold"-->
                                  <!--                                  >{{ Job.grandTotal }}</span-->
                                  <!--                                  >-->
                                  <input
                                      v-model.number="totalFormatted"
                                      class="total-input border-bottom-hide total-amount text--primary font-weight-bold"
                                      disabled
                                      min="0"
                                      type="number"
                                      @blur="ChangeAmount"
                                      @focus="selectText"
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-row>
                <v-divider class="mt-8 mb-0"></v-divider>
                <v-card-text class="pb-0 pt-0 ps-0 pe-0">
                  <v-row class="ps-5 pe-5 mt-8">
                    <v-card elevation="0" outlined tile width="100%">
                      <v-toolbar class="tab-color" dense elevation="0" tile>
                        <v-toolbar-title class="font-weight-bold theme-font-color">Assign Employees</v-toolbar-title>
                        <v-spacer></v-spacer>
                      </v-toolbar>
                      <v-card-text class="pt-0">
                        <v-row class="pt-2">
                          <v-col cols="12" md="4">
                            <v-autocomplete v-model="Job.jobEmployees" :items="Workers"
                                            :menu-props="{ bottom: true, offsetY: true }"
                                            class="custom-input my-input-common-border"
                                            color="#64718A"
                                            dense disabled hide-details item-text="fullName" label="Employees" multiple
                                            outlined return-object
                                            small-chips></v-autocomplete>
                          </v-col>
                          <v-divider vertical></v-divider>
                          <v-col cols="12" md="8">
                            <div v-for="item in Job.jobEmployees" :key="item.JobId">
                              <v-chip class="float-left mr-2" label small>{{ item.fullName }}</v-chip>
                            </div>
                          </v-col>
                        </v-row>
                        <v-divider class="mt-5"></v-divider>
                        <v-card-text class="pb-0 ps-0 pe-0">
                          <v-row class="pb-2">
                            <v-col cols="12">
                              <v-data-table :headers="CompletedJobHeader" :items="AssignedEmployees"
                                            :items-per-page="Filter.pageSize"
                                            class="custom-table elevation-0"
                                            disable-sort hide-default-footer>
                                <template v-slot:[`item.sno`]="{ index }">
                                  <span>{{ index + 1 }}</span>
                                </template>
                                <template v-slot:[`item.visitType`]="{ item }">
                                  <v-chip v-if="item.visitType == 10" class="type-chip" color="orange darken1" dark
                                          label
                                          x-small>Assessment
                                  </v-chip>
                                  <v-chip v-if="item.visitType == 20" class="type-chip" color="purple" dark label
                                          x-small>Job
                                  </v-chip>
                                </template>
                                <template v-slot:[`item.jobGallery`]="{item}">
                                  <v-btn depressed icon small>
                                    <v-icon color="primary"
                                            @click="takeImages(item.jobImages), showGallery = true">
                                      mdi-image
                                    </v-icon>
                                  </v-btn>

                                </template>

                              </v-data-table>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card-text>
                    </v-card>
                  </v-row>
                </v-card-text>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <!--    Gallery-->
    <v-dialog v-model="showGallery" max-width="700">
      <v-card class="pb-2">
        <v-card-title>
          <p class="title mb-0">{{ Job.title }}</p>
          <v-spacer></v-spacer>
          <v-icon color="red" @click="showGallery = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider class="mb-2"></v-divider>
        <v-card-text>

          <template>
            <v-row>
              <v-col
                  v-for="n in GalleryImages"
                  :key="n"
                  class="d-flex child-flex"
                  cols="4"
              >
                <v-img
                    v-if="n!=''"
                    :lazy-src="`https://picsum.photos/10/6?image=`"
                    :src="n"
                    aspect-ratio="1"
                    class="grey lighten-2"
                >
                  <template v-slot:placeholder>
                    <v-row
                        align="center"
                        class="fill-height ma-0"
                        justify="center"
                    >
                      <v-progress-circular
                          color="grey lighten-5"
                          indeterminate
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
            </v-row>
            <span v-if="GalleryImages.length == 0" class="heading pa-2">No image uploaded yet</span>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-progress-circular v-if="loader" class="custom-loader" color="#64718A" indeterminate width="2">
    </v-progress-circular>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/components/Configuration/config";
import moment from "moment";
import 'moment-timezone';

export default {
  name: "AddInvoice",
  data() {
    return {
      name: "Invoice",
      addCustomerDialog: false,
      Print: false,
      showGallery: false,
      Save: false,
      paymentDialog: false,
      CustomerSwitch: false,
      JobSave: false,
      GalleryImages: [],
      Switch: false,
      switchControl: true,
      dialog: false,
      loader: false,
      loader2: false,
      jobview: false,
      rules: {
        tax: value => value >= 0 && value <= 100 || 'Invalid tax ',
        discount: value => value >= 0 || 'Invalid Number',
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
      jobEmployeesHeader: [
        {
          text: "#",
          align: "left",
          value: "sno",
          width: "70px",
        },
        {
          text: "Name",
          align: "left",
          value: "fullName",
        },
        {
          text: "",
          value: "actions",
          width: "50px",
        },
      ],
      headers: [
        {
          text: "#",
          align: "left",
          value: "sno",
          width: "70px",
        },
        {
          text: "Title",
          align: "left",
          value: "title",
        },
        {
          text: "JobItem Type",
          value: "jobItemType"
        },
        {
          text: "Type",
          value: "customerType",
        },
        {
          text: "Amount",
          align: "right",
          value: "price",
          width: "100px",
        },
        {
          text: "",
          value: "actions",
          width: "50px",
        },
      ],
      CompletedJobHeader: [
        {
          text: "#",
          align: "left",
          value: "sno",
          width: "70px",
        },
        {
          text: "Name",
          align: "left",
          value: "fullName",
        },
        {
          text: "Visit",
          align: "center",
          value: "visitType",
        },
        {
          text: "Worked Hours",
          align: "center",
          value: "totalHours",
          width: '150px'
        }, {
          text: "Remarks",
          align: "left",
          value: "remarks",
          width: '200px'
        },
        {
          text: "Job Images",
          value: "jobGallery",
          width: "120px",
          align: "center"
        },
      ],
      editedItem: {},
      worker: 0,
      requestDateMenu: false,
      customerdatemenu: false,
      SelectedCustomer: null,
      btnText: "Add Job",
      valid: true,
      Customers: [],
      Services: [],

      customersubtypes: [],
      Customer: {
        companyId: axios.defaults.headers.common.CompanyId,
        customerId: 0,
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        province: "",
        postalCode: "",
        customerSubType: "",
        country: "",
        notes: "",
        customerCategory: 100,
        taskId: "",
        swo: "",
      },
      Countries: [],
      customerTypes: null,
      Job: {
        companyId: axios.defaults.headers.common.CompanyId,
        jobId: 0,
        title: "",
        creationDate: new Date(),
        assessmentStartDate: new Date(),
        customerTime: new Date(),
        assessmentStartTime: new Date(),
        markAsQoute: false,
        isApproved: false,
        expiryDate: new Date(),
        jobType: 0,
        jobStatus: 0,
        jobNote: "",
        isLead: false,
        isQuote: false,
        isJob: true,
        isInvoice: false,
        leadStatus: 0,
        quoteStatus: 0,
        customerId: 0,
        quoteApproved: false,
        quoteCancelled: false,
        leadClosed: false,
        leadDescription: "",
        quoteDescription: "",
        taskId: "",
        cancellationReason: 0,
        swo: "",
        termsOfJob: "",
        isPaid: false,
        paymentMethod: 0,
        paymentDate: new Date().toJSON().substring(0, 10),
        discount: 0,
        hst: 13,
        hstAmount: 0,
        otherCharges: 0,
        paidAmount: 0,
        dueAmount: 0,
        netAmount: 0,
        grandTotal: 0,
        workOrder: "",
        jobCompletionPdf: "",
        jobItems: [],
        jobEmployees: [],
      },
      ServicesWorkers: [],
      Equipments: [],
      Workers: null,
      Filter: {
        CompanyId: axios.defaults.headers.common.CompanyId,
        page: 1,
        pageSize: 10,
        pagination: null,
        pageNumOnSearch: "",
        filterText: "",
        forWholesale: false,
      },
      AssignedEmployees: [],
      Company: sessionStorage.getItem('Company'),
      Address: sessionStorage.getItem('CompanyAddress'),
      Address2: sessionStorage.getItem('CompanyAddress2'),
      CompanyTel: sessionStorage.getItem('CompanyTel'),
      HST: sessionStorage.getItem('HST'),
    };
  },
  watch: {},
  computed: {
    netAmountFormatted: {
      get() {
        return this.Job.netAmount.toFixed(2);
      },
      set(value) {
        this.Job.netAmount = parseFloat(value);
      }
    },
    otherChargesFormatted: {
      get() {
        return this.Job.otherCharges.toFixed(2);
      },
      set(value) {
        this.Job.otherCharges = parseFloat(value);
      }
    },
    discountFormatted: {
      get() {
        return this.Job.discount.toFixed(2);
      },
      set(value) {
        this.Job.discount = parseFloat(value);
      }
    },
    hstFormatted: {
      get() {
        return this.Job.hst.toFixed(2);
      },
      set(value) {
        this.Job.hst = parseFloat(value);
      }
    },
    hstAmountFormatted: {
      get() {
        return this.Job.hstAmount.toFixed(2);
      },
      set(value) {
        this.Job.hstAmount = parseFloat(value);
      }
    },
    totalFormatted: {
      get() {
        return this.Job.grandTotal.toFixed(2);
      },
      set(value) {
        this.Job.grandTotal = parseFloat(value);
      }
    },
  },
  methods: {
    selectText(event) {
      event.target.select();
    },
    ChangeAmount() {
      if (isNaN(this.Job.otherCharges) || this.Job.otherCharges < 0) {
        this.Job.otherCharges = 0
      }
      if (isNaN(this.Job.discount) || this.Job.discount < 0) {
        this.Job.discount = 0
      }
      if (isNaN(this.Job.hst) || this.Job.hst < 0) {
        this.Job.hst = 0
      }
      if (this.Job.jobItems.length < 1) {
        this.Job.netAmount = 0;
        this.Job.discount = 0;
        this.Job.hst = 13;
        this.Job.otherCharges = 0;
        this.Job.grandTotal = 0;
        return;
      }
      this.Job.netAmount = this.Job.jobItems.reduce(
          (acc, o) => acc + o.price,
          0
      );
      if (this.Job.jobItems.length > 0) {
        this.Job.jobItems.forEach(element => {
          if (element.price == "") {
            element.price = 0;
          }
        })
      }

      if (this.Job.discount > this.Job.netAmount) {
        this.Job.discount = this.Job.netAmount;
      }
      let subTotal = this.Job.netAmount + this.Job.otherCharges - this.Job.discount;

      this.Job.grandTotal = subTotal;
      if (this.Job.hst > 100) {
        this.Job.hst = 100;
        var element = document.getElementById("tax_input");
        element.classList.add("taxInput");
      } else if (this.Job.hst < 0) {
        this.Job.hst = 13;
        var element2 = document.getElementById("tax_input");
        element2.classList.add("taxInput");
      }
      if (this.Job.hst > 0 || this.Job.hst <= 100) {
        this.Job.hstAmount = (this.Job.hst / 100) * subTotal;
        this.Job.grandTotal = this.Job.hstAmount + subTotal;
      }
    },
    ShowDocument(link) {
      var myWindow = window.open("", "_blank");
      const img = document.createElement("img");
      img.src = link;
      myWindow.document.body.appendChild(img);
    },
    takeImages(jobImages) {
      this.GalleryImages = [];
      if (jobImages != null) {
        const imageurl = jobImages.split(/\s*,\s*/);
        if (!this.GalleryImages.includes(...imageurl)) {
          this.GalleryImages.push(...imageurl);
        }
      }
    },
    ServicesEquipments(type) {
      var index = this.Service.length - 1;
      var equipmentindex = this.Equipment.length - 1;
      if (index > -1) {
        if (type == 10) {
          if (this.Service[0].wholesaleTitle === "") {
            this.Service[0].wholesaleTitle = "Domestic"
          }
          if (!this.Job.jobItems.some(service => service.title === this.Service[0].title && service.customerType === this.Service[0].wholesaleTitle)) {
            this.Job.jobItems.push(this.Service[0])
            var service = this.Job.jobItems.find(({serviceId}) => serviceId === this.Service[0].serviceId);
            service.jobItemType = 10;
            if (this.Service[0].wholesaleTitle !== "") {
              service.customerType = this.Service[0].wholesaleTitle
            } else {
              service.customerType = "Domestic"
            }
          }
          this.Service = [];
          //this.Job.jobItems[index].customerType  = this.Job.jobItems[index].wholesaleTitle;
        }
      }
      if (equipmentindex > -1) {
        if (type == 20) {
          if (!this.Job.jobItems.some(equipment => equipment.title === this.Equipment[0].title && equipment.customerType === this.Equipment[0].equipmentType)) {
            this.Job.jobItems.push(this.Equipment[0])
            var equipment = this.Job.jobItems.find(({equipmentId}) => equipmentId === this.Equipment[0].equipmentId);
            equipment.jobItemType = 20;
            equipment.customerType = this.Equipment[0].details
          }
          this.Equipment = [];
        }
      }
      this.ChangeAmount();
      this.GetSpecificEmployees();
    },
    async DownloadPdf() {
      try {
        this.loader = true;
        const fd = new FormData();
        fd.append("blobName", this.Job.invoicePdf);
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Job/DownloadPdf",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        const link = document.createElement('a');
        link.href = response.data;
        link.setAttribute('download', 'INVOICE_' + this.Job.jobNo + '.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.loader = false;
      } catch (error) {
        this.loader = false;
        console.log(error);
      }
    },

    ChangePrice(item) {
      const index = this.Job.jobItems.indexOf(item);
      if (!isNaN(item.price)) {
        item.amount = item.price
      }
      if (!isNaN(item.discount)) {
        if (item.discount > 0) {
          item.amount = item.price - item.discount;
        } else {
          item.discount = 0
        }
      }
      if (!isNaN(item.taxRate)) {
        if (item.taxRate >= 0 && item.taxRate <= 100) {
          item.taxPrice = (item.taxRate / 100) * item.amount;
          item.amount += item.taxPrice;
        } else {
          item.taxRate = 0;
        }
      }
      this.Job.jobItems.splice(index, 1, item);
    }, ChangeView(type) {
      if (!type) {
        this.Customer.customerCategory = 100
        this.Customer.customerSubType = "";
      } else {
        this.Customer.customerCategory = 110
      }
    }, formatPhoneNumber() {
      this.Customer.phone = this.Customer.phone.replace(/[^0-9]/g, '')
          .replace(/^(\d{3})(\d{3})(\d{4})/g, '$1-$2-$3').substring(0, 12);
    },
    async GetEquipments() {
      try {
        this.loader = true;
        let response = await axios.post(
            config.apiBaseUrl + "/api/Equipment/All",
            {
              pageSize: this.Filter.pageSize,
              page: this.Filter.page,
              filterText: this.Filter.filterText,
              isActive: true,
            }
        );
        this.Equipments = response.data.data;
        this.loader = false;
      } catch (err) {
        this.loader = false;
        this.$toast.warning(err.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        })
        ;
      }
    }, async GetCountries() {
      let response = await axios.get("https://restcountries.com/v3.1/all");
      this.Countries = response.data;
      this.Countries = response.data;
      var canada = this.Countries.filter(x => x.name.common == "Canada");
      this.Customer.country = canada[0].name.common;
    }, getFormattedDate(date) {
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get the user's timezone
      return moment.utc(date).tz(userTimezone).format('MMM DD, yyyy');
      //return moment(date).format("MMM DD, YYYY");
    }, selectCustomer() {
      this.Job.customerId = this.SelectedCustomer.customerId;
      this.Job.customerId = this.SelectedCustomer.customerId;
      if (this.SelectedCustomer.customerCategory == 100) {
        this.Filter.forDomestic = true;
        this.Filter.forWholesale = false;
      } else {
        this.Filter.forDomestic = false;
        this.Filter.forWholesale = true;
      }
      this.GetServices();
    },
    async CloseJob() {
      try {
        const fd = new FormData();
        fd.append("jobId", this.Job.jobId);
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Job/CloseJob",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        console.log(response)
        this.$router.push('/jobs')
      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        })
        ;
      }
    },
    async GetCustomerType() {
      try {
        const fd = new FormData();
        fd.append("type", 30);
        let response = await axios.get(config.apiBaseUrl + "/api/KeyValue/All");
        this.customerTypes = response.data.filter((x) => x.type == 10);
        this.customersubtypes = response.data.filter((x) => x.type == 30);
      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        })
        ;
      }
    }, async AddorUpdateCustomer() {
      try {
        if (this.CustomerSwitch) {
          this.Customer.customerCategory = 110
        } else {
          this.Customer.customerCategory = 100
        }

        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Customer/AddOrUpdate",
          data: this.Customer,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        this.GetCustomers();
        this.SelectedCustomer = response.data.data;
        this.Job.customerId = this.SelectedCustomer.customerId;
        this.loader = false
        this.selectCustomer();
      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        })
        ;
      }
    }, async GetCustomers() {
      try {
        this.loader = true;
        let response = await axios({
          method: "POST",
          url: config.apiBaseUrl + "/api/Customer/GetCustomers",
          data: this.Filter,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        this.Customers = response.data.data;
        this.loader = false
      } catch (error) {
        this.loader = false;

      }
    }, async GetServices() {
      try {
        this.loader = true;
        axios({
          method: "POST",
          url: config.apiBaseUrl + "/api/Service/GetServicesByCustomer",
          data: this.Filter,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }).then((res) => {
          this.Services = res.data.data;
          this.GetSpecificEmployees(1);
          this.GetAssignedEmployees();
        });
        this.loader = false;
      } catch (error) {
        this.loader = false;

      }
    }, async GetWorkers() {
      try {
        this.loader2 = true;
        const fd = new FormData();
        fd.append("serviceId", this.editedItem.serviceId);
        let response = await axios({
          method: "POST",
          url: config.apiBaseUrl + "/api/Employee/GetWorkers",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        this.ServicesWorkers = response.data;
        this.loader2 = false;
      } catch (error) {
        this.loader = false;

      }
    },
    async AddorUpdate() {

      try {
        this.loader = true;
        this.Job.jobItems.forEach(element => {
          if (element.taxRate == null && element.discount == null) {
            element.amount = element.price;
          }
          element.service = null;
        });
        if (this.Job.jobEmployees != null && this.Job.jobEmployees.length > 0) {
          this.Job.jobEmployees.forEach(element => {
            element.visitType = 20;
          })
        }
        let response = await axios({
          method: "POST",
          url: config.apiBaseUrl + "/api/Job/AddOrUpdateJob",
          data: this.Job,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        console.log(response);
        this.$router.push('/jobs')
        this.loader = false
      } catch (error) {
        this.loader = false;

      }
    },
    async GetSpecificEmployees(type) {
      try {
        const fd = new FormData();
        this.loader = true;
        if (this.Job.jobItems.length > 0 && type == 1) {
          this.Job.jobItems.forEach(element => {
            if (element.jobItemType == 10) {
              fd.append("services", element.title);
            }
          })
        } else if (this.Job.jobItems.length > 0) {
          this.Job.jobItems.forEach(element => {
            if (element.JobItemType == 10) {
              fd.append("services", element.title);
            }
          })
        }
        let response = await axios({
          method: "POST",
          url: config.apiBaseUrl + "/api/Employee/GetAllByServices",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });

        this.Workers = response.data;
        this.loader = false
      } catch (err) {
        this.loader = false;

      }
    }, close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    }, deleteItem(item) {
      var index = this.Job.jobItems.indexOf(item);
      this.Job.jobItems.splice(index, 1);
    }, save() {
      if (this.editedIndex > -1) {
        Object.assign(
            this.Job.jobItems[this.editedIndex],
            this.editedItem
        );
      } else {
        if (!this.Job.jobItems.includes(this.editedItem)) {
          this.editedItem.service = this.editedItem
          this.editedItem.workerId = this.worker.workerId;
          this.editedItem.worker = this.worker;
          this.Job.jobItems.push(this.editedItem);
        } else {
          alert("service already exists");
        }
      }
      this.close();
    },
    async GetAssignedEmployees() {
      try {
        this.loader = true;
        const fd = new FormData();
        fd.append("jobId", this.Job.jobId);
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Job/GetAssignedEmployees",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        this.AssignedEmployees = response.data;

        this.loader == false;
      } catch (error) {
        this.loader = false;

      }
    },

    async GetJobRequest() {
      if (this.$route.params.item != undefined) {
        if (this.$route.params.editView != undefined) {
          this.jobview = true;
        } else {
          this.jobview = false;
        }
        this.loader == true;
        this.Job = this.$route.params.item;
        try {
          const fd = new FormData();
          fd.append("jobId", this.Job.jobId);
          let response = await axios({
            method: "post",
            url: config.apiBaseUrl + "/api/Job/Get",
            data: fd,
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
            },
          });
          this.Job = response.data.job;
          this.SelectedCustomer = this.Job.customer;
          this.selectCustomer();
          this.ChangeAmount();

          this.loader == false;
        } catch (error) {
          console.log(error);
          this.loader = false;

        }
      }
    },
  },
  created() {
    this.$store.state.DisableCompany = true;
    this.GetJobRequest();
    this.GetCustomers();
    this.GetEquipments();
    this.GetCountries();
    this.GetCustomerType();
    this.GetSpecificEmployees();
  },
};
</script>
<style scoped>
.type-chip {
  width: 100px !important;
  justify-content: center !important;
}

.v-btn-toggle .v-btn.v-btn.v-size--default {
  min-width: 25px;
  min-height: 0;
}

.nav-icon img {
  width: 16px !important;
}

.menu-list.v-list-item {
  height: 30px;
  padding-right: 8px !important;
  padding-left: 8px !important;
}

.menu-list {
  padding: 0px !important;
  box-shadow: none;
}

.total-label {
  font-size: 15px;
  margin-left: 80px;
}

.total-amount {
  font-size: 15px;
}

.wage-field {
  text-align: center !important;
}

.static-wage-field.v-text-field fieldset, .v-text-field .v-input__control {
  color: currentColor;
  text-align: center;
  border: 0px !important;
}

input[name="workOder"] {
  width: 100%;
}

.total-label {
  font-size: 15px;
  margin-left: 80px;
}

.total-amount {
  font-size: 15px;
}

.wage-field input {
  text-align: right !important;
}

.total-input {
  border-bottom: 1px solid grey;
  width: 75px !important;
  font-size: 15px !important;
  text-align: right;
}

.total-input:focus {
  outline: none !important;
}

.taxInput {
  border: 1px solid red;
}

.work-order-input {
  font-size: 11px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999 !important;
}

.modal-image {
  max-width: 90%;
  max-height: 90%;
}
</style>