<template>
  <div>
    <v-form class="mt-3">
      <v-row class="ps-8 pe-8" justify="center">
        <v-col cols="12" md="12">
          <v-card class="pb-5" elevation="0" outlined tile>
            <v-toolbar class="tab-color" dense elevation="0" flat tile>
              <v-btn
                  class="mr-2 theme-color1"
                  dark
                  elevation="0"
                  height="35px"
                  link
                  tile
                  to="/jobs"
              >
                <v-icon dense>mdi-undo-variant</v-icon>
              </v-btn>
              <v-toolbar-title>
                <h4 class="text-uppercase theme-font-color">
                  {{ name }} for
                  <span v-if="SelectedCustomer != null" class="black--text">
                    {{ SelectedCustomer.fullName }}</span
                  >
                </h4>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <div class="Status">
                <v-chip
                    v-if="Job.jobStatus == 1"
                    class="primary darken-1 mr-2"
                    dark
                    label
                    small
                >In Progress
                </v-chip
                >
                <v-chip
                    v-if="Job.jobStatus == 2"
                    class="purple darken-2 mr-2"
                    dark
                    label
                    small
                >Assigned
                </v-chip
                >
                <v-chip
                    v-if="Job.jobStatus == 3"
                    class="success darken-2 mr-2"
                    dark
                    label
                    small
                >Completed
                </v-chip
                >
                <v-chip
                    v-if="Job.jobStatus == 4"
                    class="gray darken-2 mr-2"
                    dark
                    label
                    small
                >Closed
                </v-chip
                >
              </div>
              <v-btn-toggle dense multiple tile>
                <v-btn
                    v-if="!jobview"
                    :disabled="!Job.title || Job.customerId == 0"
                    class="ps-12 pe-12 login-btns theme-color1"
                    dark
                    elevation="0"
                    tile
                    @click="(Job.isJob = true), AddorUpdate()"
                >
                  Save
                </v-btn>
                <v-btn
                    v-if="jobview"
                    :disabled="Job.jobStatus === 4"
                    class="ps-12 pe-12 login-btns theme-color1"
                    dark
                    elevation="0"
                    tile
                    @click="jobview = !jobview"
                >
                  Edit
                </v-btn>
                <v-menu class="pa-0" left offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        :disabled="!Job.title || Job.customerId == 0 || jobview"
                        class="theme-color1"
                        dark
                        left
                        offset-x="true"
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon color="#fff" small>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="pa-0" dense>
                    <v-list-item dense link @click="CloseJob()">
                      <v-list-item-icon class="nav-icon mr-2">
                        <v-icon color="success" small>mdi-check-circle</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title> Close Job</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-btn-toggle>
            </v-toolbar>

            <v-card-text class="pb-2">
              <v-row class="mt-2 ps-5 pe-5">
                <v-col class="pa-0" cols="12" md="6">
                  <v-text-field
                      v-model="Job.title"
                      :disabled="jobview"
                      class="custom-input my-input2"
                      color="#64718A "
                      dense
                      hide-details
                      label="Title"
                      outlined
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="pb-2">
              <v-row class="ps-5 pe-5">
                <v-col class="pa-0 d-flex" cols="12" md="6">
                  <v-autocomplete
                      v-if="$route.params.item == undefined"
                      v-model="SelectedCustomer"
                      :disabled="jobview"
                      :filter="customSearch"
                      :items="Customers"
                      :loading="isLoading"
                      :menu-props="{ bottom: true, offsetY: true }"
                      :search-input.sync="search"
                      class="custom-input my-input"
                      dense
                      hide-details
                      hide-no-data
                      hide-selected
                      item-text="fullName"
                      item-value="customerId"
                      label="Customer"
                      outlined
                      return-object
                      @change="selectCustomer"
                  >
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content
                            v-text="data.item"
                        ></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title>{{ data.item.fullName }} <span class="caption"> <span
                              v-if="data.item.phoneNumber !== ''">| +1-{{ data.item.phoneNumber }} </span>
                              | {{ data.item.email }} |
                             <span class="text-truncate"> {{
                                 data.item.address + " " + data.item.city + " " + data.item.province
                               }} | </span>
                                <span v-if="data.item.customerCategory === 100"> D </span>
                                <span v-if="data.item.customerCategory === 110"> W - <span
                                    class="text-truncate" style="max-width: 5px !important;">{{
                                    data.item.customerSubType.slice(0, 4)
                                  }}</span> </span>

                            </span>
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                  <v-autocomplete
                      v-else
                      v-model="SelectedCustomer"
                      :disabled="Job.jobStatus === 3 || jobview"
                      :items="Customers"
                      :menu-props="{ bottom: true, offsetY: true }"
                      class="custom-input my-input-common-border"
                      dense
                      hide-details
                      item-text="fullName"
                      item-value="customerId"
                      label="Customer"
                      outlined
                      return-object
                      @change="selectCustomer"
                  >
                  </v-autocomplete>

                  <v-btn
                      :disabled="Job.jobStatus == 3 || jobview"
                      class="theme-color1"
                      depressed
                      height="40"
                      tile
                      @click="OpenCustomerDialog"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="pb-2">
              <v-row class="ps-5 pe-5">
                <v-col class="pa-0 d-flex" cols="12" md="6">
                  <input
                      :key="fileInputKey"
                      ref="uploader1"
                      :disabled="jobview"
                      class="custom-input my-input d-none"
                      name="workOder"
                      type="file"
                      @change="onFileChanged($event,'workOrder')"
                  />
                  <v-text-field
                      v-model="computedWorkOrder"
                      :disabled="jobview"
                      class="custom-input my-input2 work-order-input" dense
                      hide-details
                      label="Upload Work Order"
                      outlined
                      readonly
                      width="100%"
                  >
                    <template v-slot:append>
                      <i v-if="Job.workOrder !== ''" class="mgc_eye_line input-icon"
                         @click="ShowDocument(Job.workOrder)"
                      ></i>
                    </template>
                  </v-text-field>
                  <v-btn
                      v-if="!Job.workOrder"
                      :disabled="Job.jobStatus == 3 || jobview"
                      class="theme-color1"
                      depressed
                      height="40"
                      tile
                      @click="handleFileImport(1)"
                  >
                    <i class="mgc_file_upload_line btn-icon" style="font-size: 20px"></i>
                  </v-btn>
                  <v-btn
                      v-else
                      :disabled="Job.jobStatus == 3 || jobview"
                      class="lighten-4"
                      color="red"
                      depressed
                      height="40"
                      tile
                      @click="RemoveWorkOrder"
                  >
                    <i class="mgc_close_line" style="color: darkred"></i>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="ps-5 pe-5">
                <v-col class="pa-0 d-flex" cols="12" md="6">
                  <input
                      :key="fileInputKey"
                      ref="uploader2"
                      class="custom-input my-input d-none"
                      name="jobPdf"
                      type="file"
                      @change="onFileChanged($event, 'jobPdf')"
                  />
                  <v-text-field
                      v-model="computedJobCompletion"
                      :disabled="Job.jobStatus === 4 ||jobview"
                      class="custom-input my-input2 work-order-input"
                      dense
                      hide-details
                      label="Upload Job Completion Pdf"
                      outlined
                      readonly
                      width="100%"
                  >
                    <template v-slot:append>
                      <i v-if="Job.jobCompletionPdf !== ''" class="mgc_eye_line input-icon"
                         @click="viewPDF()"></i>
                    </template>
                  </v-text-field>
                  <v-btn
                      v-if="!Job.jobCompletionPdf"
                      :disabled="Job.jobStatus === 4 || jobview"
                      class="theme-color1"
                      depressed
                      height="40"
                      tile
                      @click="handleFileImport(2)"
                  >
                    <i class="mgc_upload_2_line" style="font-size: 22px"></i>
                    <!--                    <v-icon>mdi-file-upload-outline</v-icon>-->
                  </v-btn>
                  <v-btn
                      v-else
                      :disabled="Job.jobStatus == 3 || jobview"
                      class="lighten-4"
                      color="red"
                      depressed
                      height="40"
                      tile
                      @click="RemoveJobPdf"
                  >
                    <i class="mgc_close_line" style="color: darkred"></i>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="">
              <v-row class="ps-5 pe-5">
                <v-col class="pa-0" cols="12" md="6">
                  <v-textarea
                      v-model="Job.jobNote"
                      :disabled="jobview"
                      auto-grow
                      class="rounded-0 border-right"
                      color="#64718A"
                      hide-details
                      label="Description"
                      outlined
                      rows="3"
                  ></v-textarea>
                </v-col>
                <v-col class="pa-0" cols="12" md="6">
                  <v-text-field v-model="Job.swo" :disabled="jobview" class="custom-input my-input2 " color="#64718A  "
                                dense hide-details label="SWO #"
                                outlined required>
                  </v-text-field>
                  <v-text-field v-model="Job.taskId" :disabled="jobview" class="custom-input bordered" color="#64718A"
                                dense height="42" hide-details
                                label="Task Id" outlined required>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text>
              <v-divider></v-divider>
            </v-card-text>
            <v-card-text class="pb- pt-0">
              <v-row class="ps-5 pe-5 mt-0">
                <v-card elevation="0" outlined tile width="100%">
                  <v-toolbar class="tab-color" dense elevation="0" tile>
                    <v-toolbar-title class="font-weight-bold theme-font-color"
                    >Services & Equipments
                    </v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-card-text class="pb-0">
                    <v-row class="pt-0 mb-2">
                      <v-col cols="12" md="6">
                        <v-autocomplete
                            v-model="Service"
                            :disabled="jobview"
                            :items="Services"
                            :menu-props="{ bottom: true, offsetY: true }"
                            class="custom-input my-input-common-border"
                            clearable
                            color="#64718A"
                            dense
                            hide-details
                            item-text="title"
                            item-value="serviceId"
                            label="Services"
                            multiple
                            outlined
                            return-object
                            small-chips
                            @change="
                            (event) => (ServicesEquipments(10))
                          "
                        >
                          <template v-slot:item="data">
                            <template v-if="typeof data.item !== 'object'">
                              <v-list-item-content
                                  v-text="data.item"
                              ></v-list-item-content>
                            </template>
                            <template v-else>
                              <v-list-item-content>
                                <v-list-item-title
                                >{{ data.item.title }}
                                  <span
                                      v-if="!data.item.forWholesale"
                                      class="caption"
                                  >
                                    (Domestic)</span
                                  >
                                  <span
                                      v-if="data.item.forWholesale"
                                      class="caption"
                                  >
                                    (Wholesale /
                                    {{ data.item.wholesaleTitle }})</span
                                  >
                                </v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-autocomplete
                            v-model="Equipment"
                            :disabled="jobview"
                            :items="Equipments"
                            :menu-props="{ bottom: true, offsetY: true }"
                            class="custom-input my-input-common-border"
                            clearable
                            color="#64718A"
                            dense
                            hide-details
                            item-text="title"
                            item-value="equipmentId"
                            label="Equipments"
                            multiple
                            outlined
                            return-object
                            small-chips
                            @change="(event) => ServicesEquipments(20)"
                        >
                          <template v-slot:item="data">
                            <template v-if="typeof data.item !== 'object'">
                              <v-list-item-content
                                  v-text="data.item"
                              ></v-list-item-content>
                            </template>
                            <template v-else>
                              <v-list-item-content>
                                <v-list-item-title
                                >{{ data.item.title }}
                                  <span class="caption">
                                      ({{ data.item.details }})
                                    </span>
                                </v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </template>

                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row class="pb-2">
                      <v-col cols="12">
                        <v-data-table
                            :headers="headers"
                            :items="Job.jobItems"
                            :items-per-page="100"
                            class="custom-table elevation-0"
                            disable-sort
                            hide-default-footer
                        >
                          <template v-slot:[`item.sno`]="{ index }">
                            <span>{{ index + 1 }}</span>
                          </template>
                          <template v-slot:[`item.jobItemType`]="{ item }">
                            <span v-if="item.jobItemType === 10">Service</span>
                            <span v-if="item.jobItemType === 20">Equipment</span>
                          </template>
                          <template v-slot:[`item.price`]="{item}">
                            <input v-model.number="item.price" :disabled="jobview"
                                   class="text-right total-input"
                                   min="0"
                                   step=".01"
                                   type="number"
                                   @blur="ChangeAmount"
                                   @focus="selectText"
                                   @input="FormatPrice(item)"
                            >
                          </template>
                          <template v-slot:[`item.actions`]="{ item }">
                            <v-icon
                                :disabled="jobview"
                                color="red"
                                small
                                @click="deleteItem(item)"
                            >
                              mdi-trash-can-outline
                            </v-icon>
                          </template>
                        </v-data-table>
                        <v-divider class="mt-2"></v-divider>
                        <v-row class="mt-2 d-flex justify-end align-center">
                          <v-col cols="12" md="5">
                            <v-row>
                              <v-col class="d-flex justify-end">
                                  <span
                                      class="total-label text--primary text-right"
                                  >AMOUNT</span
                                  >
                              </v-col>
                              <v-col class="d-flex justify-end" cols="3">
                                <input
                                    id="discount_input"
                                    v-model.number="netAmountFormatted"
                                    class="total-input border-bottom-hide"
                                    disabled
                                    min="0"
                                    type="number"
                                    @blur="ChangeAmount"
                                    @focus="selectText"
                                />
                              </v-col>
                            </v-row>
                            <v-row class="align-baseline">
                              <v-col class="pt-0 d-flex justify-end">
                                  <span class="total-label text--primary"
                                  >OTHERS</span
                                  >
                              </v-col>
                              <v-col class="d-flex justify-end pt-0" cols="3">
                                <input
                                    v-model.number="otherChargesFormatted"
                                    :disabled="jobview"
                                    class="total-input" min="0" placeholder='0.00' step='0.01' type='number'
                                    value='0.00'
                                    @blur="ChangeAmount"
                                    @focus="selectText"
                                />
                              </v-col>
                            </v-row>
                            <v-row class="align-baseline">
                              <v-col class="pt-1 d-flex justify-end">
                                  <span class="total-label text--primary"
                                  >DISCOUNT</span
                                  >
                              </v-col>
                              <v-col class="d-flex justify-end pt-1" cols="3">
                                <input
                                    id="discount_input"
                                    v-model.number="discountFormatted"
                                    :disabled="jobview"
                                    class="total-input"
                                    min="0"
                                    type="number"
                                    @blur="ChangeAmount"
                                    @focus="selectText"
                                />
                              </v-col>
                            </v-row>
                            <!--                              <v-row>-->
                            <!--                                <v-col class="pt-0 d-flex justify-end">-->
                            <!--                                  <span class="total-label text&#45;&#45;primary"-->
                            <!--                                  >SUBTOTAL</span-->
                            <!--                                  >-->
                            <!--                                </v-col>-->
                            <!--                                <v-col cols="3" class="pt-0 d-flex justify-end">-->
                            <!--                                  <span-->
                            <!--                                      class="total-amount text&#45;&#45;primary me-4"-->
                            <!--                                  >{{ Job.subTotal }}</span-->
                            <!--                                  >-->
                            <!--                                </v-col>-->
                            <!--                              </v-row>-->
                            <v-row class="align-baseline">
                              <v-col class="pt-0 d-flex justify-end">
                                  <span class="total-label text--primary"
                                  >HST (%)</span
                                  >
                              </v-col>
                              <v-col class="d-flex justify-end pt-0" cols="3">
                                <input
                                    id="tax_input"
                                    v-model.number="hstFormatted"
                                    :disabled="jobview"
                                    class="total-input"
                                    min="0"
                                    type="number"
                                    @blur="ChangeAmount"
                                    @focus="selectText"
                                />
                              </v-col>
                            </v-row>
                            <v-row class="align-baseline">
                              <v-col class="pt-1 d-flex justify-end">
                                  <span
                                      class="total-label text--primary"
                                  >HST Amount</span
                                  >
                              </v-col>
                              <v-col class="d-flex justify-end" cols="3">
                                <input
                                    v-model.number="hstAmountFormatted"
                                    class="total-input border-bottom-hide"
                                    disabled
                                    min="0"
                                    type="number"
                                    @blur="ChangeAmount"
                                    @focus="selectText"
                                />
                                <!--                                  <span
                                                                      class="total-amount text&#45;&#45;primary me-4"
                                                                  >{{ Job.hstAmount }}</span
                                                                  >-->
                              </v-col>
                            </v-row>
                            <v-row class="align-baseline">
                              <v-col class="pt-1 d-flex justify-end">
                                  <span
                                      class="total-label text--primary font-weight-bold"
                                  >TOTAL</span
                                  >
                              </v-col>
                              <v-col class="d-flex justify-end" cols="3">

                                <!--                                  <span-->
                                <!--                                      class="total-amount text&#45;&#45;primary me-4 font-weight-bold"-->
                                <!--                                  >{{ Job.grandTotal }}</span-->
                                <!--                                  >-->
                                <input
                                    v-model.number="totalFormatted"
                                    class="total-input border-bottom-hide total-amount text--primary font-weight-bold"
                                    disabled
                                    min="0"
                                    type="number"
                                    @blur="ChangeAmount"
                                    @focus="selectText"
                                />
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-row>
            </v-card-text>
            <v-card-text class="pb-0 pt-0">
              <v-row class="ps-5 pe-5 mt-8">
                <v-card elevation="0" outlined tile width="100%">
                  <v-toolbar class="tab-color" dense elevation="0" tile>
                    <v-toolbar-title class="font-weight-bold theme-font-color"
                    >Assign Employees
                    </v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-card-text class="pt-0">
                    <v-row class="pt-2">
                      <v-col cols="12" md="4">
                        <v-autocomplete
                            v-model="JobEmployee"
                            :disabled="jobview"
                            :items="Workers"
                            :menu-props="{ bottom: true, offsetY: true}"
                            :search-input.sync="searchInput"
                            class="custom-input my-input-common-border"
                            color="#64718A"
                            dense
                            hide-details
                            item-text="fullName"
                            label="Employees"
                            multiple
                            outlined
                            return-object
                            @change="AddEmployee"
                        >
                          <template v-slot:item="data">
                            <template v-if="typeof data.item !== 'object'">
                              <v-list-item-content
                                  v-text="data.item"
                              ></v-list-item-content>
                            </template>
                            <template v-else>
                              <v-list-item-content>
                                <v-list-item-title
                                >{{ data.item.fullName }}
                                </v-list-item-title>
                              </v-list-item-content>

                            </template>
                          </template>

                        </v-autocomplete>
                      </v-col>
                      <!--                      <v-divider vertical></v-divider>
                                            <v-col cols="12" md="8">
                                              <div v-for="item in Job.jobEmployees" :key="item.JobId">
                                                <v-chip class="float-left mr-2" label small>{{
                                                    item.fullName
                                                  }}
                                                </v-chip>
                                              </div>
                                            </v-col>-->
                      <v-card-text class="pb-0">
                        <v-row class="pb-2">
                          <v-col cols="12">
                            <v-data-table
                                :headers="CompletedJobHeader"
                                :items="Job.jobEmployees"
                                :items-per-page="100"
                                class="custom-table elevation-0"
                                disable-sort
                                hide-default-footer
                            >
                              <template v-slot:[`item.sno`]="{ index }">
                                <span>{{ index + 1 }}</span>
                              </template>
                              <template v-slot:[`item.employee.hourlyRate`]="{ item }">
                                <v-text-field
                                    v-model.number="item.employee.hourlyRate"
                                    class="static-wage-field"
                                    dense
                                    disabled
                                    hide-details
                                    type="number"
                                    @blur="ChangeWage(item)"
                                    @focus="selectText"
                                ></v-text-field>
                              </template>
                              <template v-slot:[`item.totalHours`]="{ item }">
                                <v-text-field
                                    v-model.number="item.totalHours"
                                    :disabled="jobview"
                                    :rules="[rules.discount]"
                                    class="wage-field"
                                    dense
                                    hide-details
                                    type="number"
                                    @blur="ChangeWage(item)"
                                    @focus="selectText"
                                ></v-text-field>
                              </template>
                              <template v-slot:[`item.totalHourlyWage`]="{ item }">
                                <v-text-field
                                    v-model.number="item.totalHourlyWage"
                                    :rules="[rules.discount]"
                                    class="wage-field"
                                    dense
                                    disabled
                                    hide-details
                                ></v-text-field>
                              </template>
                              <template
                                  v-slot:[`item.totalTaskWage`]="{ item }"
                              >
                                <v-text-field
                                    v-model.number="item.totalTaskWage"
                                    :disabled="jobview"
                                    :rules="[rules.discount]"
                                    class="wage-field"
                                    dense
                                    hide-details
                                    type="number"
                                    @blur="ChangeWage(item)"
                                    @focus="selectText"
                                ></v-text-field>
                              </template>
                              <template
                                  v-slot:[`item.totalWage`]="{ item }"
                              >
                                <v-text-field
                                    v-model.number="item.totalWage"
                                    class="static-wage-field"
                                    dense
                                    disabled
                                    hide-details
                                    type="number"

                                ></v-text-field>
                              </template>
                              <!-- <template v-slot:[`item.totalHourlyWage`]="{ item }">
                                <v-text-field
                                  :disabled="jobview"
                                  v-model.number="item.totalHourlyWage"
                                  hide-details
                                  type="number"
                                  :rules="[rules.discount]"
                                  dense
                                  @blur="ChangeWage(item)"
                                ></v-text-field>
                              </template> -->
                              <template v-slot:[`item.visitType`]="{ item }">
                                <v-chip
                                    v-if="item.visitType == 10"
                                    class="type-chip"
                                    color="orange darken1"
                                    dark
                                    label
                                    x-small
                                >Assessment
                                </v-chip>
                                <v-chip
                                    v-if="item.visitType == 20"
                                    class="type-chip"
                                    color="purple"
                                    dark
                                    label
                                    x-small
                                >
                                  Job
                                </v-chip>
                              </template>
                              <template v-slot:[`item.action`]="{ item }">
                                <div class="d-flex justify-center align-center">
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                          class="me-1"
                                          color="primary"
                                          small
                                          v-bind="attrs"
                                          @click="TakeNotes(item.installerNotes),(ShowNotes = true)"
                                          v-on="on"
                                      >
                                        mdi-information
                                      </v-icon>
                                    </template>
                                    <span>Job Notes</span>
                                  </v-tooltip>

                                  <v-icon
                                      color="primary"
                                      small
                                      @click="
                                    takeImages(item.jobImages),
                                      (showGallery = true)
                                  "
                                  >
                                    mdi-image-outline
                                  </v-icon>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                          v-if="item.jobCompleted === true"
                                          :disabled="jobview"
                                          class="ms-1"
                                          color="success"
                                          small
                                          v-bind="attrs"
                                          @click="item.jobCompleted = false"
                                          v-on="on"
                                      >mdi-checkbox-marked
                                      </v-icon
                                      >
                                      <v-icon
                                          v-else
                                          :disabled="jobview"
                                          class="ms-1"
                                          color="gray"
                                          small
                                          v-bind="attrs"
                                          @click="item.jobCompleted = true"
                                          v-on="on"
                                      >mdi-checkbox-marked
                                      </v-icon
                                      >
                                    </template>
                                    <span v-if="item.jobCompleted == false">Mark Task Complete</span>
                                    <span v-else>UnMark Task Complete</span>
                                  </v-tooltip>
                                  <v-btn :disabled="jobview" class="pt-0" icon small
                                         @click="item.jobCompleted = false ,SendMail(item)">
                                    <i class="mgc_mail_send_line btn-icon email-btn-icon theme-font-color"></i>
                                  </v-btn>
                                  <v-btn :disabled="jobview" class="pt-0 red--text" icon small
                                         @click="RemoveEmployee(item)">
                                    <i class="mgc_delete_2_line btn-icon mb-1"
                                    ></i>
                                  </v-btn>
                                </div>

                              </template>

                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
    <!--      Lead Notes-->
    <v-dialog v-model="ShowNotes" max-width="700">
      <v-card class="pb-2">
        <v-card-title>
          <p class="title mb-0">{{ Job.title }}</p>
          <v-spacer></v-spacer>
          <v-icon color="red" @click="ShowNotes = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider class="mb-2"></v-divider>
        <v-card-text>
          <template>
            <v-row>
              <v-col>
                  <span>
                    {{ EmployeeLeadNotes }}
                  </span>
              </v-col>
            </v-row>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--    Gallery-->
    <v-dialog v-model="showGallery" max-width="700">
      <v-card class="pb-2">
        <v-card-title>
          <p class="title mb-0">{{ Job.title }}</p>
          <v-spacer></v-spacer>
          <v-icon color="red" @click="showGallery = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider class="mb-2"></v-divider>
        <v-card-text>
          <template>
            <v-row>
              <v-col
                  v-for="n in GalleryImages"
                  :key="n"
                  class="d-flex child-flex"
                  cols="4"
              >
                <v-sheet class="pa-1" elevation="1" outlined rounded>
                  <v-img
                      v-if="n != ''"
                      :lazy-src="`https://picsum.photos/10/6?image=`"
                      :src="n"
                      aspect-ratio="1"
                      class="grey lighten-2"
                      @click="showModal = true, imageUrl = n"
                  >
                    <template v-slot:placeholder>
                      <v-row
                          align="center"
                          class="fill-height ma-0"
                          justify="center"
                      >
                        <v-progress-circular
                            color="grey lighten-5"
                            indeterminate
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-sheet>
              </v-col>
              <span v-if="GalleryImages.length == 0" class="heading pa-2"
              >No image uploaded yet</span
              >
            </v-row>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div v-if="showModal" class="modal" @click="showModal = false">
      <img :src="imageUrl" alt="Sample Image" class="modal-image"/>
    </div>
    <!--      Add Customer-->
    <v-dialog v-model="addCustomerDialog" max-width="700">
      <v-card class="pb-2">
        <v-toolbar color="#efefef" dense elevation="0" flat tile>
          <v-btn
              class="mr-2 theme-color1"
              dark
              elevation="0"
              height="35px"
              tile
              @click="addCustomerDialog = false"
          >
            <v-icon dense>mdi-undo-variant</v-icon>
          </v-btn>
          <v-toolbar-title>
            <h4 class="text-uppercase theme-font-color">Add Customer</h4>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
              :disabled="!Customer.firstName || !Customer.lastName"
              class="ps-10 pe-10 login-btns theme-color1"
              dark
              elevation="0"
              tile
              @click="AddorUpdateCustomer(), (addCustomerDialog = false)"
          >Save
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="mt-5">
              <v-col class="pa-0" cols="12" md="6">
                <v-text-field
                    v-model="Customer.firstName"
                    :rules="[rules.required]"
                    class="custom-input my-input"
                    color="#64718A "
                    dense
                    hide-details
                    label="First Name"
                    outlined
                >
                </v-text-field>
              </v-col>
              <v-col class="pa-0" cols="12" md="6">
                <v-text-field
                    v-model="Customer.lastName"
                    :rules="[rules.required]"
                    class="custom-input my-input2"
                    color="#64718A "
                    dense
                    hide-details
                    label="Last Name"
                    outlined
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="">
              <v-col class="pa-0" cols="12" md="6">
                <v-text-field
                    v-model="Customer.email"
                    :rules="[rules.required]"
                    class="custom-input border-right"
                    color="#64718A "
                    dense
                    hide-details
                    label="Email"
                    outlined
                >
                </v-text-field>
              </v-col>
              <v-col class="pa-0" cols="12" md="6">
                <v-text-field
                    v-model="Customer.phoneNumber"
                    v-mask="['###-###-####']"
                    :rules="[rules.required]"
                    class="custom-input my-input-common-border"
                    color="#64718A "
                    dense
                    hide-details
                    label="Phone"
                    outlined
                    prefix="+1"

                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-10">
              <v-col class="pa-0" cols="12" md="6">
                <v-text-field
                    v-model="Customer.address"
                    class="custom-input my-input"
                    color="#64718A "
                    dense
                    hide-details
                    label="Address"
                    outlined
                >
                </v-text-field>
              </v-col>
              <v-col class="pa-0" cols="12" md="6">
                <v-text-field
                    v-model="Customer.city"
                    class="custom-input my-input2"
                    color="#64718A "
                    dense
                    hide-details
                    label="City"
                    outlined
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pa-0" cols="12" md="6">
                <v-text-field
                    v-model="Customer.postalCode"
                    class="custom-input border-right"
                    color="#64718A "
                    dense
                    hide-details
                    label="Postal Code"
                    outlined
                >
                </v-text-field>
              </v-col>
              <v-col class="pa-0" cols="12" md="6">
                <v-autocomplete
                    v-model="Customer.country"
                    :items="Countries"
                    :menu-props="{ bottom: true, offsetY: true }"
                    :return-object="false"
                    class="custom-input my-input-common-border"
                    dense
                    hide-details
                    item-text="name.common"
                    item-value="name.common"
                    label="Country"
                    outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="Job.isJob === true" class="ps-2">
              <v-col class="pa-0 d-flex align-center" cols="12" md="6">
                <v-switch
                    v-model="CustomerSwitch"
                    class="custom-switch"
                    color="success darken-2"
                    inset
                    @change="ChangeView(CustomerSwitch)"
                ></v-switch>
                <div>
                  <span
                      v-if="CustomerSwitch"
                      class="font-weight-bold d-block grey--text text--darken-2"
                  >Wholesale</span
                  >
                  <span
                      v-if="!CustomerSwitch"
                      class="font-weight-bold d-block grey--text text--darken-2"
                  >Domestic</span
                  >
                </div>
              </v-col>
              <v-col class="pa-0 mt-3" cols="12" md="6">
                <v-autocomplete
                    v-model="Customer.customerSubType"
                    :disabled="!CustomerSwitch"
                    :items="customersubtypes"
                    :menu-props="{ bottom: true, offsetY: true }"
                    :return-object="false"
                    class="custom-input bordered"
                    color="#64718A "
                    dense
                    hide-details
                    item-text="value"
                    item-value="value"
                    label="Customer Type"
                    outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-progress-circular
        v-if="loader"
        class="custom-loader"
        color="#64718A"
        indeterminate
        width="2"
    >
    </v-progress-circular>
  </div>
</template>
<script>
import axios from "axios";
import config from "../Configuration/config";
import moment from "moment";
import 'moment-timezone';

export default {
  name: "AddJob",
  data() {
    return {
      name: "Job",
      addCustomerDialog: false,
      EmployeeLeadNotes: "",
      showGallery: false,
      showModal: false,
      imageUrl: "",
      ShowNotes: false,
      pdfExtensions: ['.pdf'],
      imageExtensions: ['.jpg', '.jpeg', '.png', '.gif', '.bmp'],
      descriptionLimit: 60,
      searchInput: null,
      entries: [],
      isLoading: false,
      model: null,
      search: "",
      Print: false,
      Save: false,
      paymentDialg: false,
      CustomerSwitch: false,
      JobSave: false,
      Switch: false,
      switchControl: true,
      GalleryImages: [],
      dialog: false,
      loader: false,
      fileInputKey: Date.now(),
      loader2: false,
      jobview: false,
      rules: {
        tax: (value) => (value >= 0 && value <= 100) || "Invalid tax ",
        discount: (value) => value >= 0 || "Invalid Number",
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      TotalAmount: 0,
      JobEmployee: [],
      jobEmployeesHeader: [
        {
          text: "#",
          align: "left",
          value: "sno",
          width: "70px",
        },
        {
          text: "Name",
          align: "left",
          value: "fullName",
        },
        {
          text: "",
          value: "actions",
          width: "50px",
        },
      ],
      headers: [
        {
          text: "#",
          align: "left",
          value: "sno",
          width: "70px",
        },
        {
          text: "Title",
          align: "left",
          value: "title",
        },
        {
          text: "JobItem Type",
          value: "jobItemType"
        },
        {
          text: "Type",
          value: "customerType",
        },
        {
          text: "Amount",
          align: "right",
          value: "price",
          width: "100px",
        },
        {
          text: "",
          value: "actions",
          width: "50px",
        },
      ],
      CompletedJobHeader: [
        {
          text: "#",
          align: "left",
          value: "sno",
          width: "20px",
        },
        {
          text: "Name",
          align: "left",
          value: "fullName",
        },
        {
          text: "Visit",
          align: "center",
          value: "visitType",
        },
        {
          text: "Hourly Rate",
          align: "right",
          value: "employee.hourlyRate",
          width: "110px",
        },

        {
          text: "Worked Hours",
          align: "right",
          value: "totalHours",
          width: "120px",
        },
        {
          text: "Hourly Wage",
          align: "center",
          value: "totalHourlyWage",
          width: "120px",
        },
        {
          text: "Task Wage",
          align: "right",
          value: "totalTaskWage",
          width: "100px",
        },
        {
          text: "Net Wage",
          align: "right",
          value: "totalWage",
          width: "100px",
        },

        {
          text: "Actions",
          value: "action",
          width: "150px",
          align: "center",
        },
      ],
      Service: [],
      Equipment: [],
      editedItem: {},
      worker: 0,
      requestDateMenu: false,
      customerdatemenu: false,
      SelectedCustomer: null,
      btnText: "Add Job",
      valid: true,
      Customers: [],
      Services: [],

      customersubtypes: [],
      Customer: {
        companyId: axios.defaults.headers.common.CompanyId,
        customerId: 0,
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        address: "",
        city: "",
        province: "",
        postalCode: "",
        customerSubType: "",
        country: "",
        notes: "",
        customerCategory: 100,
        taskId: "",
        swo: "",
      },
      Countries: [],
      customerTypes: null,
      Job: {
        companyId: axios.defaults.headers.common.CompanyId,
        jobId: 0,
        title: "",
        markAsQoute: false,
        isApproved: false,
        jobType: 0,
        jobStatus: 0,
        jobNote: "",
        isLead: false,
        isQuote: false,
        isJob: true,
        isInvoice: false,
        leadStatus: 0,
        quoteStatus: 0,
        customerId: 0,
        quoteApproved: false,
        quoteCancelled: false,
        leadClosed: false,
        leadDescription: "",
        quoteDescription: "",
        taskId: "",
        cancellationReason: 0,
        swo: "",
        termsOfJob: "",
        isPaid: false,
        paymentMethod: 0,
        discount: 0,
        hst: 13,
        hstAmount: 0,
        otherCharges: 0,
        paidAmount: 0,
        dueAmount: 0,
        netAmount: 0,
        grandTotal: 0,
        workOrder: "",
        jobCompletionPdf: "",
        jobItems: [],
        jobEmployees: [],
      },
      ServicesWorkers: [],
      Equipments: [],
      Workers: null,
      Filter: {
        CompanyId: axios.defaults.headers.common.CompanyId,
        page: 1,
        pageSize: 10,
        pagination: null,
        pageNumOnSearch: "",
        filterText: "",
        forWholesale: false,
      },
      AssignedEmployees: [],
      /* Company: sessionStorage.getItem("Company"),
       Address: sessionStorage.getItem("CompanyAddress"),
       Address2: sessionStorage.getItem("CompanyAddress2"),
       CompanyTel: sessionStorage.getItem("CompanyTel"),
       HST: sessionStorage.getItem("HST"),
 */
      //File Upload
      selectedWorkOrder: {
        name: "",
      },
      isSelecting: false,
      netTax: 13,
      netDiscount: 0,
      netCharges: 0,
      decimals: 2,
    };
  },
  computed: {
    computedWorkOrder() {

      if (this.Job.workOrder != "") {
        if (this.Job.workOrder != null) {

          let saf = this.Job.workOrder.substring(this.Job.workOrder.lastIndexOf("_") + 1);
          return saf.replaceAll(/%20/g, " ");
        }
      }
      return "";
    },
    computedJobCompletion() {
      if (this.Job.jobCompletionPdf != "") {
        if (this.Job.jobCompletionPdf != null) {
          let saf = this.Job.jobCompletionPdf.substring(this.Job.jobCompletionPdf.lastIndexOf("_") + 1);
          return saf.replaceAll(/%20/g, " ");
        }
      }
      return "";
    },

    netAmountFormatted: {
      get() {
        return this.Job.netAmount.toFixed(2);
      },
      set(value) {
        this.Job.netAmount = parseFloat(value);
      }
    },
    otherChargesFormatted: {
      get() {
        return this.Job.otherCharges.toFixed(2);
      },
      set(value) {
        this.Job.otherCharges = parseFloat(value);
      }
    },
    discountFormatted: {
      get() {
        return this.Job.discount.toFixed(2);
      },
      set(value) {
        this.Job.discount = parseFloat(value);
      }
    },
    hstFormatted: {
      get() {
        return this.Job.hst.toFixed(2);
      },
      set(value) {
        this.Job.hst = parseFloat(value);
      }
    },
    hstAmountFormatted: {
      get() {
        return this.Job.hstAmount.toFixed(2);
      },
      set(value) {
        this.Job.hstAmount = parseFloat(value);
      }
    },
    totalFormatted: {
      get() {
        return this.Job.grandTotal.toFixed(2);
      },
      set(value) {
        this.Job.grandTotal = parseFloat(value);
      }
    },
  },
  methods: {
    FormatCurrency(num) {
      return num.toFixed(2);
    },
    AddEmployee() {
      let employee = {
        employeId: this.JobEmployee[0].employeId,
        employee: this.JobEmployee[0],
        services: this.JobEmployee[0].services,
        fullName: this.JobEmployee[0].fullName,
        hourlyRate: this.JobEmployee[0].hourlyRate,
        totalHourlyWage: 0,
        visitType: 20,
        totalTaskWage: 0,
        totalWage: 0,
        totalHours: 0,
        jobCompleted: false,
        jobImages: "",
        remarks: "",
        installerNotes: "",
        id: 0,
      }
      if (!this.Job.jobEmployees.some(obj => obj.employeId == employee.employeId && obj.visitType == employee.visitType)) {
        this.Job.jobEmployees.push(employee);
      }
      this.JobEmployee = [];
    },
    FormatPrice(item) {
      //var index = this.Job.jobItems.indexOf(item);
      //item.amount.toFixed(2);
      item.price = Math.round(item.price * 100) / 100;
    },
    selectText(event) {
      event.target.select();
    },
    TakeNotes(InstallerNotes) {
      this.EmployeeLeadNotes = InstallerNotes;
    },
    viewPDF() {
      const pdfUrl = this.Job.jobCompletionPdf;
      window.open(pdfUrl, '_blank'); // open the PDF in a new tab or window
    },
    ShowDocument(link) {
      if (this.imageExtensions.some(ext => link.endsWith(ext))) {
        let myWindow = window.open("", "_blank");
        const img = document.createElement("img");
        img.src = link;
        myWindow.document.body.appendChild(img);
      } else {
        //window.open(link, "_blank");
        const viewerUrl = `https://docs.google.com/viewerng/viewer?url=${encodeURIComponent(link)}&embedded=true`;
        window.open(viewerUrl, '_blank');
      }
    },

    ChangeAmount() {
      if (isNaN(this.Job.otherCharges) || this.Job.otherCharges < 0) {
        this.Job.otherCharges = 0
      }
      if (isNaN(this.Job.discount) || this.Job.discount < 0) {
        this.Job.discount = 0
      }
      if (isNaN(this.Job.hst) || this.Job.hst < 0) {
        this.Job.hst = 0
      }
      if (this.Job.jobItems.length < 1) {
        this.Job.netAmount = 0;
        this.Job.discount = 0;
        this.Job.hst = 13;
        this.Job.otherCharges = 0;
        this.Job.grandTotal = 0;
        return;
      }
      this.Job.netAmount = this.Job.jobItems.reduce(
          (acc, o) => acc + o.price,
          0
      );
      if (this.Job.jobItems.length > 0) {
        this.Job.jobItems.forEach(element => {
          if (element.price == "") {
            element.price = 0;
          }
        })
      }

      if (this.Job.discount > this.Job.netAmount) {
        this.Job.discount = this.Job.netAmount;
      }
      let subTotal = this.Job.netAmount + this.Job.otherCharges - this.Job.discount;

      this.Job.grandTotal = subTotal;
      if (this.Job.hst > 100) {
        this.Job.hst = 100;
        var element = document.getElementById("tax_input");
        element.classList.add("taxInput");
      } else if (this.Job.hst < 0) {
        this.Job.hst = 13;
        var element2 = document.getElementById("tax_input");
        element2.classList.add("taxInput");
      }
      if (this.Job.hst > 0 || this.Job.hst <= 100) {
        this.Job.hstAmount = (this.Job.hst / 100) * subTotal;
        this.Job.grandTotal = this.Job.hstAmount + subTotal;
      }
    },
    customSearch(item, queryText) {
      return (
          item.fullName
              .toLocaleLowerCase()
              .indexOf(queryText.toLocaleLowerCase()) > -1 ||
          item.email.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
          item.phoneNumber
              .toLocaleLowerCase()
              .indexOf(queryText.toLocaleLowerCase()) > -1 ||
          item.address
              .toLocaleLowerCase()
              .indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },

    takeImages(jobImages) {
      this.GalleryImages = [];
      if (jobImages != null) {
        const imageurl = jobImages.split(/\s*,\s*/);
        if (!this.GalleryImages.includes(...imageurl)) {
          this.GalleryImages.push(...imageurl);
        }
      }
    },
    OpenCustomerDialog() {
      this.addCustomerDialog = true;
      setTimeout(this.validate, 200);
    },
    RemoveEmployee(item) {
      var index = this.Job.jobEmployees.indexOf(item);
      this.Job.jobEmployees.splice(index, 1);
    },
    async RemoveWorkOrder() {
      try {
        this.loader = true;
        const url = this.Job.workOrder;
        const docsIndex = url.indexOf('docs/');
        const blobName = url.substring(docsIndex + 5);
        let fd = new FormData();
        fd.append("blobName", blobName);
        let response = await axios({
          method: "Post",
          url: config.apiBaseUrl + "/api/Job/RemoveBlob",
          data: fd,
          headers: {
            'Content-Type': "multiform/form-data",
            'Accept': 'application/json'
          }
        });
        console.log(response);
        this.loader = false;
        this.Job.workOrder = "";
      } catch (e) {
        this.loader = false;
        console.log(e)
      }
    },
    async RemoveJobPdf() {
      try {
        this.loader = true;
        const url = this.Job.jobCompletionPdf;
        const docsIndex = url.indexOf('docs/');
        const blobName = url.substring(docsIndex + 5);
        let fd = new FormData();
        fd.append("blobName", blobName);
        let response = await axios({
          method: "Post",
          url: config.apiBaseUrl + "/api/Job/RemoveBlob",
          data: fd,
          headers: {
            'Content-Type': "multiform/form-data",
            'Accept': 'application/json'
          }
        });
        this.loader = false;
        console.log(response);
        this.Job.jobCompletionPdf = "";
      } catch (e) {
        this.loader = false;
        console.log(e)
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    handleFileImport(type) {
      this.isSelecting = true;
      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener(
          "focus",
          () => {
            this.isSelecting = false;
          },
          {once: true}
      );
      switch (type) {
        case 1:
          this.$refs.uploader1.click();
          break;
        case 2:
          this.$refs.uploader2.click();
          break;
      }

    },
    async onFileChanged(e, type) {
      this.fileInputKey = Date.now();
      this.loader = true;
      this.selectedWorkOrder = "";
      this.selectedWorkOrder = e.target.files[0];
      // const allowedWorkOrderTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];

      switch (type) {
        case "jobPdf":
          if (this.selectedWorkOrder.type !== 'application/pdf') {
            this.ShowWarningToast("Invalid file type. Please select a Pdf file.");
            this.loader = false;
            return;
          }
          break;
      }
      try {
        const fd = new FormData();
        fd.append("file", this.selectedWorkOrder);
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Job/UploadDocument/UploadDocument",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        switch (type) {
          case "jobPdf":
            this.Job.jobCompletionPdf = response.data;

            break;
          case "workOrder":
            this.Job.workOrder = response.data;
            break;
        }
        if (response.status == 200) {
          this.ShowSuccessToast("File uploaded successfully!");
        } else if (response.status == 204) {
          this.ShowWarningToast("File upload failed!");
        }
        this.loader = false;
      } catch (error) {
        this.loader = false;
        console.log(error);
      }
    },
    ChangeWage(item) {
      var index = this.Job.jobEmployees.indexOf(item);
      if (!isNaN(item.totalHours)) {
        if (item.totalHours > 0) {
          item.totalWage =
              item.totalHours * this.Job.jobEmployees[index].employee.hourlyRate;
          item.totalHourlyWage = item.totalWage;
        }
      }
      if (!isNaN(item.totalTaskWage)) {
        if (item.totalTaskWage > 0) {
          item.totalWage = item.totalWage + item.totalTaskWage;
        }
      }
    },
    ChangePrice(item) {
      const index = this.Job.jobItems.indexOf(item);
      if (!isNaN(item.price)) {
        item.amount = item.price;
      }
      if (!isNaN(item.discount)) {
        if (item.discount > 0) {
          item.amount = item.price - item.discount;
        } else {
          item.discount = 0;
        }
      }
      if (!isNaN(item.taxRate)) {
        if (item.taxRate >= 0 && item.taxRate <= 100) {
          item.taxAmount = (item.taxRate / 100) * item.amount;
          item.amount += item.taxAmount;
        } else {
          item.taxRate = 0;
        }
      }
      this.Job.jobItems.splice(index, 1, item);
      this.Job.grandTotal = 0;
      this.Job.jobItems.forEach((item) => {
        this.Job.grandTotal += item.amount;
      });
    },
    ServicesEquipments(type) {
      var index = this.Service.length - 1;
      var equipmentindex = this.Equipment.length - 1;
      if (index > -1) {
        if (type == 10) {
          if (this.Service[0].wholesaleTitle === "") {
            this.Service[0].wholesaleTitle = "Domestic"
          }
          if (!this.Job.jobItems.some(service => service.title === this.Service[0].title && service.customerType === this.Service[0].wholesaleTitle)) {
            this.Job.jobItems.push(this.Service[0])
            var service = this.Job.jobItems.find(({serviceId}) => serviceId === this.Service[0].serviceId);
            service.jobItemType = 10;
            if (this.Service[0].wholesaleTitle !== "") {
              service.customerType = this.Service[0].wholesaleTitle
            } else {
              service.customerType = "Domestic"
            }
          }
          this.Service = [];
          //this.Job.jobItems[index].customerType  = this.Job.jobItems[index].wholesaleTitle;
        }
      }
      if (equipmentindex > -1) {
        if (type == 20) {
          if (!this.Job.jobItems.some(equipment => equipment.title === this.Equipment[0].title && equipment.details === this.Equipment[0].details)) {
            this.Job.jobItems.push(this.Equipment[0])
            var equipment = this.Job.jobItems.find(({equipmentId}) => equipmentId === this.Equipment[0].equipmentId);
            equipment.jobItemType = 20;
            equipment.customerType = this.Equipment[0].details
          }
          this.Equipment = [];
        }
      }
      this.ChangeAmount();
      this.GetSpecificEmployees();
    },
    ChangeView(type) {
      if (!type) {
        this.Customer.customerCategory = 100
        this.Customer.swo = "";
        this.Customer.taskId = "";
        this.Customer.customerSubType = "";
      } else {
        this.Customer.customerCategory = 120;
        this.Customer.customerSubType = this.customersubtypes[0].value;
      }
    }
    ,
    formatPhoneNumber() {
      this.Customer.phoneNumber = this.Customer.phoneNumber
          .replace(/[^0-9]/g, "")
          .replace(/^(\d{3})(\d{3})(\d{4})/g, "$1-$2-$3")
          .substring(0, 12);
    }
    ,
    async GetEquipments() {
      if (this.$route.name == "AddJob") {
        try {
          this.loader = true;
          let response = await axios.post(
              config.apiBaseUrl + "/api/Equipment/GetAll",
              {
                pageSize: this.Filter.pageSize,
                page: this.Filter.page,
                filterText: this.Filter.filterText,
                isActive: true,
              }
          );
          this.Equipments = response.data.data;
          this.loader = false;
        } catch (err) {
          console.log(err);
        }
      }
    }
    ,
    async GetCountries() {
      let response = await axios.get("https://restcountries.com/v3.1/all");
      this.Countries = response.data;
      this.Countries = response.data;
      var canada = this.Countries.filter((x) => x.name.common == "Canada");
      this.Customer.country = canada[0].name.common;
    }
    ,
    getFormattedDate(date) {
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get the user's timezone
      return moment.utc(date).tz(userTimezone).format('MMM DD, yyyy');
      //return moment(date).format("MMM DD, YYYY");
    }
    ,
    selectCustomer() {
      this.Job.customerId = this.SelectedCustomer.customerId;
      if (this.SelectedCustomer.customerCategory === 100) {
        this.Filter.forDomestic = true;
        this.Filter.forWholesale = false;
        this.Filter.filterText = "";
      } else {
        this.Filter.forDomestic = false;
        this.Filter.forWholesale = true;
        this.Filter.filterText = "";
      }
      this.GetServices();
    }
    ,
    async CloseJob() {
      try {
        if (this.Job.jobStatus != 3) {
          this.$toast("Incomplete job cannot be closed", {
            position: "top-center",
            timeout: 3000,
            closeOnClick: true,
            pauseOnfocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
          });
          return;
        }
        this.loader = true;
        const fd = new FormData();
        fd.append("jobId", this.Job.jobId);
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Job/CloseJob",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        this.loader = false;
        console.log(response);
        this.$router.push("/jobs");
      } catch (error) {
        this.loader = false;
        this.ShowWarningToast(error.message);
      }
    }
    ,
    AddService(item) {
      this.Job.jobItems.push(item);
    }
    ,
    async GetCustomerType() {
      try {
        this.loader = true;
        const fd = new FormData();
        fd.append("type", 30);
        let response = await axios.get(config.apiBaseUrl + "/api/KeyValue/All");
        this.customersubtypes = response.data.filter((x) => x.type == 30);
        this.loader = false;
      } catch (error) {
        console.log(error);
        this.loader = false;
      }
    }
    ,
    async AddorUpdateCustomer() {
      try {
        if (this.CustomerSwitch) {
          this.Customer.customerCategory = 110;
        } else {
          this.Customer.customerCategory = 100;
        }

        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Customer/AddOrUpdate",
          data: this.Customer,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        this.SelectedCustomer = response.data.data;
        this.Job.customerId = this.SelectedCustomer.customerId;
        this.loader = false;
        this.search = this.SelectedCustomer.fullName;
        this.selectCustomer();
      } catch (error) {
        this.loader = false;
        this.ShowWarningToast(error.message);
      }
    }
    ,
    GetServices() {
      try {
        let URL = "";
        /*if (type === "All") {
          URL = "/api/Service/All"
        } else {*/
        URL = "/api/Service/GetServicesByCustomer"
        // }
        this.loader = true;
        axios({
          method: "POST",
          url: config.apiBaseUrl + URL,
          data: this.Filter,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }).then((res) => {
          this.Services = res.data.data;
          this.GetSpecificEmployees();
        })

        this.loader = false;
      } catch (error) {
        this.loader = false;
      }
    }
    ,
    async GetWorkers() {
      try {
        this.loader2 = true;
        const fd = new FormData();
        fd.append("serviceId", this.editedItem.serviceId);
        let response = await axios({
          method: "POST",
          url: config.apiBaseUrl + "/api/Employee/GetWorkers",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        this.ServicesWorkers = response.data;
        this.loader2 = false;
      } catch (error) {
        this.loader = false;
      }
    },
    compareArrays(arr1, arr2) {
      return (      // Compare each object's property values one-by-one for equality
          arr2.every((obj) => arr1.some((o) => obj.title == o.title))
      );
    },
    ShowWarningToast(message) {
      try {
        this.$toast.warning(message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      } catch (e) {
        console.log(e)
      }
    },
    ShowSuccessToast(message) {
      try {
        this.$toast.success(message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      } catch (e) {
        console.log(e)
      }
    },
    async AddorUpdate() {
      try {
        let ReadyToSave = false;
        let jobservices = this.Job.jobItems.filter((job) => job.jobItemType == 10)

        if (this.Job.jobEmployees.length > 0) {

          this.Job.jobEmployees.forEach(employee => {

            ReadyToSave = this.compareArrays(employee.employee.services, jobservices);

          })
        } else {
          ReadyToSave = true;
        }
        if (!ReadyToSave) {
          this.ShowWarningToast('The selected employees cannot perform all of the selected tasks!');
          return;
        }
        // eslint-disable-next-line no-unreachable
        this.loader = true;
        this.Job.jobItems.forEach((element) => {
          if (element.taxRate == null && element.discount == null) {
            element.amount = element.price;
          }
          element.service = null;
        });
        // eslint-disable-next-line no-unreachable
        // eslint-disable-next-line no-unreachable
        let response = await axios({
          method: "POST",
          url: config.apiBaseUrl + "/api/Job/AddOrUpdateJob",
          data: this.Job,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        console.log(response);
        this.$router.push("/jobs");
        this.loader = false;
      } catch (error) {
        this.loader = false;
        console.log(error.message)
      }
    }
    ,
    async SendMail(employee) {
      try {
        this.loader = true;
        var fd = new FormData();
        fd.append("employeeId", employee.employeId);
        fd.append("jobId", this.Job.jobId);
        let response = await axios({
          method: 'post',
          url: config.apiBaseUrl + "/api/Job/SendEmployeeMail",
          data: fd,
          headers: {
            "Content-Type": "mutlipart/formdata",
            Accept: "application/json",
          }
        });
        if (response.data == true) {
          this.ShowSuccessToast("Mail Sent successfully");
        } else {
          this.ShowWarningToast("Mail sending failed!.");
        }
        this.loader = false;
      } catch (error) {
        this.loader = false;
        console.log(error);
        this.ShowWarningToast("Mail sending failed!.");

      }
    }
    ,
    async GetSpecificEmployees() {
      try {
        while (this.Services.length === 0) {
          await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second before checking again
        }
        this.loader = true;
        const fd = new FormData();
        if (this.Job.jobItems.length > 0) {
          this.Services.forEach((element) => {
            if (!element.forWholesale) {
              element.wholesaleTitle = "Domestic"
            }
          })

          this.Job.jobItems.forEach(element => {
            if (element.jobItemType == 10) {
              this.Services.forEach(el => {
                if (el.title == element.title && el.wholesaleTitle == element.customerType)
                  fd.append("services", el.serviceId);
              })
            }
          })
        }

        let response = await axios({
          method: "POST",
          url: config.apiBaseUrl + "/api/Employee/GetAllByServices",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        this.Workers = response.data;
        this.loader = false;
      } catch (err) {
        this.loader = false;
      }
    }
    ,
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    }
    ,
    deleteItem(item) {
      var index = this.Job.jobItems.indexOf(item);
      this.Job.jobItems.splice(index, 1);
      this.ChangeAmount();
      this.GetSpecificEmployees();
    }
    ,
    save() {
      if (this.Job.jobItems.length == 0) {
        this.Job.hst = 13;
        this.Job.netAmount = 0;
        this.Job.grandTotal = 0;
        this.Job.discount = 0;
        return;
      }
      this.Job.jobItems.push(this.editedItem);
      this.close();
    }
    ,
    async GetAssignedEmployees() {
      try {
        this.loader = true;
        const fd = new FormData();
        fd.append("jobId", this.Job.jobId);
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Job/GetAssignedEmployees",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        this.AssignedEmployees = response.data;
        this.loader === false;
      } catch (error) {
        this.loader = false;

      }
    }
    ,
    /*GettingJob() {
      if (this.$route.params.item !== undefined) {
        this.Job = this.$route.params.item;
        this.SelectedCustomer = this.Job.customer;
        this.Customers.push(this.Job.customer);
        this.selectCustomer();

      }
    },*/
    async GetJob() {
      if (this.$route.params.item !== undefined) {
        this.Job = this.$route.params.item;
        this.Job.netAmount = 0;
        if (this.$route.params.editView !== undefined) {
          this.jobview = true;
        } else {
          this.jobview = false;
        }
        this.loader = true;

        try {
          const fd = new FormData();
          fd.append("jobId", this.Job.jobId);
          let response = await axios({
            method: "post",
            url: config.apiBaseUrl + "/api/Job/Get",
            data: fd,
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
            },
          });
          this.Job = response.data.job;
          this.SelectedCustomer = this.Job.customer;
          //this.GetAssignedEmployees();
          this.Customers.push(this.Job.customer);
          this.GetEquipments();
          this.ChangeAmount();
          this.selectCustomer();
          this.GetSpecificEmployees();
        } catch (error) {
          this.loader = false;
          this.ShowWarningToast(error.message);
        }
      }
    }
    ,
  }
  ,
  watch: {
    async search(val) {
      if (this.isLoading) return;
      if (val == "" || val == null) {
        return;
      }
      this.isLoading = true;
      // Lazily load input items
      this.loader = true;
      this.Filter.filterText = val;
      let response = await axios({
        method: "POST",
        url: config.apiBaseUrl + "/api/Customer/GetCustomers",
        data: this.Filter,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      this.Customers = response.data.data;
      this.loader = false;
      this.isLoading = false;
      if (val == "") {
        this.Customers = [];
      }
    }
    ,
  }
  ,
  created() {
    this.$store.state.DisableCompany = true;
    if (this.$route.params.item !== undefined) {
      //this.GettingJob();
      this.GetJob();
    } else {
      this.GetEquipments();
      this.GetCountries();
      this.GetCustomerType();
    }
    this.$root.$on("myEvent", () => {
      this.GetEquipments();
      this.GetCountries();
      this.GetCustomerType();
      this.GetSpecificEmployees();
    });
  }
  ,
}
;
</script>
<style lang="scss" scoped>
.type-chip {
  width: 100px !important;
  justify-content: center !important;
}

.v-btn-toggle .v-btn.v-btn.v-size--default {
  min-width: 25px;
  min-height: 0;
}

.nav-icon img {
  width: 16px !important;
}

.menu-list.v-list-item {
  height: 30px;
  padding-right: 8px !important;
  padding-left: 8px !important;
}

.menu-list {
  padding: 0px !important;
  box-shadow: none;
}

.total-label {
  font-size: 15px;
  margin-left: 80px;
}

.total-amount {
  font-size: 15px;
}

.wage-field {
  text-align: center !important;
}

.static-wage-field.v-text-field fieldset, .v-text-field .v-input__control {
  color: currentColor;
  text-align: center;
  border: 0px !important;
}

input[name="workOder"] {
  width: 100%;
}

.total-label {
  font-size: 15px;
  margin-left: 80px;
}

.total-amount {
  font-size: 15px;
}

.wage-field input {
  text-align: right !important;
}

.total-input {
  border-bottom: 1px solid grey;
  width: 75px !important;
  font-size: 15px !important;
  text-align: right;
}

.total-input:focus {
  outline: none !important;
}

.taxInput {
  border: 1px solid red;
}

.work-order-input {
  font-size: 11px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999 !important;
}

.modal-image {
  max-width: 90%;
  max-height: 90%;
}


</style>