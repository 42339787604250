<template>
  <div id="my-container" class="theme-color1">
    <v-container class="pa-0" fluid>
      <v-row align="center" class="login-container" justify="center">
        <v-col cols="12" md="4">
          <v-card class="d-flex flex-column p-4" elevation="1">
            <h2 class="text-center theme-font-color text-uppercase">Tracking Buddy</h2>
            <h4 class="text-center grey--text mb-2 font-weight-regular">Forgot Password?</h4>
            <span class="mb-5">Just provide us your email address..</span>
            <v-text-field id="email" v-model="email" v-model.lazy="email" class="custom-input" color="#64718A" label="Email Address"
                          name="email" outlined prepend-inner-icon="mdi-email"></v-text-field>
            <!-- :rules="[rules.required, rules.email]" -->


            <div class="d-flex align-center justify-center">
              <v-btn icon link to='/'>
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn :disabled="!email" class="login-btns theme-color1" height="45" tile width="25%"
                     @click="ResetPassword(), (loader = true)">
                Reset
              </v-btn>
            </div>
          </v-card>
        </v-col>

      </v-row>

      <v-progress-circular v-if="loader" :size="30" :width="2" class="login-loader" color="#64718A"
                           indeterminate></v-progress-circular>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import config from "../Configuration/config.js"

export default {
  name: "ForgotPassword",

  data: () => ({
    title: "Preliminary report",
    email: "",
    loader: false,
  }),
  methods: {
    async ResetPassword() {
      const fd = new FormData();
      fd.append("email", this.email);
      let response = await axios({
        method: "post",
        url: config.apiBaseUrl + "/api/User/ResetPassword",
        data: fd,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      });
      console.log(response);
      this.loader = false;
      if (response.data.isSuccessful === true) {
        this.$toast.success(response.data.msg + "to your email", {
          position: "top-center",
          timeout: 1041,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        })
        this.$router.push('/')
      }
    }
  },
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
  font-family: 'Roboto', sans-serif;
}

.v-card {
  padding: 20px;
  padding-top: 30px;
  padding-bottom: 25px;
}

.login-btns {
  color: #fff;
}

.v-application a {
  margin-left: 0px;
  text-decoration: none;
  text-transform: initial;
  font-weight: 500;
  font-size: 13px;
}

.login-container {
  height: 100vh;
}

.login-loader {
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
}
</style>
