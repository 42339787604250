import Vue from 'vue'
import VueRouter from 'vue-router'
import WorkersModule from '../components/Workers/WorkersModule.vue'
import AddWorker from '../components/Workers/AddWorker.vue'
import CustomerModule from '../components/Customers/CustomerModule.vue'
import AddCustomer from '../components/Customers/AddCustomer.vue'
import ServicesModule from '../components/Services/ServicesModule.vue'
import LeadModule from '../components/Leads/LeadModule.vue'
import AddLead from '../components/Leads/AddLead.vue'
import JobModule from '../components/Jobs/JobModule.vue'
import AddJob from '../components/Jobs/AddJob.vue'
import QoutationsModule from '../components/Qoutation/QoutationsModule.vue'
import AddQuotation from '../components/Qoutation/AddQuotation.vue'
import Expenses from '..//components/Accounting/Expenses.vue'
import InvoicesModule from '..//components/Accounting/Invoice/InvoicesModule.vue'
import InvoiceDetails from '..//components/Accounting/Invoice/InvoiceDeatils.vue'
import SettingsComp from '..//components/Settings/SettingsComp.vue'
import DashboardComp from '..//components/Dashboards/DashboardComp.vue'
import LoginPage from '..//components/Navigation/LoginPage.vue'
import ForgotPassword from '..//components/User/ForgotPassword.vue'
import PageNotFound from '..//components/Navigation/PageNotFound.vue'
import AlertComp from '..//components/Notifications/AlertComp';
import MobileComp from "@/components/Mobile/MobileComp";
import JobDetails from "@/components/Mobile/JobDetails";
import EquipmentComp from "@/components/Equipments/EquipmentComp";
import InventoryModule from "@/components/Equipments/InventoryModule";
import ReportsComp from "@/components/Reports/ReportsComp";
import {checkAuthentication} from "../components/Utiliy/Authenticate";

Vue.use(VueRouter)

const routes = [
    {
        path: '/404',
        name: 'PageNotFound',
        component: PageNotFound
    },
    {
        path: "*/",
        name: 'redirectPageNotFound',
        redirect: '/404'
    },
    {
        path: '/',
        name: 'LoginPage',
        component: LoginPage
    },
    {
        path: '/forgotpassword',
        name: 'ForgotPassword',
        component: ForgotPassword
    },
    {
        path: '/dashboard',
        name: 'DashboardComp',
        component: DashboardComp,
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            const isAuthenticated = checkAuthentication(); // Implement your own authentication check

            if (isAuthenticated) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: '/settings',
        name: 'SettingsComp',
        component: SettingsComp,
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            const isAuthenticated = checkAuthentication(); // Implement your own authentication check

            if (isAuthenticated) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: '/invoices',
        name: 'InvoicesModule',
        component: InvoicesModule,
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            const isAuthenticated = checkAuthentication(); // Implement your own authentication check

            if (isAuthenticated) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    }, {
        path: '/invoice',
        name: 'InvoiceDetails',
        component: InvoiceDetails,
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            const isAuthenticated = checkAuthentication(); // Implement your own authentication check

            if (isAuthenticated) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: '/expenses',
        name: 'ExpensesModule',
        component: Expenses,
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            const isAuthenticated = checkAuthentication(); // Implement your own authentication check

            if (isAuthenticated) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: '/qoutations',
        name: 'QoutationsModule',
        component: QoutationsModule,
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            const isAuthenticated = checkAuthentication(); // Implement your own authentication check

            if (isAuthenticated) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: '/jobs',
        name: 'JobModule',
        component: JobModule,
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            const isAuthenticated = checkAuthentication(); // Implement your own authentication check

            if (isAuthenticated) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: '/leads',
        name: 'LeadModule',
        component: LeadModule,
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            const isAuthenticated = checkAuthentication(); // Implement your own authentication check

            if (isAuthenticated) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: '/addlead',
        name: 'AddLead',
        component: AddLead,
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            const isAuthenticated = checkAuthentication(); // Implement your own authentication check

            if (isAuthenticated) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: '/addquotation',
        name: 'AddQuotation',
        component: AddQuotation,
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            const isAuthenticated = checkAuthentication(); // Implement your own authentication check

            if (isAuthenticated) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: '/job',
        name: 'AddJob',
        component: AddJob,
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            const isAuthenticated = checkAuthentication(); // Implement your own authentication check

            if (isAuthenticated) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: '/services',
        name: 'ServicesModule',
        component: ServicesModule,
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            const isAuthenticated = checkAuthentication(); // Implement your own authentication check

            if (isAuthenticated) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: '/customers',
        name: 'CustomerModule',
        component: CustomerModule,
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            const isAuthenticated = checkAuthentication(); // Implement your own authentication check

            if (isAuthenticated) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: '/addcustomer',
        name: 'AddCustomer',
        component: AddCustomer,
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            const isAuthenticated = checkAuthentication(); // Implement your own authentication check

            if (isAuthenticated) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: '/employees',
        name: 'WorkersModule',
        component: WorkersModule,
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            const isAuthenticated = checkAuthentication(); // Implement your own authentication check

            if (isAuthenticated) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: '/',
        name: 'WorkersModule',
        component: WorkersModule,
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            const isAuthenticated = checkAuthentication(); // Implement your own authentication check

            if (isAuthenticated) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: '/addworker',
        name: 'AddWorker',
        component: AddWorker,
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            const isAuthenticated = checkAuthentication(); // Implement your own authentication check

            if (isAuthenticated) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },

    {
        path: '/alerts',
        name: 'AlertComp',
        component: AlertComp,
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            const isAuthenticated = checkAuthentication(); // Implement your own authentication check

            if (isAuthenticated) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: '/mobile',
        name: 'MobileComp',
        component: MobileComp,
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            const isAuthenticated = checkAuthentication(); // Implement your own authentication check

            if (isAuthenticated) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    }, {
        path: '/jobdetails',
        name: 'JobDetails',
        component: JobDetails,
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            const isAuthenticated = checkAuthentication(); // Implement your own authentication check

            if (isAuthenticated) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: '/equipments',
        name: 'EquipmentsModule',
        component: EquipmentComp,
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            const isAuthenticated = checkAuthentication(); // Implement your own authentication check

            if (isAuthenticated) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: '/inventory',
        name: 'InventoryModule',
        component: InventoryModule,
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            const isAuthenticated = checkAuthentication(); // Implement your own authentication check

            if (isAuthenticated) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: '/reports',
        name: 'ReportsComp',
        component: ReportsComp,
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            const isAuthenticated = checkAuthentication(); // Implement your own authentication check

            if (isAuthenticated) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
