<template>
  <div class="">
    <v-row justify="space-between">
      <v-col class="d-flex" cols="12" sm="6">
        <v-btn class="mr-2 theme-color1" dark elevation="0" tile
        >
          <v-icon>mdi-account-group</v-icon>
        </v-btn
        >
        <h2 class="text-uppercase theme-font-color">{{ name }}</h2>
      </v-col>
      <v-col class="mb-1 d-flex" cols="12" sm="5">
        <v-text-field v-model="Filter.filterText" append-icon="mdi-magnify" class="custom-input" clearable
                      color="#64718A" dense
                      hide-details label="Search" outlined v-on:keyup="AllMethod"
                      @click:clear="(Filter.filterText = ''), GetAll()" @click:append="GetAll"></v-text-field>

        <v-btn class="ms-2 custom-btn theme-color1" dark elevation="0" height="40" link tile to="/addcustomer">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <div class="custom-line"></div>
    <v-data-table v-if="Customers == null" :headers="headers"
                  :items-per-page="Filter.pageSize"
                  class="custom-table elevation-0"
                  disable-sort hide-default-footer
    >
    </v-data-table>
    <v-data-table v-else :headers="headers"
                  :items="Customers"
                  :items-per-page="Filter.pageSize"
                  class="custom-table elevation-0"
                  disable-sort hide-default-footer
    >
      <template v-slot:[`item.fullName`]="{ item }">
        <router-link
            :to="{ name: 'AddCustomer', params: { item, editview } }"
            class="ms-0"
            style="text-decoration: underline"
        >
          {{ item.fullName }}
        </router-link>
      </template>
      <template v-slot:[`item.customerCategory`]="{item}">
        <span v-if="item.customerCategory == 100">Domestic</span>
        <span v-if="item.customerCategory == 110">Wholesale</span>
      </template>
      <template v-slot:[`item.phoneNumber`]="{item}">
        <span v-if="!isNullOrEmpty(item.phoneNumber)"> +1 {{ item.phoneNumber }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <router-link
            :to="{ name: 'AddCustomer', params: { item } }"
            class="ms-0"
        >
          <v-icon class="ms-2" color="primary darken-1" small @click="takeData(item)"
          >mdi-pencil
          </v-icon
          >
        </router-link>
        <v-icon
            class="ms-2"
            color="red"
            small
            @click="takeData(item), (deleteDialog = true)"
        >mdi-trash-can-outline
        </v-icon
        >
      </template>
    </v-data-table>
    <v-row align="center" class="no-gutters mt-2">
      <v-spacer style="width: 75%"></v-spacer>
      <span class="pagination-text">Rows per page</span>
      <v-select
          v-model="Filter.pageSize"
          :items="options"
          :menu-props="{ bottom: true, offsetY: true }"
          class="ma-2"
          dense
          style="width: 50px"
          @change="GetAll"
      ></v-select>
      <span v-if="pagination != null" class="mr-4 grey--text pagination-text">
        Page {{ pagination.page }} of {{ pagination.totalPages }}
      </span>
      <v-icon
          class="mr-1"
          color="primary darken-2"
          dark
          small
          @click="backwardButtonCall"
      >mdi-chevron-left
      </v-icon
      >
      <v-icon
          class="mr-1"
          color="primary darken-2"
          dark
          small
          @click="forwardButtonCall()"
      >mdi-chevron-right
      </v-icon
      >
    </v-row>
    <!-- Delete Dialog -->
    <v-dialog v-model="deleteDialog" max-width="400" persistent>
      <v-card>
        <v-card-title class="text-h5"> Delete Customer</v-card-title>
        <v-card-text
        >Are You sure? You want to delete
          <b
              v-if="CurrentCustomer != null"
              class="error--text text--darken-2"
          >{{ CurrentCustomer.fullName }}</b
          ></v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" elevation="0" text tile @click="deleteDialog = false">
            Cancel
          </v-btn>
          <v-btn
              color="error darken-2" elevation="0" tile
              @click="DeleteCustomer(), (deleteDialog = false)"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-progress-circular v-if="loader" class="custom-loader" color="#64718A" indeterminate width="2">
    </v-progress-circular>
  </div>
</template>
<script>
import axios from "axios";
import config from "../Configuration/config";

export default {
  name: "CustomerModule",
  data() {
    return {
      name: "Customers",
      deleteDialog: false,
      loader: false,
      editview: false,
      headers: [
        {
          text: "Full Name",
          value: "fullName",
        },
        {
          text: "Email Address",
          value: "email",
        },
        {
          text: "Phone",
          value: "phoneNumber",
        },
        {
          text: "City",
          value: "city",
        },
        {
          text: "Address",
          value: "address",
        },


        {
          text: "Notes",
          value: "notes",
        },
        {
          text: "Type",
          value: "customerCategory",
        },
        {text: "Actions", value: "actions", width: "100px", sortable: false},
      ],
      options: [
        {text: "10", value: 10},
        {text: "25", value: 25},
        {text: "50", value: 50},
        {text: "100", value: 100},
      ],
      Customers: null,
      CurrentCustomer: null,


      pagination: null,
      pageNumOnSearch: "",

      totalRecords: "",
      Filter: {
        CompanyId: axios.defaults.headers.common.CompanyId,
        filterText: "",
        pageSize: 10,
        page: 1,
      }
    };
  },
  methods: {
    isNullOrEmpty(value) {
      return value === null || value === undefined || value === '';
    },
    takeData(employee) {
      this.CurrentCustomer = employee;
    },
    async GetAll() {
      if (this.$route.name == "CustomerModule") {
        this.loader = true;
        try {
          let response = await axios({
            method: "post",
            url: config.apiBaseUrl + "/api/Customer/All",
            data: this.Filter,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          });
          this.Customers = response.data.data;
          this.pagination = response.data.pagination;
          this.loader = false;
        } catch (err) {
          console.log(err);
          this.loader = false;
          this.$toast.warning(err.message, {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnfocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
          })
          ;
        }
      }
    },
    async DeleteCustomer() {
      try {
        const fd = new FormData();
        fd.append("CustomerId", this.CurrentCustomer.customerId);
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Customer/Remove",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        this.$toast.warning(response.data.msg, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        })
        ;
        this.GetAll();
      } catch (error) {
        console.error(error);
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        })
        ;
      }
    },
    forwardButtonCall() {
      //it does not run on equal to condition
      if (this.Filter.page < this.pagination.totalPages) {
        this.Filter.page++;
        this.GetAll();
      } else {
        return;
      }
    },
    backwardButtonCall() {
      if (this.Filter.page > 1) {
        this.Filter.page--;
        this.GetAll();
      } else {
        return;
      }
    },
    AllMethod() {
      setTimeout(() => {
        this.loader = true;
      }, 500);
      setTimeout(this.GetAll, 1000);
    }
  },
  created() {
    this.$store.state.DisableCompany = false;
    this.GetAll();
    this.$root.$on("myEvent", () => {
      this.Filter.filterText = "";
      this.Filter.page = 1;
      this.GetAll();
    });
  },
};
</script>