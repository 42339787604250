<template>
  <div class="">
    <v-row justify="space-between">
      <v-col class="d-flex" cols="12" sm="6">
        <v-btn class="mr-2 theme-color1" dark elevation="0" link tile>
          <v-icon>mdi-bell</v-icon>
        </v-btn>
        <h2 class="text-uppercase theme-font-color">{{ name }}</h2>
      </v-col>
      <v-col class="mb-1 d-flex" cols="12" sm="5">
        <v-text-field v-model="Filter.filterText" append-icon="mdi-magnify" class="custom-input" clearable
                      color="#64718A" dense
                      hide-details label="Search" outlined
                      @keyup="GetAll" @click:clear="(Filter.filterText = ''), GetAll()"
                      @click:append="GetAll"></v-text-field>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ms-2 theme-color1"
                   dark elevation="0" tile v-bind="attrs" @click="bulkDialog = true" v-on="on">
              <v-icon>mdi-email-outline</v-icon>
            </v-btn>
          </template>
          <span>Send Pending Alerts</span>
        </v-tooltip>
        <v-btn class="ms-2 theme-color1" dark elevation="0" tile @click="ClearData(),addDialog = true">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <div class="custom-line"></div>
    <v-data-table v-if="Alerts == null" :headers="headers" :items-per-page="Filter.pageSize"
                  class="custom-table elevation-0"
                  disable-sort hide-default-footer>
    </v-data-table>
    <v-data-table v-else :headers="headers" :items="Alerts" :items-per-page="Filter.pageSize"
                  class="custom-table elevation-0"
                  disable-sort hide-default-footer>
      <template v-slot:[`item.sno`]="{index}">
        {{ index + 1 }}
      </template>
      <template v-slot:[`item.alertFrequency`]="{item}">
        <span v-if="item.alertFrequency == 1">Daily</span>
        <span v-if="item.alertFrequency == 2">Weekly</span>
        <span v-if="item.alertFrequency == 3">Bi Weekly</span>
        <span v-if="item.alertFrequency == 4">Quarter Month</span>
        <span v-if="item.alertFrequency == 5">Monthly</span>
        <span v-if="item.alertFrequency == 6">Half Year</span>
        <span v-if="item.alertFrequency == 7">Yearly</span>
      </template>
      <template v-slot:[`item.dueDate`]="{ item }">
        <span v-if="item.dueDate !== null"> {{ formatedDate(item.dueDate) }}</span>

      </template>
      <template v-slot:[`item.lastSent`]="{ item }">
        <span v-if="item.lastSent !== null">{{ formatedDate(item.lastSent) }}</span>
      </template>
      <template v-slot:[`item.isSent`]="{ item }">
        <v-chip v-if="item.isSent" class="success darken-2" dark label x-small>True</v-chip>
        <v-chip v-if="!item.isSent" class="red darken-2" dark label x-small>False</v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon :disabled="item.taskCompleted" class="ms-1" color="success" small @click="takeData(item),
          (completeDialog = !completeDialog)
        ">mdi-checkbox-marked
        </v-icon>
        <v-icon class="ms-2" color="primary darken-1" small
                @click="takeData(item), (emailDialog = true)">mdi-email-outline
        </v-icon>
        <v-icon class="ms-2" color="primary darkne-2" small @click="takeData(item), (addDialog = true)">mdi-pencil
        </v-icon>

        <v-icon class="ms-2" color="red" small @click="takeData(item), (deleteDialog = true)">mdi-trash-can-outline
        </v-icon>
      </template>
    </v-data-table>
    <v-row align="center" class="no-gutters mt-2">
      <v-spacer style="width: 75%"></v-spacer>
      <span class="pagination-text">Rows per page</span>
      <v-select v-model="Filter.pageSize" :items="options" :menu-props="{ bottom: true, offsetY: true }" class="ma-2"
                dense style="width: 50px"
                @change="GetAll"></v-select>
      <span v-if="pagination != null" class="mr-4 grey--text pagination-text">
        Page {{ pagination.page }} of {{ pagination.totalPages }}
      </span>
      <v-icon class="mr-1" color="primary darken-2" dark small @click="backwardButtonCall">mdi-chevron-left</v-icon>
      <v-icon class="mr-1" color="primary darken-2" dark small @click="forwardButtonCall()">mdi-chevron-right</v-icon>
    </v-row>
    <!-- Delete Dialog -->
    <v-dialog v-model="deleteDialog" max-width="400" persistent>
      <v-card>
        <v-card-title class="text-h5"> Delete Alert</v-card-title>
        <v-card-text>Are You sure? You want to delete alert for
          <b v-if="Alert != null" class="error--text text--darken-2">{{
              Alert.fullName
            }}</b></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="deleteDialog = false">
            Cancel
          </v-btn>
          <v-btn color="error darken-2" depressed tile @click="DeleteAlert(), (deleteDialog = false)">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Complete Task Dialog -->
    <v-dialog v-model="completeDialog" max-width="500" persistent>
      <v-card>
        <v-card-title class="text-h5"> Complete Task</v-card-title>
        <v-card-text>Are You sure? You want to complete <span v-if="Alert != null">{{ Alert.alertType }}. </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" dark elevation="0" tile @click="completeDialog = false">
            Cancel
          </v-btn>
          <v-btn color="success darken-2" dark elevation="0" tile @click="CompleteTask(), (completeDialog = false)">
            Complete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--     Add Dialog-->
    <v-dialog v-model="addDialog" max-width="700" persistent>
      <v-card outlined>
        <v-toolbar color="#efefef" dense elevation="0" flat tile>
          <v-btn class="mr-2 theme-color1" dark elevation="0" height="35px" tile @click="addDialog = false">
            <v-icon
                dense>mdi-undo-variant
            </v-icon>
          </v-btn>
          <v-toolbar-title>
            <h4 class="text-uppercase theme-font-color">New Alert</h4>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn class="ps-10 pe-10 login-btns theme-color1" dark elevation="0" tile
                 @click="AddOrUpdate(),(addDialog = false)">Save
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col class="ps-1 pe-1" cols="12" md="6">
                <v-select v-model="Alert.alertType" :items="alertTypes" :menu-props="{ bottom: true, offsetY: true }"
                          :return-object="false"
                          class="custom-input"
                          color="#64718A" dense hide-details item-text="value" item-value="value" label="Alert Type"
                          outlined></v-select>
              </v-col>
              <v-col class="ps-1 pe-1" cols="12" md="6">
                <v-select v-model="Alert.employeId" :items="Workers" :menu-props="{ bottom: true, offsetY: true }"
                          :return-object="false"
                          class="custom-input"
                          color="#64718A" dense hide-details item-text="fullName" item-value="employeId"
                          label="Employee"
                          outlined></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="ps-1 pe-1" cols="12" md="4">
                <v-select v-model="Alert.alertFrequency" :items="alertFrequencies"
                          :menu-props="{ bottom: true, offsetY: true }"
                          :return-object="false" class="custom-input" color="#64718A  " dense hide-details
                          item-text="text" item-value="value" label="Frequency" outlined
                          persistent-hint></v-select>
              </v-col>
              <v-col class="date-col ps-1 pe-1" cols="12" md="4">
                <span class="date-label">Due Date</span>
                <input v-model="Alert.dueDate" class="datepicker date-input custom-date" type="date"
                       width="100%">
              </v-col>
              <v-col class="ps-1 pe-1" cols="12" md="4">
                <v-text-field v-model.number="Alert.reminderDays" :rules="[rules.reminder]" class="custom-input"
                              color="#64718A" dense hide-details label="Reminder Days" outlined
                              placeholder="Reminder days (0 - 30)" type="number"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="ps-1 pe-1" cols="12" md="6">
                <v-text-field v-model="Alert.sentTo" :rules="[rules.email]" class="custom-input" color="#64718A" dense
                              hide-details label="Sent To"
                              outlined required></v-text-field>
              </v-col>
              <!--              <v-col cols="12" md="6" class="ps-1 pe-1">-->
              <!--                <v-text-field v-model.number="Alert.reminderDays" type="number" label="Reminder" required outlined color="#64718A" dense-->
              <!--                              hide-details class="custom-input"></v-text-field>-->
              <!--              </v-col>-->
              <v-col class="ps-1 pe-1" cols="12" md="6">
                <v-textarea v-model="Alert.description" auto-grow class="custom-input" color="#64718A" dense
                            hide-details
                            label="Description" outlined required rows="1"></v-textarea>
              </v-col>
            </v-row>
            <!--            <v-row>-->
            <!--              <v-col cols="12" md="12" class="ps-1 pe-1">-->
            <!--                <v-textarea rows="2" v-model="Alert.description" label="Description" required outlined color="#64718A"-->
            <!--                              dense auto-grow hide-details class="custom-input"></v-textarea>-->
            <!--              </v-col>-->
            <!--            </v-row>-->
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Mail dialog -->
    <v-dialog v-model="emailDialog" max-width="500" persistent>
      <v-card>
        <v-card-title class="text-h5"> Send Mail</v-card-title>
        <v-card-text>Are You sure? You want to send email to
          <b v-if="Alert.employee != null" class="theme-font-color">{{
              Alert.employee.fullName
            }}</b></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text tile @click="emailDialog = false">
            Cancel
          </v-btn>
          <v-btn class="theme-color1" elevation="0" tile @click="SendMail(), (emailDialog = false)">
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Bulk Mail dialog -->
    <v-dialog v-model="bulkDialog" max-width="500" persistent>
      <v-card>
        <v-card-title class="text-h5"> Send Pending Alerts</v-card-title>
        <v-card-text>Are You sure? You want to send bulk alerts
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text tile @click="bulkDialog = false">
            Cancel
          </v-btn>
          <v-btn class="theme-color1" elevation="0" tile @click="SendBulkMails(), (bulkDialog = false)">
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Progress Bar -->
    <v-progress-circular v-if="loader" class="custom-loader" color="primary darken-2" indeterminate
                         width="2"></v-progress-circular>
  </div>
</template>

<script>


import axios from "axios";
import config from "@/components/Configuration/config";
import moment from "moment";
import 'moment-timezone';

export default {
  name: "AlertComp",
  data() {
    return {
      name: "Alerts",
      deleteDialog: false,
      emailDialog: false,
      bulkDialog: false,
      valid: false,
      addDialog: false,
      completeDialog: false,
      menu: false,
      loader: false,
      alertFrequencies: [
        {text: "Daily", value: 1},
        {text: "Weekly", value: 2},
        {text: "Every Two Weeks", value: 3},
        {text: "Monthly", value: 4},
        {text: "Every Three Months", value: 5},
        {text: "Every Six Months", value: 6},
        {text: "Yearly", value: 7},
      ],
      headers: [
        {
          text: "#",
          value: "sno",
          align: 'start',
          width: '70px'
        },
        {
          text: "Alert Type",
          value: "alertType",
        },
        {
          text: "Description",
          value: "description",
          sortable: false,
        },
        {
          text: "Frequency",
          value: "alertFrequency",
          sortable: false,
        },

        {
          text: "Employee",
          value: "employee.fullName",
          sortable: false,
        },
        {
          text: "Sent To",
          value: "sentTo",
          sortable: false,
        },
        {
          text: "Due Date",
          value: "dueDate",
          sortable: false,
        },
        {
          text: "Days Before",
          value: "reminderDays",
          align: "center",
          sortable: false,
        },
        {
          text: "Last Sent",
          value: "lastSent",
          sortable: false,
        }, {
          text: "Is Sent",
          value: "isSent",
          sortable: false,
        },
        {text: "Actions", align: 'center', value: "actions", width: "140px", sortable: false},
      ],
      rules: {
        required: value => !!value || 'Required.',
        reminder: v => (v < 30) || 'Reminder days must be between 0 to 30',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
      alertTypes: null,
      Workers: null,
      CurrentAlert: null,
      //Model
      Alert: {
        companyId: axios.defaults.headers.common.CompanyId,
        "createdAt": "2023-01-26T11:20:57.026Z",
        "alertId": 0,
        "employeId": 0,
        "description": "",
        "alertType": "",
        "dueDate": new Date().toJSON().substring(0, 10),
        "alertFrequency": 1,
        "reminderDays": 0,
        "comments": "",
        "completed": false,
        "isSent": false,
        "lastSent": null,
        "sentTo": ""
      },
      options: [
        {text: "10", value: 10},
        {text: "25", value: 25},
        {text: "50", value: 50},
        {text: "100", value: 100},
      ],
      Alerts: null,
      pagination: null,
      pageNumOnSearch: "",
      filterText: "",
      totalRecords: "",
      Filter: {
        CompanyId: axios.defaults.headers.common.CompanyId,
        pageSize: 10,
        page: 1,
        filterText: "",
      },
    };
  },
  methods: {
    formatedDate(date) {
      return moment(date).format("MMM DD, yyyy");
    },
    takeData(alert) {
      this.Alert = alert;
      this.Alert.dueDate = this.Alert.dueDate.substring(0, 10);
    },
    ClearData() {
      this.Alert = {};
      this.Alert.dueDate = new Date().toJSON().substring(0, 10);
      this.Alert.companyId = axios.defaults.headers.common.CompanyId;
    },
    async GetAlertType() {
      try {
        this.loader = true;
        const fd = new FormData();
        fd.append("type", 40);
        let response = await axios.get(config.apiBaseUrl + "/api/KeyValue/All");
        this.alertTypes = response.data.filter((x) => x.type == 40);
        this.Alert.alertType = this.alertTypes[0]
        this.loader = false;
      } catch (error) {
        this.loader = false;
      }
    },
    async GetAll() {
      if (this.$route.name === "AlertComp") {
        try {
          this.loader = true;
          let response = await axios({
            method: "POST",
            url: config.apiBaseUrl + "/api/Alert/All",
            data: this.Filter,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          });
          this.Alerts = response.data.data;
          this.pagination = response.data.pagination;
          this.loader = false;
        } catch (error) {
          this.loader = false;
          this.$toast.warning(error.message, {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnfocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
          });
        }
      }
    },
    async GetWorkers() {
      if (this.$route.name == "AlertComp") {
        try {
          this.loader = true;
          let response = await axios.post(
              config.apiBaseUrl + "/api/Employee/All",
              {
                pageSize: this.pageSize,
                page: this.page,
                filterText: this.filterText,
                isActive: true,
              }
          );
          this.Workers = response.data.data;
          this.loader = false
        } catch (err) {
          this.loader = false;
        }
      }
    },
    async AddOrUpdate() {
      try {
        this.loader = true;
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Alert/AddOrUpdate",
          data: this.Alert,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        console.log(response);
        this.loader = false;
        this.GetAll();
      } catch (err) {
        this.loader = false;
        this.$toast.warning(err.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },
    async SendMail() {
      try {
        this.loader = true;
        const fd = new FormData();
        fd.append("alertId", this.Alert.alertId);
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Alert/SendAlertEmail",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        if (response.status == 200) {
          this.$toast.success("Email sent successfully", {
            position: "top-center",
            timeout: 1041,
            closeOnClick: true,
            pauseOnfocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
          })
        }
        this.loader = false;
        this.GetAll();
      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },
    async SendBulkMails() {
      try {
        this.loader = true;

        let response = await axios({
          method: "get",
          url: config.apiBaseUrl + "/api/Alert/SendBulkMails",
          headers: {
            "Content-Type": "none",
            Accept: "application/json",
          },
        });
        if (response.status == 200) {
          this.$toast.success("Email sent successfully", {
            position: "top-center",
            timeout: 1041,
            closeOnClick: true,
            pauseOnfocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
          })
        }
        this.loader = false;
      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },
    async DeleteAlert() {
      try {
        this.loader = true;
        const fd = new FormData();
        fd.append("alertId", this.Alert.alertId);
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Alert/Remove",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        console.log(response);
        this.loader = false;
        this.GetAll();
      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    }, async CompleteTask() {
      try {
        this.loader = true;
        const fd = new FormData();
        fd.append("alertId", this.Alert.alertId);
        let response = await axios({
          method: "post",
          url: config.apiBaseUrl + "/api/Alert/CompleteTask",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        console.log(response);
        this.loader = false;
        this.GetAll();
      } catch (error) {
        this.loader = false;
        this.$toast.warning(error.message, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnfocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },
    forwardButtonCall() {
      //it does not run on equal to condition
      if (this.Filter.page < this.pagination.totalPages) {
        this.Filter.page++;
        this.GetAll();
      } else {
        return;
      }
    },
    backwardButtonCall() {
      if (this.Filter.page > 1) {
        this.Filter.page--;
        this.GetAll();
      } else {
        return;
      }
    },
  },
  created() {
    this.$store.state.DisableCompany = false;
    this.GetAll();
    this.GetWorkers();
    this.GetAlertType();
    this.$root.$on("myEvent", () => {
      this.Filter.page = 1;
      this.Filter.filterText = "";
      this.GetAll();
    });
  }
}
</script>

<style scoped>

</style>