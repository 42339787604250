export default {
    //apiBaseUrl: "https://localhost:44396",
    //apiBaseUrl: "https://hvac-dev.azurewebsites.net",
    apiBaseUrl: "https://hvac-live.azurewebsites.net",
};


/*

function addZeroes(num) {
    return num.toLocaleString("en", {useGrouping: false, minimumFractionDigits: 2})
}*/
